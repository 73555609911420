import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AppButton from '../common/AppButton'
import AppTextField from '../common/AppTextField'
import Add from '@material-ui/icons/Add'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as AppConstants from '../../constants/app'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Snackbar from '@material-ui/core/Snackbar'
import DialogTitle from '@material-ui/core/DialogTitle'
import {SingleSelect} from 'react-select-material-ui'

import 'react-datepicker/dist/react-datepicker.css'
import './third-party.scss'

class question extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    code: '',
    errorMessage: '',
    isLoaded: false,
    auth_token: '',
    third_party: [],
    active_jobs: [],
    third_party_edit: [],
    job: '',
    question: '',
    open: false,
    openEdit: false,
    data: [],
    snackBar: {
      open: false,
      message: '',
      backgroundColor: '#1976d2',
    },
  }

  componentDidMount() {
    this.getAuthToken()
    this.fetchThridParty()
    this.activeJobs()
  }

  async activeJobs() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/active_jobs/`)
    if (response.data) {
      this.setState({
        active_jobs: response.data,
      })
    }
  }

  async getAuthToken() {
    let params = {
      username: 'admin@anewrecruit.com',
      password: 'Welcome123!!',
    }
    const response = await axios.post(`${AppConstants.API_BASE_URL}api-token-auth/`, params)
    if (response.data) {
      let token = response.data.token
      this.setState({
        auth_token: token,
      })
    }
  }

  async fetchThridParty() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/question/`)
    if (response.data) {
      this.setState({
        third_party: response.data.result,
      })
    }
  }

  async getThirdPartyById(id) {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/question/` + id)

    if (response.data) {
      
      this.setState({
        third_party_edit: response.data,
      })
    }
  }

  handleChange = e => {
    const { data, third_party_edit } = this.state
    const _data = { ...data }
    const edit_data = { ...third_party_edit }
    const prop = e.target.name
    _data[prop] = e.target.value
    edit_data[prop] = e.target.value
    this.setState({ data: _data, third_party_edit: edit_data, question:  e.target.value})
  }

  addThirdParty = () => {
    const _data = this.state.data
    var headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${this.state.auth_token}`,
    }

    axios
      .post(`${AppConstants.API_BASE_URL}recruit/question/`, _data, { headers: headers })
      .then(response => {
        this.setState({ isLoaded: true, open: false })
        let message = 'Question added successfully'
        this.openSnackBar(message, 'success-bar')
        this.fetchThridParty()
      })
      .catch(error => {
        this.setState({ isLoaded: true })
        let message = 'Please check your inputs, and try again'
        this.openSnackBar(message, 'error-bar')
        //this.resetState();
      })
  }

  EditThirdParty = () => {
    const _data = this.state.data
    const id = this.state.third_party_edit.id
    var headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${this.state.auth_token}`,
    }

    axios
      .put(`${AppConstants.API_BASE_URL}recruit/question/` + id + `/`, _data, { headers: headers })
      .then(response => {
        this.setState({ isLoaded: true, open: false, openEdit: false })
        let message = 'Updated successfully'
        this.openSnackBar(message, 'success-bar')
        this.fetchThridParty()
      })
      .catch(error => {
        this.setState({ isLoaded: true })
        let message = 'Please check your inputs, and try again'
        this.openSnackBar(message, 'error-bar')
        //this.resetState();
      })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  handleOpen = () => {
    this.setState({
      open: true,
    })
  }

  handleCloseEdit = () => {
    this.setState({
      openEdit: false,
    })
  }

  handleOpenEdit = id => {
    this.getThirdPartyById(id)
    this.setState({
      openEdit: true,
    })
  }

  openSnackBar = (message, bgcolor) => {
    const { snackBar } = this.state
    snackBar.open = true
    snackBar.message = message
    snackBar.backgroundColor = bgcolor
    this.setState({ snackBar: snackBar })
  }
  closeSnackBar = () => {
    const { snackBar } = this.state
    snackBar.open = false
    this.setState({ snackBar: snackBar })
  }

  deleteHandle = id => {
    var headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${this.state.auth_token}`,
    }

    axios
      .delete(`${AppConstants.API_BASE_URL}recruit/question/` + id, { headers: headers })
      .then(response => {
        this.setState({ isLoaded: true, open: false })
        let message = 'Deleted successfully'
        this.openSnackBar(message, 'success-bar')
        this.fetchThridParty()
      })
      .catch(error => {
        this.setState({ isLoaded: true })
        let message = 'Please check your inputs, and try again'
        this.openSnackBar(message, 'error-bar')
        //this.resetState();
      })
  }

  handleJobFilter = e => {
    let value = e
    
    this.setState({
        job: e.target.value

    })
    // const _data = []
    // const {actual_data} = this.state
    // const job_id = value
    // this.setState({
    //   isLoaded: false,
    //   page: 1,
    //   employe_filter: 0
    // })
    // if (job_id !== -1) {
    //   this.setState(
    //     {
    //       job_filter: value
    //     },
    //     function () {
    //       this.fetchCandidates()
    //     }
    //   )
    // } else {
    //   this.setState(
    //     {
    //       job_filter: 0
    //     },
    //     function () {
    //       this.fetchCandidates()
    //     }
    //   )
    // }
    // this.setState({
    //   placement_filter: false,
    //   submittals_filter: false,
    //   contract_placement_filter: false
    // })
  }

  activeJobsData = () => {
    return this.state.active_jobs.map((row, index) => (
    <option value={row.id} selected={this.state.third_party_edit.job==row.id ? true : false}>{row.title}</option>
    ))
  }

  thirdPartyHandle = () => {
    var i = 1
    return this.state.third_party.map((row, index) => (
      <TableRow>
        <TableCell>{index + i}</TableCell>
        <TableCell>{row.job_title}</TableCell>
        <TableCell>{row.label}</TableCell>
        <TableCell>
          <button onClick={() => this.handleOpenEdit(row.value)} className="edit-btn">
            Edit
          </button>
          <button onClick={() => this.deleteHandle(row.value)} className="del-btn">
            trash
          </button>
        </TableCell>
      </TableRow>
    ))
  }

  render() {
    return (
      <div style={{ marginTop: '70px', marginLeft: '70px' }}>
        <div className="row" style={{ width: '99%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <h3>JOB QUESTIONS</h3>
            </Grid>
            <Grid item xs={12} sm={9} style={{ textAlign: 'right' }}>
              <AppButton
                label="Add Question"
                color="secondary"
                onClick={this.handleOpen}
                icon={{ component: Add, position: 'right' }}
                className="btn-add-third-party"
              />
            </Grid>
          </Grid>

          <Table>
            <TableHead>
              <TableRow className="cotsumheader">
                <TableCell> # </TableCell>
                <TableCell> JOB NAME </TableCell>
                <TableCell> QUESTION</TableCell>
                <TableCell> ACTION </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.thirdPartyHandle()}</TableBody>
          </Table>
        </div>

        <Dialog fullWidth="true" maxWidth="sm" open={this.state.open} aria-labelledby="simple-dialog-title">
          <DialogTitle onClose={this.handleClose} id="simple-dialog-title">
            Add Question
          </DialogTitle>

          <DialogContent>
            <Table className="spplynow">
              <TableRow>
                <TableCell style={{ border: 'none' }}>

                <div class="app-text-field app-text-field-filter full-width"><div class="MuiFormControl-root MuiTextField-root AppTextField-root-264 AppTextField-root-264 input  MuiFormControl-fullWidth"><label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled" data-shrink="true">JOB</label><div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">

                <select name="job" onChange={this.handleChange} class="MuiInputBase-input MuiOutlinedInput-input">
                        {this.activeJobsData()}
                    </select>
<fieldset aria-hidden="true" class="PrivateNotchedOutline-root-323 MuiOutlinedInput-notchedOutline"><legend class="PrivateNotchedOutline-legendLabelled-325 PrivateNotchedOutline-legendNotched-326"><span></span></legend></fieldset></div></div></div>
           
           
                  <AppTextField fullWidth name="question" label="QUESTION" onChange={this.handleChange} />
                </TableCell>
              </TableRow>
            </Table>
          </DialogContent>
          <DialogActions>
            <AppButton onClick={this.addThirdParty} color="primary" label="ADD" />
            <AppButton onClick={this.handleClose} label="EXIT" color="secondary" autoFocus></AppButton>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth="true" maxWidth="sm" open={this.state.openEdit} aria-labelledby="simple-dialog-title">
          <DialogTitle onClose={this.handleCloseEdit} id="simple-dialog-title">
            Edit Question
          </DialogTitle>

          <DialogContent>
            <Table className="spplynow">
              <TableRow>
                <TableCell style={{ border: 'none' }}>

                <div class="app-text-field app-text-field-filter full-width"><div class="MuiFormControl-root MuiTextField-root AppTextField-root-264 AppTextField-root-264 input  MuiFormControl-fullWidth"><label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled" data-shrink="true">JOB</label><div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">

<select name="job"  value={this.state.third_party_edit.job}onChange={this.handleChange} class="MuiInputBase-input MuiOutlinedInput-input">
        {this.activeJobsData()}
    </select>
<fieldset aria-hidden="true" class="PrivateNotchedOutline-root-323 MuiOutlinedInput-notchedOutline"><legend class="PrivateNotchedOutline-legendLabelled-325 PrivateNotchedOutline-legendNotched-326"><span></span></legend></fieldset></div></div></div>


  <AppTextField fullWidth name="question" value={this.state.third_party_edit.question} label="QUESTION" onChange={this.handleChange} />
                  {/* <AppTextField
                    fullWidth
                    name="name"
                    label="NAME"
                    value={this.state.third_party_edit.name}
                    onChange={this.handleChange}
                  /> */}
                </TableCell>
              </TableRow>
            </Table>
          </DialogContent>
          <DialogActions>
            <AppButton onClick={this.EditThirdParty} color="primary" label="Update" />
            <AppButton onClick={this.handleCloseEdit} label="EXIT" color="secondary" autoFocus></AppButton>
          </DialogActions>
        </Dialog>

        <Snackbar
          className={this.state.snackBar.backgroundColor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackBar.open}
          autoHideDuration={2000}
          onClose={this.closeSnackBar}
          message={<span>{this.state.snackBar.message}</span>}
        />
      </div>
    )
  }
}

export default withStyles()(question)
