import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import Add from '@material-ui/icons/Add'
import Remove from '@material-ui/icons/Remove'
import './index.scss'

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 'auto',
  },
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,.03)',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0 12px 12px',
    },
    marginLeft: '12px',
  },
  expandIcon: {
    left: 0,
    right: 'auto',
  },
  expanded: {},
})(props => <MuiExpansionPanelSummary {...props} />)

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary'

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 5,
    textAlign: 'justify',
  },
}))(MuiExpansionPanelDetails)

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
})

class AppExpansionPanel extends React.Component {
  state = {
    expanded: '',
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    })
  }

  render() {
    const { expanded } = this.state
    const { data, classes } = this.props
    return (
      <div className={`app-expansion-panel ${classes.margin}`}>
        {data.map((d, i) => {
          return (
            <ExpansionPanel key={i} square expanded={expanded === d.expanded} onChange={this.handleChange(d.expanded)}>
              <ExpansionPanelSummary
                expandIcon={
                  this.state.expanded === d.expanded ? (
                    <div className="collapse">
                      <Remove />
                    </div>
                  ) : (
                    <Add />
                  )
                }
              >
                <Typography>{d.header}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div>{d.content}</div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })}
      </div>
    )
  }
}

export default withStyles(styles)(AppExpansionPanel)
