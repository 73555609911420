class CandidateModel {
  is_new = false
  id = ''
  name = ''
  recruiter = -1
  company = ''
  role = ''
  activity = ''
  industry = ''
  location = ''
  message = ''
  subject = ''
  sent_to = ''
  source = ''
  visit = ''
  invoice_no = ''
  paid = 0
  vacation = ''
  status_visa = ''
  communication = ''
  bill_rate_manula = ''
  phone = []
  email = []
  salary = null
  payRate = null
  expected_salary = ''
  availability = ''
  resume_url = ''
  resume = ''
  responded = ''
  start_date = undefined
  placement_date = undefined
  interview_date = undefined
  notes = ''
  status = 1
  visa_rate = 0
  confirmed = 0
  widthraw = 0
  client = null
  job = null
  linkedin_company = ''
  linkedin_role = ''
  linkedin_industry = ''
  linkedin_subject = ''
  linkedin_message = ''
  linkedin_msg_info = ''
  linkedin_url = ''
  send_signature_html = ''
  client_selected = false
  rc_name = ''
  account_manager_id = ''
  third_party_id = ''
  client_fee_percentage = ''
  client_fee_amount = ''
  rc_commission_percent = ''
  am_commission_percent = ''
  net_revenue = ''
  client_contact_name = []
  client_contact_phone = []
  client_contact_email = []
  // job_id = '';
  job_third_party_bill_rate = 0
  job_visa_1099_bill_rate = 0
  pay_rate = null
  bill_rate = null
  name = ''
  email = []
  phone = []
  title = ''
  skills = ''


  constructor(is_new) {
    this.is_new = is_new
  }
}

export default CandidateModel
