import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import config from '../../config/index'
import { receiveFetchData } from '../../actions/index'
import { withStyles } from '@material-ui/core/styles'
import AppButton from '../common/AppButton'
import AppDataTable from '../common/AppDataTable'
import Add from '@material-ui/icons/Add'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as AppConstants from '../../constants/app'
import axios from 'axios'
import JobForm from './Form'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import './job.scss'

import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import JobModel from './JobModel'
import { CSVLink } from 'react-csv'
import AppSelect from '../common/AppSelect'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    marginBottom: '50px',
  },
  child: {
    backgroundColor: '#fff',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

class Jobs extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    actual_data: [],
    selectedChild: [],
    pageHeading: 'All Jobs',
    data: [],
    open: false,
    logopen: true,
    clients: [],
    jobs: [],
    users: [],
    account_managers: [],
    opened: [],
    isLoaded: false,
    auth_token: '',
    placement_filter: false,
    submittals_filter: false,
    contract_placement_filter: false,
    page: 1,
    searchvale: 'AAAALL',
    total_record: 0,
    recruiter_filter: 0,
    client_filter: 0,
    job_filter: 0,
    stage_filter: 0,
    total_sub: 0,
    total_inter: 0,
    total_send: 0,
    total_perma: 0,
    total_cont: 0,
    employe_filter: 0,
    total_sub_class: 0,
    total_inter_class: 0,
    total_send_class: 0,
    total_perma_class: 0,
    total_cont_class: 0,
    third_party: [],
    columns: [],
    client_filter: '',
    is_active: 1,
  }

  componentWillMount() {
    // TODO: Make it one call, as I couldn't merge that to a single endpoint due to short time
    if (this.props.match.params.id) {
      const id = this.props.match.params.id
      this.setState({ client_filter: id })
    }
  }
  componentDidMount() {
    // TODO: Make it one call, as I couldn't merge that to a single endpoint due to short time
    if (this.props.match.params.id) {
      const id = this.props.match.params.id
      this.setState({ client_filter: id })
    }
    this.fetchJobs()
    this.getAuthToken()
    this.fetchClients(this.state.is_active)
    this.fetchUsers()
  }

  async getAuthToken() {
    let params = {
      username: 'admin@anewrecruit.com',
      password: 'Welcome123!!',
    }
    const response = await axios.post(`${AppConstants.API_BASE_URL}api-token-auth/`, params)
    if (response.data) {
      let token = response.data.token
      this.setState({
        auth_token: token,
      })
    }
  }

  async fetchUsers() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/user/`)
    if (response.data && response.data['successful']) {
      this.setState({ users: response.data.result })
    }
  }

  async fetchClients() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client/?jobs=true&is_active=true`)
    if (response.data) {
      this.setState({ clients: response.data.result.clients })
      this.setState({ category: response.data.result.category })
      this.setState({ skill: response.data.result.skills })
    }
  }

  handleChange = (e) => {
    console.log('e.target.value', e.target.value)
    this.setState(
      () => ({
        client_filter: e.target.value === -1 ? '' : e.target.value,
      }),
      () => {
        this.fetchJobs()
      }
    )
  }

  handleStatusChange = (e) => {
    this.setState(
      () => ({
        is_active: e.target.value,
      }),
      () => {
        this.fetchJobs()
      }
    )
  }

  async fetchJobs() {
    const { client_filter, is_active } = this.state
    const response = await axios.get(
      `${AppConstants.API_BASE_URL}recruit/job/?client=${client_filter}&status=${is_active}`
    )
    if (response.data) {
      const jobs = []
      response.data.forEach((c) => jobs.push(this.formatIT(c)))
      this.setState({ jobs: jobs, isLoaded: true }, function () {})
    }
  }

  formatIT = (job) => {
    console.log(job)
    job.salary = job.min_salary + '-' + job.max_salary

    if (job.publish_at) job.publish_at_f = this.formatToCalendarDate(job.publish_at)

    if (job.status == 1) job.status = 'Active'
    else job.status = 'Draft'

    if (job.employment_type == 1) job.employment_type = 'Contract Job'
    else if (job.employment_type == 2) job.employment_type = 'Contract To Hire'
    else job.employment_type = 'Permanent Job'
    let _skills = []
    if (job.skill_name && job.skill_name.length) {
      job.skill_name.forEach(function (sk) {
        _skills.push(sk.title)
      })
    }
    job.skill_value = _skills.toString()
    job.job_notes = this.extractContent(job.notes)
    job.job_no_of_jobs = job.no_of_jobs

    return job
  }

  extractContent = (s) => {
    const span = document.createElement('span')
    span.innerHTML = s
    return span.textContent || span.innerText
  }

  formatToCalendarDate = (date) => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`

    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`

    return `${month}/${_date}/${date.getFullYear()}`
  }

  addJobsPopup = () => {
    this.setState({
      data: [],
      open: true,
    })
  }

  editJob = (d) => {
    this.setState({
      data: d,
      open: true,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
    this.fetchJobs()
  }

  addNewJobInline = () => {
    const { jobs } = this.state
    console.log('_data', jobs)

    const _data = [...jobs]
    if (!_data.find((c) => c.is_new === true)) {
      _data.splice(0, 0, new JobModel(true))
      console.log('_data', _data)
      this.setState({ jobs: _data })
    }
  }

  formatDate = (date) => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`
    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`
    let hour = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    return `${month}/${_date}/${date.getFullYear()} (${hour}:${minutes}:${seconds})`
  }

  // START: MAZ

  // get columns() {
  //   return [
  //     { id: 'id', numeric: false, disablePadding: false, label: 'Unique ID' },
  //     { id: 'client_name', numeric: false, disablePadding: false, label: 'Client' },
  //     { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
  //     { id: 'min_salary', numeric: false, disablePadding: false, label: 'Salary1' },
  //     { id: 'max_salary', numeric: false, disablePadding: false, label: 'Salary2' },
  //     { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  //     { id: 'category_name', numeric: false, disablePadding: false, label: 'Category' },
  //     { id: 'publish_at_f', numeric: false, disablePadding: false, label: 'Publish Date' },
  //     { id: 'skill_value', numeric: false, disablePadding: false, label: 'Skills' },
  //     { id: 'candidates', numeric: false, disablePadding: false, label: '#Candidates' },
  //     { id: 'priority', numeric: false, disablePadding: false, label: 'Priority' },
  //     { id: 'created', numeric: false, disablePadding: false, label: 'Created' },
  //     { id: 'modified', numeric: false, disablePadding: false, label: 'Updated' },
  //   ]
  // }

  get columns() {
    return [
      { id: 'id', numeric: false, disablePadding: false, label: 'Job ID' },
      { id: 'priority', numeric: false, disablePadding: false, label: 'Priority' },
      { id: 'client_name', numeric: false, disablePadding: false, label: 'Client' },
      { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
      { id: 'city', numeric: false, disablePadding: false, label: 'Location' }, // Loc
      { id: 'min_salary', numeric: false, disablePadding: false, label: 'Salary1' },
      { id: 'max_salary', numeric: false, disablePadding: false, label: 'Salary2' },
      { id: 'job_ote', numeric: false, disablePadding: false, label: 'OTE' }, // ote
      { id: 'job_notes', numeric: false, disablePadding: false, label: 'Notes' }, // notes
      { id: 'total_pipelines', numeric: false, disablePadding: false, label: 'Pipeline' }, //
      { id: 'candidates', numeric: false, disablePadding: false, label: '#Candidates' },
      { id: 'total_internal_submittals', numeric: false, disablePadding: false, label: 'Internal Submittal' }, //
      { id: 'total_client_submittals', numeric: false, disablePadding: false, label: 'Client Submittal' }, //
      { id: 'total_interviews', numeric: false, disablePadding: false, label: 'Interview' }, //
      { id: 'publish_at_f', numeric: false, disablePadding: false, label: 'Published' },
      { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
      { id: 'jd_link', numeric: false, disablePadding: false, label: 'JD Link' }, //
      { id: 'job_fee', numeric: false, disablePadding: false, label: 'Fee' }, //
      { id: 'job_no_of_jobs', numeric: false, disablePadding: false, label: 'No of Jobs' }, //
      { id: 'potential_rev', numeric: false, disablePadding: false, label: 'Potential Rev' }, //
      { id: 'category_name', numeric: false, disablePadding: false, label: 'Category' },
      // { id: 'skill_value', numeric: false, disablePadding: false, label: 'Skills' },
      { id: 'created', numeric: false, disablePadding: false, label: 'Created' },
      { id: 'modified', numeric: false, disablePadding: false, label: 'Updated' },
    ]
  }

  // END: MAZ

  csvHeaders = () => {
    return [
      { key: 'id', label: 'Unique ID' },
      { key: 'client_name', label: 'Client' },
      { key: 'title', label: 'Title' },
      { key: 'city', label: 'City' },
      { key: 'state', label: 'State' },
      { key: 'country', label: 'Country' },
      { key: 'min_salary', label: 'Salary1' },
      { key: 'max_salary', label: 'Salary2' },
      { key: 'status', label: 'Status' },
      { key: 'category_name', label: 'Category' },
      { key: 'publish_at_f', label: 'Publish Date' },
      { key: 'long_description', label: 'Long Description' },
      { key: 'short_description', label: 'Short Description' },
      { key: 'hides', label: 'Hidden' },
      { key: 'skill_value', label: 'Skills' },
      { key: 'intake_call', label: 'Intake Call' },
      { key: 'template_email', label: 'Email Template' },
      { key: 'notes', label: 'Notes' },
      { key: 'created', label: 'Created' },
      { key: 'modified', label: 'Updated' },
    ]
  }

  getChildRow = (d) => {
    let pad = { padding: '15px' }
    return (
      <TableRow key={`child-${d.id}`}>
        <TableCell colSpan="18" style={pad}>
          <JobForm
            users={this.state.users}
            clients={this.state.clients}
            category={this.state.category}
            skill={this.state.skill}
            token={this.state.auth_token}
            close={this.handleClose}
            data={d}
            userId={this.props.auth.user_id}
          />
        </TableCell>
      </TableRow>
    )
  }

  getChildRows = () => {
    const {
      state: { jobs },
      getChildRow,
    } = this
    return jobs.map((d) => {
      return {
        id: d.id,
        element: getChildRow(d),
      }
    })
  }

  getProgress = () => (this.state.isLoaded ? false : <LinearProgress />)

  render() {
    const {
      columns,
      state: { data, opened },
      props: { classes },
      getChildRows,
      getProgress,
    } = this
    return (
      <div className={classes.root}>
        <div className="filter-section">
          <div className="search-section" style={{ display: 'inline-flex' }}></div>
          <div style={{ marginTop: '20px' }}>
            <AppButton
              label="Add New Job"
              color="secondary"
              onClick={this.addNewJobInline}
              icon={{ component: Add, position: 'right' }}
            />
          </div>
        </div>

        <div className={classes.card}>
          {getProgress()}

          <Dialog fullWidth="true" maxWidth="500" open={this.state.open} aria-labelledby="simple-dialog-title">
            <DialogTitle onClose={this.handleClose} id="simple-dialog-title">
              {' '}
              Add Job
            </DialogTitle>

            <DialogContent>
              <JobForm
                users={this.state.users}
                clients={this.state.clients}
                category={this.state.category}
                skill={this.state.skill}
                token={this.state.auth_token}
                close={this.handleClose}
                data={this.state.data}
              />
            </DialogContent>
          </Dialog>
          <h1 className="page-heading">{this.state.pageHeading} </h1>
          <div class="test-container" style={{ position: 'absolute', top: '160px', right: '2%', zIndex: 9 }}>
            <div class="cust-container">
              <div class="c-left" style={{ marginRight: '0px' }}>
                <AppSelect
                  fullWidth
                  id="client"
                  label={'Client'}
                  value={parseInt(this.state.client_filter)}
                  name="client_filter"
                  onChange={this.handleChange}
                  items={this.state.clients}
                  // helperText={this.state.job_client.length === 0 ? ' ' : 'This field is required'}
                  // error={this.state.job_client.length === 0 ? false : true}
                />
              </div>
              <div class="c-right">
                <AppSelect
                  fullWidth
                  id="status"
                  label={'Status'}
                  value={parseInt(this.state.is_active)}
                  name="is_active"
                  onChange={this.handleStatusChange}
                  items={[
                    { value: 1, label: 'Active' },
                    { value: 2, label: 'InActive' },
                  ]}
                  // helperText={this.state.job_client.length === 0 ? ' ' : 'This field is required'}
                  // error={this.state.job_client.length === 0 ? false : true}
                />
                &nbsp;&nbsp;
                <CSVLink data={this.state.jobs} headers={this.csvHeaders()} filename={'jobs.csv'}>
                  Export Csv
                </CSVLink>
              </div>
            </div>
          </div>
          <AppDataTable
            selected={this.state.selectedChild}
            columns={columns}
            data={this.state.jobs}
            opened=""
            label="Job List"
            editRow={this.editJob}
            childRows={this.getChildRows()}
          />
        </div>
      </div>
    )
  }
}

Jobs.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
  return {
    ...state,
    // candidates: state.data.candidates,
    // jobs: state.data.jobs,
    // skills: state.data.skills,
    auth: state.auth,
  }
}
const mapDispatchToProps = {
  receiveFetchData: receiveFetchData,
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(withRouter(Jobs))

// export default withStyles(styles)(Jobs)
