import React, {useEffect, useState} from 'react'
import axios from 'axios'
import moment from 'moment'
import _cloneDeep from 'lodash/cloneDeep'
import _sumBy from 'lodash/sumBy'
import _filter from 'lodash/filter'
import Highcharts from 'highcharts'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import CloseIcon from '@material-ui/icons/Close'
import Select from '@material-ui/core/Select'
import * as AppConstants from '../../constants/app'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {InputLabel} from '@material-ui/core'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid'
import StatsticsTable from './StatsticsTable'
import ContentLoader from 'react-content-loader'
import {useHistory} from "react-router"

const stageOptions = [
  {value: 1, label: 'Leads'},
  {value: 2, label: 'Submittals'},
  {value: 3, label: 'Sendout'},
  {value: 4, label: 'Interview'},
  {value: 5, label: 'Hired/Placement'},
  {value: 6, label: 'Rejected'},
  {value: 7, label: 'On Hold'},
  {value: 14, label: 'Closed'}
]

const initialChartOptionsCircle = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    backgroundColor: '#dedede',
    type: 'pie'
  },
  title: {
    text: ''
  },
  tooltip: {
    pointFormat: '{series.name}: <h3>{point.custom}</h3>'
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: <h3>{point.custom}<h3>'
      }
    }
  },
  series: [
    {
      name: 'Total',
      colorByPoint: true,
      data: []
    }
  ]
}

const chartOptionsArea = {
  chart: {
    type: 'bar'
  },

  title: {
    text: 'Candidate Listing'
  },
  subtitle: {
    text: ''
  },
  xAxis: {
    categories: []
  },
  yAxis: {
    labels: {
      formatter: function () {
        return this.value
      }
    }
  },

  series: [
    {
      name: 'Candidate',
      data: []
    }
  ]
}

const chartColor = {
  submittlas: 'rgb(149, 206, 255)',
  permanantPlacement: 'rgb(247, 163, 92)',
  leads: 'blue',
  sendout: 'rgb(169, 255, 150)',
  interview: 'rgb(67, 67, 72)',
  closed: 'purple',
}

const DashboardNew = ({classes}) => {

  const history = useHistory()
  const [chart, setChartData] = useState({
    total_leads: 0,
    total_closed: 0,
    total_onold: 0,
    total_rejected: 0,
    total_hired: 0,
    total_sendout: 0,
    total_submitals: 0,
    total_interview: 0,
    chartOptionsArea: chartOptionsArea,
    chartOptionsCircle2: initialChartOptionsCircle,
    chartOptionsCircle: initialChartOptionsCircle,
    allCandidateData: [],
    resultByRecruiter: []
  })

  const [payload, setPayload] = useState({
    recruiter: 0,
    currentStage: 0,
    date: {startDate: moment().subtract(1, 'days').format('l'), endDate: moment().format('l')}
  })
  const [loading, setLoading] = useState(false)
  const [recruiters, setRecruiters] = useState([])
  const inputLabel = React.useRef(null);

  const handleChangePayload = (e) => {
    const {name, value} = e.target

    setPayload({
      ...payload,
      [name]: value
    })
  }

  useEffect(() => {
    if (recruiters.length > 0 && chart.allCandidateData.length > 0) {
      setDataForChart(chart.allCandidateData, recruiters, payload.date)
    }
  }, [recruiters, chart.allCandidateData])

  useEffect(() => {
    const {allCandidateData} = chart
    const {date} = payload
    let dates = {
      startDate: date.startDate === date.endDate ? 0 : date.startDate,
      endDate: date.startDate === date.endDate ? 0 : date.endDate
    }
    if (payload.recruiter) {
      let rr = _filter(recruiters, o => o.value === payload.recruiter)
      setDataForChart(allCandidateData, rr, dates)
    } else {
      setDataForChart(allCandidateData, recruiters, dates)
    }
  }, [payload.recruiter])

  useEffect(() => {
    const {startDate, endDate} = payload.date
    let rr = recruiters.slice()

    if (payload.recruiter) {
      rr = _filter(recruiters, o => o.value === payload.recruiter)
    }

    if (startDate !== endDate) {
      setDataForChart(chart.allCandidateData, rr, {startDate, endDate})
    } else {
      setDataForChart(chart.allCandidateData, rr)
    }
  }, [payload.date])

  console.log('chart', chart)

  const setDataForChart = (result, recruiters = [], dates = {startDate: 0, endDate: 0}) => {
    const revenueMonthWise = []
    const currentSelectedYear = '2020'
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']
    const chartOptionsArea = {...chart.chartOptionsArea}
    let resultByRecruiter = []
    const {startDate, endDate} = dates
    let data = result.slice()
    if (startDate !== 0 && endDate !== 0) {
      data = _filter(data, o => moment(o.created).isBetween(startDate, endDate))
    }

    if (recruiters.length > 0) {
      recruiters.map((r) => {
        if (r.value === -1 || r.label.toLowerCase() === 'none') return;

        let filter = {recruiter: r.value}
        const total_leads = _filter(data, {status: 1, ...filter}).length
        const total_submitals = _filter(data, {status: 2, ...filter}).length
        const total_sendout = _filter(data, {status: 3, ...filter}).length
        const total_interview = _filter(data, {status: 4, ...filter}).length
        const total_hired = _filter(data, {status: 5, ...filter}).length
        const total_rejected = _filter(data, {status: 6, ...filter}).length
        const total_onold = _filter(data, {status: 7, ...filter}).length
        const total_closed = _filter(data, {status: 14, ...filter}).length
        return resultByRecruiter.push({
          name: r.label,
          client_id: r.value,
          closed: {
            id: 14,
            total: total_closed
          },
          onhold: {
            id: 7,
            total: total_onold
          },
          rejected: {
            id: 6,
            total: total_rejected
          },
          hired: {
            id: 5,
            total: total_hired
          },
          interview: {
            id: 4,
            total: total_interview
          },
          leads: {
            id: 1,
            total: total_leads
          },
          submitals: {
            id: 2,
            total: total_submitals
          },
          sendout: {
            id: 3,
            total: total_sendout
          }
        })
      })
    }


    const total_leads = _sumBy(resultByRecruiter, o => o.leads.total)
    const total_submitals = _sumBy(resultByRecruiter, o => o.submitals.total)
    const total_sendout = _sumBy(resultByRecruiter, o => o.sendout.total)
    const total_interview = _sumBy(resultByRecruiter, o => o.interview.total)
    const total_hired = _sumBy(resultByRecruiter, o => o.hired.total)
    const total_rejected = _sumBy(resultByRecruiter, o => o.rejected.total)
    const total_onold = _sumBy(resultByRecruiter, o => o.onhold.total)
    const total_closed = _sumBy(resultByRecruiter, o => o.closed.total)

    // result.forEach(function(item) {
    //   var monthNam = monthNames[new Date(item.created).getMonth()]
    //   var yearValue = new Date(item.created).getFullYear()
    //   if (currentSelectedYear == yearValue) {
    //     if (monthNam in revenueMonthWise) {
    //       revenueMonthWise[monthNam] = revenueMonthWise[monthNam] + 1
    //     } else {
    //       revenueMonthWise[monthNam] = 1
    //     }
    //   }
    // })

    chartOptionsArea.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptionsArea.series[0].data = Object.values(revenueMonthWise)
    //chartOptions.series[1].data   = Object.values(revenueMonthWiseContract);

    let chartOption1 = _cloneDeep(chart.chartOptionsCircle)
    chartOption1.series[0].data = [
      {
        name: 'Submited',
        y: total_submitals,
        custom: total_submitals,
        colorIndex: chartColor.submittlas
      },
      {
        name: 'Interview',
        y: total_interview,
        custom: total_interview,
        colorIndex: chartColor.interview
      },
      {
        name: 'Sendout',
        y: total_sendout,
        custom: total_sendout,
        colorIndex: chartColor.sendout
      },
      {
        name: 'Permanent Placements',
        y: total_hired,
        custom: total_hired,
        colorIndex: chartColor.permanantPlacement
      }
    ]

    setChartData({
      ...chart,
      allCandidateData: result,
      total_leads,
      total_closed,
      total_onold,
      chartOptionsArea,
      total_rejected,
      total_hired,
      total_interview,
      total_sendout,
      total_submitals,
      chartOptionsCircle: chartOption1,
      resultByRecruiter
    })
  }

  const fetchCandidateData = async () => {
    setLoading(true)
    const allCandidate = await axios.get(`${AppConstants.API_BASE_URL}recruit/candidate/data/`)
    const {data: {result}} = allCandidate
    setChartData({
      ...chart,
      allCandidateData: result
    })
    setLoading(false)
  }

  useEffect(() => {

    fetchCandidateData()

    axios.get(`${AppConstants.API_BASE_URL}recruit/user/`)
      .then((response) => {
        if (response.data && response.data['successful']) {
          setRecruiters(response.data.result)
        }
      })

  }, [])

  const handleEvent = (event, {startDate, endDate}) => {
    if (startDate && endDate) {
      setPayload({
        ...payload,
        date: {
          startDate: moment(startDate).format('l'),
          endDate: moment(endDate).format('l')
        }
      })
    }
  }

  const renderDateText = () => {
    const {date: {startDate, endDate}} = payload
    if (startDate === endDate) {
      return 'Select Date'
    } else {
      return `${startDate} - ${endDate}`
    }
  }

  const handleClearDates = () => {
    setPayload({
      ...payload,
      date: {
        startDate: moment().format('l'),
        endDate: moment().format('l')
      }
    })
  }

  const gotoCandidate = (query = '') => {
    history.push(`/filtered-candidates${query}`)
  }

  if (loading) {
    return (
      <ContentLoader/>
    )
  }

  return (
    <div style={{ background: '#dedede', minHeight: '100vh' }}>
      <div style={{paddingTop: '70px', paddingLeft: '70px'}}>
      <div className="row" style={{width: '99%'}}>
        <div className='dashboard-container' style={{width: '100%'}}>
          <div className='dashboard-heading'><h2>Coders Activity Report</h2></div>
          <div className='dashboard-action-bar'>
            <div className="dashboard-control">
              <FormControl
                size='small'
                variant="outlined"
                className='select-recruiter select-common'
              >
                <InputLabel ref={inputLabel} htmlFor='recruiter-select'>Recruiter</InputLabel>
                <Select
                  name='recruiter'
                  placeholder='Recruiter'
                  value={payload.recruiter}
                  labelWidth={60}
                  onChange={handleChangePayload}
                  inputProps={{
                    id: 'recruiter-select'
                  }}
                >
                  <MenuItem value={0}>All</MenuItem>
                  {
                    recruiters.map((r) => (
                      <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className="dashboard-control">
              <DateRangePicker
                startDate={payload.date.startDate}
                endDate={payload.date.endDate}
                onApply={handleEvent}
              >
                <button className='date-range-text'>
                  <div> {renderDateText()} </div>
                  <div onClick={handleClearDates} className='close-icon-btn'><CloseIcon/></div>
                </button>
              </DateRangePicker>
            </div>
          </div>
          <div className='dashboard-body'>
            <div className='dashboard-pie-chart'>
              <Grid item xs={12} sm={3} md={5} style={{ margin: '0 auto' }}>
                {
                  loading
                    ?
                    <ContentLoader style={{width: '100%'}}/>
                    :
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={chart.chartOptionsCircle}
                      className='chart-custom-css'
                      containerProps={{style: {height: '400px', fill: '#dedede'}}}

                    />
                }

              </Grid>
            </div>
            <div className='dashboard-chart-stastics'>
              <StatsticsTable
                gotoCandidate={gotoCandidate}
                loading={loading}
                payload={payload}
                chartColor={chartColor}
                data={chart.resultByRecruiter}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default DashboardNew