import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AppTextField from "../../common/AppTextField";
import AppButton from "../../common/AppButton";
import AppSelect from "../../common/AppSelect";
import Snackbar from "@material-ui/core/Snackbar";
import axios from "axios";
import * as AppConstants from "../../../constants/app";
import "react-datepicker/dist/react-datepicker.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
  root: {
    padding: 0,
    height: "320px"
  },
  margin: {
    margin: theme.spacing.unit
  },
  header: {
    textAlign: "center",
    marginTop: 0
  },
  contactInfo: {
    display: "flex",
    padding: "0 16px",
    height: "300px"
  },
  phone: {
    flex: 1
  },
  email: {
    flex: 1
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});
var divStyle = {
  "border-radius": "8px",
  "margin-right": "15px",
  border: "1px solid #dcdcdc",
  padding: 0,
  "padding-left": "7px"
};

var spanstyle = {
  color: "red"
};

class AddHourSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: new Date(),
      end_date: new Date()
    };
  }

  state = {
    start_date: "",
    end_date: "",
    hours: "",
    message: "",
    open: false,
    className: "",
    paydate: "",
    client_paid: "",
    candidate_paid: "",
    dateDisable: "",
    shoerrr: false
  };

  closeSnackBar = () => {
    this.setState({ open: false });
  };
  /*
    componentDidMount() {
        // TODO: Make it one call, as I couldn't merge that to a single endpoint due to short time
        alert(this.props.dateDisable)
        this.setState({
          dateDisable:this.props.dateDisable
        })
    }*/

  handleChange = e => {
    if (this.state.paydate == "" || this.state.client_paid == "") {
      this.setState({
        open: true,
        message: "Please enter all required field",
        className: "error-bar"
      });
    } else {
      const _hdata = {
        hours: this.props.hours,
        client_paid: this.state.client_paid,
        pay_date: this.state.paydate
      };
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${this.props.token}`
      };

      axios
        .post(
          `${AppConstants.API_BASE_URL}recruit/candidate/weekhours/`,
          _hdata
        )
        .then(response => {
          this.setState({
            open: true,
            message: "Hours Added Succesfully",
            className: "success-bar"
          });
          let th = this;
          setTimeout(function() {
            th.props.closeDialog("client_contact_hours: false");
          }, 700);
        })
        .catch(error => {
          let message = "Please check your inputs, and try again";
          this.setState({
            open: true,
            message: message,
            className: "error-bar"
          });
        });
    }
  };

  setHours = e => {
    if (e.target.value) {
      this.setState({
        hours: e.target.value
      });
    }
  };

  setClientPay = e => {
    if (e.target.value) {
      this.setState({
        client_paid: e.target.value
      });
    }
  };

  setCanPay = e => {
    if (e.target.value) {
      this.setState({
        candidate_paid: e.target.value
      });
    }
  };

  setPayDate = e => {
    if (e.target.value) {
      this.setState({
        paydate: e.target.value
      });
    }
  };

  handleChangedate = ({ startDate, endDate }) => {
    startDate = startDate || this.state.start_date;
    endDate = endDate || this.state.end_date;
    if (this.getDatesvalid(startDate, endDate)) {
      this.setState({ start_date: startDate, end_date: endDate });
      this.setState({ shoerrr: false });
    } else {
      this.setState({ shoerrr: true });
    }
  };

  handleChangeStart = startDate => this.handleChangedate({ startDate });

  handleChangeEnd = endDate => this.handleChangedate({ endDate });

  getDatesvalid = (startDate, endDate) => {
    let dates = true;
    let fromd = startDate;
    let to = endDate;
    let check = this.props.dateDisable[0];
    let check2 = this.props.dateDisable[this.props.dateDisable.length - 1];

    if (check.getTime() <= to.getTime() && check.getTime() >= fromd.getTime()) {
      dates = false;
    }

    if (
      check2.getTime() <= to.getTime() &&
      check2.getTime() >= fromd.getTime()
    ) {
      dates = false;
    }
    return dates;
  };
  formatDate = date => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  formatToCalendarDate = date => {
    if (date) {
      if (typeof date === "string") date = new Date(date);
      let month = date.getMonth() + 1;
      if (month.toString().length === 1) month = `0${month}`;

      let _date = date.getDate().toString();
      if (_date.length === 1) _date = `0${_date}`;

      return `${month}/${_date}/${date.getFullYear()}`;
    } else {
      return;
    }
  };

  componentDidMount() {
    const { props } = this;
    console.log("HOURSSSSSS");
    console.log(props.hours);
  }

  render() {
    const { props, handleChange } = this;
    const { data } = this.state;
    let Thours = 0;
    let hours = props.data.filter(function(user) {
      //alert(props.hours.indexOf(user.id.toString()))
      if (props.hours.indexOf(user.id.toString()) >= 0) {
        Thours = Thours + parseFloat(user.hours);
      }
      return props.hours.indexOf(user.id.toString()) >= 0;
    });

    return (
      <React.Fragment>
        <Dialog
          open={props.open}
          maxWidth="md"
          fullWidth={true}
          onClose={() => props.closeDialog("client_contact_hours: false")}
          aria-labelledby="dialog-moats"
          className={props.classes.margin}
        >
          <DialogTitle
            onClose={() => props.closeDialog("client_contact_hours: false")}
            id="simple-dialog-title"
          >
            Hours Summary
          </DialogTitle>

          <DialogContent className={props.classes.root}>
            <Snackbar
              className={this.state.className}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              open={this.state.open}
              autoHideDuration={700}
              message={<span>{this.state.message}</span>}
            />

            <h2 className={props.classes.header}> Hours Summary </h2>

            <Table>
              <TableHead>
                <TableRow className="cotsumheader">
                  <TableCell align="right">Start Date</TableCell>
                  <TableCell align="right">End Date</TableCell>
                  <TableCell align="right">Hours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hours.map(row => (
                  <TableRow>
                    <TableCell align="right">
                      {this.formatToCalendarDate(row.start_date)}
                    </TableCell>
                    <TableCell align="right">
                      {this.formatToCalendarDate(row.end_date)}
                    </TableCell>
                    <TableCell align="right">{row.hours} </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell align="right"> Total Hours </TableCell>
                  <TableCell align="right"> - </TableCell>
                  <TableCell align="right">{Thours.toFixed(0)} </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <div className={props.classes.phone}>
              <AppTextField
                label="Pay Date"
                fullWidth
                name="pay_date"
                value={this.state.paydate}
                type="date"
                onChange={this.setPayDate}
              />
            </div>

            <div className={props.classes.phone}>
              <AppSelect
                fullWidth
                name="client_paid"
                value={this.state.client_paid}
                onChange={this.setClientPay}
                label="Client Paid"
                items={[
                  { value: 1, label: "Yes" },
                  { value: 0, label: "No" }
                ]}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <AppButton
              label="UPDATE"
              color="primary"
              onClick={this.handleChange}
            />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AddHourSummary);
