import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Root from './Root'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Root)
