import React from 'react'
import propTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

import NotFound from '../NofFound'

const classStyles = {
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  container: {
    padding: '24px',
    width: '100%',
  },
}

const RouteFactory = (props) => {
  const { routesConfig, routeList, classes } = props
  return (
    <Switch>
      {routesConfig.map((route) => {
        const RouteComponent = routeList[route.component]
        return <Route exact={route.exact} key={route.path} path={route.path} component={RouteComponent} />
      })}
      <Route component={NotFound} />
    </Switch>
  )
}

RouteFactory.propTypes = {
  routesConfig: propTypes.array.isRequired,
  routeList: propTypes.object.isRequired,
  classes: propTypes.object.isRequired,
}

export default withStyles(classStyles)(RouteFactory)
