import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import * as AppConstants from '../../constants/app'
import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import AppButton from '../common/AppButton'
import AppExpansionPanel from '../common/AppExpansionPanel'
// import CandidateForm from './Form'
import CandidateTabForm from './CandidateTabForm'
import '../../assets/css/custom.css'
import LinearProgress from '@material-ui/core/LinearProgress'
import { green } from '@material-ui/core/colors'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const getVisaLabel = (id) => {
  try {
    switch (Number(id)) {
      case 1:
        return 'None'
      case 2:
        return 'Citizenship'
      case 3:
        return 'Green Card'
      case 5:
        return '1099'
      case 6:
        return 'Third party'
      case 7:
        return 'Other'
      default:
        return 'Super Admin'
    }
  } catch {
    return 'None'
  }
}
function formatDate(date) {
  if (date) {
    var date = date.split('/')
    var f = date[2] + '-' + date[0] + '-' + date[1]
    return f
  }
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
  },
  tabP: {
    width: '100% !important',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    background: '#eaeaea !important',
  },
  tableCell: {
    color: '#5161bc',
  },
  tableCellBlack: {
    color: 'black',
  },
  linearProgressRoot: {
    height: '20px',
    width: '50px',
    borderRadius: '3px',
    // border: "0.5px solid #b8b8b8"
  },
  greyColorPrimary: {
    backgroundColor: '#b8b8b8',
  },
  yellowBarColorPrimary: {
    backgroundColor: 'yellow',
  },
  greenBarColorPrimary: {
    backgroundColor: 'green',
  },
  redBarColorPrimary: {
    backgroundColor: 'red',
  },
  customMarginBottom: {
    verticalAlign: 'top',
    padding: '0 0 0 10px',
  },
}))

export default function VerticalTabs({
  data,
  clients,
  jobs,
  users,
  leads_filter,
  contact_filter,
  account_managers,
  third_parties,
  isSubmittal,
  token,
  onCandidateUpdate,
  placementFilter,
  contractPlacementFilter,
  showHideApproval,
  skills,
  user_id,
  t_open
}) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [expandDescription, setExpandDescription] = React.useState({})
  const [contactsByClient, setContactsByClient] = React.useState([])
  const [answers, setAnswers] = React.useState([])
  // const [currentAddress, setCurrentAddress] = React.useState({});

  useEffect(() => {
    fetchContactsByClientId()
    getAnswer()
  }, [])

  async function getAnswer() {
    let candidateID = data.id
    let th = this
    if (candidateID) {
      const response = await axios.get(
        // `${AppConstants.API_BASE_URL}recruit/answer/`+ candidateID
        `${AppConstants.API_BASE_URL}recruit/job/${candidateID}/questions/answers`
      )
      let resp = ''
      if (response.data.results) {
        resp = response.data.results
      } else {
        resp = response.data
      }
      setAnswers(resp)
    }
  }

  async function fetchContactsByClientId() {
    let id = data.client
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/clients/` + id + '/')
    setContactsByClient(response.data.contact)
    // this.setState({
    //   contactsByClient: response.data.contact,
    //   client: response.data,
    //   currentAddress: response.data.address,
    // })
  }
  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }
  const setColorPrimary = (score) => {
    // return ((score && score < 11 && score > 6) ? classes.greenColorPrimary :
    //     (score && score < 7 && score > 3) ? classes.yellowColorPrimary :
    //         classes.redColorPrimary);
    return classes.greyColorPrimary
  }
  const setBarColorPrimaryForTens = (score) => {
    return score && score < 11 && score > 6
      ? classes.greenBarColorPrimary
      : score && score < 7 && score > 3
        ? classes.yellowBarColorPrimary
        : classes.redBarColorPrimary
  }
  const setBarColorPrimaryForHundreds = (score) => {
    return score && score < 101 && score > 69
      ? classes.greenBarColorPrimary
      : score && score < 70 && score > 39
        ? classes.yellowBarColorPrimary
        : classes.redBarColorPrimary
  }
  const client = [...clients].find((j) => j['value'] === data.client)
  const client_detail = client ? client['detail'] : {}
  const job = [...jobs].find((j) => j['value'] === data.job)
  const job_detail = job ? job['detail'] : {}

  return (
    <div className={classes.root} style={{ color: '#5161bc' }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        textColor="secondary"
        inkBarStyle={{ border: 'blue' }}
        onChange={handleTabChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
        style={{ minWidth: '250px' }}
      >
        <Tab className={value === 0 ? 'custom-active-tab' : ''} label="Info" {...a11yProps(0)} />
        {/* <Tab className={value === 1 ? "custom-active-tab" : ""} label="Company & Jobs" {...a11yProps(1)} /> */}
        <Tab className={value === 1 ? 'custom-active-tab' : ''} label="Company" {...a11yProps(1)} />
        <Tab className={value === 2 ? 'custom-active-tab' : ''} label="Jobs" {...a11yProps(2)} />

        <Tab className={value === 3 ? 'custom-active-tab' : ''} label="Linkedin" {...a11yProps(3)} />
        <Tab className={value === 4 ? 'custom-active-tab' : ''} label="Scores" {...a11yProps(4)} />
        {answers.length > 0 ? (
          <Tab className={value === 5 ? 'custom-active-tab' : ''} label="Expedite" {...a11yProps(5)} />
        ) : (
          ''
        )}

        {/* <Tab label="Scores" {...a11yProps(4)} /> */}
      </Tabs>
      <TabPanel className={classes.tabP} value={value} index={0}>
        <CandidateTabForm
          data={data}
          clients={clients}
          jobs={jobs}
          users={users}
          leads_filter={leads_filter}
          contact_filter={contact_filter}
          account_managers={account_managers}
          third_parties={third_parties}
          isSubmittal={isSubmittal}
          token={token}
          onCandidateUpdate={onCandidateUpdate}
          placementFilter={placementFilter}
          contractPlacementFilter={contractPlacementFilter}
          showHideApproval={showHideApproval}
          showJobs={false}
          showInfo={true}
          showLinkedin={false}
          skills={skills}
          user_id={user_id}
          t_open={t_open}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Table style={{ width: '600px !important', maxWidth: '600px !important' }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Box fontWeight="fontWeightBold" m={1}>
                  {'Company Details'}
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Box fontWeight="fontWeightBold" m={1}>
                  {'Information'}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}>Company Info</TableCell>
              <TableCell>{client_detail.company_info}</TableCell>
            </TableRow>

            {/* <TableRow>
                            <TableCell>Contact</TableCell>
                            <TableCell>{data.linkedin_msg_info}</TableCell>
                        </TableRow> */}

            <TableRow>
              <TableCell className={classes.tableCell}>Competition</TableCell>
              <TableCell>{client_detail.competitors}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Selling Point</TableCell>
              <TableCell>{client_detail.selling_points}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Products</TableCell>
              <TableCell>{client_detail.products}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Table style={{ width: '600px !important', maxWidth: '600px !important' }}>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Box fontWeight="fontWeightBold" m={1}>
                  {'Job'}
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Box fontWeight="fontWeightBold" m={1}>
                  {'Information'}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}> Job Details</TableCell>
              <TableCell>
                <div className="job-details">
                  <h2 className="title">{job_detail.title}</h2>
                  {/* <div className="location">{job_detail.location}</div>
                                    <div className="salary">
                                        ${job_detail.min_salary}K - ${job_detail.max_salary}K
                                    </div>
                                    <p>{job_detail.short_description}</p> */}
                  {/* <div className="link" onClick={() => this.onLongDescriptionClick(job_detail)}>
                                        Long Description
                                    </div> */}
                  {/* {this.getLongDescription(job_detail)} */}
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Client Name</TableCell>
              <TableCell>{job_detail.client_name}</TableCell>
            </TableRow>
            {job_detail.location && (
              <TableRow>
                <TableCell className={classes.tableCell}>location</TableCell>
                <TableCell>{job_detail.location}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className={classes.tableCell}>Salary</TableCell>
              <TableCell>{` $${job_detail.min_salary}K - $${job_detail.max_salary}K`}</TableCell>
            </TableRow>
            {job_detail.short_description && (
              <TableRow>
                <TableCell className={classes.tableCell}>Short Description</TableCell>
                <TableCell>{job_detail.short_description}</TableCell>
              </TableRow>
            )}
            {job_detail.long_description && (
              <TableRow>
                <TableCell className={classes.tableCell}>Long Description</TableCell>
                <TableCell>{<div dangerouslySetInnerHTML={{ __html: job_detail.long_description }}></div>}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className={classes.tableCell}>Intake Call & Interview</TableCell>
              <TableCell>{job_detail.intake_call}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Candidate Feedback</TableCell>
              <TableCell>{job_detail.candidate_feedback}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Companies Hiring</TableCell>
              <TableCell>{job_detail.companies_hiring}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Template Email</TableCell>
              <TableCell>{job_detail.template_email}</TableCell>
              {/* <TableCell>Hello Bhai jan</TableCell> */}
            </TableRow>

            {/* <TableRow>
                            <TableCell>Hiring Manager</TableCell>
                            <TableCell>
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow className="cotsumheader">
                                                <TableCell> Name </TableCell>
                                                <TableCell> Title </TableCell>
                                                <TableCell> Email </TableCell>
                                                <TableCell> Phone </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.contactsByClient.map((contact) => (
                                                <TableRow>
                                                    <TableCell>{contact.name}</TableCell>
                                                    <TableCell>{contact.title}</TableCell>
                                                    <TableCell>
                                                        {contact.email.map((email) => (
                                                            <p>{email.email}</p>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell>
                                                        {contact.phone.map((phone) => (
                                                            <p>{phone.phone}</p>
                                                        ))}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </TableCell>
                       
                        </TableRow> */}
          </TableBody>
        </Table>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Table style={{ width: '600px !important', maxWidth: '600px !important' }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Box fontWeight="fontWeightBold" m={1}>
                  {'LinkedIn Details'}
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Box fontWeight="fontWeightBold" m={1}>
                  {'Information'}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}>Company</TableCell>
              <TableCell>{data.linkedin_company}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Recent Activity Messages</TableCell>
              <TableCell>{data.linkedin_msg_info}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Role</TableCell>
              <TableCell>{data.linkedin_role}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Industry</TableCell>
              <TableCell>{data.linkedin_industry}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Location</TableCell>
              <TableCell>{data.location}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Subject</TableCell>
              <TableCell>{data.linkedin_subject}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Email Message</TableCell>
              <TableCell>{data.linkedin_message}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Linkedin Background</TableCell>
              <TableCell>{data.linkedin_background}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Message Detail Section</TableCell>
              {/* <TableCell>{data.linkedin_background}</TableCell> */}
              <TableCell></TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Linkedin Experience1</TableCell>
              <TableCell>{data.linkedin_experience1}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Linkedin Experience2</TableCell>
              <TableCell>{data.linkedin_experience2}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Linkedin Experience3</TableCell>
              <TableCell>{data.linkedin_experience3}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Linkedin Education</TableCell>
              <TableCell>{data.linkedin_education}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Linkedin skills</TableCell>
              <TableCell>{data.linkedin_skills}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Linkedin Language</TableCell>
              <TableCell>{data.linkedin_language}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TabPanel>

      <TabPanel value={value} index={4}>
        <Table style={{ width: '600px !important', maxWidth: '600px !important' }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Box fontWeight="fontWeightBold" m={1}>
                  {'SCORES'}
                </Box>
              </TableCell>
              <TableCell>
                <Box fontWeight="fontWeightBold" m={1}>
                  {' '}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}>Score</TableCell>
              <TableCell>
                <span>
                  <label>
                    <LinearProgress
                      variant="determinate"
                      // color="secondary"
                      // valueBuffer={10}
                      width
                      value={parseInt(data.score) * 10}
                      classes={{
                        root: classes.linearProgressRoot,
                        colorPrimary: setColorPrimary(data.score),
                        barColorPrimary: setBarColorPrimaryForTens(data.score),
                      }}
                    />
                  </label>
                  <label className={classes.customMarginBottom}>{(data.score && data.score) + '/10'}</label>
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Score 1</TableCell>
              <TableCell>
                <span>
                  <label>
                    <LinearProgress
                      variant="determinate"
                      // color="secondary"
                      // valueBuffer={10}
                      width
                      value={parseInt(data.score1)}
                      classes={{
                        root: classes.linearProgressRoot,
                        colorPrimary: setColorPrimary(data.score1),
                        barColorPrimary: setBarColorPrimaryForHundreds(data.score1),
                      }}
                    />
                  </label>
                  <label className={classes.customMarginBottom}>{(data.score1 && data.score1) + '/100'}</label>
                </span>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell}>Score 2</TableCell>
              <TableCell>
                <span>
                  <label>
                    <LinearProgress
                      variant="determinate"
                      // color="secondary"
                      // valueBuffer={10}
                      width
                      value={parseInt(data.score2)}
                      classes={{
                        root: classes.linearProgressRoot,
                        colorPrimary: setColorPrimary(data.score2),
                        barColorPrimary: setBarColorPrimaryForHundreds(data.score2),
                      }}
                    />
                  </label>
                  <label className={classes.customMarginBottom}>{(data.score2 && data.score2) + '/100'}</label>
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Score 3</TableCell>
              <TableCell>
                <span>
                  <label>
                    <LinearProgress
                      variant="determinate"
                      // color="secondary"
                      // valueBuffer={10}
                      width
                      value={parseInt(data.score3)}
                      classes={{
                        root: classes.linearProgressRoot,
                        colorPrimary: setColorPrimary(data.score3),
                        barColorPrimary: setBarColorPrimaryForHundreds(data.score3),
                      }}
                    />
                  </label>
                  <label className={classes.customMarginBottom}>{(data.score3 && data.score3) + '/100'}</label>
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Score 4</TableCell>
              <TableCell>
                <span>
                  <label>
                    <LinearProgress
                      variant="determinate"
                      // color="secondary"
                      // valueBuffer={10}
                      width
                      value={parseInt(data.score4)}
                      classes={{
                        root: classes.linearProgressRoot,
                        colorPrimary: setColorPrimary(data.score4),
                        barColorPrimary: setBarColorPrimaryForHundreds(data.score4),
                      }}
                    />
                  </label>
                  <label className={classes.customMarginBottom}>{(data.score4 && data.score4) + '/100'}</label>
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Score 5</TableCell>
              <TableCell>
                <span>
                  <label>
                    <LinearProgress
                      variant="determinate"
                      // color="secondary"
                      // valueBuffer={10}
                      width
                      value={parseInt(data.score5)}
                      classes={{
                        root: classes.linearProgressRoot,
                        colorPrimary: setColorPrimary(data.score5),
                        barColorPrimary: setBarColorPrimaryForHundreds(data.score5),
                      }}
                    />
                  </label>
                  <label className={classes.customMarginBottom}>{(data.score5 && data.score5) + '/100'}</label>
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <CandidateTabForm
          data={data}
          clients={clients}
          jobs={jobs}
          users={users}
          leads_filter={leads_filter}
          contact_filter={contact_filter}
          account_managers={account_managers}
          third_parties={third_parties}
          isSubmittal={isSubmittal}
          token={token}
          onCandidateUpdate={onCandidateUpdate}
          placementFilter={placementFilter}
          contractPlacementFilter={contractPlacementFilter}
          showHideApproval={showHideApproval}
          showJobs={true}
          showInfo={false}
          showLinkedin={false}
          skills={skills}
          user_id={user_id}
        />
      </TabPanel>

      {/* <TabPanel value={value} index={5}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Box fontWeight="fontWeightBold" m={1}>
                                    {"Questions"}
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box fontWeight="fontWeightBold" m={1}>
                                    {"Answers"}
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {answers.length == 0 && <TableRow> <TableCell> No Question Found</TableCell> <TableCell> </TableCell></TableRow>}
                        {answers.length > 0 && answers.map((key, value) => {

                            return (
                                <TableRow>
                                    <TableCell>{key.question.question}</TableCell>
                                    <TableCell>{key.answer}</TableCell>
                                </TableRow>
                            )

                        })}


                    </TableBody>
                </Table>
            </TabPanel> */}

      {/* <TabPanel value={value} index={4} >
                <CandidateTabForm
                    data={data}
                    clients={clients}
                    jobs={jobs}
                    users={users}
                    leads_filter={leads_filter}
                    contact_filter={contact_filter}
                    account_managers={account_managers}
                    third_parties={third_parties}
                    isSubmittal={isSubmittal}
                    token={token}
                    onCandidateUpdate={onCandidateUpdate}
                    placementFilter={placementFilter}
                    contractPlacementFilter={contractPlacementFilter}
                    showHideApproval={showHideApproval}
                />
            </TabPanel>
        */}
    </div>
  )
}
