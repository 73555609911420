import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  root: {
    padding: 0,
  },
  header: {
    textAlign: 'center',
    marginTop: 0,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

class LinkedInInfoDialog extends React.Component {
  render() {
    const { props } = this
    const { data } = props
    return (
      <React.Fragment>
        <Dialog
          open={props.open}
          maxWidth="md"
          fullWidth={true}
          onClose={() => props.closeDialog('linkedIn')}
          aria-labelledby="dialog-moats"
          className={props.classes.margin}
        >
          <DialogTitle onClose={() => props.closeDialog('linkedIn')} id="simple-dialog-title">
            Candidate LinkedIn Info
          </DialogTitle>

          <DialogContent className={props.classes.root}>
            <Table className={props.classes.root}>
              <TableHead>
                <TableRow>
                  <TableCell>LinkedIn Details</TableCell>
                  <TableCell>Information</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Company</TableCell>
                  <TableCell>{data.linkedin_company}</TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell>Recent Activity Messages</TableCell>
                  <TableCell>{data.linkedin_msg_info}</TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell>Role</TableCell>
                  <TableCell>{data.linkedin_role}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Industry</TableCell>
                  <TableCell>{data.linkedin_industry}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Location</TableCell>
                  <TableCell>{data.location}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Subject</TableCell>
                  <TableCell>{data.linkedin_subject}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email Message</TableCell>
                  <TableCell>{data.linkedin_message}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Linkedin Background</TableCell>
                  <TableCell>{data.linkedin_background}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Message Detail Section</TableCell>
                  {/* <TableCell>{data.linkedin_background}</TableCell> */}
                  <TableCell>{data.linkedin_msg_info}</TableCell>

                </TableRow>
                

                <TableRow>
                  <TableCell>Linkedin Experience1</TableCell>
                  <TableCell>{data.linkedin_experience1}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Linkedin Experience2</TableCell>
                  <TableCell>{data.linkedin_experience2}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Linkedin Experience3</TableCell>
                  <TableCell>{data.linkedin_experience3}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Linkedin Education</TableCell>
                  <TableCell>{data.linkedin_education}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Linkedin skills</TableCell>
                  <TableCell>{data.linkedin_skills}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Linkedin Language</TableCell>
                  <TableCell>{data.linkedin_language}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(LinkedInInfoDialog)
