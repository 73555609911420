// Local Host URL
//export const API_BASE_URL = 'http://localhost:8000/api/';
// export const API_BASE_URL = 'http://127.0.0.1:8000/api/';
// export const SERVER_BASE_URL = 'http://localhost:8000';


//Production Server URL without HTTPS
// export const API_BASE_URL = 'http://44.224.101.255:8001/api/';
// export const SERVER_BASE_URL = 'http://35.166.252.112:8001/';

//Staging Server URL
export const API_BASE_URL = 'https://resume-anewcrm.enclosedit.com/api/';
export const SERVER_BASE_URL = 'https://resume-anewcrm.enclosedit.com/';