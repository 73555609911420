import React, {useEffect, useState} from 'react'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import EditIcon from '@material-ui/icons/Edit'
import AppTextField from "../../common/AppTextField"
import {makeStyles} from "@material-ui/core/styles"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios'
import * as AppConstants from '../../../constants/app'

const useStyles = makeStyles({
  moatsContainer: {
    display: 'block'
  },
  lineh: {
    lineHeight: '1.7'
  },
  notesDiv: {
    display: 'flex',
  },
  marginR: {
    marginRight: '20px',
    width: '47.3%'
  }
})

const SubmittalsMoats = (props) => {
  
  const classes = useStyles()
  const [moatsValue, setMoatValue] = useState('')

  useEffect(() => {
    setMoatValue(getValue())
  }, []);

  /****Slider Code Starts */
  
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(parseInt(props.data.responded)===1?true:false);
  const [isResponse,setResponse] = useState(false);
  const [responseMsg,setResponseMsg] = useState("Error occured");

  const handleModalOpen = async() => {
      if(!checked){
        setOpen(true);
        setChecked(true);
      }
      else{
        
        //disapproving the candidate
        let token = sessionStorage.getItem('token');
        var headers = {
          'Content-Type': 'application/json',
          Authorization: token,
        }
        const id = props.data.id;
        const response = await axios.post(
          `${AppConstants.API_BASE_URL}recruit/candidate/approval-status/`,
          {
            headers: headers,
            params: {
              id:id,
              approval:0,
              type:"Approval"
            },
          }
        )
        if (response.data && response.data['successful']) {
          setChecked(false);
          setResponse(true);
          setResponseMsg("Candidate disapproved successfully");
        }else{
          setResponse(true);
          setResponseMsg("Error while disapproving candidate");
        }
      }
        
  };

  const handleModalClose = () => {
    setOpen(false);
    setChecked(false);
  };

  const handelCandidateApproval = async() =>{

    //approving the candidate
    let token = sessionStorage.getItem('token');
    var headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    }
   const id = props.data.id;
    const response = await axios.post(
      `${AppConstants.API_BASE_URL}recruit/candidate/approval-status/`,
      {
        headers: headers,
        params: {
          "id":id,
          "approval":1,
          "type":"Approval"
        },
      }
    )
    if (response.data && response.data['successful']) {
      setOpen(false);
      setResponse(true);
      setResponseMsg("Candidate approved successfully");
    }else{
      setResponse(true);
      setResponseMsg("Error while approving candidate");
    }
    
  }

  const handleResponseModalClose = () =>{
    setResponse(false);
    setResponseMsg("");
  }

  /****Slider Code Ends */

  const getValue = () => {
    let required = ''
    const lineBreak = '\n'
    const { data } = props
    let _value = []
    _value.push('Name: ')
    if (data.name) _value.push(`${data.name}`)
    else required = required + 'Name, '
    _value.push(lineBreak)

    _value.push('Role: ')
    if (data.job_title) _value.push(`${data.job_title}`)
    else required = required + 'Role, '
    _value.push(lineBreak)

    _value.push('Email: ')
    if (data.email && data.email.length > 0) _value.push(`${data.email.map(e => e.value).join(', ')}`)
    else required = required + 'Email, '
    _value.push(lineBreak)

    _value.push('Phone: ')
    if (data.phone && data.phone.length > 0) _value.push(`${data.phone.map(p => p.value).join(', ')}`)
    else required = required + 'Phone, '
    _value.push(lineBreak)

    if (data.employment_type == 1) {
      _value.push('Pay Rate: ')
      if (data.pay_rate) _value.push(`${data.pay_rate}`)
      else required = required + 'Pay Rate, '
      _value.push(lineBreak)

      if (data.bill_rate_manula == 1) {
        _value.push('Custom Bill Rate: ')
        if (data.bill_rate) _value.push(`${data.bill_rate}`)
        else required = required + 'Bill Rate, '
        _value.push(lineBreak)
      } else {
        _value.push('Bill Rate: ')
        if (data.bill_rate) _value.push(`${data.bill_rate}`)
        else required = required + 'Bill Rate, '
        _value.push(lineBreak)
      }
    } else {
      _value.push('Salary: ')
      if (data.salary) _value.push(`${data.salary}`)
      else required = required + 'Salary, '
      _value.push(lineBreak)
    }
    _value.push('Availability: ')
    if (data.availability) _value.push(`${data.availability}`)
    else required = required + 'Availability, '
    _value.push(lineBreak)

    _value.push('Location: ')
    if (data.location) _value.push(`${data.location}`)
    else required = required + 'Location, '
    _value.push(lineBreak)

    _value.push('Vacation: ')
    if (data.vacation) _value.push(`${data.vacation}`)
    else required = required + 'Vacation, '
    _value.push(lineBreak)

    _value.push('Communication: ')
    if (data.communication) _value.push(`${data.communication}`)
    else required = required + 'Communication, '
    _value.push(lineBreak)

    _value.push('Start Date: ')
    if (data.start_date) _value.push(`${data.start_date}`)
    else required = required + 'Start Date, '
    _value.push(lineBreak)

    _value.push('Visa Status: ')
    if (data.status_visa) _value.push(`${data.status_visa}`)
    else required = required + 'Visa Status, '
    _value.push(lineBreak)

    _value.push('Candidate Activity: ')
    if (data.activity) _value.push(`${data.activity}`)
    else required = required + 'Candidate Activity, '
    _value.push(lineBreak)

    _value.push('Linkedin: ')
    if (data.linkedin_url) _value.push(`${data.linkedin_url}`)
    else required = required + 'Linkedin, '
    _value.push(lineBreak)

    _value.push('Notes: ')
    if (data.notes) _value.push(`${data.notes}`)
    else required = required + 'Notes, '

    return _value.join('')
  }

  return (
    <div className={classes.moatsContainer}>
    <div style={{ width: '55vw' }}>
      <div>
        <AppTextField
          value={moatsValue}
          name="value"
          classes={classes.lineh}
          onChange={() => {}}
          rows={16}
          multiline={true}
          autoFocus
          margin="dense"
          id="txtMoats"
          label="MOATS"
          fullWidth
          readOnly
        />
      </div>
      <div className={classes.notesDiv}>
        <div className={classes.marginR}>
          <AppTextField
            label="Notes"
            value={props.data.notes}
            fullWidth
            rows={10}
            multiline={true}
            name="notes"
            readOnly
          />
        </div>
        <div className={classes.marginR}>
          <AppTextField
            label="Submittal Notes"
            value={props.data.submittal_notes}
            fullWidth
            rows={10}
            multiline={true}
            name="submittal_notes"
            readOnly
          />
        </div>
      </div>
    </div>
    <div className="d-flex align-items-center">
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<EditIcon />}
        onClick={props.setEditSubmittal}
      >
        Edit
      </Button>
      {
        props.data.status_display === "Submittals" && props.showHideApproval &&
        <FormControlLabel
        className="ml-4 mb-0"
          control={
            <Checkbox
              checked={checked}
              onChange={handleModalOpen}
              name="checkedB"
              color="primary"
            />
          }
          label="Approve"
        />
      }
      <div>
        <Dialog
          open={open}
          onClose={handleModalClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Approve Candidate"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to approve this candidate?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handelCandidateApproval} color="primary">
              OK
            </Button>
            <Button onClick={handleModalClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isResponse}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Response"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {responseMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResponseModalClose} color="primary">
              OK
            </Button>
            
          </DialogActions>
        </Dialog>
      </div>
    </div>
    </div>

  )
}

export default SubmittalsMoats