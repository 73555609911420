import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
// import store from './store'
import Layout from './components/Layout'
import * as RouteComponents from './components'
import routesConfig from './routesConfig'
import RouteFactory from './components/RouteFactory'
import './App.scss'
import theme from './theme'
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

// my changes
import { createStore, compose } from 'redux';

import rootReducer from "./reducers/index";
import { loadState } from './reducers/localstorage.js';

const persistedState = loadState();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, persistedState, composeEnhancers());

const App = () => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <div className="app">
            <Layout />
            <RouteFactory routeList={RouteComponents} routesConfig={routesConfig} />
          </div>
        </BrowserRouter>
      </MuiThemeProvider>
    </Provider>
  )
}

export default App
