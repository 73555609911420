import {
    SET_MESSAGE
} from "../constants/base-constants";
import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    SET_AUTH
} from "../constants/auth-constants";

import {
    RECEIVE_FETCH_DATA
} from '../constants/fetch-constants'

export function setMessage(message) {
    return { type: SET_MESSAGE, message }
}

export function login(user_data) {
    return { type: AUTH_LOGIN, user_data }
}

export function logout() {
    return { type: AUTH_LOGOUT }
}

export function setAuth(user_data) {
    return { type: SET_AUTH, user_data }
}

export function receiveFetchData(fetch_data) {
    return { type: RECEIVE_FETCH_DATA, fetch_data }
}
