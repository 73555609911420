
export const formatToCalendarDate = date => {
  if (typeof date === 'string') date = new Date(date)
  let month = date.getMonth() + 1
  if (month.toString().length === 1) month = `0${month}`

  let _date = date.getDate().toString()
  if (_date.length === 1) _date = `0${_date}`

  return `${month}/${_date}/${date.getFullYear()}`
}

export const formatDate = date => {
  if (typeof date === 'string') date = new Date(date)
  let month = date.getMonth() + 1
  if (month.toString().length === 1) month = `0${month}`
  let _date = date.getDate().toString()
  if (_date.length === 1) _date = `0${_date}`
  let hour = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()
  return `${month}/${_date}/${date.getFullYear()} (${hour}:${minutes}:${seconds})`
}

export const formatCandidate = candidate => {
  const _candidate = {...candidate}
  if (_candidate.email[0]) {
    _candidate['email_s'] = _candidate.email[0].email
  } else {
    _candidate['email_s'] = ''
  }
  _candidate.phone = _candidate.phone.map(p => {
    return {
      id: p.id,
      value: p.phone,
      label: 'Phone'
    }
  })
  _candidate.email = _candidate.email.map(e => {
    return {
      id: e.id,
      value: e.email,
      label: 'Email'
    }
  })

  if (_candidate.weekly_total_hours) {
    _candidate.weekly_total_hours = _candidate.weekly_total_hours.as_float__sum
  }
  if (_candidate.bill_rate) {
    _candidate.bill_rate = parseFloat(_candidate.bill_rate).toFixed(1)
  } else {
    let bill = parseFloat(_candidate.bill_rate_cal) * _candidate.pay_rate
    if (bill > 0) bill = bill + 30
    else bill = 0.0
    _candidate.bill_rate = parseFloat(bill).toFixed(1)
  }

  if (_candidate.bill_rate) {
    _candidate.bill_rate = parseFloat(_candidate.bill_rate).toFixed(1)
  }

  let bill = parseFloat(_candidate.bill_rate_cal) * _candidate.pay_rate
  if (bill > 0) bill = bill + 30
  else bill = 0.0
  _candidate.bill_rate_cals = bill.toFixed(1)

  // if(_candidate.employment_type==1){
  //
  //  _candidate.net_revenue= (_candidate.bill_rate*_candidate.weekly_total_hours)-(_candidate.pay_rate*_candidate.weekly_total_hours);
  // }else{
  //
  //  _candidate.net_revenue=parseFloat( _candidate.salary)-(_candidate.client_fee_amount+parseFloat(_candidate.total_rc_commision_amount)+parseFloat(_candidate.total_am_commision_amount));
  // }
  if (_candidate.client_fee != null) {
    _candidate.net_revenue =
      (_candidate.salary * _candidate.client_fee) / 100 -
      parseFloat(_candidate.total_rc_commision_amount) -
      parseFloat(_candidate.total_am_commision_amount)
  } else {
    _candidate.net_revenue =
      (_candidate.salary * _candidate.client_fee_percentage) / 100 -
      parseFloat(_candidate.total_rc_commision_amount) -
      parseFloat(_candidate.total_am_commision_amount)
  }

  _candidate.net_revenue = parseFloat(_candidate.net_revenue).toFixed(1)

  if (_candidate.start_date) _candidate.start_date = formatToCalendarDate(_candidate.start_date)
  if (_candidate.interview_date) _candidate.interview_date = formatToCalendarDate(_candidate.interview_date)
  if (_candidate.placement_date) _candidate.placement_date = formatToCalendarDate(_candidate.placement_date)
  _candidate.created = formatDate(_candidate.created)
  _candidate.modified = formatDate(_candidate.modified)

  //client_fee_amount=client_fee_amount_candidate.modified
  return _candidate
}

export const getEploymentType = etype => {
  if (etype == 1) {
    return 'Contract Job'
  } else if (etype == 2) {
    return `Contract To Hire`
  } else if (etype == 3) {
    return ' Permanent Job'
  } else {
    return ''
  }
}

export const getEploymentTypeC = etype => {
  if (etype == 1) {
    return 'c_type'
  } else if (etype == 2) {
    return 'ch_type'
  } else if (etype == 3) {
    return 'p_type'
  } else {
    return ''
  }
}
