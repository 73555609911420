import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import _find from 'lodash/find'
import RoomIcon from '@material-ui/icons/Room'
import CloseIcon from '@material-ui/icons/Close'
import Card from '@material-ui/core/Card'
import SearchIcon from '@material-ui/icons/Search'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Slider from '@material-ui/core/Slider'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import * as AppConstants from '../../constants/app'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FacebookProgress from './FacebookProgress'
import './leftPanel.scss'


const styles = {
  root: {
    flexGrow: 1,
  },
  card: {
    margin: ' 20px 80px 20px 0px',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },
  child: {
    backgroundColor: '#fff',
  },
  cardcontent: {
    paddingBottom: 0,
  },
  title: {
    fontSize: 24,
    color: '#2aadde',
  },
  toolbarButtons: {
    marginRight: 12,
    float: 'right',
  },
  divider: {
    margin: `5px 0`,
  },
  bodyname: {
    fontSize: 16,
    marginBottom: 15,
  },
  pos: {
    marginTop: 10,
    marginBottom: 0,
  },
  titlefilter: {
    fontSize: 16,
    color: '#479e6a',
    marginBottom: 5,
    fontWeight: '600',
  },
  topbar: {
    padding: 10,
    display: 'inline-block',
  },
  phone: {
    flex: 1,
  },
  marginTop: {
    marginTop: '1rem',
  },
  marginDivider: {
    margin: '1rem 0',
  },
  paddingLR: {
    padding: '0 1rem',
  },
  paddingTB: {
    padding: '1rem 0',
  },
  skillsHeight: {
    height: '10rem',
    flexFlow: 'column',
    overflow: 'auto'
  },
  btnSearch: {
    padding: '1rem 1rem 0 0',
    textAlign: 'right',
  },
  m2: {
    margin: '2px',
  },
}

const initialEmpType = [
  { value: 1, label: 'Permanent Job', checked: false },
  { value: 2, label: 'Contract To Hire', checked: false },
  { value: 3, label: 'Contract Job', checked: false },
]

const initialPayload = {
  terms: '',
  country: '',
  priority: '',
  emptype: '',
  skill: '',
  category: '',
}

const initialSal = {
  salaryrange: [0, 100000], salaryfrom: 0, salaryto: 100000,
}

const LeftFilterPanel = (props) => {
  const { classes } = props
  const [payload, setPayload] = useState(initialPayload)
  const [loading, setLoading] = useState(false)
  const [skills, setSkills] = useState([])
  const [clients, setClients] = useState([])
  const [category, setCategory] = useState([])
  const [emptype, setEmptype] = useState(initialEmpType)
  const [salary, setSalary] = useState(initialSal)
  const prioritys = [
    { value: 1, label: 'High' },
    { value: 2, label: 'Low' },
  ]

  useEffect(() => {
    setLoading(true)
    axios.get(`${AppConstants.API_BASE_URL}recruit/client/?jobs=false`).then((response) => {
      if (response.data) {
        setClients(response.data.result.clients)
        setCategory(response.data.result.category.map(o => ({ ...o, checked: false })))
        setSkills(response.data.result.skills.map(o => ({ ...o, checked: false })))
      }
      setLoading(false)
    }).catch((err) => {
      console.log('err', err)
      setLoading(false)
    })

  }, [])

  const handleChangeSlider = (e, newval) => {
    //alert(newval)
    let newls = newval
    setSalary({ salaryrange: newval, salaryfrom: newls[0], salaryto: newls[1] })
    setPayload({
      ...payload,
      salary: `${newls[0]},${newls[1]}`
    })
  }

  const handleTextChange = (e) => {
    const { name, value } = e.target
    setPayload({
      ...payload,
      [name]: value,
    })
  }
  const handleCheckbox = (e, val) => {
    const { checked, name, value } = e.target
    if (name === 'category') {
      let index = category.findIndex(o => o.value === val)
      category[index].checked = checked
      setCategory(category)
    }
    if (name === 'skill') {
      let index = skills.findIndex(o => o.value === val)
      skills[index].checked = checked
      setSkills(skills)
    }
    if (name === 'emptype') {
      let index = emptype.findIndex(o => o.value === val)
      emptype[index].checked = checked
      setEmptype(emptype)
    }
    if (checked) {
      let str = ''
      if (payload[name] === '') {
        str = payload[name].concat(value)
      } else {
        str = payload[name].concat(',', value)
      }

      setPayload({
        ...payload,
        [name]: str,
      })
    } else {
      let arr = payload[name].split(',')
      arr.splice(arr.findIndex(o => o === value), 1)
      setPayload({
        ...payload,
        [name]: arr.join(','),
      })
    }
  }

  const removeFilter = (key) => {
    if (key === 'skill') {
      setSkills(skills.map(s => ({ ...s, checked: false })))
    }
    if (key === 'category') {
      setCategory(category.map(c => ({ ...c, checked: false })))
    }
    if (key === 'emptype') {
      setEmptype(emptype.map((em => ({ ...em, checked: false }))))
    }
    setPayload({
      ...payload,
      [key]: '',
    })
  }

  const renderAppliedFilters = () => {
    let filters = []
    for (let o in payload) {
      if (payload[o] !== '') {
        let value = payload[o]
        if (o === 'client') {
          let client = _find(clients, c => c.value === payload[o])
          value = client && client.label
        }
        if (o === 'priority') {
          value = payload[o] === 1 ? 'High' : 'Low'
        }
        if (o === 'skill') {
          let arr = payload[o].split(',')
          let tags = arr.map(a => {
            let skill = _find(skills, ss => ss.value == a)
            if (skill) {
              return skill.label
            }
            return ''

          })
          value = tags.join(',')
        }
        if (o === 'category') {
          let arr = payload[o].split(',')
          let tags = arr.map(a => {
            let cate = _find(category, ss => ss.value == a)
            if (cate) {
              return cate.label
            }
            return ''

          })
          value = tags.join(',')
        }
        if (o === 'emptype') {
          let arr = payload[o].split(',')
          let tags = arr.map(a => {
            let emp = _find(emptype, ss => ss.value == a)
            if (emp) {
              return emp.label
            }
            return ''
          })
          value = tags.join(',')
        }
        filters.push(
          <div className='filter-tags-container'>
            <div className='close-icon' onClick={() => removeFilter(o)}><CloseIcon/></div>
            <div className='filter-key'>{o}: &nbsp;</div>
            <div className='filter-value'>{renderFilterTags(value)}</div>
          </div>,
        )
      }
    }
    return filters
  }

  const renderFilterTags = (value) => {
    let arr = value.split(',')
    return arr.map(a => <Chip className={classes.m2} label={a}/>)
  }

  const applyFilters = () => {
    let queryStr = ''
    for (let p in payload) {
      if (payload[p] !== '') {
        if (queryStr === '') {
          queryStr = queryStr.concat(`${p}=${payload[p]}`)
        } else {
          queryStr = queryStr.concat('&', `${p}=${payload[p]}`)
        }
      }
    }
    props.applyFilters(queryStr)
  }

  const clearAllFilter = () => {
    setSkills(skills.map(s => ({ ...s, checked: false })))
    setCategory(category.map(c => ({ ...c, checked: false })))
    setEmptype(emptype.map((em => ({ ...em, checked: false }))))
    setPayload(initialPayload)
    setSalary(initialSal)
  }

  const filterTags = renderAppliedFilters()

  return (
    <Grid item xs="12" sm="12" md="3">
      <Card className={classes.card}>
        <div className={clsx(classes.btnSearch)}>
          <Button
            onClick={applyFilters}
            variant="contained"
            color="primary"
          >
            Apply Filters
          </Button>
        </div>
        <CardContent className={classes.cardcontent}>
          <Typography variant="caption" component='h2' className={classes.titlefilter}>
            <div className='filter-div'>
              <div>Applied filters</div>
              <div
                className='filter-clear'
                onClick={clearAllFilter}
              >clear all
              </div>
            </div>
          </Typography>
          <div className={classes.paddingLR}>
            {filterTags.map(d => d)}
            {filterTags.length === 0 && <p>No filter applied</p>}
          </div>

          <Divider className={classes.marginDivider}/>
          <Typography variant="caption" component="p" className={classes.bodyname}>
            <FormControl size="small" fullWidth className={clsx(classes.margin)} variant="outlined">
              <InputLabel htmlFor="search-keyword">Keyword</InputLabel>
              <OutlinedInput
                id="search-keyword"
                type='text'
                name='terms'
                value={payload.terms}
                onChange={handleTextChange}
                endAdornment={<SearchIcon/>}
                labelWidth={70}
              />
            </FormControl>

            <FormControl size="small" fullWidth className={clsx(classes.margin, classes.marginTop)} variant="outlined">
              <InputLabel htmlFor="search-location">Location</InputLabel>
              <OutlinedInput
                id="search-location"
                type='text'
                name='country'
                value={payload.country}
                onChange={handleTextChange}
                endAdornment={<RoomIcon/>}
                labelWidth={70}
              />
            </FormControl>
          </Typography>
          <Divider className={classes.marginTop}/>

          <Divider className={classes.marginDivider}/>

          <Typography variant="h5" component="h2" className={classes.titlefilter}>
            Salary
          </Typography>

          <div className={classes.paddingLR}>
            <Slider
              value={salary.salaryrange}
              onChange={handleChangeSlider}
              valueLabelDisplay="auto"
              valueLabelDisplay="off"
              aria-labelledby="range-slider"
              color='rgb(71, 158, 106)'
              max={200000}
              getAriaValueText={''}
            />
            <Typography
              variant="p"
              component="p"
              style={{ color: '#000', fontSize: 15 }}
              className={classes.titlefilter}
            >
              From ${salary.salaryfrom} to ${salary.salaryto}
            </Typography>
          </div>


          <Divider className={classes.marginDivider}/>

          <Typography variant="h5" component="h2" className={classes.titlefilter}>
            Employment Type
          </Typography>

          <div className={classes.paddingLR}>
            <FormGroup>
              {
                emptype.map(e => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={e.value}
                        checked={e.checked}
                        name="emptype"
                        onChange={(event) => handleCheckbox(event, e.value)}
                      />
                    }
                    label={e.label}
                  />
                ))
              }
            </FormGroup>
          </div>

          <Divider className={classes.marginDivider}/>

          <Typography variant="h5" component="h2" className={classes.titlefilter}>
            Skills
          </Typography>

          <div className={clsx(classes.paddingLR, classes.skillsHeight)}>
            {loading && <FacebookProgress/>}
            <FormGroup>
              {skills.map(function(c) {
                if (c.label === '') return null

                return (
                  <FormControlLabel
                    control={
                      <Checkbox name="skill"
                                checked={c.checked}
                                onChange={(e) => handleCheckbox(e, c.value)}
                                value={c.value}/>}
                    label={c.label}
                  />
                )
              })}
            </FormGroup>
          </div>

          <Divider className={classes.marginDivider}/>

          <Typography variant="h5" component="h2" className={classes.titlefilter}>
            Category
          </Typography>
          <div className={classes.paddingLR}>
            {loading && <FacebookProgress/>}
            <FormGroup>
              {category.map(function(c) {
                return (
                  <FormControlLabel
                    control={<Checkbox name="category" onChange={(e) => handleCheckbox(e, c.value)} checked={c.checked}
                                       value={c.value}/>}
                    label={c.label}
                  />
                )
              })}
            </FormGroup>
          </div>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(LeftFilterPanel)