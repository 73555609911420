import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import './index.scss'

const styles = (theme) => ({
  root: {
    margin: theme.spacing.unit,
    '&$disabled $notchedOutline': {
      borderColor: 'orange',
    },
  },
})

class AppSelect extends React.Component {
  state = {
    value: -1,
    labelWidth: 0,
    error: null,
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    })
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { props, handleChange } = this
    const { classes } = props
    let size = props.size || 'md'
    const fullWidth = props.fullWidth ? 'full-width' : ''
    size = fullWidth ? '' : size
    const selectProps = {
      name: 'value',
      value: this.state.value,
      onChange: handleChange,
      input: <OutlinedInput labelWidth={this.state.labelWidth} name={props.name} id={props.id} />,
      ...props,
    }
    return (
      <FormControl
        variant="outlined"
        size={props.size || 'small'}
        className={`${classes.root} ${size} ${fullWidth} app-select ${props.selectClasses} ${props.hideShowButtons}`}
      >
        <InputLabel
          className={`select-label ${props.lebelClass} `}
          shrink={true}
          variant="outlined"
          ref={(ref) => {
            this.InputLabelRef = ref
          }}
          htmlFor={props.id}
        >
          {props.label}
        </InputLabel>
        <Select {...selectProps} className="select">
          {props.items
            ? props.items.map((item, i) => (
                <MenuItem key={`${props.id}${i}`} value={item.value}>
                  {item.label}
                </MenuItem>
              ))
            : ''}
        </Select>
      </FormControl>
    )
  }
}

AppSelect.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppSelect)
