export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('recruiter-app-auth-user');

    if (serializedState === null) {
      return undefined;
    }

    return {
      auth: JSON.parse(serializedState),
      data: {
        candidates:{},
        jobs:{},
        skills:{},
      },
      message: {}
    };

  } catch (err) {
    return undefined;
  }
};
