import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'

const styles = {
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}

class AppCard extends React.Component {
  getActions = () => {
    const { actions } = this.props
    if (actions) {
      return <CardActions>{actions}</CardActions>
    } else {
      return null
    }
  }

  render() {
    const { classes, content } = this.props
    const { getActions } = this

    return (
      <Card className={classes.card}>
        <CardContent>{content}</CardContent>
        {getActions()}
      </Card>
    )
  }
}

AppCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppCard)
