
export const formatMoney = (amount, decimalCount = 0, decimal = '.', thousands = ',') => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
    let j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
        : '')
    )
  } catch (e) {
    console.log(e)
    return null
  }
}


export const formatIT = job => {
  job.salary = job.min_salary + '-' + job.max_salary
  if (job.hides) job.hides = job.hides.split(',')
  else job.hides = []

  if (job.publish_at) job.publish_at = formatToCalendarDate(job.publish_at)

  if (job.long_description) job.long_description = job.long_description.replace(/(?:\r\n|\r|\n)/g, '<br>')

  if (job.status == 1) job.status = 'Active'
  else job.status = 'Draft'

  if (job.employment_type == 1) job.employment_type = 'Contract Job'
  else if (job.employment_type == 1) job.employment_type = 'Contract To Hire'
  else job.employment_type = 'Permanent Job'

  return job
}

export const formatToCalendarDate = date => {
  if (typeof date === 'string') date = new Date(date)
  let month = date.getMonth() + 1
  if (month.toString().length === 1) month = `0${month}`

  let _date = date.getDate().toString()
  if (_date.length === 1) _date = `0${_date}`

  return `${month}/${_date}/${date.getFullYear()}`
}

export const getUrlQueryParam = (query = "") => {
  let result = -1;
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let value = params.get(query);
  if (value) {
    result = value
  }
  return result;
};