/* global UIENV */
let env = typeof UIENV !== 'undefined' ? UIENV : process.env; // UIENV comes when run through webpack

let envURL = env.hasOwnProperty('REACT_APP_API_URL') && env.REACT_APP_API_URL !== '' ? env.REACT_APP_API_URL : false;

const API_URL = '';    


let config = {
  'STRIPE_PUBLIC_KEY': '',
  'moduleURL': envURL || 'http://127.0.0.1:3000',
  'me': API_URL + '/me',
};
// API Endpoints on server
config.env = env;
export default config