import React from 'react'
import Fab from '@material-ui/core/Fab'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import './index.scss'

const styles = (theme) => ({
  root: {
    margin: theme.spacing.unit,
  },
})

class AppButton extends React.Component {
  getIcon = () => {
    const { props } = this
    const leftClass = this.props.label ? 'left-icon' : ''

    if (props.icon) {
      console.log(props?.icon.position, 's.....')
      if (props.icon.position === 'right') return <props.icon.component className={leftClass} />
      return <props.icon.component className="right-icon" />
    }
    return null
  }

  getContent = () => {
    const { props, getIcon } = this
    return (
      <React.Fragment>
        {props.label}
        {getIcon()}
      </React.Fragment>
    )
  }

  render() {
    const { props, getContent } = this
    const { classes, loading } = props
    return (
      <div className="app-button">
        <Fab variant="extended" aria-label="Add" className={`${classes.root}`} {...props}>
          <div className="app-button-content">
            {getContent()}
            {loading && <CircularProgress className="loading-btn" color="#ff" size={22} />}
          </div>
        </Fab>
      </div>
    )
  }
}

AppButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppButton)
