import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AppTextField from '../AppTextField'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline'
import './index.scss'

const styles = theme => ({})

class AppMoreDynamicTextField extends React.Component {
  state = {
    data: [],
  }

  componentWillMount() {
    const { data, label } = this.props
    const _data = []
    if (data && data.length > 0) {
      data.forEach(d => _data.push({ id: new Date().getTime(), label: label, value: d.value, errMsg: d.errMsg, err: d.err }))
    } else if (label) {
      _data.push(this.getNewDataObject())
    }
    this.setState({ data: _data })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ data: nextProps.data && nextProps.data.length > 0 ? nextProps.data : [this.getNewDataObject()] })
  }

  getNewDataObject = () => {
    const { label } = this.props
    return this.props.leads ? { id: new Date().getTime(), label: label, value: '', errMsg: '', err: false }
      : { id: new Date().getTime(), label: label, value: '', errMsg: 'This field is required', err: true }
  }

  onAddClick = () => {
    const { data } = this.state
    const _data = data.splice(0)
    _data.push(this.getNewDataObject())
    this.emitChange(_data)
  }

  onRemoveClick = d => {
    const { data } = this.state
    const _data = data.splice(0).filter(_d => _d.id !== d.id)
    this.emitChange(_data)
  }

  handleChange = (e, id) => {
    const { state } = this
    const _data = state.data.slice(0)
    _data.find(d => d.id === id).value = e.target.value
    this.emitChange(_data)
  }

  emitChange = data => {
    const { props } = this
    if (props.onChange) {
      props.onChange({
        target: {
          value: data,
          name: props.name,
        },
      })
    }
  }

  render() {
    const {
      state: { data },
      onAddClick,
      onRemoveClick,
      props,
      handleChange,
    } = this
    return (
      <div className="app-dynamic-text-field">
        {data.map((d, i) => {
          return i === 0 ? (
            <div className="container" key={`${props.id}${i}`}>
              <AppTextField {...props} helperText={d.errMsg} error={d.err} label={d.label} value={d.value} onChange={e => handleChange(e, d.id)} />
              <AddCircleOutline color="primary" className="action-button" onClick={onAddClick} />
            </div>
          ) : (
            <div className="container" key={`${props.id}${i}`}>
              <AppTextField {...props} helperText={d.errMsg} error={d.err} label={d.label} value={d.value} onChange={e => handleChange(e, d.id)} />
              <RemoveCircleOutline color="secondary" className="action-button" onClick={() => onRemoveClick(d)} />
            </div>
          )
        })}
      </div>
    )
  }
}

export default withStyles(styles)(AppMoreDynamicTextField)
