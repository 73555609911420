import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    flexGrow: 1,
  },
}

class Submittals extends React.Component {
  render() {
    const { classes } = this.props

    return <div className={classes.root}>Submittals</div>
  }
}

Submittals.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Submittals)
