import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router'
import {makeStyles} from "@material-ui/core/styles"
import axios from "axios"
import * as AppConstants from "../../constants/app"
import ClientViewTableHeader from "./table-header"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { columns,candidateColumns } from './columns'
import ClientDataRow from "./client-data-row"
import { SingleSelect } from 'react-select-material-ui'
import {
  fetchUsers,
  fetchAccountManagers,
  fetchThirdParty,
  getAuthToken
} from "./service"
import {devToolsEnhancer} from "redux-devtools-extension"

const useStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '10px'
  }
}))


const ClientJobsView = (props) => {

  const [jobs, setJobs] = useState([])
  const { id = '' } = useParams()
  const [token, setToken] = useState('')
  const [page, setPage] = useState({
    clients: [],
    account_managers: [],
    third_parties: [],
    users: []
  })

  useEffect(() => {
    fetchInitialData()
  }, [])

  const fetchInitialData = async () => {
    const token = await getAuthToken()
    const users = await fetchUsers()
    const third_parties = await fetchThirdParty()
    const account_managers = await fetchAccountManagers()
    setToken(token)
    setPage({
      users,
      third_parties,
      account_managers
    })
  }

  //stages
  const stageOptions = [
    {
      label:"All",
      value:'0'
    },
    {
      label : 'Hired',
      value : '5'
    },
    {
      label : 'Interview',
      value : '4'
    },
    {
      label : 'Sendouts',
      value : '3'
    },
    {
      label : 'Hold',
      value : '7'
    },
    {
      label : 'Reject',
      value : '6'
    },
    {
      label : 'Submittals',
      value : '2'
    }
  ];


  const formatToCalendarDate = date => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`

    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`

    return `${month}/${_date}/${date.getFullYear()}`
  }

  const formatIT = job => {
    console.log(job)
    job.salary = job.min_salary + '-' + job.max_salary

    if (job.publish_at) job.publish_at_f = formatToCalendarDate(job.publish_at)

    if (job.status == 1) job.status = 'Active'
    else job.status = 'Draft'

    if (job.employment_type == 1) job.employment_type = 'Contract Job'
    else if (job.employment_type == 2) job.employment_type = 'Contract To Hire'
    else job.employment_type = 'Permanent Job'
    let _skills = []
    if (job.skill_name && job.skill_name.length) {
      job.skill_name.forEach(function(sk) {
        _skills.push(sk.title)
      })
    }
    job.skill_value = _skills.toString()

    return job
  }

  const fetchJobs = async () => {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/job/?client=` + id)
    if (response.data) {
      const jobs = []
      response.data.forEach(c => jobs.push(formatIT(c)))
      setJobs(jobs)
    }
  }




  const getHeading = () => {
    if(jobs.length > 0) {
      return jobs[0].client_name
    }
    return ''
  }

  useEffect(() => {
    fetchJobs()
  }, [])

  const [filteredJobs, setFilteredJobs] = useState('0')

  const [filteredStage, setFilteredStages] = useState('0')

  const [jobOption, setJobOption] = useState([])

  const [seletedTitleId, setSeletedTitleId] = useState('0')

  useEffect(() => {
    let jobsOptions = []

    jobsOptions.push({
      label:"All",
      value:'0'
    });

    jobs.map((job) => {
      jobsOptions.push({
        label : job.title,
        value : job.id
      })
    })
    setJobOption(jobsOptions)
  }, [jobs])

  useEffect(() => {
    //fetchJobs()
  }, [seletedTitleId])

  const classes = useStyle()

  return (
    <div className={classes.root}>
      <div className='client-tabl-container'>
      <div className="title-filter d-flex mb-4 align-items-center">
        <h1>{getHeading()}</h1>
        <SingleSelect
          name="job-title-filter"
          label="Job Title"
          options={jobOption}
          onChange={(value) => {
            setFilteredJobs(value)
          }}
          value={filteredJobs}
          style={{ width: '20%' }}
          className="ml-4"
        />

        {/* module added */}

        <SingleSelect
          name="stage-filter"
          label="Stage Filter"
          options={stageOptions}
          onChange={(value) => {
            setFilteredStages(value)
          }}
          value={filteredStage}
          style={{ width: '20%' }}
          className="ml-4"
        />
      </div>
        <>
          <ClientDataRow
            columns={candidateColumns}
            job={filteredJobs}
            stage={filteredStage}
            client_id={id}
            token={token}
            page_data={page}
          />
          </>
      </div>
    </div>
  )
}

export default ClientJobsView
