import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppButton from '../common/AppButton'
import AppTextField from '../common/AppTextField'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as AppConstants from '../../constants/app'
import axios from 'axios'
import AppSelect from '../common/AppSelect'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import RoomIcon from '@material-ui/icons/Room'
import GradeIcon from '@material-ui/icons/Grade'
import DateRangeIcon from '@material-ui/icons/DateRange'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Slider from '@material-ui/core/Slider'

const styles = {
  root: {
    flexGrow: 1,
  },
  card: {
    margin: ' 20px 80px 20px 0px',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },
  child: {
    backgroundColor: '#fff',
  },
  cardcontent: {
    paddingBottom: 0,
  },
  title: {
    fontSize: 24,
    color: '#2aadde',
  },
  toolbarButtons: {
    marginRight: 12,
    float: 'right',
  },
  divider: {
    margin: `5px 0`,
  },
  bodyname: {
    fontSize: 16,
    marginTop: 22,
    marginBottom: 15,
  },
  pos: {
    marginTop: 10,
    marginBottom: 0,
  },
  titlefilter: {
    fontSize: 24,
    color: '#3f51b5',
    marginTop: 20,
    marginBottom: 5,
  },
  topbar: {
    padding: 10,
    display: 'inline-block',
  },
  phone: {
    flex: 1,
  },
}

class JobsFilters extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    actual_data: [],
    selectedChild: [],
    pageHeading: 'Jobs',
    client_drop: '',
    data: [],
    open: false,
    logopen: true,
    clients: [],
    prioritys: [
      { value: 1, label: 'High' },
      { value: 2, label: 'Low' },
    ],
    jobs: [],
    users: [],
    category: [],
    skills: [],
    salaryrange: [0, 100000],
    account_managers: [],
    opened: [],
    isLoaded: false,
    auth_token: '',
    placement_filter: false,
    submittals_filter: false,
    contract_placement_filter: false,
    page: 1,
    searchvale: 'AAAALL',
    total_record: 0,
    recruiter_filter: 0,
    client_filter: 0,
    job_filter: 0,
    stage_filter: 0,
    total_sub: 0,
    total_inter: 0,
    total_send: 0,
    total_perma: 0,
    total_cont: 0,
    employe_filter: 0,
    total_sub_class: 0,
    total_inter_class: 0,
    total_send_class: 0,
    total_perma_class: 0,
    salaryto: 100000,
    salaryfrom: 0,
    total_cont_class: 0,
    third_party: [],
    columns: [],
  }

  componentDidMount() {
    // TODO: Make it one call, as I couldn't merge that to a single endpoint due to short time

    const { data } = this.state
    const _data = { ...data }
    _data['terms'] = this.getQueryVariable('terms')
    _data['category'] = this.getQueryVariable('category')
    _data['location'] = this.getQueryVariable('location')
    _data['emptype'] = ''
    _data['skill'] = ''
    _data['client_drop'] = ''
    _data['prio_drop'] = ''

    this.setState({ data: _data }, function() {
      this.fetchJobs()
    })

    this.getAuthToken()
    this.fetchClients()
  }

  getQueryVariable = variable => {
    var query = this.props.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1])
      }
    }
    console.log('Query variable %s not found', variable)
  }

  async getAuthToken() {
    let params = {
      username: 'admin@anewrecruit.com',
      password: 'Welcome123!!',
    }
    const response = await axios.post(`${AppConstants.API_BASE_URL}api-token-auth/`, params)
    if (response.data) {
      let token = response.data.token
      this.setState({
        auth_token: token,
      })
    }
  }

  async fetchClients() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client/?jobs=true`)
    if (response.data) {
      this.setState({ clients: response.data.result.clients })
      this.setState({ category: response.data.result.category })
      this.setState({ skills: response.data.result.skills })
    }
  }

  async fetchJobs() {
    let terms = ''
    let category = ''
    let location = ''
    let emptype = ''
    let skills = ''
    let salary = ''
    let client_drop = ''
    let prio_drop = ''
    /*
    if(this.props.match.params.terms){
      terms=this.props.match.params.terms
    }*/
    terms = this.state.data.terms
    category = this.state.data.category.replace(/,+$/, '')
    location = this.state.data.location
    emptype = this.state.data.emptype.replace(/,+$/, '')
    skills = this.state.data.skill.replace(/,+$/, '')
    salary = this.state.salaryrange
    client_drop = this.state.data.client_drop
    prio_drop = this.state.data.prio_drop
    let th = this
    const response = await axios.get(
      `${AppConstants.API_BASE_URL}recruit/job/?terms=` +
        terms +
        `&category=` +
        category +
        `&emptype=` +
        emptype +
        `&location=` +
        location +
        `&skill=` +
        skills +
        `&salary=` +
        salary +
        `&client_drop=` +
        client_drop +
        `&prio_drop=` +
        prio_drop
    )
    if (response.data) {
      const jobs = []
      //response.data.forEach(c => jobs.push(this.formatIT(c)));
      response.data.forEach(function(c) {
        if (c.status == 1) jobs.push(th.formatIT(c))
      })
      this.setState({ jobs: jobs, isLoaded: true }, function() {})
    }
  }

  formatIT = job => {
    job.salary = job.min_salary + '-' + job.max_salary

    if (job.publish_at) job.publish_at = this.formatToCalendarDate(job.publish_at)

    if (job.hides) job.hides = job.hides.split(',')
    else job.hides = []

    if (job.status == 1) job.status = 'Active'
    else job.status = 'Draft'

    if (job.employment_type == 1) job.employment_type = 'Contract Job'
    else if (job.employment_type == 1) job.employment_type = 'Contract To Hire'
    else job.employment_type = 'Permanent Job'

    return job
  }

  formatToCalendarDate = date => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`

    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`

    return `${month}/${_date}/${date.getFullYear()}`
  }

  goToJobDetails = id => {
    window.location.href = '/jobs-details/' + id
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
    this.fetchJobs()
  }

  valuetext = value => {
    return '$' + value
  }
  handleChangeSlider = (e, newval) => {
    //alert(newval)
    let newls = newval
    this.setState({ salaryrange: newval, salaryfrom: newls[0], salaryto: newls[1] }, function() {
      this.fetchJobs()
    })
  }

  handelChnage = e => {
    if (e.target.type == 'checkbox') {
      if (e.target.checked) {
        const { data } = this.state
        const _data = { ...data }
        const prop = e.target.name
        _data[prop] = data[prop] + e.target.value + ','
        this.setState({ data: _data, isLoaded: false }, function() {
          this.fetchJobs()
        })
      } else {
        const { data } = this.state
        const _data = { ...data }
        const prop = e.target.name
        let avl = _data[prop].split(',')
        let farray = this.removeA(avl, e.target.value)
        _data[prop] = farray.join(',')

        this.setState({ data: _data, isLoaded: false }, function() {
          this.fetchJobs()
        })
      }
    } else {
      const { data } = this.state
      const _data = { ...data }
      const prop = e.target.name
      _data[prop] = e.target.value

      this.setState({ data: _data, isLoaded: false }, function() {
        this.fetchJobs()
      })
    }
  }

  removeA = (array, item) => {
    var index = array.indexOf(item)
    if (index !== -1) array.splice(index, 1)
    return array
  }

  handlekeydown = e => {
    if (e.key === 'Enter') {
      const { data } = this.state
      const _data = { ...data }
      const prop = e.target.name
      _data[prop] = e.target.value
      this.setState({ data: _data, isLoaded: false }, function() {
        this.fetchJobs()
      })
    } else {
      const { data } = this.state
      const _data = { ...data }
      const prop = e.target.name
      _data[prop] = e.target.value
      this.setState({ data: _data })
    }
  }

  formatDate = date => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`
    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`
    let hour = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    return `${month}/${_date}/${date.getFullYear()} (${hour}:${minutes}:${seconds})`
  }

  get columns() {
    return [
      { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
      { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
      { id: 'client_name', numeric: false, disablePadding: false, label: 'Client Name' },
      { id: 'salary', numeric: false, disablePadding: false, label: 'Salary' },
      { id: 'employment_type', numeric: false, disablePadding: false, label: 'Job Type' },
      { id: 'category_name', numeric: false, disablePadding: false, label: 'Category' },
      { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
      { id: 'publish_at', numeric: false, disablePadding: false, label: 'Publish' },
    ]
  }

  getChildRows = () => {
    const {
      state: { data },
      getChildRow,
    } = this
    const colSpan = data && data.length > 0 ? Object.keys(data[0]).length + 1 : 1
    return data.map(d => {
      return {
        id: d.id,
        element: getChildRow(d, colSpan),
      }
    })
  }

  getProgress = () => (this.state.isLoaded ? false : <LinearProgress />)

  render() {
    const {
      columns,
      state: { data, opened },
      props: { classes },
      getChildRows,
      getProgress,
    } = this
    const th = this
    return (
      <div className={classes.root}>
        <div className="filter-section">
          <div className="search-section" style={{ display: 'inline-flex' }}></div>
        </div>

        <div className="jobs-list">
          {getProgress()}

          <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs="12" sm="12" md="1">
              &nbsp;
            </Grid>
            <Grid item xs="12" sm="12" md="3">
              <Card className={classes.card}>
                <CardContent className={classes.cardcontent} style={{ paddingLeft: 40 }}>
                  <Typography variant="h5" component="h2" className={classes.titlefilter}>
                    SEARCH
                  </Typography>
                  <Divider style={{ marginBottom: 50 }} />

                  <Typography variant="h5" component="h2" className={classes.titlefilter}>
                    KEYWORD
                  </Typography>
                  <Divider />

                  <Typography variant="caption" component="p" className={classes.bodyname}>
                    <AppTextField
                      onChange={th.handelChnage}
                      name="terms"
                      value={th.state.data.terms}
                      onKeyDown={th.handlekeydown}
                      size="sm"
                    />
                  </Typography>
                  <Divider />

                  <Typography variant="h5" component="h2" className={classes.titlefilter}>
                    Location
                  </Typography>
                  <Typography variant="caption" component="p" className={classes.bodyname}>
                    <AppTextField
                      onChange={th.handelChnage}
                      name="location"
                      value={th.state.data.location}
                      onKeyDown={th.handlekeydown}
                      size="sm"
                    />
                  </Typography>
                  <Divider />

                  <Typography variant="h5" component="h2" className={classes.titlefilter}>
                    Client
                  </Typography>
                  <Typography variant="caption" component="p" className={classes.bodyname}>
                    <AppSelect
                      fullWidth
                      id="client_drop"
                      label="Clients"
                      value={this.state.data.client_drop}
                      name="client_drop"
                      onChange={th.handelChnage}
                      items={this.state.clients}
                    />
                  </Typography>
                  <Divider />

                  <Typography variant="h5" component="h2" className={classes.titlefilter}>
                    Priority
                  </Typography>
                  <Typography variant="caption" component="p" className={classes.bodyname}>
                    <AppSelect
                      fullWidth
                      id="prio_drop"
                      label="Priority"
                      value={this.state.data.prio_drop}
                      name="prio_drop"
                      onChange={th.handelChnage}
                      items={this.state.prioritys}
                    />
                  </Typography>
                  <Divider />

                  <Typography variant="h5" component="h2" className={classes.titlefilter}>
                    Filters
                  </Typography>

                  <Divider></Divider>

                  <Divider></Divider>

                  <Typography variant="h5" component="h2" className={classes.titlefilter}>
                    Salary
                  </Typography>

                  <Divider></Divider>

                  <Slider
                    value={this.state.salaryrange}
                    onChange={th.handleChangeSlider}
                    valueLabelDisplay="auto"
                    valueLabelDisplay="off"
                    aria-labelledby="range-slider"
                    max={200000}
                    getAriaValueText={th.valuetext}
                  />

                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: '#000', fontSize: 15 }}
                    className={classes.titlefilter}
                  >
                    From ${this.state.salaryfrom} to ${this.state.salaryto}
                  </Typography>

                  <Typography variant="h5" component="h2" className={classes.titlefilter}>
                    Employment Type
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox value="1" name="emptype" onChange={th.handelChnage} />}
                      label="Contract Job"
                    />
                    <FormControlLabel
                      control={<Checkbox value="2" name="emptype" onChange={th.handelChnage} />}
                      label="Contract To Hire"
                    />
                    <FormControlLabel
                      control={<Checkbox value="3" name="emptype" onChange={th.handelChnage} />}
                      label="Permanent Job"
                    />
                  </FormGroup>

                  <Divider />

                  <Typography variant="h5" component="h2" className={classes.titlefilter}>
                    Skill
                  </Typography>

                  <FormGroup>
                    {this.state.skills.map(function(c) {
                      return (
                        <FormControlLabel
                          control={<Checkbox name="skill" onChange={th.handelChnage} value={c.value} />}
                          label={c.label}
                        />
                      )
                    })}
                  </FormGroup>

                  <Divider />

                  <Typography variant="h5" component="h2" className={classes.titlefilter}>
                    Category
                  </Typography>

                  <FormGroup>
                    {this.state.category.map(function(c) {
                      return (
                        <FormControlLabel
                          control={<Checkbox name="category" onChange={th.handelChnage} value={c.value} />}
                          label={c.label}
                        />
                      )
                    })}
                  </FormGroup>

                  <Divider />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs="12" sm="12" md="8">
              {this.state.jobs.map(function(job, index) {
                return (
                  <Card className={classes.card}>
                    <CardContent className={classes.cardcontent}>
                      <Typography variant="h5" component="h2" className={classes.title}>
                        {index + 1}-{job.title} (ANEW{job.id})
                        <Typography className={classes.toolbarButtons}>
                          <AppButton
                            style={{ backgroundColor: '#f2538c' }}
                            size="small"
                            label={job.employment_type}
                            color="secondary"
                          >
                            {' '}
                          </AppButton>
                        </Typography>
                      </Typography>

                      <Typography variant="caption" component="p" className={classes.bodyname}>
                        {job.long_description.substring(0, 300)}....
                      </Typography>
                      <Divider></Divider>
                      <Typography className={classes.pos} color="textPrimary">
                        {job.hides.indexOf('category') == -1 ? (
                          <span className={classes.topbar}>
                            <GradeIcon color="secondary" /> {job.category_name}
                          </span>
                        ) : (
                          ''
                        )}

                        {job.hides.indexOf('location') == -1 ? (
                          <span className={classes.topbar}>
                            <RoomIcon color="secondary" /> {job.location}
                          </span>
                        ) : (
                          ''
                        )}

                        {job.hides.indexOf('date') == -1 ? (
                          <span className={classes.topbar}>
                            <DateRangeIcon color="secondary" /> {job.publish_at}
                          </span>
                        ) : (
                          ''
                        )}
                      </Typography>
                    </CardContent>
                    <CardActions className={classes.toolbarButtons}>
                      <AppButton
                        style={{ backgroundColor: '#0b9cf0' }}
                        onClick={() => th.goToJobDetails(job.id)}
                        label="View Details"
                        color="primary"
                      ></AppButton>
                    </CardActions>
                  </Card>
                )
              })}
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

JobsFilters.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(JobsFilters)
