import * as React from 'react'
import AppBar from '../common/AppBar'

const Layout = props => {
  return (
    <React.Fragment>
      <AppBar />
    </React.Fragment>
  )
}

export default Layout
