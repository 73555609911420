import React from 'react'
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Tooltip from "@material-ui/core/Tooltip"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import TableHead from "@material-ui/core/TableHead"
import {makeStyles} from "@material-ui/core/styles"
import './client-job-view.scss'

const useStyles = makeStyles(theme => ({
  expandCollapse: {
    paddingLeft: 0,
    paddingRight: 0
  },
  checkbox: {
    width: '20px',
    paddingRight: 0,
    paddingLeft: 0
  }
}))

const ClientViewTableHeader = (props) => {
  const classes = useStyles()
  const { order, orderBy, columns } = props
  return (
    <TableHead>
      <TableRow key="tableHead">
        {props.expandCollapse() || ''}
        {columns.map(
          column => (
            <TableCell
              className="client-table-cell"
              key={`tableHead${column.id}`}
              align={column.numeric ? 'right' : 'left'}
              padding={column.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === column.id ? order : false}
              style={column.id == 'job_title' ? {} : {}}
            >
              <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={() => {}}
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ),
          this
        )}
      </TableRow>
    </TableHead>
  )
}

export default ClientViewTableHeader