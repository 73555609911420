import React, { useState } from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import AppTextField from '../../common/AppTextField'
import AppButton from '../../common/AppButton'
import Send from '@material-ui/icons/Send'
import axios from 'axios'
import * as AppConstants from '../../../constants/app'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Input from "@material-ui/core/Input"
import Button from '@material-ui/core/Button'
import DialogContentText from '@material-ui/core/DialogContentText';

const styles = theme => ({
  root: {
    padding: 0,
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing.unit,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

class MoatsDialog extends React.Component {
  state = {
    value: '',
    email: 'submittals@anewrecruit.com',
    required: '1',
    isLoaded: true,
    file: null,
    email_req: '',
    most_req: '',
    file_req: '',
    snackBar: {
      open: false,
      message: '',
    },
    confirmationModal: false,
    confirmationAction: ''
  }

  componentWillMount() {
    this.setState({ value: this.value })
    this.setEmail()
  }

  setEmail = () => {
    const {
      props: { data },
    } = this

    if (data.client_contact_email[0] && data.client_contact_email[0]['value'].length > 0) {
      this.setState({
        email: data.client_contact_email[0]['value'][0]['email'],
      })
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  get value() {
    console.log('get Value() called.')
    let required = ''
    const { props } = this
    const lineBreak = '\n'
    const { data } = props
    let _value = []
    console.log('get Value() called.', data)

    _value.push('AI Score: ')
    if (data.score1) _value.push(`${data.score1}`)
    else required = required + 'AI Score, '
    _value.push(lineBreak)

    _value.push('RC Score: ')
    if (data.score) _value.push(`${data.score}`)
    else required = required + 'RC Score, '
    _value.push(lineBreak)

    _value.push('Bachelor’s degree: ')
    // if (data.name) _value.push(`${data.name}`)
    // else required = required + 'Bachelor’s degree, '
    _value.push(lineBreak)

    _value.push('Currently working?: ')
    // if (data.name) _value.push(`${data.name}`)
    // else required = required + 'Bachelor’s degree, '
    _value.push(lineBreak)

    _value.push('Name: ')
    if (data.name) _value.push(`${data.name}`)
    else required = required + 'Name, '
    _value.push(lineBreak)

    _value.push('Current Title: ')
    if (data.job_title) _value.push(`${data.job_title}`)
    else required = required + 'Current Title, '
    _value.push(lineBreak)

    _value.push('Phone: ')
    if (data.phone && data.phone.length > 0) _value.push(`${data.phone.map(p => p.value).join(', ')}`)
    else required = required + 'Phone, '
    _value.push(lineBreak)

    _value.push('Email: ')
    if (data.email && data.email.length > 0) _value.push(`${data.email.map(e => e.value).join(', ')}`)
    else required = required + 'Email, '
    _value.push(lineBreak)

    _value.push('Linkedin: ')
    if (data.linkedin_url) _value.push(`${data.linkedin_url}`)
    else required = required + 'Linkedin, '
    _value.push(lineBreak)

    _value.push('Current Comp: ')
    // if (data.linkedin_url) _value.push(`${data.linkedin_url}`)
    // else required = required + 'Linkedin, '
    _value.push(lineBreak)

    _value.push('Desired Comp: ')
    // if (data.linkedin_url) _value.push(`${data.linkedin_url}`)
    // else required = required + 'Linkedin, '
    _value.push(lineBreak)

    _value.push('Start Date: ')
    if (data.start_date) _value.push(`${data.start_date}`)
    else required = required + 'Start Date, '
    _value.push(lineBreak)

    _value.push('Location: ')
    if (data.location) _value.push(`${data.location}`)
    else required = required + 'Location, '
    _value.push(lineBreak)

    _value.push('Candidate Activity: ')
    if (data.activity) _value.push(`${data.activity}`)
    else required = required + 'Candidate Activity, '
    _value.push(lineBreak)

    _value.push('Vacation: ')
    if (data.vacation) _value.push(`${data.vacation}`)
    else required = required + 'Vacation, '
    _value.push(lineBreak)


    _value.push('Communication Skills: ')
    if (data.communication) _value.push(`${data.communication}`)
    else required = required + 'Communication, '
    _value.push(lineBreak)

    _value.push('Visa Status: ')
    if (data.status_visa) _value.push(`${data.status_visa}`)
    else required = required + 'Visa Status, '
    _value.push(lineBreak)

    _value.push('RFL: ')
    // if (data.availability) _value.push(`${data.availability}`)
    // else required = required + 'RFL, '
    _value.push(lineBreak)

    _value.push('Notes: ')
    if (data.notes) _value.push(`${data.notes}`)
    else required = required + 'Notes, '
    _value.push(lineBreak)

    // if (data.employment_type == 1) {
    //   _value.push('Pay Rate: ')
    //   if (data.pay_rate) _value.push(`${data.pay_rate}`)
    //   else required = required + 'Pay Rate, '
    //   _value.push(lineBreak)

    //   if (data.bill_rate_manula == 1) {
    //     _value.push('Custom Bill Rate: ')
    //     if (data.bill_rate) _value.push(`${data.bill_rate}`)
    //     else required = required + 'Bill Rate, '
    //     _value.push(lineBreak)
    //   } else {
    //     _value.push('Bill Rate: ')
    //     if (data.bill_rate) _value.push(`${data.bill_rate}`)
    //     else required = required + 'Bill Rate, '
    //     _value.push(lineBreak)
    //   }
    // } else {
    //   _value.push('Salary: ')
    //   if (data.salary) _value.push(`${data.salary}`)
    //   else required = required + 'Salary, '
    //   _value.push(lineBreak)
    // }
    // _value.push('Availability: ')
    // if (data.availability) _value.push(`${data.availability}`)
    // else required = required + 'Availability, '
    // _value.push(lineBreak)




    // _value.push('Bonus: ')
    // if (data.bonus) _value.push(`${data.bonus}`)
    // else required = required + 'Bonus, '
    // _value.push(lineBreak)

    // _value.push('Current OTE: ')
    // if (data.current_ote) _value.push(`${data.current_ote}`)
    // else required = required + 'Current OTE, '
    // _value.push(lineBreak)

    // _value.push('OTE Expectations: ')
    // if (data.ote_expectation) _value.push(`${data.ote_expectation}`)
    // else required = required + 'OTE Expectations, '
    // _value.push(lineBreak)

    // _value.push('Salary Expectations: ')
    // if (data.expected_salary) _value.push(`${data.expected_salary}`)
    // else required = required + 'Salary Expectations:, '
    // _value.push(lineBreak)



    // _value.push('Start Date: ')
    // if (data.start_date) _value.push(`${data.start_date}`)
    // else required = required + 'Start Date, '
    // _value.push(lineBreak)







    this.setState({ required: required })
    return _value.join('')
  }

  handleCopy = () => {
    const el = document.getElementById('txtMoats')
    el.select()
    document.execCommand('copy')
    this.clearSelection()
  }

  getValue = (str, start, end) => {
    return (
      str
        .substring(str.lastIndexOf(start) + start.length, end ? str.lastIndexOf(end) : str.length - 1)
        .replace('\n', '') || null
    )
  }

  handleSubmit = () => {
    this.hideConfirmationModal();
    this.sendEmail()
    this.props.saveCandidate()

  }
  handleUpdate = () => {
    this.hideConfirmationModal();
    this.props.saveCandidate()
  }

  handleSave = () => {
    const moatsData = {
      name: '',
      job_title: '',
      email: '',
      phone: '',
      current_salary: '',
      availability: '',
      location: '',
      vacation: '',
      communication: '',
      start_date: null,
      status_visa: '',
      linkedin_url: '',
      notes: '',
      file: '',
    }
    const value = this.state.value
    moatsData.name = this.getValue(value, 'Name: ', 'Role: ')
    moatsData.job_title = this.getValue(value, 'Role: ', 'Email: ')
    const email = this.getValue(value, 'Email: ', 'Phone: ')
    moatsData.email = email ? email.split(',') : []
    moatsData.email = moatsData.email.map(e => e.trim())
    const phone = this.getValue(value, 'Phone: ', 'Current Salary: ')
    moatsData.phone = phone ? phone.split(',') : []
    moatsData.phone = moatsData.phone.map(p => p.trim())
    moatsData.current_salary = this.getValue(value, 'Current Salary: ', 'Expected Salary: ')
    moatsData.expected_salary = this.getValue(value, 'Expected Salary: ', 'Availability: ')
    moatsData.availability = this.getValue(value, 'Availability: ', 'Location: ')
    moatsData.location = this.getValue(value, 'Location: ', 'Vacation: ')
    moatsData.vacation = this.getValue(value, 'Vacation: ', 'Communication: ')
    moatsData.communication = this.getValue(value, 'Communication: ', 'Start Date: ')
    moatsData.start_date = this.getValue(value, 'Start Date: ', 'Visa Status: ')
    moatsData.status_visa = this.getValue(value, 'Visa Status: ', 'Linkedin: ')
    moatsData.linkedin_url = this.getValue(value, 'Linkedin: ', 'Notes: ')
    moatsData.notes = this.getValue(value, 'Notes: ', null)
    moatsData.file = this.state.file
    this.sendEmail()

  }

  validate = () => {
    let email_req = ''
    let most_req = ''
    let file_req = ''
    let errorText = 'This field is required'
    if (!this.state.value) {
      most_req = errorText
    }
    if (!this.state.email) {
      email_req = errorText
    }
    if (email_req || most_req) {
      this.setState({
        email_req: email_req,
        most_req: most_req,
      })
      return false
    } else {
      return true
    }
  }

  handleSend = () => this.sendEmail()

  async sendEmail() {
    if (this.validate()) {
      this.setState({ isLoaded: false })
      let formData = new FormData()
      formData.append(
        'subject',
        `Submittal (${this.props.data.name},${this.props.data.job_title},${this.props.data.location},${this.props.data.client_name})`
      )
      formData.append('message', this.state.value)
      formData.append('recipients', this.state.email)
      if (this.props.data.resume_url) {
        console.log('attaching..')
        formData.append('file', '/' + this.props.data.resume_url.split('/')[2])
      }

      const response = await axios.post(`${AppConstants.API_BASE_URL}recruit/email/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      this.setState({ isLoaded: true })
      let message = 'Something went wrong, please contact admin'
      if (response.data && response.data['successful']) {
        message = response.data['message']
        this.props.closeDialog('moats', message)
      } else {
        this.props.closeDialog('moats', message)
      }
    }
  }

  // TODO: Make formatDate somewhere in utils to use it throughout application
  formatDate = date => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`
    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`
    return `${month}/${_date}/${date.getFullYear()}`
  }

  getProgress = () =>
    this.state.isLoaded ? false : <CircularProgress size={24} className={this.props.classes.buttonProgress} />

  clearSelection = () => {
    if (window.getSelection) {
      window.getSelection().removeAllRanges()
    } else if (document.selection) {
      document.selection.empty()
    }
  }

  handleFileChange = e => {
    this.setState({ file: e.target.files })
  }


  showConfirmationModal = (confirmationAction) => {
    console.log('showConfirmationModal')
    this.setState({
      confirmationModal: true,
      confirmationAction: confirmationAction
    })
  }

  hideConfirmationModal = () => {
    console.log('hideConfirmationModal')
    this.setState({
      confirmationModal: false,
      confirmationAction: ''
    })
  }

  render() {
    const {
      props,
      handleChange,
      state: { value, email },
      handleSave,
      handleSend,
      getProgress,
      handleFileChange,
    } = this

    return (
      <React.Fragment>

        <Dialog
          fullWidth='true'
          maxWidth='lg'
          open={this.state.confirmationModal}
          onClose={this.hideConfirmationModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`${((this.state.confirmationAction === 'handleSubmit') ? 'Are you sure to submit the details of this candidate?' : 'Update Confirmation')}`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div style={{ 'whiteSpace': 'pre-line' }}>
                {value}
              </div>
              {` ${(this.state.confirmationAction === 'handleSubmit') ? '' : 'Are you sure you want to update this candidate?'}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              if (this.state.confirmationAction === 'handleSubmit') this.handleSubmit();
              if (this.state.confirmationAction === 'handleUpdate') this.handleUpdate();
            }} color="primary">
              OK
            </Button>
            <Button onClick={this.hideConfirmationModal} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={props.open}
          maxWidth="md"
          fullWidth={true}
          onClose={() => props.closeDialog('moats')}
          aria-labelledby="dialog-moats"
          className={props.classes.margin}
        >
          <DialogTitle onClose={() => props.closeDialog('moats')} id="simple-dialog-title">
            Moats
          </DialogTitle>

          <DialogContent className={clsx(props.classes.root, props.classes.textAlignCenter)}>
            <AppTextField
              value={value}
              name="value"
              onChange={handleChange}
              rows={16}
              multiline={true}
              autoFocus
              readOnly
              disabled
              margin="dense"
              id="txtMoats"
              label="MOATS"
              helperText={this.state.most_req.length === 0 ? ' ' : 'This field is required'}
              error={this.state.most_req.length === 0 ? false : true}
              fullWidth
            />
            <p style={{ color: 'red' }}>
              {this.state.required}
              {this.props.required && 'are required'}
            </p>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <div>
              <Input
                label="Resume Url"
                type="file"
                name="resume_url_ajx"
                onChange={props.handleChange}
              />

              {props.data.resume_url ? (
                <a download="true" target="_blank" href={`${AppConstants.SERVER_BASE_URL + props.data.resume_url}`}>
                  Download
                </a>
              ) : null}
              <AppTextField
                value={email}
                name="email"
                onChange={handleChange}
                id="txtSendToEmail"
                label="Email"
                helperText={this.state.email_req.length === 0 ? ' ' : 'This field is required'}
                error={this.state.email_req.length === 0 ? false : true}
                style={{ float: 'left', marginLeft: '10px' }}
                size="sm"
              />
              <input type="hidden" value={props.data.resume_url} name="file" />
              <span> {props.data.resume_url ? props.data.resume_url.split('/')[2] : null}</span>
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <AppButton
                  color="primary"
                  label="Send Email"
                  icon={{ component: Send, position: 'right' }}
                  onClick={() => {
                    this.showConfirmationModal('handleSubmit')
                    //this.handleSubmit()
                  }}
                  disabled={this.state.required}
                />
                {getProgress()}
              </div>
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <AppButton
                  color="primary"
                  label="Update Details"
                  icon={{ component: Send, position: 'right' }}
                  onClick={() => {
                    this.showConfirmationModal('handleUpdate')
                    //this.handleUpdate()
                  }}
                  disabled={!this.state.isLoaded}
                />
                {getProgress()}
              </div>
              {/*<AppButton*/}
              {/*  color="primary"*/}
              {/*  label="Save"*/}
              {/*  icon={{ component: Save, position: 'right' }}*/}
              {/*  onClick={handleSave}*/}
              {/*/>*/}
            </div>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(MoatsDialog)
