import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { withRouter } from 'react-router'
import AppCard from '../../common/AppCard'
import AppButton from '../../common/AppButton'
import AppDataTable from '../../common/AppDataTable'
import AppTextField from '../../common/AppTextField'
import Add from '@material-ui/icons/Add'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import CandidateForm from '../Form'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as AppConstants from '../../../constants/app'
import CandidateModel from '../CandidateModel'
import axios from 'axios'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'
import { SingleSelect } from 'react-select-material-ui'
import { MultipleSelect } from 'react-select-material-ui'
import { CSVLink } from 'react-csv'
import compose from 'recompose/compose'
import { connect } from "react-redux";
import config from '../../../config/index';
import { receiveFetchData } from '../../../actions/index';
import { sub_status ,stage_list} from '../../../constants/status'


const styles = {
  root: {
    flexGrow: 1,
  },
  card: {
    padding: '1rem 0',
  },
  child: {
    backgroundColor: '#fff',
  },
}

class Archived extends React.Component {
  constructor(props) {
    super(props)
    var handleRecruiterFilter = this.handleRecruiterFilter.bind(this)
  }

  state = {
    actual_data: [],
    selectedChild: [],
    pageHeading: 'Archived Candidates',
    data: [],
    filterDatefrom: 0,
    filterDateto: 0,
    logopen: true,
    clients: [],
    jobs: [],
    jobsdrops: [],
    users: [],
    account_managers: [],
    opened: [],
    isLoaded: false,
    auth_token: '',
    placement_filter: false,
    leads_filter: true,
    total_leads_candidate_class: 1,
    submittals_filter: false,
    contract_placement_filter: false,
    page: 1,
    searchvale: 'AAAALL',
    total_record: 0,
    recruiter_filter: 0,
    client_filter: 0,
    job_filter: 0,
    stage_filter: 0,
    total_sub: 0,
    total_inter: 0,
    total_send: 0,
    total_perma: 0,
    total_cont: 0,
    employe_filter: 0,
    total_sub_class: 0,
    total_inter_class: 0,
    total_send_class: 0,
    total_perma_class: 0,
    total_cont_class: 0,
    third_party: [],
    columns: [],
    totalfee: 0,
    totalRcCommission: 0,
    label_text: 'Candidate List',
    total_txt: 'Total Candidates:',
  }

  componentDidMount() {
    // TODO: Make it one call, as I couldn't merge that to a single endpoint due to short time
    this.getAuthToken()
    this.fetchUsers()
    this.fetchClientsAndJobs()
    this.setFiltersOptionByUrl()
  }


  setFiltersOptionByUrl = () => {
    const param = this.useQuery()
    this.setState({
      recruiter_filter: param.get('client_id') || 0,
      stage_filter: param.get('stage_id') || 0,
      from: param.get('from') || 0,
      to: param.get('to') || 0,
    })
  }

  useQuery = () => {
    return new URLSearchParams(this.props.location.search)
  }

  gotoNextPage = () => {
    this.setState(
      {
        isLoaded: false,
        page: this.state.page + 1,
      },
      function () {
        this.fetchCandidates()
      }
    )
  }

  filterJobsOnClient = (jobs, clientId) => jobs.filter((j) => j['detail'].client === clientId || j['value'] === -1)

  updateSearchValue = (evt) => {
    if (evt.target.value != '') {
      this.setState({
        searchvale: evt.target.value,
        page: 1,
      })
    } else {
      this.setState(
        {
          searchvale: 'AAAALL',
          isLoaded: false,
          page: 1,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }

    if (evt.key == 'Enter') {
      this.setState(
        {
          isLoaded: false,
          page: 1,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }
  }

  gotoPrevPage = () => {
    if (this.state.page == 1) {
      return false
    }
    this.setState(
      {
        isLoaded: false,
        page: this.state.page - 1,
      },
      function () {
        this.fetchCandidates()
      }
    )
  }

  async fetchUsers() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/user/`)
    if (response.data && response.data['successful']) {
      this.setState({ users: response.data.result })
    }
  }

  async getAuthToken() {
    let params = {
      username: 'admin@anewrecruit.com',
      password: 'Welcome123!!',
    }
    const response = await axios.post(`${AppConstants.API_BASE_URL}api-token-auth/`, params)
    if (response.data) {
      let token = response.data.token
      this.setState({
        auth_token: token,
      })
    }
  }

  async fetchCandidates(isInitial = false) {
    console.log('in candidate data');
    let token = sessionStorage.getItem('token')
    var headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    }

    const param = this.useQuery()
    const response = await axios.get(
      `${AppConstants.API_BASE_URL}recruit/candidate/get-archived-candidates/` +
        this.state.page +
        '/' +
        this.state.searchvale,
      {
        headers: headers,
        params: {
          recruiter_filter: this.state.recruiter_filter,
          client_filter: this.state.client_filter,
          job_filter: this.state.job_filter,
          stage_filter: this.state.stage_filter,
          employe_filter: this.state.employe_filter,
          from: this.state.filterDatefrom || this.state.from || 0,
          to: this.state.filterDateto || this.state.to || 0,
          applicant: this.state.applicant_filter || false,
          leads: this.state.leads_filter || false,
        },
      }
    )

    if (response.data && response.data['successful']) {
      let allfee = 0,
      totalRcCommissionAmount = 0;
      const candidates = []
      response.data.result.forEach((c) => {
        c.paidtext = c.paid == 0 ? 'No' : 'Yes'
        let revenue = 0;
        totalRcCommissionAmount += c.total_rc_commision_amount;
        candidates.push(this.formatCandidate(c))
      })

      this.setState({
        isLoaded: true,
        data: candidates,
        actual_data: candidates,
        total_record: response.data.total,
        total_sub: response.data.total_sub,
        total_inter: response.data.total_inter,
        total_send: response.data.total_send,
        total_perma: response.data.total_perma,
        total_cont: response.data.total_cont,
        totalfee: allfee,
        totalRcCommission: totalRcCommissionAmount,
        total_applicant: response.data.total_applicant,
        candidate_total: response.data.total_lead_candidate,
      })
    }

  }

  async fetchClientsAndJobs() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client?jobs=true`)
    if (response.data && response.data['successful']) {
      const { clients, jobs } = response.data.result
      this.setState({ clients: clients, jobs: jobs, jobsdrops: jobs })
      this.fetchCandidates(true)
    }
  }

  applyFiltesDate = (e) => {
    let key = e.target.name
    let val = e.target.value
    this.setState({ [key]: val }, function () {
      console.log('filterDatefrom' + this.state.filterDatefrom)
      console.log('filterDateto' + this.state.filterDateto)
      if (this.state.filterDatefrom != 0 && this.state.filterDateto != 0) {
        this.fetchCandidates()
      }
    })
  }

  clearapplyFiltesDate = () => {
    this.setState({ filterDatefrom: 0, filterDateto: 0 }, function () {
      this.fetchCandidates()
    })
  }

  formatDate = (date) => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`
    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`
    let hour = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    return `${month}/${_date}/${date.getFullYear()} (${hour}:${minutes}:${seconds})`
  }

  formatCandidate = (candidate) => {
    const _candidate = { ...candidate }
    if (_candidate.email[0]) {
      _candidate['email_s'] = _candidate.email[0].email
    } else {
      _candidate['email_s'] = ''
    }

    _candidate.email = _candidate.email.map((e) => {
      return {
        id: e.id,
        value: e.email,
        label: 'Email',
      }
    })

    _candidate.created = this.formatDate(_candidate.created)
    _candidate.modified = this.formatDate(_candidate.modified)

    //client_fee_amount=client_fee_amount_candidate.modified
    return _candidate
  }

  get columns() {

      return [
        {
          id: 'recruiter_name',
          numeric: false,
          disablePadding: false,
          label: 'Recruiter',
        },
        {
          id: 'client_name',
          numeric: false,
          disablePadding: false,
          label: 'Client Name',
        },

        {
          id: 'job_title',
          numeric: false,
          disablePadding: false,
          label: 'Job',
        },
        {
          id: 'name',
          numeric: false,
          disablePadding: false,
          label: 'Candidate Name',
        },
        {
          id: 'email_s',
          numeric: false,
          disablePadding: false,
          label: 'Candidate Email',
        },
        {
          id: 'location',
          numeric: false,
          disablePadding: false,
          label: 'Location',
        },
        {
          id: 'salary',
          numeric: false,
          disablePadding: false,
          label: 'Current Salary',
        },
        {
          id: 'source',
          numeric: false,
          disablePadding: false,
          label: 'Source',
        },
        {
          id: 'activity',
          numeric: false,
          disablePadding: false,
          label: 'Activity',
        },
        {
          id: 'created',
          numeric: false,
          disablePadding: false,
          label: 'Created At',
        },
        {
          id: 'job_type',
          numeric: false,
          disablePadding: false,
          label: 'Job Type',
        },
        {
          id: 'status_display',
          numeric: false,
          disablePadding: false,
          label: 'Stage',
        },
      ]

  }

  clearFilters = () => {
    this.setState({
      isLoaded: false,
      selectedChild: [],
      page: 1,
      pageHeading: 'All Candidates',
      searchvale: 'AAAALL',
      recruiter_filter: 0,
      client_filter: 0,
      job_filter: 0,
      stage_filter: 0,
      total_sub_class: 0,
      total_inter_class: 0,
      total_send_class: 0,
      total_perma_class: 0,
      total_cont_class: 0,
      employe_filter: 0,
      total_applicant_class: 0,
      total_leads_candidate_class: 0,
      applicant_filter: false,
      leads_filter: false,
    })

    this.setState({
      placement_filter: false,
      submittals_filter: false,
      contract_placement_filter: false,
    })

    this.setState(
      {
        stage_filter: 0,
      },
      function () {
        this.fetchCandidates()
      }
    )
  }

  handleRecruiterFilter = (e) => {
    let value = e
    this.setState({
      isLoaded: false,
      recruitersValue: value,
      page: 1,
      leads_filter: false,
    })
    if (value !== -1) {
      this.setState(
        {
          recruiter_filter: value,
        },
        function () {
          this.fetchCandidates()
        }
      )
    } else {
      this.setState(
        {
          recruiter_filter: 0,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }
    this.setState({
      submittals_filter: false,
      contract_placement_filter: false,
      leads_filter: false,
    })
  }

  handleClientFilter = (e) => {
    const { jobs } = this.state

    let value = e
    this.setState({
      client_filter: value,
    })
    const client_id = value
    this.setState({
      isLoaded: false,
      page: 1,
      // employe_filter: 0
    })
    if (client_id !== -1) {
      const _jobs = this.filterJobsOnClient([...jobs], value)
      this.setState(
        {
          client_filter: value,
          jobsdrops: _jobs,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    } else {
      this.setState(
        {
          client_filter: 0,
          jobsdrops: jobs,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }
    this.setState({
      // placement_filter: false,
      submittals_filter: false,
      contract_placement_filter: false,
    })
  }

  handleJobFilter = (e) => {
    let value = e
    this.setState({
      job_filter: value,
    })
    const _data = []
    const { actual_data } = this.state
    const job_id = value
    this.setState({
      isLoaded: false,
      page: 1,
      // employe_filter: 0,
      leads_filter: false,
    })
    if (job_id !== -1) {
      this.setState(
        {
          job_filter: value,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    } else {
      this.setState(
        {
          job_filter: 0,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }
    this.setState({
      // placement_filter: false,
      submittals_filter: false,
      leads_filter: false,
      contract_placement_filter: false,
    })
  }

  totalCandidates = () => {
    return this.state.total_record
  }

  handleStageFilter = (e) => {
    //alert(e)
    const _data = []
    const { actual_data } = this.state
    const status_id = e.join(',')
    this.setState({
      isLoaded: false,
      page: 1,
      employe_filter: 0,
      leads_filter: false,
    })
    if (status_id !== -1) {
      this.setState(
        {
          stage_filter: status_id || 0,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    } else {
      this.setState(
        {
          stage_filter: 0,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }
    this.setState({
      placement_filter: false,
      submittals_filter: false,
      contract_placement_filter: false,
      leads_filter: false,
    })
  }

  filterLeads = () => {
    this.setState(
      {
        isLoaded: false,
        page: 1,
        pageHeading: 'Archived Candidates',
        total_sub_class: 0,
        total_inter_class: 0,
        total_send_class: 0,
        total_perma_class: 0,
        total_cont_class: 0,
        stage_filter: 0,
        employe_filter: 0,
        total_applicant_class: 0,
        total_leads_candidate_class: 1,
        applicant_filter: 'false',
        leads_filter: true,
        placement_filter: false,
        total_txt: 'Total Candidates',
        label_text: 'Candidates Lists',
      },
      () => {
        this.fetchCandidates()
      }
    )
  }

  renderStageFilter = () => {
    const { placement_filter } = this.state
    if (!placement_filter)
      return (
        <div id="clients" style={{ width: '100%', position: 'relative', left: '-12%' }}>
          <MultipleSelect
            name="status"
            id="Status"
            label="Stage"
            options={stage_list
            //   [
            //   { value: 1, label: 'Leads' },
            //   { value: 2, label: 'Submittals' },
            //   { value: 3, label: 'Sendout' },
            //   { value: 4, label: 'Interview' },
            //   { value: 5, label: 'Hired/Placement' },
            //   { value: 6, label: 'Rejected' },
            //   { value: 7, label: 'On Hold' },
            //   { value: 14, label: 'Closed' },
            // ]
          }
            onChange={this.handleStageFilter}
            style={{ width: '70%' }}
            value={this.state.stagesValue}
            inputProps={{
              name: 'stagesValue',
              id: 'frmClients',
            }}
          />
        </div>
      )

    return null
  }

  getFilterCardContents = () => {
    return (
      <div className="filter-section">
        <div className="search-section" style={{ display: 'inline-flex' }}>
          <div id="recruiter" style={{ width: '100%' }}>
            <SingleSelect
              placeholder="Recruiter"
              id="frmRecruiter"
              label="Recruiter"
              style={{ width: '70%' }}
              name="recruitersValue"
              value={this.state.recruitersValue}
              options={this.state.users}
              onChange={this.handleRecruiterFilter}
            />
          </div>

          <div id="clients" style={{ width: '100%', position: 'relative', left: '-4%' }}>
            <SingleSelect
              name="clients"
              label="Clients"
              options={this.state.clients}
              onChange={this.handleClientFilter}
              style={{ width: '70%' }}
            />
          </div>
          <div id="clients" style={{ width: '100%', position: 'relative', left: '-8%' }}>
            <SingleSelect
              name="jobs"
              label="Jobs"
              options={this.state.jobsdrops}
              onChange={this.handleJobFilter}
              style={{ width: '70%' }}
            />
          </div>
          {this.renderStageFilter()}


        </div>
      </div>
    )
  }

  getProgress = () => (this.state.isLoaded ? false : <LinearProgress />)

  render() {
    console.log(this.state);
    const {
      getFilterCardContents,
      columns,
      state: { data, opened },
      props: { classes },
      getProgress,
    } = this
    const filterCard = {
      content: getFilterCardContents(),
    }
    const param = this.useQuery()
    const location = param.get('location') || ''

    return (
      <div className={classes.root}>
        <div className={classes.card + ' cardsn'}>{location !== 'dashboard' && <AppCard {...filterCard} />}</div>
        <div className={classes.card}>
          {getProgress()}
          {this.state.total_applicant_class !== 1 && <h1 className="page-heading">{this.state.pageHeading} </h1>}
          
          <AppDataTable
            selected={this.state.selectedChild}
            columns={columns}
            data={data}
            opened=""
            type={this.state.pageHeading}
            label={this.state.label_text}
            search="true"
            updateCandidateData={this.updateCandidateData}
            setDates={this.setDates}
            filterDatefrom={this.state.filterDatefrom || moment().format('YYYY-MM-DD')}
            filterDateto={this.state.filterDateto || moment().format('YYYY-MM-DD')}
            pagging="true"
            token={this.state.auth_token}
            totalText={this.state.total_txt}
            totalCandidates={this.totalCandidates()}
            totalfee={this.state.totalfee}
            showtotalfee={this.state.placement_filter}
            totalRcCommission = {this.state.totalRcCommission}
            showTotalRcCommission = {this.state.placement_filter}
            applicantFilter = {this.state.applicant_filter}
            applyFiltesDate={this.applyFiltesDate}
            clearapplyFiltesDate={this.clearapplyFiltesDate}

            reloadCandidate={this.filterLeads}
            isArchived={1}
            user_id={this.props.auth.user_id}

            rightAction={
              <div>
                <AppTextField
                  size="lg"
                  onKeyUp={this.updateSearchValue}
                  onChange={this.updateSearchValue}
                  label="Search"
                  type="search"
                  margin="normal"
                />

              </div>
            }
          />
          {getProgress()}
          <div className="pager">
            <div className="pager-inner">
              <span className="page-link" onClick={this.gotoPrevPage}>
                Prev
              </span>
              &nbsp; &nbsp;{this.state.page}&nbsp;of&nbsp;{Math.ceil(this.state.total_record / 25)}&nbsp; &nbsp;
              <span className="page-link" onClick={this.gotoNextPage}>
                Next
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
  }

Archived.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    ...state,
    candidates: state.data.candidates,
    jobs: state.data.jobs,
    skills: state.data.skills,
    auth: state.auth
  };
};
const mapDispatchToProps = {
  receiveFetchData: receiveFetchData,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(Archived))
// export default withStyles(styles)(Archived)
