class ClientModel {
  is_new = false
  id = ''
  name = ''
  location = ''
  website = ''
  logo_url = ''
  city = ''
  state = ''
  zip = ''
  country = ''
  linkedin = ''
  status = ''
  employee_count = 1
  company_info = ''
  competitors = ''
  selling_points = ''
  products = ''
  description = ''
  fee = 0
  constructor(is_new) {
    this.is_new = is_new
  }
}

export default ClientModel
