import React from 'react'
import { LinkedIn } from 'react-linkedin-login-oauth2'
import PropTypes from 'prop-types'
import cx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import AppButton from '../common/AppButton'
import AppTextField from '../common/AppTextField'
import TableRow from '@material-ui/core/TableRow'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as AppConstants from '../../constants/app'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import WhereToVoteIcon from '@material-ui/icons/WhereToVote'
import DateRangeIcon from '@material-ui/icons/DateRange'
import AttachMoneyIocn from '@material-ui/icons/AttachMoney'
import ReorderIcon from '@material-ui/icons/Reorder'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import CandidateModel from '../Candidates/CandidateModel'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Snackbar from '@material-ui/core/Snackbar'
import Input from '@material-ui/core/Input'
import './job-details.scss'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    margin: ' 20px 0 20px 80px',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },
  child: {
    backgroundColor: '#fff',
  },
  title: {
    fontSize: 24,
    color: '#3f51b5',
  },
  toolbarButtons: {
    marginRight: 12,
    float: 'right',
  },
  topbar: {
    padding: '10px 10px 10px 0',
    display: 'inline-block',
  },
  divider: {
    margin: `5px 0`,
  },
  bodyname: {
    fontSize: 15,
  },
  shortDesc: {
    marginTop: '.5rem'
  },
  pos: {
    marginTop: 10,
    marginBottom: 12,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

class JobsDetails extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    code: '',
    errorMessage: '',
    actual_data: [],
    selectedChild: [],
    pageHeading: 'Jobs',
    candidate: new CandidateModel(),
    data: [],
    open: false,
    logopen: true,
    clients: [],
    jobs: [],
    hides: [],
    users: [],
    skills: [],
    account_managers: [],
    opened: [],
    isLoaded: false,
    auth_token: '',
    placement_filter: false,
    submittals_filter: false,
    contract_placement_filter: false,
    page: 1,
    searchvale: 'AAAALL',
    total_record: 0,
    recruiter_filter: 0,
    client_filter: 0,
    job_filter: 0,
    stage_filter: 0,
    total_sub: 0,
    total_inter: 0,
    candidate_name: '',
    candidate_email: '',
    candidate_phone: '',
    candidate_file: '',
    total_send: 0,
    total_perma: 0,
    total_cont: 0,
    employe_filter: 0,
    total_sub_class: 0,
    total_inter_class: 0,
    total_send_class: 0,
    total_perma_class: 0,
    total_cont_class: 0,
    redirect_url: 'http://35.166.252.112:81/jobs-details/',
    third_party: [],
    columns: [],
    snackBar: {
      open: false,
      message: '',
      backgroundColor: '#1976d2',
    },
  }

  componentDidMount() {
    this.getAuthToken()
    this.fetchJobs()
  }

  handleSuccess = data => {
    console.log(data.code)
    this.setState({
      code: data.code,
      errorMessage: '',
    })
  }

  handleFailure = error => {
    console.log(error)
    this.setState({
      code: '',
      errorMessage: error.errorMessage,
    })
  }

  async getAuthToken() {
    let params = {
      username: 'admin@anewrecruit.com',
      password: 'Welcome123!!',
    }
    const response = await axios.post(`${AppConstants.API_BASE_URL}api-token-auth/`, params)
    if (response.data) {
      let token = response.data.token
      this.setState({
        auth_token: token,
      })
    }
  }

  validate(data) {
    let errorText = 'This field is required'
    let candidate_name = ''
    let candidate_title = ''
    let candidate_email = ''
    let candidate_phone = ''
    let candidate_file = ''

    if (!data.name) {
      candidate_name = errorText
    }

    if (!data.resume_url) {
      candidate_file = errorText
    }

    if (data.email.length == 0) {
      candidate_email = errorText
    }

    if (data.phone.length == 0) {
      candidate_phone = errorText
    }

    if (candidate_name || candidate_email || candidate_phone || candidate_file) {
      this.setState({ candidate_name, candidate_email, candidate_phone, candidate_file })
      return false
    } else {
      return true
    }
  }

  saveCandidate = () => {
    const _data = this.state.candidate

    const is_valid = this.validate(_data)

    if (is_valid) {
      this.setState({ isLoaded: false })
      var headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${this.state.auth_token}`,
      }

      _data['client'] = this.state.jobs.client
      _data['job'] = this.state.jobs.id
      _data['recruiter'] = 5
      _data['bill_rate_manula'] = 0
      _data['status_visa'] = 1
      _data['send_signature_html'] = 1
      let eml = _data['email']
      _data['email'] = []
      _data['email'].push(eml)

      axios
        .post(`${AppConstants.API_BASE_URL}recruit/candidate/`, _data, { headers: headers })
        .then(response => {
          this.setState({ isLoaded: true, open: false, candidate: new CandidateModel() })
          let message = 'Your application submitted successfully'
          this.openSnackBar(message, 'success-bar')
        })
        .catch(error => {
          this.setState({ isLoaded: true })
          let message = 'Please check your inputs, and try again'
          this.openSnackBar(message, 'error-bar')
          //this.resetState();
        })
    }
  }

  openSnackBar = (message, bgcolor) => {
    const { snackBar } = this.state
    snackBar.open = true
    snackBar.message = message
    snackBar.backgroundColor = bgcolor
    this.setState({ snackBar: snackBar })
  }

  closeSnackBar = () => {
    const { snackBar } = this.state
    snackBar.open = false
    this.setState({ snackBar: snackBar })
  }

  async fetchJobs() {
    let id = ''
    if (this.props.match.params.id) {
      id = this.props.match.params.id
    }
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/job/` + id + `/`)
    if (response.data) {
      let jobs

      jobs = this.formatIT(response.data)
      this.setState({ jobs: jobs, skills: response.data.skill_name, isLoaded: true }, function() {
      })
    }
  }

  formatIT = job => {
    job.salary = job.min_salary + '-' + job.max_salary
    if (job.hides) job.hides = job.hides.split(',')
    else job.hides = []

    if (job.publish_at) job.publish_at = this.formatToCalendarDate(job.publish_at)

    if (job.long_description) job.long_description = job.long_description.replace(/(?:\r\n|\r|\n)/g, '<br>')

    if (job.status == 1) job.status = 'Active'
    else job.status = 'Draft'

    if (job.employment_type == 1) job.employment_type = 'Contract Job'
    else if (job.employment_type == 1) job.employment_type = 'Contract To Hire'
    else job.employment_type = 'Permanent Job'

    this.setState({ hides: job.hides })
    return job
  }

  handleChangeCandidate = e => {
    let xdt = {}
    let index = ''
    const { candidate } = this.state
    const _data = { ...candidate }
    if (e.target.name == 'resume_url_ajx') {
      this.openSnackBar('Please wait while uploading', 'success-bar')
      const dataf = new FormData()
      let candidateID = this.state.data.id
      dataf.append('file', e.target.files[0])
      dataf.append('id', 0)

      var headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${this.state.auth_token}`,
      }

      this.setState({ isLoaded: false })
      let th = this
      axios
        .post(`${AppConstants.API_BASE_URL}recruit/candidate/uploadingfile`, dataf, { headers: headers })
        .then(response => {
          _data['resume_url'] = response.data.result
          th.setState({ candidate: _data })
          th.setState({ isLoaded: true })
          this.openSnackBar('File uploaded successfully', 'success-bar')
        })
        .catch(error => {
          console.log('errrr')

          let message = 'Please check your inputs, and try again'
          this.openSnackBar(message, 'error-bar')
          //this.resetState();
        })
    } else {
      // const {candidate} = this.state;
      // const _data = {...candidate};
      const prop = e.target.name
      _data[prop] = e.target.value
      this.setState({ candidate: _data })
    }
  }

  formatToCalendarDate = date => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`

    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`

    return `${month}/${_date}/${date.getFullYear()}`
  }

  addJobsPopup = () => {
    this.setState({
      open: true,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
    this.fetchJobs()
  }

  applyJob = () => {
    this.setState({
      open: true,
    })
  }

  formatDate = date => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`
    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`
    let hour = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    return `${month}/${_date}/${date.getFullYear()} (${hour}:${minutes}:${seconds})`
  }

  get columns() {
    return [
      { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
      { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
      { id: 'client_name', numeric: false, disablePadding: false, label: 'Client Name' },
      { id: 'salary', numeric: false, disablePadding: false, label: 'Salary' },
      { id: 'employment_type', numeric: false, disablePadding: false, label: 'Job Type' },
      { id: 'category_name', numeric: false, disablePadding: false, label: 'Category' },
      { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
      { id: 'publish_at', numeric: false, disablePadding: false, label: 'Publish' },
    ]
  }

  getChildRows = () => {
    const {
      state: { data },
      getChildRow,
    } = this
    const colSpan = data && data.length > 0 ? Object.keys(data[0]).length + 1 : 1
    return data.map(d => {
      return {
        id: d.id,
        element: getChildRow(d, colSpan),
      }
    })
  }

  rawMarkup() {
    var rawMarkup = this.state.jobs.long_description
    return { __html: rawMarkup }
  }

  formatMoney = (amount, decimalCount = 0, decimal = '.', thousands = ',') => {
    try {
      decimalCount = Math.abs(decimalCount)
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount

      const negativeSign = amount < 0 ? '-' : ''

      let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
      let j = i.length > 3 ? i.length % 3 : 0

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
          : '')
      )
    } catch (e) {
      console.log(e)
      return null
    }
  }

  getProgress = () => (this.state.isLoaded ? false : <LinearProgress/>)

  render() {
    const {
      columns,
      state: { data, opened },
      props: { classes },
      getChildRows,
      getProgress,
    } = this

    return (
      <div className={classes.root}>
        <div className="filter-section">
          <div className="search-section" style={{ display: 'inline-flex' }}></div>
        </div>
        <div className="jobs-details">
          {getProgress()}
          <div className='back-result'>
            <a href='/job-listing'>
              <div className='back-align-center'>
                <ArrowBackIosIcon/>Back to search result
              </div>
            </a>
          </div>
          <Grid container md={18} direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs="12" sm="12" md="7">
              <Card className={classes.card}>
                <CardContent>
                  <div className='jd-header'>
                    <div>
                      <Typography variant="h5" component="h2" className={classes.title}>
                        {this.state.jobs.title}
                        &nbsp;<Chip label={this.state.jobs.employment_type} color='primary' />
                      </Typography>
                    </div>
                    <div className='jd-salary'>
                      <LocalAtmIcon/> {this.formatMoney(this.state.jobs.min_salary)}&nbsp;-&nbsp;
                      ${this.formatMoney(this.state.jobs.max_salary)}
                    </div>
                  </div>
                  <br />
                  <div component="p" className='jd-shortDesc'>
                    {
                      this.state.hides.indexOf('short description') == -1 && (
                        <span>{this.state.jobs.short_description} </span>
                      )
                    }
                  </div>

                  <div className='info-row'>

                    {
                      this.state.hides.indexOf('location') == -1 && (
                        <span className={cx(classes.topbar, 'info')}>
                          <WhereToVoteIcon color="primary"/>
                          <div>Location:&nbsp;{this.state.jobs.location || 'N/A'} </div>
                        </span>
                      )
                    }

                    {
                      this.state.hides.indexOf('client') == -1 && (
                        <span className={cx(classes.topbar, 'info')}>
                          <AssignmentIndIcon color="primary"/>
                          <div>&nbsp;{this.state.jobs.client_name}</div>
                        </span>
                      )
                    }

                    {
                      this.state.hides.indexOf('category') == -1 && (
                        <span className={cx(classes.topbar, 'info')}>
                          <ReorderIcon color="primary"/>
                          <div>&nbsp;{this.state.jobs.category_name}</div>
                        </span>
                      )
                    }

                    {
                      this.state.hides.indexOf('date') == -1 && (
                        <span className={cx(classes.topbar, 'info')}>
                        <DateRangeIcon color="primary"/>&nbsp;
                          <div>{this.state.jobs.publish_at}</div>
                      </span>)
                    }
                  </div>

                  <Divider />

                  <Typography
                    variant="caption"
                    component="div"
                    className={classes.bodyname}
                    dangerouslySetInnerHTML={this.rawMarkup()}
                  ></Typography>
                  <p>
                    {this.state.skills.map(function(skill) {
                      if (skill.title.trim() === '') return null

                      return (
                        <Chip
                          style={{ backgroundColor: '#f2538c' }}
                          size="small"
                          color="secondary"
                          label={skill.title}
                        />
                      )
                    })}
                  </p>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs='12' md='4'>
              <Card className={classes.card}>
                <CardActions className='job-details-apply'>
                  <Table className="spplynow">
                    <TableRow>
                      <div className='job-apply-header'>
                        <div className='job-apply-text'>
                          <span>Apply for a job :</span>
                        </div>
                        <div className='job-linkdin-btn'>

                          <LinkedIn
                            clientId="7890lwot5erjaq"
                            onFailure={this.handleFailure}
                            onSuccess={this.handleSuccess}
                            scope="r_basicprofile"
                            redirectUri={this.state.redirect_url}
                          />
                        </div>
                      </div>
                    </TableRow>
                    <TableRow>
                      <div className='margin-tb'>or</div>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ border: 'none' }}>
                        <AppTextField
                          style={{ margin: '0' }}
                          helperText={this.state.candidate_name.length === 0 ? null : 'This field is required'}
                          error={this.state.candidate_name.length === 0 ? false : true}
                          fullWidth
                          value={this.state.candidate.name || ''}
                          name="name"
                          label="Name"
                          onChange={this.handleChangeCandidate}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ border: 'none' }}>
                        <AppTextField
                          style={{ margin: '0' }}
                          fullWidth
                          name="title"
                          value={this.state.candidate.title || ''}
                          label="Title"
                          onChange={this.handleChangeCandidate}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ border: 'none' }}>
                        <AppTextField
                          style={{ margin: '0' }}
                          helperText={this.state.candidate_email.length === 0 ? null : 'This field is required'}
                          error={this.state.candidate_email.length === 0 ? false : true}
                          fullWidth
                          name="email"
                          value={this.state.candidate.email || ''}
                          label="Email"
                          onChange={this.handleChangeCandidate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ border: 'none' }}>
                        <AppTextField
                          style={{ margin: '0' }}
                          helperText={this.state.candidate_phone.length === 0 ? null : 'This field is required'}
                          error={this.state.candidate_phone.length === 0 ? false : true}
                          fullWidth
                          name="phone"
                          value={this.state.candidate.phone || ''}
                          label="Phone"
                          onChange={this.handleChangeCandidate}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.value}>
                        <Input
                          helperText={this.state.candidate_file.length === 0 ? null : 'This field is required'}
                          error={this.state.candidate_file.length === 0 ? false : true}
                          label="Resume Url"
                          type="file"
                          name="resume_url_ajx"
                          onChange={this.handleChangeCandidate}
                        />

                        {data.resume_url ? (
                          <a download="true" target="_blank" href={`${AppConstants.SERVER_BASE_URL + data.resume_url}`}>
                            Download
                          </a>
                        ) : null}

                        <TableRow>
                          <TableCell style={{ border: 'none' }}>&nbsp;</TableCell>
                        </TableRow>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ display: 'none' }}>
                        <AppTextField
                          multiline={true}
                          rows={5}
                          fullWidth
                          name="title"
                          label="Cover latter"
                          onChange={this.handleChangeCandidate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <div className='dialog-action-button'>
                        <Button onClick={this.saveCandidate} variant="contained" color="primary" label="SAVE">Apply
                          now</Button>
                      </div>
                    </TableRow>
                  </Table>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </div>

        <Snackbar
          className={this.state.snackBar.backgroundColor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackBar.open}
          autoHideDuration={2000}
          onClose={this.closeSnackBar}
          message={<span>{this.state.snackBar.message}</span>}
        />
      </div>
    )
  }
}

JobsDetails.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(JobsDetails)
