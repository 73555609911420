import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ContentLoader from 'react-content-loader'
import CloseIcon from '@material-ui/icons/Close'
import SendIcon from '@material-ui/icons/Send'
import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/core/styles'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import { formatIT, formatMoney } from '../../utility'
import axios from 'axios'
import * as AppConstants from '../../constants/app'

const styles = {
  root: {
    flexGrow: 1,
  },
  card: {
    margin: ' 20px 80px 20px 0px',
    borderRadius: 15,
    border: '1px solid #3f51b5',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },
  jobDetails: {
    width: '39vw'
  },
  jobDetailsHeader: {
    padding: '.5rem 2rem 1rem 1rem',
  },
  jobDetailsBody: {
    padding: '1rem',
    height: '58vh',
    overflow: 'auto'
  },
  applyNowByn: {
    display: 'flex',
  },
  svgRotate: {
    transform: 'rotate(320deg)',
  },
}

const JobDetailsRightPanel =
  ({ jobId, classes, onClose, onApply }) => {

    const [jobs, setJobs] = useState({})
    const [skills, setSkills] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      // calling api
      setLoading(true)
      axios.get(`${AppConstants.API_BASE_URL}recruit/job/` + jobId + `/`)
        .then((response) => {
          if (response.data) {
            setJobs(formatIT(response.data))
            setSkills(response.data.skill_name)
            setLoading(false)
          }
        }).catch((err) => {
        console.log('err', err)
        setLoading(false)
      })
    }, [jobId])

    console.log('jobs', jobs)

    if (loading) {
      return (
        <Grid className='jd-right-container' item xs="12" sm="12" md="5">
          <Card className={classes.card}>
            <div className='jd-content-loader'>
              <ContentLoader  />
            </div>
          </Card>
        </Grid>
      )
    }


    return (
      <Grid className='jd-right-container' item xs="12" sm="12" md="5">
        <Card className={classes.card}>
          <div className={classes.jobDetails}>
            <div className={classes.jobDetailsHeader}>
              <div className='jd-header-div'>
                <div className='job-title-r'>
                  {jobs.title} ANEW({jobs.id})
                </div>
                <div className='j-close-icon'>
                  <CloseIcon onClick={onClose}/>
                </div>
              </div>
              <div className='apply-button'>
                <Button onClick={() => onApply(jobs)} className='jd-apply-button' variant="contained" color='primary'>
                  <div>
                    <SendIcon className={classes.svgRotate}/>&nbsp;
                  </div>
                  <div>
                    Apply Now
                  </div>
                </Button>
              </div>
            </div>
            <hr/>
            <div className={classes.jobDetailsBody}>
              <div className={clsx(classes.flexCenter, 'job-salary-amt')}>
                <LocalAtmIcon/>&nbsp;{formatMoney(jobs.min_salary)} - ${formatMoney(jobs.max_salary)}
              </div>
              <div className='jd-description'>
                <div dangerouslySetInnerHTML={{ __html: jobs.long_description }}/>
              </div>
            </div>
          </div>
        </Card>
      </Grid>
    )
  }

export default withStyles(styles)(JobDetailsRightPanel)