import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import * as AppConstants from '../../constants/app'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import {Line} from 'peity-react'
import Grid from '@material-ui/core/Grid'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import './dashboard.scss'
import {AccessAlarm} from '@material-ui/icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    chartOptions: {
      xAxis: {
        categories: []
      },
      title: {
        text: 'Revenew Generated by Placements'
      },
      subtitle: {
        text: 'Amount in Thousands'
      },
      yAxis: {
        labels: {
          formatter: function () {
            return this.value + 'k'
          }
        }
      },
      chart: {
        type: 'column'
      },
      series: [
        {
          name: 'Permanent',
          data: []
        },
        {
          name: 'Contract',
          data: []
        }
      ],
      plotOptions: {
        series: {
          point: {
            events: {}
          }
        }
      }
    },
    chartOptionsArea: {
      chart: {
        type: 'area'
      },

      title: {
        text: 'Candidate Listing'
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        categories: []
      },
      yAxis: {
        labels: {
          formatter: function () {
            return this.value
          }
        }
      },

      series: [
        {
          name: 'Candidate',
          data: []
        }
      ]
    },
    chartOptionsCircle: {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },
      series: [
        {
          name: 'Brands',
          colorByPoint: true,
          data: []
        }
      ]
    },
    chartOptionsCircle2: {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },
      series: [
        {
          name: 'Brands',
          colorByPoint: true,
          data: []
        }
      ]
    },
    hoverData: null,
    searchvale: 'AAAALL',
    total_record: 0,
    recruiter_filter: 0,
    client_filter: 0,
    job_filter: 0,
    stage_filter: 0,
    employe_filter: 0,
    total_placement: 0,
    totalitem: 0,
    total_sub: 0,
    auth_token: '',
    total_clients: 0,
    total_jobs: 0,
    active_jobs: 0,
    inactive_jobs: 0,
    total_send: 0,
    allPlacementData: [],
    allCandidateData: [],
    yearList: [],
    currentYear: new Date().getFullYear(),
    users: [],
    start_date: new Date(),
    end_date: new Date(),
    shoerrr: false,
    graphStartDate: new Date(),
    graphEndDate: new Date(),
    currentStage: '',
    stageOptions: [
      {value: 1, label: 'Leads'},
      {value: 2, label: 'Submittals'},
      {value: 3, label: 'Sendout'},
      {value: 4, label: 'Interview'},
      {value: 5, label: 'Hired/Placement'},
      {value: 6, label: 'Rejected'},
      {value: 7, label: 'On Hold'},
      {value: 14, label: 'Closed'}
    ]
  }

  componentDidMount() {
    this.getAuthToken()
    this.fetchUsers()
    this.fetchRevenueGeneratedByPlacement()
    this.fetchAllCandidateData()
    this.fetchClientsAndJobs()
    this.fetchCandidateData()
  }

  async fetchUsers() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/user/`)
    if (response.data && response.data['successful']) {
      this.setState({users: response.data.result})
    }
  }

  async fetchCandidateData() {
    const allCandidate = await axios.get(`${AppConstants.API_BASE_URL}recruit/candidate/data/`)

    const revenueMonthWise = []
    const revenueMonthWiseContract = []
    const currentSelectedYear = this.state.currentYear
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']
    var chartOptionsArea = {...this.state.chartOptionsArea}

    // allCandidate.data.result.sort(function(a, b){
    // 	     return new Date(a.created) - new Date(b.created);
    // 	   });

    allCandidate.data.result.forEach(function (item) {
      var monthNam = monthNames[new Date(item.created).getMonth()]
      var yearValue = new Date(item.created).getFullYear()
      if (currentSelectedYear == yearValue) {
        if (monthNam in revenueMonthWise) {
          revenueMonthWise[monthNam] = revenueMonthWise[monthNam] + 1
        } else {
          revenueMonthWise[monthNam] = 1
        }
      }
    })

    chartOptionsArea.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptionsArea.series[0].data = Object.values(revenueMonthWise)
    //chartOptions.series[1].data   = Object.values(revenueMonthWiseContract);

    this.setState({chartOptionsArea, allCandidateData: allCandidate.data.result})
  }

  async getAuthToken() {
    let params = {
      username: 'admin@anewrecruit.com',
      password: 'Welcome123!!'
    }
    const response = await axios.post(`${AppConstants.API_BASE_URL}api-token-auth/`, params)
    if (response.data) {
      let token = response.data.token
      this.setState({
        auth_token: token
      })
    }
  }

  async fetchRevenueGeneratedByPlacement() {
    const permanent = await axios.get(
      `${AppConstants.API_BASE_URL}recruit/candidate/get-all-candidates/1/` + this.state.searchvale,
      {
        params: {
          recruiter_filter: this.state.recruiter_filter,
          client_filter: this.state.client_filter,
          job_filter: this.state.job_filter,
          stage_filter: 5,
          employe_filter: 3
        }
      }
    )

    const contract = await axios.get(
      `${AppConstants.API_BASE_URL}recruit/candidate/get-all-candidates/1/` + this.state.searchvale,
      {
        params: {
          recruiter_filter: this.state.recruiter_filter,
          client_filter: this.state.client_filter,
          job_filter: this.state.job_filter,
          stage_filter: 5,
          employe_filter: 1
        }
      }
    )

    const min = new Date().getFullYear() - 4
    const max = min + 9
    const yearObj = {}
    var yearList = {...this.state.yearList}

    for (var i = min; i <= max; i++) {
      yearObj[i] = i
    }

    yearList = Object.values(yearObj)

    const allPlacement = [...contract.data.result, ...permanent.data.result]
    const revenueMonthWise = []
    const revenueMonthWiseContract = []
    const currentSelectedYear = this.state.currentYear
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']

    var chartOptions = {...this.state.chartOptions}

    allPlacement.sort(function (a, b) {
      return new Date(a.placement_date) - new Date(b.placement_date)
    })

    allPlacement.forEach(function (item) {
      item.net_revenue =
        item.client_fee_amount - parseFloat(item.total_rc_commision_amount) - parseFloat(item.total_am_commision_amount)

      item.net_revenue = parseFloat(item.net_revenue).toFixed(1)

      if (item.net_revenue != 'NaN' && item.placement_date != null) {
        var monthNam = monthNames[new Date(item.placement_date).getMonth()]
        var yearValue = new Date(item.placement_date).getFullYear()
        if (currentSelectedYear == yearValue) {
          if (item.employment_type == 1) {
            if (monthNam in revenueMonthWiseContract) {
              revenueMonthWiseContract[monthNam] = revenueMonthWiseContract[monthNam] + item.net_revenue / 1000
            } else {
              revenueMonthWiseContract[monthNam] = item.net_revenue / 1000
            }
          } else {
            if (monthNam in revenueMonthWise) {
              revenueMonthWise[monthNam] = revenueMonthWise[monthNam] + item.net_revenue / 1000
            } else {
              revenueMonthWise[monthNam] = item.net_revenue / 1000
            }
          }
        }
      }
    })

    chartOptions.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptions.series[0].data = Object.values(revenueMonthWise)
    chartOptions.series[1].data = Object.values(revenueMonthWiseContract)

    this.setState({chartOptions, allPlacementData: allPlacement, yearList})
  }

  onYearChange = e => {
    const {allPlacementData} = this.state
    var chartOptions = {...this.state.chartOptions}
    const currentSelectedYear = e.currentTarget.value
    const revenueMonthWise = []
    const revenueMonthWiseContract = []
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']

    allPlacementData.sort(function (a, b) {
      return new Date(a.placement_date) - new Date(b.placement_date)
    })

    allPlacementData.forEach(function (item) {
      item.net_revenue =
        item.client_fee_amount - parseFloat(item.total_rc_commision_amount) - parseFloat(item.total_am_commision_amount)

      item.net_revenue = parseFloat(item.net_revenue).toFixed(1)

      if (item.net_revenue != 'NaN' && item.placement_date != null) {
        var monthNam = monthNames[new Date(item.placement_date).getMonth()]
        var dateFormat = new Date(item.placement_date).toLocaleDateString()
        var yearValue = new Date(item.placement_date).getFullYear()
        if (item.employment_type == 1) {
          if (currentSelectedYear == yearValue) {
            if (monthNam in revenueMonthWiseContract) {
              revenueMonthWiseContract[monthNam] = revenueMonthWiseContract[monthNam] + item.net_revenue / 1000
            } else {
              revenueMonthWiseContract[monthNam] = item.net_revenue / 1000
            }
          }
        } else {
          if (currentSelectedYear == yearValue) {
            if (monthNam in revenueMonthWise) {
              revenueMonthWise[monthNam] = revenueMonthWise[monthNam] + item.net_revenue / 1000
            } else {
              revenueMonthWise[monthNam] = item.net_revenue / 1000
            }
          }
        }
      }
    })

    chartOptions.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptions.series[0].data = Object.values(revenueMonthWise)
    chartOptions.series[1].data = Object.values(revenueMonthWiseContract)

    this.setState({chartOptions, currentYear: currentSelectedYear})
  }

  onAreaGraphYearChange = e => {
    const {allCandidateData} = this.state
    var chartOptionsArea = {...this.state.chartOptionsArea}
    const currentSelectedYear = e.currentTarget.value
    const revenueMonthWise = []
    const revenueMonthWiseContract = []
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']

    allCandidateData.forEach(function (item) {
      var monthNam = monthNames[new Date(item.created).getMonth()]
      var dateFormat = new Date(item.created).toLocaleDateString()
      var yearValue = new Date(item.created).getFullYear()

      if (currentSelectedYear == yearValue) {
        if (monthNam in revenueMonthWise) {
          revenueMonthWise[monthNam] = revenueMonthWise[monthNam] + 1
        } else {
          revenueMonthWise[monthNam] = 1
        }
      }
    })

    chartOptionsArea.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptionsArea.series[0].data = Object.values(revenueMonthWise)
    //chartOptions.series[1].data   = Object.values(revenueMonthWiseContract);

    this.setState({chartOptionsArea, currentYear: currentSelectedYear})
  }

  onMonthChange = e => {
    const type = e.currentTarget.value
    const {allPlacementData} = this.state
    var chartOptions = {...this.state.chartOptions}
    const currentSelectedYear = this.state.currentYear
    const revenueMonthWise = []
    const revenueMonthWiseContract = []
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']

    allPlacementData.sort(function (a, b) {
      return new Date(a.placement_date) - new Date(b.placement_date)
    })

    allPlacementData.forEach(function (item) {
      // if(item.employment_type==1){
      //     item.net_revenue= (item.bill_rate*item.weekly_total_hours)-(item.pay_rate*item.weekly_total_hours);
      //    }else{
      //     item.net_revenue=parseFloat( item.salary)-(item.client_fee_amount+parseFloat(item.total_rc_commision_amount)+parseFloat(item.total_am_commision_amount));
      //    }

      item.net_revenue =
        item.client_fee_amount - parseFloat(item.total_rc_commision_amount) - parseFloat(item.total_am_commision_amount)

      item.net_revenue = parseFloat(item.net_revenue).toFixed(1)

      if (item.net_revenue != 'NaN' && item.placement_date != null) {
        var monthNam = monthNames[new Date(item.placement_date).getMonth()]
        var dateFormat = new Date(item.placement_date).toLocaleDateString()
        var yearValue = new Date(item.placement_date).getFullYear()
        if (item.employment_type == 1) {
          if (type == monthNam && currentSelectedYear == yearValue) {
            if (dateFormat in revenueMonthWiseContract) {
              revenueMonthWiseContract[dateFormat] = revenueMonthWiseContract[dateFormat] + item.net_revenue / 1000
            } else {
              revenueMonthWiseContract[dateFormat] = item.net_revenue / 1000
            }
          }
        } else {
          if (type == monthNam && currentSelectedYear == yearValue) {
            if (dateFormat in revenueMonthWise) {
              revenueMonthWise[dateFormat] = revenueMonthWise[dateFormat] + item.net_revenue / 1000
            } else {
              revenueMonthWise[dateFormat] = item.net_revenue / 1000
            }
          }
        }
      }
    })

    chartOptions.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptions.series[0].data = Object.values(revenueMonthWise)
    chartOptions.series[1].data = Object.values(revenueMonthWiseContract)

    this.setState({chartOptions})
  }

  onAreaGraphMonthChange = e => {
    const type = e.currentTarget.value
    const {allCandidateData} = this.state
    var chartOptionsArea = {...this.state.chartOptionsArea}
    const currentSelectedYear = this.state.currentYear
    const revenueMonthWise = []
    const revenueMonthWiseContract = []
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']

    allCandidateData.forEach(function (item) {
      var monthNam = monthNames[new Date(item.created).getMonth()]
      var dateFormat = new Date(item.created).toLocaleDateString()
      var yearValue = new Date(item.created).getFullYear()

      if (type == monthNam && currentSelectedYear == yearValue) {
        if (dateFormat in revenueMonthWise) {
          revenueMonthWise[dateFormat] = revenueMonthWise[dateFormat] + 1
        } else {
          revenueMonthWise[dateFormat] = 1
        }
      }
    })

    chartOptionsArea.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptionsArea.series[0].data = Object.values(revenueMonthWise)
    //chartOptions.series[1].data   = Object.values(revenueMonthWiseContract);

    this.setState({chartOptionsArea})
  }

  onSiteChanged = e => {
    const type = e.currentTarget.value
    const {allPlacementData} = this.state
    var chartOptions = {...this.state.chartOptions}

    const revenueMonthWise = []
    const revenueMonthWiseContract = []
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']

    allPlacementData.sort(function (a, b) {
      return new Date(a.placement_date) - new Date(b.placement_date)
    })

    allPlacementData.forEach(function (item) {
      // if(item.employment_type==1){
      //    item.net_revenue= (item.bill_rate*item.weekly_total_hours)-(item.pay_rate*item.weekly_total_hours);
      //   }else{
      //    item.net_revenue=parseFloat( item.salary)-(item.client_fee_amount+parseFloat(item.total_rc_commision_amount)+parseFloat(item.total_am_commision_amount));
      //   }

      item.net_revenue =
        item.client_fee_amount - parseFloat(item.total_rc_commision_amount) - parseFloat(item.total_am_commision_amount)

      item.net_revenue = parseFloat(item.net_revenue).toFixed(1)

      if (item.net_revenue != 'NaN' && item.placement_date != null) {
        var monthNam = monthNames[new Date(item.placement_date).getMonth()]
        var yearValue = new Date(item.placement_date).getFullYear()

        if (type == 'month') {
          if (item.employment_type == 1) {
            if (monthNam in revenueMonthWiseContract) {
              revenueMonthWiseContract[monthNam] = revenueMonthWiseContract[monthNam] + item.net_revenue / 1000
            } else {
              revenueMonthWiseContract[monthNam] = item.net_revenue / 1000
            }
          } else {
            if (monthNam in revenueMonthWise) {
              revenueMonthWise[monthNam] = revenueMonthWise[monthNam] + item.net_revenue / 1000
            } else {
              revenueMonthWise[monthNam] = item.net_revenue / 1000
            }
          }
        } else if (type == 'year') {
          if (item.employment_type == 1) {
            if (yearValue in revenueMonthWiseContract) {
              revenueMonthWiseContract[yearValue] = revenueMonthWiseContract[yearValue] + item.net_revenue / 1000
            } else {
              revenueMonthWiseContract[yearValue] = item.net_revenue / 1000
            }
          } else {
            if (yearValue in revenueMonthWise) {
              revenueMonthWise[yearValue] = revenueMonthWise[yearValue] + item.net_revenue / 1000
            } else {
              revenueMonthWise[yearValue] = item.net_revenue / 1000
            }
          }
        } else {
          if (monthNam == 'JAN' || monthNam == 'FEB' || monthNam == 'MAR') {
            if (item.employment_type == 1) {
              if ('Q1' in revenueMonthWiseContract) {
                revenueMonthWiseContract['Q1'] = revenueMonthWiseContract['Q1'] + item.net_revenue / 1000
              } else {
                revenueMonthWiseContract['Q1'] = item.net_revenue / 1000
              }
            } else {
              if ('Q1' in revenueMonthWise) {
                revenueMonthWise['Q1'] = revenueMonthWise['Q1'] + item.net_revenue / 1000
              } else {
                revenueMonthWise['Q1'] = item.net_revenue / 1000
              }
            }
          } else if (monthNam == 'APR' || monthNam == 'MAY' || monthNam == 'JUN') {
            if (item.employment_type == 1) {
              if ('Q2' in revenueMonthWiseContract) {
                revenueMonthWiseContract['Q2'] = revenueMonthWiseContract['Q2'] + item.net_revenue / 1000
              } else {
                revenueMonthWiseContract['Q2'] = item.net_revenue / 1000
              }
            } else {
              if ('Q2' in revenueMonthWise) {
                revenueMonthWise['Q2'] = revenueMonthWise['Q2'] + item.net_revenue / 1000
              } else {
                revenueMonthWise['Q2'] = item.net_revenue / 1000
              }
            }
          } else if (monthNam == 'JUL' || monthNam == 'AUG' || monthNam == 'SEPT') {
            if (item.employment_type == 1) {
              if ('Q3' in revenueMonthWiseContract) {
                revenueMonthWiseContract['Q3'] = revenueMonthWiseContract['Q3'] + item.net_revenue / 1000
              } else {
                revenueMonthWiseContract['Q3'] = item.net_revenue / 1000
              }
            } else {
              if ('Q3' in revenueMonthWise) {
                revenueMonthWise['Q3'] = revenueMonthWise['Q3'] + item.net_revenue / 1000
              } else {
                revenueMonthWise['Q3'] = item.net_revenue / 1000
              }
            }
          } else {
            if (item.employment_type == 1) {
              if ('Q4' in revenueMonthWiseContract) {
                revenueMonthWiseContract['Q4'] = revenueMonthWiseContract['Q4'] + item.net_revenue / 1000
              } else {
                revenueMonthWiseContract['Q4'] = item.net_revenue / 1000
              }
            } else {
              if ('Q4' in revenueMonthWise) {
                revenueMonthWise['Q4'] = revenueMonthWise['Q4'] + item.net_revenue / 1000
              } else {
                revenueMonthWise['Q4'] = item.net_revenue / 1000
              }
            }
          }
        }
      }
    })

    chartOptions.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptions.series[0].data = Object.values(revenueMonthWise)
    chartOptions.series[1].data = Object.values(revenueMonthWiseContract)

    this.setState({chartOptions})
  }

  onAreaGrapChanged = e => {
    const type = e.currentTarget.value
    const {allCandidateData} = this.state
    const currentSelectedYear = this.state.currentYear
    var chartOptionsArea = {...this.state.chartOptionsArea}

    const revenueMonthWise = []
    const revenueMonthWiseContract = []
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']

    allCandidateData.forEach(function (item) {
      var monthNam = monthNames[new Date(item.created).getMonth()]
      var yearValue = new Date(item.created).getFullYear()

      if (type == 'month') {
        if (currentSelectedYear == yearValue) {
          if (monthNam in revenueMonthWise) {
            revenueMonthWise[monthNam] = revenueMonthWise[monthNam] + 1
          } else {
            revenueMonthWise[monthNam] = 1
          }
        }
      } else if (type == 'year') {
        if (yearValue in revenueMonthWise) {
          revenueMonthWise[yearValue] = revenueMonthWise[yearValue] + 1
        } else {
          revenueMonthWise[yearValue] = 1
        }
      } else {
        if (currentSelectedYear == yearValue) {
          if (monthNam == 'JAN' || monthNam == 'FEB' || monthNam == 'MAR') {
            if ('Q1' in revenueMonthWise) {
              revenueMonthWise['Q1'] = revenueMonthWise['Q1'] + 1
            } else {
              revenueMonthWise['Q1'] = 1
            }
          } else if (monthNam == 'APR' || monthNam == 'MAY' || monthNam == 'JUN') {
            if ('Q2' in revenueMonthWise) {
              revenueMonthWise['Q2'] = revenueMonthWise['Q2'] + 1
            } else {
              revenueMonthWise['Q2'] = 1
            }
          } else if (monthNam == 'JUL' || monthNam == 'AUG' || monthNam == 'SEPT') {
            if ('Q3' in revenueMonthWise) {
              revenueMonthWise['Q3'] = revenueMonthWise['Q3'] + 1
            } else {
              revenueMonthWise['Q3'] = 1
            }
          } else {
            if ('Q4' in revenueMonthWise) {
              revenueMonthWise['Q4'] = revenueMonthWise['Q4'] + 1
            } else {
              revenueMonthWise['Q4'] = 1
            }
          }
        }
      }
    })

    chartOptionsArea.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptionsArea.series[0].data = Object.values(revenueMonthWise)
    //chartOptions.series[1].data   = Object.values(revenueMonthWiseContract);
    this.setState({chartOptionsArea})
  }

  async fetchAllCandidateData() {
    console.log('seachVal', this.state.searchvale)
    const response = await axios.get(
      `${AppConstants.API_BASE_URL}recruit/candidate/get-all-candidates/1/` + this.state.searchvale,
      {
        params: {
          recruiter_filter: this.state.recruiter_filter,
          client_filter: this.state.client_filter,
          job_filter: this.state.job_filter,
          stage_filter: this.state.stage_filter,
          employe_filter: this.state.employe_filter
        }
      }
    )
    if (response.data && response.data['successful']) {
      var chartOptionsCircle = {...this.state.chartOptionsCircle}
      chartOptionsCircle.series[0].data = [
        {
          name: 'Submited',
          y: response.data.total_sub
        },
        {
          name: 'Interview',
          y: response.data.total_inter
        },
        {
          name: 'Sendout',
          y: response.data.total_send
        },
        {
          name: 'Permanent Placements',
          y: response.data.total_perma
        },
        {
          name: 'Contract Placements',
          y: response.data.total_cont
        }
      ]

      var chartOptionsCircle2 = {...this.state.chartOptionsCircle2}
      chartOptionsCircle2.series[0].data = [
        {
          name: 'Total Candidates',
          y: response.data.total
        },
        {
          name: 'Submited',
          y: response.data.total_sub
        }
      ]

      this.setState({
        totalitem: response.data.total,
        total_sub: response.data.total_sub,
        total_inter: response.data.total_inter,
        total_send: response.data.total_send,
        total_perma: response.data.total_perma,
        total_cont: response.data.total_cont,
        total_placement: response.data.total_perma + response.data.total_cont,
        chartOptionsCircle,
        chartOptionsCircle2
      })
    }
  }

  async fetchClientsAndJobs() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client?jobs=true`)
    if (response.data && response.data['successful']) {
      const {clients, jobs} = response.data.result
      const active = jobs.filter(item => item.detail.status === 1)
      const active_ = active.length
      this.setState({
        total_clients: clients.length,
        total_jobs: jobs.length,
        active_jobs: active_,
        inactive_jobs: jobs.length - active_
      })
    }
  }

  setHoverData = e => {
    // The chart is not updated because `chartOptions` has not changed.
    this.setState({hoverData: e.target.category})
  }

  onRecuiterChange = e => {
    const recruit_value = e.target.value
    this.setState({recruiter_filter: recruit_value}, function () {
      this.fetchRevenueGeneratedByPlacement()
    })
  }

  onGraphAreaRecuiterChange = e => {
    const recruit_value = e.target.value
    const type = e.currentTarget.value
    const {allCandidateData} = this.state
    var chartOptionsArea = {...this.state.chartOptionsArea}
    const currentSelectedYear = this.state.currentYear
    const revenueMonthWise = []
    const revenueMonthWiseContract = []
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']

    allCandidateData.forEach(function (item) {
      var monthNam = monthNames[new Date(item.created).getMonth()]
      var dateFormat = new Date(item.created).toLocaleDateString()
      var yearValue = new Date(item.created).getFullYear()
      var recuiter_type = item.send_signature_html
      if (currentSelectedYear == yearValue && recuiter_type == recruit_value) {
        if (monthNam in revenueMonthWise) {
          revenueMonthWise[monthNam] = revenueMonthWise[monthNam] + 1
        } else {
          revenueMonthWise[monthNam] = 1
        }
      }
    })

    chartOptionsArea.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptionsArea.series[0].data = Object.values(revenueMonthWise)
    //chartOptions.series[1].data   = Object.values(revenueMonthWiseContract);

    this.setState({chartOptionsArea})
  }

  onGraphAreaStageChange = e => {
    const stage_value = e.target.value

    const {allCandidateData} = this.state
    var chartOptionsArea = {...this.state.chartOptionsArea}
    const currentSelectedYear = this.state.currentYear
    const revenueMonthWise = []
    const revenueMonthWiseContract = []
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']

    allCandidateData.forEach(function (item) {
      var monthNam = monthNames[new Date(item.created).getMonth()]
      var dateFormat = new Date(item.created).toLocaleDateString()
      var yearValue = new Date(item.created).getFullYear()
      var stage_type = item.status
      if (currentSelectedYear == yearValue && stage_type == stage_value) {
        if (monthNam in revenueMonthWise) {
          revenueMonthWise[monthNam] = revenueMonthWise[monthNam] + 1
        } else {
          revenueMonthWise[monthNam] = 1
        }
      }
    })

    chartOptionsArea.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptionsArea.series[0].data = Object.values(revenueMonthWise)
    //chartOptions.series[1].data   = Object.values(revenueMonthWiseContract);

    this.setState({chartOptionsArea, currentStage: stage_value})
  }

  onClearFilter = e => {
    this.setState({recruiter_filter: 0, currentYear: new Date().getFullYear()}, function () {
      this.fetchRevenueGeneratedByPlacement()
    })
  }

  onClearFilterAreaGraph = e => {
    this.setState({recruiter_filter: 0, currentYear: new Date().getFullYear(), currentStage: ''}, function () {
      this.fetchCandidateData()
    })
  }

  updateSeries = () => {
    // The chart is updated only with new options.
    this.setState({
      chartOptions: {
        series: [{data: [Math.random() * 5, 2, 1]}]
      }
    })
  }

  handleChangedate = ({startDate, endDate}) => {
    this.setState({start_date: startDate, end_date: endDate})
  }

  handleChangeStart = startDate => {
    this.setState({start_date: startDate}, function () {
      this.onDateChangeFilter()
    })
  }

  handleChangeEnd = endDate => {
    this.setState({end_date: endDate}, function () {
      this.onDateChangeFilter()
    })
  }

  graphHandleChangeStart = startDate => {
    this.setState({graphStartDate: startDate}, function () {
      this.onDateChangeFilterAreaGraph()
    })
  }

  graphHandleChangeEnd = endDate => {
    this.setState({graphEndDate: endDate}, function () {
      this.onDateChangeFilterAreaGraph()
    })
  }

  onDateChangeFilterAreaGraph = () => {
    const {allCandidateData, graphStartDate, graphEndDate} = this.state
    var chartOptionsArea = {...this.state.chartOptionsArea}
    const currentSelectedYear = this.state.currentYear
    const revenueMonthWise = []
    const revenueMonthWiseContract = []
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']

    allCandidateData.forEach(function (item) {
      var monthNam = monthNames[new Date(item.created).getMonth()]
      var dateFormat = new Date(item.created).toLocaleDateString()
      var startDateString = new Date(graphStartDate).getTime()
      var endDateString = new Date(graphEndDate).getTime()
      var dateFormatString = new Date(item.created).getTime()
      var yearValue = new Date(item.created).getFullYear()

      if (
        currentSelectedYear == yearValue &&
        dateFormatString >= startDateString &&
        dateFormatString <= endDateString
      ) {
        if (dateFormat in revenueMonthWise) {
          revenueMonthWise[dateFormat] = revenueMonthWise[dateFormat] + 1
        } else {
          revenueMonthWise[dateFormat] = 1
        }
      }
    })

    chartOptionsArea.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptionsArea.series[0].data = Object.values(revenueMonthWise)
    //chartOptions.series[1].data   = Object.values(revenueMonthWiseContract);
    this.setState({chartOptionsArea})
  }

  onDateChangeFilter = () => {
    const {allPlacementData, start_date, end_date} = this.state
    var chartOptions = {...this.state.chartOptions}
    const currentSelectedYear = this.state.currentYear
    const revenueMonthWise = []
    const revenueMonthWiseContract = []
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']

    allPlacementData.sort(function (a, b) {
      return new Date(a.placement_date) - new Date(b.placement_date)
    })

    allPlacementData.forEach(function (item) {
      // if(item.employment_type==1){
      // 	 item.net_revenue= (item.bill_rate*item.weekly_total_hours)-(item.pay_rate*item.weekly_total_hours);
      // 	}else{
      // 	 item.net_revenue=parseFloat( item.salary)-(item.client_fee_amount+parseFloat(item.total_rc_commision_amount)+parseFloat(item.total_am_commision_amount));
      // 	}
      item.net_revenue =
        item.client_fee_amount - parseFloat(item.total_rc_commision_amount) - parseFloat(item.total_am_commision_amount)

      item.net_revenue = parseFloat(item.net_revenue).toFixed(1)

      if (item.net_revenue != 'NaN' && item.placement_date != null) {
        var monthNam = monthNames[new Date(item.placement_date).getMonth()]
        var dateFormat = new Date(item.placement_date).toLocaleDateString()
        var startDateString = new Date(start_date).getTime()
        var endDateString = new Date(end_date).getTime()
        var dateFormatString = new Date(item.placement_date).getTime()
        var yearValue = new Date(item.placement_date).getFullYear()

        if (
          currentSelectedYear == yearValue &&
          dateFormatString >= startDateString &&
          dateFormatString <= endDateString
        ) {
          if (item.employment_type == 1) {
            if (dateFormat in revenueMonthWiseContract) {
              revenueMonthWiseContract[dateFormat] = revenueMonthWiseContract[dateFormat] + item.net_revenue / 1000
            } else {
              revenueMonthWiseContract[dateFormat] = item.net_revenue / 1000
            }
          } else {
            if (dateFormat in revenueMonthWise) {
              revenueMonthWise[dateFormat] = revenueMonthWise[dateFormat] + item.net_revenue / 1000
            } else {
              revenueMonthWise[dateFormat] = item.net_revenue / 1000
            }
          }
        }
      }
    })

    chartOptions.xAxis.categories = Object.keys(revenueMonthWise)
    chartOptions.series[0].data = Object.values(revenueMonthWise)
    chartOptions.series[1].data = Object.values(revenueMonthWiseContract)

    this.setState({chartOptions})
  }

  render() {
    const {chartOptions, hoverData, chartOptionsArea, chartOptionsCircle, chartOptionsCircle2} = this.state
    console.log('chartOptionsCircle', chartOptionsCircle)
    return (
      <div style={{marginTop: '70px', marginLeft: '70px'}}>
        <div className="row" style={{width: '99%'}}>
          <h3>Anew Recruit Dashboard</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} className="cat-box">
              <h4>Total Candidates</h4>
              <p>{this.state.totalitem}</p>
              <Line
                values={[10, 20, 22, 24, 28, 30, 28, 10, 15, 20, 10]}
                min="0"
                max="100"
                fill="#9966ff"
                width="300"
                height="50"
                stroke="#4d89f9"
                strokeWidth="2"
                delimiter="sale"
              />
            </Grid>

            <Grid item xs={12} sm={3} className="cat-box">
              <h4>Total Client</h4>
              <p>{this.state.total_clients}</p>
              <Line
                values={[10, 20, 22, 24, 28, 30, 28, 10, 15, 20, 10]}
                min="0"
                max="100"
                fill="#9966ff"
                width="300"
                height="50"
                stroke="#4d89f9"
                strokeWidth="2"
                delimiter="sale"
              />
            </Grid>

            <Grid item xs={12} sm={3} className="cat-box">
              <h4>Total Jobs</h4>
              <p>{this.state.total_jobs}</p>
              <Line
                values={[10, 20, 22, 24, 28, 30, 28, 10, 15, 20, 10]}
                min="0"
                max="100"
                fill="#9966ff"
                width="300"
                height="50"
                stroke="#4d89f9"
                strokeWidth="2"
                delimiter="sale"
              />
            </Grid>

            <Grid item xs={12} sm={3} className="cat-box">
              <h4>Total Placements</h4>
              <p>{this.state.total_placement}</p>
              <Line
                values={[10, 20, 22, 24, 28, 30, 28, 10, 15, 20, 10]}
                min="0"
                max="100"
                fill="#9966ff"
                width="300"
                height="50"
                stroke="#4d89f9"
                strokeWidth="2"
                delimiter="sale"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} style={{marginTop: 30}}>
            <Grid item xs={12} sm={11}>
              <span className="filterGrap">
                <input type="radio" value="month" name="filterGrap" onChange={this.onSiteChanged}/>
                MONTHLY
              </span>
              <span className="filterGrap">
                <input type="radio" value="quater" name="filterGrap" onChange={this.onSiteChanged}/>
                QUATERLY
              </span>
              <span className="filterGrap">
                <input type="radio" value="year" name="filterGrap" onChange={this.onSiteChanged}/>
                YEARLY
              </span>

              <select className="monthSelection" onChange={this.onMonthChange}>
                <option value="JAN">JAN</option>
                <option value="FEB">FEB</option>
                <option value="MAR">MAR</option>
                <option value="APR">APR</option>
                <option value="MAY">MAY</option>
                <option value="JUN">JUN</option>
                <option value="JUL">JUL</option>
                <option value="AUG">AUG</option>
                <option value="SEPT">SEPT</option>
                <option value="OCT">OCT</option>
                <option value="NOV">NOV</option>
                <option value="DEC">DEC</option>
              </select>

              <select className="yearSelection" onChange={this.onYearChange} value={this.state.currentYear}>
                <option value="">Year</option>
                {this.state.yearList.map(year => {
                  return <option value={year}>{year}</option>
                })}
              </select>

              <select className="recruiterSelection" onChange={this.onRecuiterChange}>
                <option value="0">Recruiter</option>
                {this.state.users.map(recruit => {
                  return <option value={recruit.value}>{recruit.label}</option>
                })}
              </select>

              <DatePicker
                className="datepicker-ranage"
                selected={this.state.start_date}
                selectsStart
                startDate={this.state.start_date}
                dateFormat="MM/dd/yyyy"
                endDate={this.state.end_date}
                onChange={this.handleChangeStart}
              />

              <DatePicker
                className="datepicker-ranage"
                selected={this.state.end_date}
                selectsEnd
                startDate={this.state.start_date}
                dateFormat="MM/dd/yyyy"
                endDate={this.state.end_date}
                onChange={this.handleChangeEnd}
                minDate={this.state.start_date}
              />

              <button className="clear-btn" onClick={this.onClearFilter}>
                CLEAR
              </button>

              <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
            </Grid>
          </Grid>

          <Grid container spacing={1} style={{marginTop: 30}}>
            <Grid item xs={12} sm={11}>
              <span className="filterGrap">
                <input type="radio" value="month" name="filterGrap" onChange={this.onAreaGrapChanged}/>
                MONTHLY
              </span>
              <span className="filterGrap">
                <input type="radio" value="quater" name="filterGrap" onChange={this.onAreaGrapChanged}/>
                QUATERLY
              </span>
              <span className="filterGrap">
                <input type="radio" value="year" name="filterGrap" onChange={this.onAreaGrapChanged}/>
                YEARLY
              </span>

              <select className="monthSelection" onChange={this.onAreaGraphMonthChange}>
                <option value="JAN">JAN</option>
                <option value="FEB">FEB</option>
                <option value="MAR">MAR</option>
                <option value="APR">APR</option>
                <option value="MAY">MAY</option>
                <option value="JUN">JUN</option>
                <option value="JUL">JUL</option>
                <option value="AUG">AUG</option>
                <option value="SEPT">SEPT</option>
                <option value="OCT">OCT</option>
                <option value="NOV">NOV</option>
                <option value="DEC">DEC</option>
              </select>

              <select className="yearSelection" onChange={this.onAreaGraphYearChange} value={this.state.currentYear}>
                <option value="">Year</option>
                {this.state.yearList.map(year => {
                  return <option value={year}>{year}</option>
                })}
              </select>

              <select className="recruiterSelection" onChange={this.onGraphAreaRecuiterChange}>
                <option value="0">Recruiter</option>
                {this.state.users.map(recruit => {
                  return <option value={recruit.value}>{recruit.label}</option>
                })}
              </select>
              <select
                className="recruiterSelection"
                onChange={this.onGraphAreaStageChange}
                value={this.state.currentStage}
              >
                <option value="0">Stage</option>
                {this.state.stageOptions.map(option => {
                  return <option value={option.value}>{option.label}</option>
                })}
              </select>

              <DatePicker
                className="datepicker-ranage"
                selected={this.state.graphStartDate}
                selectsStart
                startDate={this.state.graphStartDate}
                dateFormat="MM/dd/yyyy"
                endDate={this.state.graphEndDate}
                onChange={this.graphHandleChangeStart}
              />

              <DatePicker
                className="datepicker-ranage"
                selected={this.state.graphEndDate}
                selectsEnd
                startDate={this.state.graphStartDate}
                dateFormat="MM/dd/yyyy"
                endDate={this.state.graphEndDate}
                onChange={this.graphHandleChangeEnd}
                minDate={this.state.graphStartDate}
              />

              <button className="clear-btn" onClick={this.onClearFilterAreaGraph}>
                CLEAR
              </button>

              <HighchartsReact highcharts={Highcharts} options={chartOptionsArea}/>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{marginTop: 30}}>
            <Grid item xs={12} sm={4} className="cat-box4">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4}>
                  <Icon>
                    <AccessAlarm/>
                  </Icon>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4> {this.state.active_jobs} Jobs Active</h4>
                  <p>{this.state.inactive_jobs} Jobs inactive</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} className="cat-box4">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={3}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptionsCircle}
                    containerProps={{style: {height: '145px'}}}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <h4>{this.state.total_placement} Placement</h4>
                  <p> Last 1 month</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} className="cat-box4">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={3}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptionsCircle2}
                    containerProps={{style: {height: '130px'}}}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <h4>{this.state.total_sub} Submitals</h4>
                  <p>Last 1 month</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

export default withStyles()(Dashboard)
