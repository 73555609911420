import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import AppMoreDynamicTextField from '../../common/AppMoreDynamicTextField'
import CandidateModel from '../CandidateModel'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  root: {
    padding: 0,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  header: {
    textAlign: 'center',
    marginTop: 0,
  },
  contactInfo: {
    display: 'flex',
    padding: '0 16px',
  },
  phone: {
    flex: 1,
  },
  email: {
    flex: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

class ClientContactInfoDialog extends React.Component {
  state = {
    data: new CandidateModel(),
  }

  componentWillMount() {
    this.setState({ data: { ...this.props.data } })
  }

  handleChange = e => {
    const { data } = this.state
    const _data = { ...data }
    const prop = e.target.name
    _data[prop] = e.target.value
    this.setState({ data: _data })
    console.log(_data)
    if (this.props.handleChange) {
      this.props.handleChange(e)
      console.log(e)
    }
  }

  render() {
    const { props, handleChange } = this
    const { data } = this.state
    return (
      <React.Fragment>
        <Dialog
          open={props.open}
          maxWidth="md"
          fullWidth={true}
          onClose={() => props.closeDialog('linkedIn')}
          aria-labelledby="dialog-moats"
          className={props.classes.margin}
        >
          <DialogTitle onClose={() => props.closeDialog('linkedIn')} id="simple-dialog-title">
            Contact
          </DialogTitle>

          <DialogContent className={props.classes.root}>
            <h2 className={props.classes.header}>Client Contact Person</h2>
            <div className={props.classes.contactInfo}>
              <div className={props.classes.phone}>
                <AppMoreDynamicTextField
                  id="txtClientName"
                  label="Name"
                  name="client_contact_name"
                  fullWidth
                  data={data.client_contact_name}
                  onChange={handleChange}
                />
              </div>
              <div className={props.classes.phone}>
                <AppMoreDynamicTextField
                  id="txtClientPhone"
                  label="Phone"
                  name="client_contact_phone"
                  fullWidth
                  data={data.client_contact_phone}
                  onChange={handleChange}
                />
              </div>
              <div className={props.classes.email}>
                <AppMoreDynamicTextField
                  id="txtClientEmail"
                  label="Email"
                  name="client_contact_email"
                  fullWidth
                  data={data.client_contact_email}
                  onChange={handleChange}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ClientContactInfoDialog)
