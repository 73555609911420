import React, {useEffect, useState,useParams} from 'react'
import axios from 'axios'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Table from "@material-ui/core/Table"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import {API_BASE_URL} from "../../constants/app"
import ClientViewTableHeader from "./table-header"
import {candidateColumns} from "./columns"
import {CircularProgress} from "@material-ui/core"
import {formatCandidate} from "./util"
import TabsView from "./Tabs"
import CandidatesDataRow from "./candidate-data-row"


const ClientDataRow = (props) => {
  const [expand, setExand] = useState(false)
  const {columns, job,stage,client_id} = props
  const [loading, setLoading] = useState(false)
  const [candidates, setCandidates] = useState([]);


  const fetchCandidateByJob = (job_id, stage_id) => {
    
    setLoading(true)
    axios.get(`${API_BASE_URL}recruit/candidate/candidates-by-job/${client_id}/${job_id}/${stage_id}`, ).then((res) => {
      setCandidates(res.data.result.map(c => {
        return formatCandidate(c)
      }))
      setLoading(false)
    }).catch(err => {
      console.log('err', err)
      setLoading(false)
    })
  }

  useEffect(() => {
      fetchCandidateByJob(job,stage)
  }, [job,stage])


  const renderExpandedRow = () => {
    return (
      
        
          <>
            <div><h3>Candidates</h3></div>
            <Table>
              <ClientViewTableHeader
                order={'id'}
                orderBy={'asc'}
                expandCollapse={() => {
                }}
                columns={candidateColumns}
              />
              <TableBody>
                {renderCandidateTableBody()}
              </TableBody>
            </Table>
          </>
        
      
    )
  }

  const renderCandidateTableBody = () => {

    if (loading)
      return (
        <TableRow>
          <TableCell
            style={{textAlign: 'center'}}
            colSpan={13}
          >
            <CircularProgress/>
          </TableCell>
        </TableRow>
      )

    if(candidates.length === 0)
      return (
        <TableRow>
          <TableCell
            style={{ textAlign: 'center' }}
            colSpan={13}
          >
            <h5>No candidates found</h5>
          </TableCell>
        </TableRow>
      )

    return (
      <>
        { candidates.map((d) => (
          <CandidatesDataRow
            d={d}
            page_data={props.page_data}
            token={props.token}
            job_id={job}
            stage_id={stage}
            reloadCandidates={fetchCandidateByJob}
          />
        )) }
      </>
    )

  }

  return (
    <>
      {renderExpandedRow()}
    </>
  )
}

export default ClientDataRow