export default [
  {
    path: '/',
    component: 'Root',
    exact: true,
  },
  {
    path: '/jobs-listing',
    component: 'JobsListing',
    exact: true,
  },
  {
    path: '/client-jobs-view/:id',
    component: 'ClientJobsView',
    exact: true,
  },

  {
    path: '/candidates/:id',
    component: 'Candidates',
    exact: true,
  },
  {
    path: '/candidates',
    component: 'Candidates',
    exact: true,
  },
  {
    path: '/archived',
    component: 'Archived',
    exact: true,
  },
  {
    path: '/filtered-candidates',
    component: 'Candidates',
    exact: true,
  },

  {
    path: '/dashboard',
    component: 'DashboardNew',
    exact: true,
  },
  {
    path: '/dashboard-new',
    component: 'DashboardNew',
    exact: true,
  },
  {
    path: '/submittals',
    component: 'Submittals',
    exact: true,
  },
  {
    path: '/jobs/:id',
    component: 'Jobs',
    exact: true,
  },
  {
    path: '/jobs',
    component: 'Jobs',
    exact: true,
  },
  {
    path: '/clients',
    component: 'Clients',
    exact: true,
  },
  {
    path: '/contacts/:id',
    component: 'Contacts',
    exact: true,
  },
  {
    path: '/contacts',
    component: 'Contacts',
    exact: true,
  },
  {
    path: '/jobs-filter',
    component: 'JobsFilters',
    exact: true,
  },
  {
    path: '/jobs-details/:id',
    component: 'JobsDetails',
    exact: true,
  },
  {
    path: '/jobs-details',
    component: 'JobsDetails',
    exact: true,
  },
  {
    path: '/third-party',
    component: 'thirdParty',
    exact: true,
  },
  {
    path: '/question',
    component: 'question',
    exact: true,
  },
  {
    path: '/visa-status',
    component: 'VisaStatus',
    exact: true,
  },
  {
    path: '/activity-report',
    component: 'ActivityReport',
    exact: true,
  },
]
