import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as AppConstants from '../../constants/app'
import WorkIcon from '@material-ui/icons/Work'
import axios from 'axios'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'

import LeftFilterPanel from './LeftFilterPanel'
import './job-listing.scss'
import JobCard from './JobCard'
import ApplyNowForm from './ApplyNowForm'
import Snackbar from '@material-ui/core/Snackbar'
import CandidateModel from '../Candidates/CandidateModel'
import JobDetailsRightPanel from './JobDetailsRightPanel'

const styles = {
  root: {
    flexGrow: 1,
  },
  card: {
    margin: ' 20px 80px 20px 80px',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },
  child: {
    backgroundColor: '#fff',
  },
  cardcontent: {
    paddingBottom: 0,
  },
  title: {
    fontSize: 24,
    color: '#2aadde',
  },
  titlefilter: {
    fontSize: 24,
    color: '#000',
  },
  toolbarButtons: {
    marginRight: 12,
    float: 'right',
  },
  divider: {
    margin: `5px 0`,
  },
  bodyname: {
    fontSize: 16,
    marginTop: 22,
    marginBottom: 15,
    height: 110,
    overflow: 'hidden',
  },
  pos: {
    marginTop: 10,
    marginBottom: 0,
  },
  topbar: {
    padding: 10,
    display: 'inline-block',
  },
  phone: {
    flex: 1,
  },
  jobListHeader: {
    marginTop: '1.5rem',
    border: '1px solid #dedede',
    padding: '1rem',
  },
}

class JobsListing extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    actual_data: [],
    selectedChild: [],
    pageHeading: 'Jobs',
    page: 0,
    jobId: null,
    totaljobs: 0,
    data: [],
    open: false,
    logopen: true,
    clients: [],
    jobs: [],
    users: [],
    category: [],
    account_managers: [],
    opened: [],
    isLoaded: false,
    auth_token: '',
    placement_filter: false,
    submittals_filter: false,
    contract_placement_filter: false,
    searchvale: 'AAAALL',
    total_record: 0,
    recruiter_filter: 0,
    client_filter: 0,
    job_filter: 0,
    stage_filter: 0,
    total_sub: 0,
    total_inter: 0,
    total_send: 0,
    total_perma: 0,
    total_cont: 0,
    employe_filter: 0,
    total_sub_class: 0,
    total_inter_class: 0,
    total_send_class: 0,
    total_perma_class: 0,
    total_cont_class: 0,
    third_party: [],
    columns: [],
    snackBar: {
      open: false,
      message: '',
      backgroundColor: '#1976d2',
    },
    viewJobDetails: false,
  }

  handelChnage = (e) => {
    const { data } = this.state
    const _data = { ...data }
    const prop = e.target.name
    _data[prop] = e.target.value
    this.setState({ data: _data })
  }

  handleChangePage = (event, newPage) => {
    let th = this
    this.setState({ page: newPage }, function () {
      setTimeout(function () {
        th.fetchJobs()
      }, 100)
    })
  }

  doSearch = (e) => {
    window.location.href =
      '/jobs-filter/?terms=' +
      this.state.data.terms +
      '&location=' +
      this.state.data.location +
      '&category=' +
      this.state.data.category
  }

  componentDidMount() {
    // TODO: Make it one call, as I couldn't merge that to a single endpoint due to short time

    const { data } = this.state
    const _data = { ...data }
    _data['terms'] = ''
    _data['location'] = ''
    _data['category'] = ''
    this.setState({ data: _data })

    this.fetchJobs()
    this.getAuthToken()
    this.fetchClients()
  }

  async getAuthToken() {
    let params = {
      username: 'admin@anewrecruit.com',
      password: 'Welcome123!!',
    }
    const response = await axios.post(`${AppConstants.API_BASE_URL}api-token-auth/`, params)
    if (response.data) {
      let token = response.data.token
      this.setState({
        auth_token: token,
      })
    }
  }

  async fetchClients() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client/?jobs=true&status=1`)
    if (response.data) {
      this.setState({ clients: response.data.result.clients })
      this.setState({ category: response.data.result.category })
      this.setState({ totaljobs: response.data.result.jobs.length })
    }
  }

  async fetchJobs() {
    let id = ''
    if (this.props.match.params.id) {
      id = this.props.match.params.id
    }
    let pg = this.state.page + 1
    this.setState({ isLoaded: false })
    const response = await axios.get(
      `${AppConstants.API_BASE_URL}recruit/job/?client=` + id + `&page=` + pg + `&status=1`
    )
    let th = this
    if (response.data) {
      const jobs = []
      response.data.forEach(function (c) {
        if (c.status == 1) jobs.push(th.formatIT(c))
      })
      this.setState({ jobs: jobs, isLoaded: true }, function () {})
    }
  }

  formatIT = (job) => {
    job.salary = job.min_salary + '-' + job.max_salary

    if (job.hides) job.hides = job.hides.split(',')
    else job.hides = []

    if (job.publish_at) job.publish_at = this.formatToCalendarDate(job.publish_at)

    if (job.status == 1) job.status = 'Active'
    else job.status = 'Draft'

    if (job.employment_type == 1) job.employment_type = 'Contract Job'
    else if (job.employment_type == 1) job.employment_type = 'Contract To Hire'
    else job.employment_type = 'Permanent Job'

    return job
  }

  formatToCalendarDate = (date) => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`

    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`

    return `${month}/${_date}/${date.getFullYear()}`
  }

  goToJobDetails = (id) => {
    // window.location.href = '/jobs-details/' + id
    this.setState({
      viewJobDetails: true,
      jobId: id,
    })
  }

  formatDate = (date) => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`
    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`
    let hour = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    return `${month}/${_date}/${date.getFullYear()} (${hour}:${minutes}:${seconds})`
  }

  get columns() {
    return [
      { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
      { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
      { id: 'client_name', numeric: false, disablePadding: false, label: 'Client Name' },
      { id: 'salary', numeric: false, disablePadding: false, label: 'Salary' },
      { id: 'employment_type', numeric: false, disablePadding: false, label: 'Job Type' },
      { id: 'category_name', numeric: false, disablePadding: false, label: 'Category' },
      { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
      { id: 'publish_at', numeric: false, disablePadding: false, label: 'Publish' },
    ]
  }

  getChildRows = () => {
    const {
      state: { data },
      getChildRow,
    } = this
    const colSpan = data && data.length > 0 ? Object.keys(data[0]).length + 1 : 1
    return data.map((d) => {
      return {
        id: d.id,
        element: getChildRow(d, colSpan),
      }
    })
  }

  applyFilters = async (queryStr) => {
    let th = this
    this.setState({
      isLoaded: false,
    })
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/job?${queryStr}`)
    if (response.data) {
      const jobs = []
      response.data.forEach(function (c) {
        if (c.status == 1) jobs.push(th.formatIT(c))
      })
      this.setState({ jobs: jobs, isLoaded: true }, function () {})
    }
  }

  getProgress = () => (this.state.isLoaded ? false : <LinearProgress />)

  applyNow = (job) => {
    this.setState({
      open: true,
      job: job,
    })
  }

  openSnackBar = (message, bgcolor) => {
    const { snackBar } = this.state
    snackBar.open = true
    snackBar.message = message
    snackBar.backgroundColor = bgcolor
    this.setState({ snackBar: snackBar })
  }

  closeSnackBar = () => {
    const { snackBar } = this.state
    snackBar.open = false
    this.setState({ snackBar: snackBar })
  }

  saveCandidate = (_data) => {
    console.log('candidate', _data)
    var headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${this.state.auth_token}`,
    }

    _data['client'] = this.state.job.client
    _data['job'] = this.state.job.id
    _data['recruiter'] = 5
    _data['bill_rate_manula'] = 0
    _data['status_visa'] = 1
    _data['send_signature_html'] = 1
    let eml = _data['email']
    _data['email'] = []
    _data['email'].push(eml)

    console.log('_data', _data)

    axios
      .post(`${AppConstants.API_BASE_URL}recruit/candidate/`, _data, { headers: headers })
      .then((response) => {
        this.setState({ isLoaded: true, open: false })
        let message = 'Your application submitted successfully'
        this.openSnackBar(message, 'success-bar')
      })
      .catch((error) => {
        this.setState({ isLoaded: true })
        let message = 'Please check your inputs, and try again'
        this.openSnackBar(message, 'error-bar')
        //this.resetState();
      })
  }

  handleCloseJob = () => {
    this.setState({
      viewJobDetails: false,
      jobId: null,
    })
  }

  render() {
    const {
      columns,
      state: { viewJobDetails, jobId },
      props: { classes },
      getChildRows,
      getProgress,
    } = this
    const th = this
    return (
      <div className={classes.root}>
        <div className="filter-section">
          <div className="search-section" style={{ display: 'inline-flex' }}></div>
        </div>

        <div className="jobs-list">
          {getProgress()}

          <Grid container spacing={2} md={16} direction="row" justify="flex-start" alignItems="flex-start">
            <LeftFilterPanel applyFilters={this.applyFilters} />
            <Grid item xs="12" sm="12" md={viewJobDetails ? '4' : '8'}>
              <Card className={clsx(classes.card)}>
                <div className="job-list-header">
                  <div className="job-header-title">
                    {' '}
                    <WorkIcon color="primary" />
                    &nbsp;Jobs
                  </div>
                  <TablePagination
                    component="div"
                    count={this.state.totaljobs}
                    rowsPerPage={16}
                    className="job-pagination"
                    page={this.state.page}
                    labelRowsPerPage=""
                    backIconButtonProps={{
                      'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'next page',
                    }}
                    onChangePage={this.handleChangePage}
                  />
                </div>
              </Card>
              {this.state.jobs.map(function (job, index) {
                return (
                  <JobCard
                    index={index}
                    job={job}
                    viewJobDetails={viewJobDetails}
                    selected={jobId === job.id}
                    onApplyNow={th.applyNow}
                    goToJobDetails={th.goToJobDetails}
                  />
                )
              })}
            </Grid>
            {viewJobDetails && <JobDetailsRightPanel jobId={jobId} onApply={th.applyNow} onClose={th.handleCloseJob} />}
          </Grid>

          <TablePagination
            component="div"
            count={this.state.totaljobs}
            rowsPerPage={16}
            page={this.state.page}
            labelRowsPerPage=""
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={this.handleChangePage}
          />
        </div>
        {this.state.open && (
          <ApplyNowForm
            handleClose={() => this.setState({ open: false })}
            data={{}}
            openSnackBar={th.openSnackBar}
            authToken={this.state.auth_token}
            loading={!this.state.isLoaded}
            open={this.state.open}
            saveCandidate={this.saveCandidate}
          />
        )}

        <Snackbar
          className={th.state.snackBar.backgroundColor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={th.state.snackBar.open}
          autoHideDuration={2000}
          onClose={th.closeSnackBar}
          message={<span>{th.state.snackBar.message}</span>}
        />
      </div>
    )
  }
}

JobsListing.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(JobsListing)
