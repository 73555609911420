class JobModel {
  is_new = false
  id = ''
  status = -1
  title = ''
  location = ''
  min_salary = ''
  max_salary = ''
  third_party_bill_rate = 1.18
  visa_1099_bill_rate = 1.15
  citizen_bill_rate = 1.18
  green_card_bill_rate = 1.18
  skills = ''
  short_description = ''
  long_description = ''
  intake_call = ''
  candidate_feedback = ''
  companies_hiring = ''
  template_email = ''
  publish_at = ''
  employment_type = ''
  publish_until = null
  client = ''
  priority = 2
  notes = ''
  hides = []
  category = ''
  password = ''
  email = ''
  username = ''
  is_active = true
  confirm_password = ''
  recruiter = ""
  constructor(is_new) {
    this.is_new = is_new
  }
}

export default JobModel
