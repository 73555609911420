import React from 'react'
import Add from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'
import AppTextField from '../common/AppTextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import AppButton from '../common/AppButton'
import AppSelect from '../common/AppSelect'
import SaveIcon from '@material-ui/icons/Save'
import Snackbar from '@material-ui/core/Snackbar'
import * as AppConstants from '../../constants/app'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import JobModel from './JobModel'
import { MultipleSelect } from 'react-select-material-ui'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, convertFromRaw, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import 'easymde/dist/easymde.min.css'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import ClientModel from '../Clients/ClientModel'
import ClientForm from '../Clients/Form'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import draftToHtml from 'draftjs-to-html'
import './job.scss'

const content = {
  blocks: [
    {
      key: '637gr',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
}

const styles = theme => ({
  root: {},
  row: {
    backgroundColor: '#fff !important',
  },
  value: {
    borderBottom: 'none',
    verticalAlign: 'top',
    padding: '0 24px',
    width: '33.33%',
  },
  label: {
    fontWeight: 'bold',
    fontcolor: 'rgba(0, 0, 0, 0.54)',
  },
  relative: {
    position: 'relative',
  },
  actionButtons: {
    position: 'absolute',
    bottom: 0,
    right: '30px',
  },
  phone: {
    flex: 1,
    padding: '5px',
  },
  contactInfo: {
    display: 'flex',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      <IconButton id="closeAddClient" aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

class JobForm extends React.Component {
  constructor(props) {
    super(props)
    const contentBlock = convertFromRaw(content)
    this.state = {
      data: new JobModel(),
      clients: [{ value: -1, label: 'None' }],
      jobs: [{ value: -1, label: 'None' }],
      users: [{ value: -1, label: 'None' }],
      editorState: EditorState.createEmpty(),
      editorCandidateState: EditorState.createWithContent(contentBlock),
      editorLongDescState: EditorState.createWithContent(contentBlock),
      editorTemplateState: EditorState.createWithContent(contentBlock),
      editorNoteState: EditorState.createWithContent(contentBlock),
      oldjob: '',
      changes: [],
      clients_list: [],
      status_visa: [
        { value: 1, label: 'None' },
        { value: 2, label: 'Citizenship' },
        { value: 3, label: 'Green Card' },
        { value: 5, label: '1099' },
        { value: 6, label: 'Third party' },
        { value: 7, label: 'Other' },
      ],
      employment_type: [
        { value: 1, label: 'Contract Job' },
        { value: 2, label: 'Contract To Hire' },
        { value: 3, label: 'Permanent Job' },
      ],
      status: [
        { value: 2, label: 'In Active' },
        { value: 1, label: 'Active' },
      ],
      prioritys: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
      ],
      // recruiter:"",
      account_managers: [{ value: -1, label: 'None' }],
      third_parties: [{ value: -1, label: 'None' }],
      bill_rate: 0,
      from_date_month: '',
      expandDescription: {},
      dialogOpen: {
        moats: false,
        contact: false,
        linkedIn: false,
        client_contact: false,
        client_contact_hours: false,
        client_contact_hours_edit: false,
      },
      snackBar: {
        open: false,
        message: '',
        backgroundColor: '#1976d2',
      },
      isLoaded: true,
      to_date: '',
      from_date: '',
      total_hours: 0,
      hoursedit: [],
      total_bill: 0,
      total_client_bill: 0,
      candidate_name: '',
      send_signature_html: '',
      candidate_title: '',
      location: '',
      vacation: '',
      years: '',
      communication: '',
      availability: '',
      current_salary: '',
      pay_rate: '',
      start_data: false,
      interview_date: false,
      activity: '',
      notes: '',
      currentjob: '',
      hours: [],
      job_title: '',
      job_location: '',
      job_max_salary: '',
      job_min_salary: '',
      job_short_description: '',
      short_description: '',
      job_description: '',
      job_category: '',
      job_client: '',
      job_status: '',
      job_publish_at: '',
      maxpayrate: 'Max Salary',
      minpayrate: 'Min Salary',
      open: false,
      clientslist: [],
      users: [],
      auth_token: '',
      empcount: {
        1: 'From 1 to 10',
        2: 'From 10 to 50',
        3: 'From 50 to 100',
        4: 'From 100 to 500',
        5: 'From 500 to 1000',
      },
      openCategory: false,
      openRecruiter: false,
      openSkill: false,
      title: '',
      password: '',
      email: '',
      username: '',
      is_active: true,
      confirm_password: '',
      save_skill_name: '',
      nskills: [],
      isClientLoaded: false,
    }

    this.saveJob = this.saveJob.bind(this)
    this.saveCategory = this.saveCategory.bind(this)
    this.saveRecruiter = this.saveRecruiter.bind(this)
    this.saveSkill = this.saveSkill.bind(this)
  }

  async fetchUsers() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/user/`)
    if (response.data && response.data['successful']) {
      this.setState({ users: response.data.result })
    }
  }

  async fetchSkill() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client/?jobs=true`)
    if (response.data) {
      this.setState({ nskills: response.data.result.skills })
      //alert('tatch')
    }
  }

  async getAuthToken() {
    let params = {
      username: 'admin@anewrecruit.com',
      password: 'Welcome123!!',
    }
    const response = await axios.post(`${AppConstants.API_BASE_URL}api-token-auth/`, params)
    if (response.data) {
      let token = response.data.token
      this.setState({
        auth_token: token,
      })
    }
  }
  formatToCalendarDate = date => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`

    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`

    return `${month}/${_date}/${date.getFullYear()}`
  }
  formatDate = date => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`
    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`
    let hour = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    return `${date.getFullYear()}-${month}-${_date}`
  }

  async fetchClients() {
    this.setState({ isClientLoaded: true });
    let th = this
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client/`)
    if (response.data) {
      let clients = []
      let clientslist = []
      response.data.result.forEach(function (c) {
        clientslist.push(c)
        if (c.detail.contact) {
          if (c.detail.contact.length > 0) {
            c.detail.contact.forEach(function (cnt) {
              clients.push(th.formatIT(c.detail, cnt))
            })
          }
        }
      })
      this.setState({ isClientLoaded: false });
      console.log('AAAAAA')
      console.log(JSON.stringify(clients))

      this.setState({ clients: clients, clientslist: clientslist, isLoaded: true })
    }
  }
  formatIT = (clients, contact) => {
    let _clients = { ...contact }
    _clients['name'] = contact.name
    _clients['title'] = contact.title
    _clients['id'] = contact.id
    _clients['client_id'] = clients.id

    if (_clients.email[0]) {
      _clients['phone_nm'] = _clients.phone[0].phone
    }
    if (_clients.phone[0]) {
      _clients['email_nm'] = _clients.email[0].email
    }

    _clients.phone = _clients.phone.map(p => {
      return {
        id: p.id,
        value: p.phone,
        label: 'Phone',
      }
    })
    _clients.email = _clients.email.map(e => {
      return {
        id: e.id,
        value: e.email,
        label: 'Email',
      }
    })

    _clients['cmp_name'] = clients.name
    _clients['location'] = clients.location
    _clients['company_infos'] = clients.company_infos
    _clients['products'] = clients.products
    _clients['status_display'] = contact.status_display
    _clients['notes'] = contact.notes
    if (contact.created) _clients['created_'] = this.formatToCalendarDate(contact.created)
    if (contact.created) _clients['created'] = this.formatDate(contact.created)
    if (contact.modified) _clients['modified'] = this.formatDate(contact.modified)
    _clients['employee_count'] = this.state.empcount[clients.employee_count]

    return _clients
  }

  addNewClient = () => {
    this.setState({ open: true })
  }
  addNewCategory = () => {
    this.setState({ openCategory: true })
  }
  addNewSkill = () => {
    this.setState({ openSkill: true })
  }

  addNewRecruiter = () => {
    this.setState({ openRecruiter: true })
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    })

    const blocks = convertToRaw(editorState.getCurrentContent()).blocks
    const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n')

    const { data } = this.state
    const _data = { ...data }
    _data['template_email'] = value
    this.setState({ data: _data })
  }

  onEditorCandidateStateChange = editorCandidateState => {
    this.setState({
      editorCandidateState,
    })
  }
  onEditorLongStateChange = editorLongDescState => {
    this.setState({
      editorLongDescState,
    })
  }
  onEditorTemplateStateChange = editorTemplateState => {
    this.setState({
      editorTemplateState,
    })
  }
  onEditorNoteStateChange = editorNoteState => {
    this.setState({
      editorNoteState,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
      openCategory: false,
      openRecruiter: false,
    })
  }
  handleChange = e => {
    if (e.target.name == 'employment_type' && e.target.value == 1) {
      this.setState({ maxpayrate: 'Max Pay Rate', minpayrate: 'Min Pay Rate' })
    } else if (e.target.name == 'employment_type' && e.target.value == 3) {
      this.setState({ maxpayrate: 'Max Salary', minpayrate: 'Min Salary' })
    } else if (e.target.name == 'employment_type' && e.target.value == 2) {
      this.setState({ maxpayrate: 'Max PayRate/Salary', minpayrate: 'Min PayRate/Salary' })
    }
    const { data } = this.state
    const _data = { ...data }
    const prop = e.target.name
    _data[prop] = e.target.value

    this.setState({ data: _data })
  }
  handleChangeEditor = (value, prop) => {
    const { data } = this.state
    const _data = { ...data }
    _data[prop] = value
    this.setState({ data: _data })
  }
  handleWygChangeEditor = (eState, prop) => {
    const { data } = this.state
    const _data = { ...data }
    _data[prop] = draftToHtml(convertToRaw(eState.getCurrentContent()))
    this.setState({ data: _data })
  }

  handleChangeSkill = e => {
    //  alert(e);
    const { data } = this.state
    const _data = { ...data }

    _data['skills'] = e
    this.setState({ data: _data })
  }

  handleChangeHide = e => {
    const { data } = this.state
    const _data = { ...data }
    _data['hides'] = e
    this.setState({ data: _data })
  }

  componentDidMount() {
    this.getAuthToken()
    this.fetchClients()
    this.refreshClients()
    this.fetchUsers()
    this.fetchSkill()
    let data = new JobModel()
    if (this.props.data.id) {
      data = this.props.data
      //alert(data.publish_at)
      console.log('SKILLSSSS', data)
      //alert(data.skills)
      let dskills = []
      if (data.skill_name) {
        data.skill_name.forEach(function (s) {
          dskills.push(s.id)
          //alert(s.id)
        })
        //   alert(dskills)
        data.skills = dskills
        if (data.hides) {
          //  alert(data.hides);
          if (typeof (data.hides) === "string") {
            data.hides = data.hides.split(',');
          }
        }
      }
      if (data.status == 'Active') data.status = 1
      else data.status = 2

      if (data.employment_type == 'Contract Job') data.employment_type = 1
      else if (data.employment_type == 'Contract To Hire') data.employment_type = 2
      else data.employment_type = 3
    } else {
      data.visa_1099_bill_rate = 1.15
      data.citizen_bill_rate = 1.18
      data.green_card_bill_rate = 1.18
      data.third_party_bill_rate = 1.18
      data.recruiter = this.props.userId;
      console.log("this.props.userID", data.recruiter)

      //alert(data.citizen_bill_rate)
    }
    this.setState({ clients_list: this.props.clients })
    //this.setState({data: data})
    this.setState({ data: data })
    const { editorState } = this.state

    if (data.long_description) {
      this.setState({
        editorLongDescState: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(data.long_description))
        ),
      })
    }
    if (data.candidate_feedback) {
      this.setState({
        editorCandidateState: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(data.candidate_feedback))
        ),
      })
    }
    if (data.notes) {
      this.setState({
        editorNoteState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data.notes))),
      })
    }
    if (data.template_email) {
      this.setState({
        editorTemplateState: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(data.template_email))
        ),
      })
    }
  }

  handleSaveCandidateResponse = (response, is_new) => {
    this.setState({ isLoaded: true })
    // let message = 'Something went wrong, please contact admin';
    const { editorState } = this.state

    let message = 'There is some problem. Please contact your administrator'
    if (response.data) {
      message = response.data['message']
      const { onCandidateUpdate } = this.props
      if (onCandidateUpdate) setTimeout(() => onCandidateUpdate(response.data.result, is_new))
    }
    this.openSnackBar('Job saved successfully', 'success-bar')
    this.props.close()
  }

  async validate(data) {
    let errorText = 'This field is required'
    let job_title = ''
    let job_location = ''
    let job_max_salary = ''
    let job_min_salary = ''
    let job_short_description = ''
    let short_description = ''
    let job_description = ''
    let job_category = ''
    let job_publish_at = ''
    let job_client = ''
    let job_status = ''

    if (!data.title) {
      job_title = errorText
    }

    if (!data.max_salary) {
      job_max_salary = errorText
    }

    if (!data.min_salary) {
      job_min_salary = errorText
    }

    if (!data.publish_at) {
      job_publish_at = errorText
    }

    /*if (!data.short_description) {
            job_short_description = errorText;
        }*/

    /*if (!data.long_description) {
            job_description = errorText;
        }*/

    if (!data.client) {
      job_client = errorText
    }
    if (!data.category) {
      job_category = errorText
    }
    /*if (!data.category) {
            job_category = errorText;
        }*/

    // if (!data.pay_rate && data.status===2) {
    //     pay_rate = errorText;
    // }

    if (
      job_title ||
      job_publish_at ||
      job_status ||
      job_short_description ||
      job_description ||
      job_client ||
      job_category ||
      job_min_salary ||
      job_max_salary
    ) {
      await this.setState({
        job_title,
        job_status,
        job_short_description,
        job_description,
        job_client,
        job_category,
        job_min_salary,
        job_max_salary,
      })
      return false
    } else {
      return true
    }
  }

  refreshClientsList = () => {
    this.refreshClients()
    this.setState({ open: false })
  }

  async refreshClients() {
    this.setState({ isClientLoaded: true });
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client/?jobs=true`)
    if (response.data) {
      this.setState({ clients_list: response.data.result.clients })
    }
    this.setState({ isClientLoaded: false });
  }

  async saveRecruiter() {
    const _data = { ...this.state.data }
    this.setState({ isLoaded: false })
    var headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${this.props.token}`,
    }
    axios
      .post(`${AppConstants.API_BASE_URL}recruit/user/`, _data, { headers: headers })
      .then(response => {
        console.log(response)
        this.setState({
          open: false,
          openCategory: false,
          openRecruiter: false,
        })
        // this.handleSaveCandidateResponse(response, _data.is_new);
      })
      .catch(error => {
        console.log('errorrrrrrrrrr')
        this.setState({ isLoaded: true })
        let message = ''
        let eror = JSON.parse(error.response.request.response)
        for (var key in eror) {
          message = key + '-:' + eror[key]
          this.openSnackBar(message, 'error-bar')
        }
      })
  }

  async saveCategory() {
    const _data = { ...this.state.data }
    this.setState({ isLoaded: false })
    var headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${this.props.token}`,
    }
    axios
      .post(`${AppConstants.API_BASE_URL}recruit/category/`, _data, { headers: headers })
      .then(response => {
        console.log(response)
        // this.refreshCategory()
        // this.handleSaveCandidateResponse(response, _data.is_new);
        this.setState({
          open: false,
          openCategory: false,
          openRecruiter: false,
        })
      })
      .catch(error => {
        console.log('errorrrrrrrrrr')
        this.setState({ isLoaded: true })
        let message = ''
        let eror = JSON.parse(error.response.request.response)
        for (var key in eror) {
          message = key + '-:' + eror[key]
          this.openSnackBar(message, 'error-bar')
        }
      })
  }

  async saveSkill() {
    const _data = { ...this.state.data }
    this.setState({ isLoaded: false })
    var headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${this.props.token}`,
    }
    axios
      .post(`${AppConstants.API_BASE_URL}recruit/skill/`, _data, { headers: headers })
      .then(response => {
        console.log(response)
        // this.refreshCategory()
        // this.handleSaveCandidateResponse(response, _data.is_new);
        this.fetchSkill()
        this.setState({
          open: false,
          openCategory: false,
          openRecruiter: false,
          openSkill: false,
        })
        this.setState({ isLoaded: true })
      })
      .catch(error => {
        console.log('errorrrrrrrrrr')
        this.setState({ isLoaded: true })
        let message = ''
        let eror = JSON.parse(error.response.request.response)
        for (var key in eror) {
          message = key + '-:' + eror[key]
          this.openSnackBar(message, 'error-bar')
        }
      })
  }

  async saveJob() {
    const _data = { ...this.state.data }
    let today = new Date();
    _data.modified = this.formatDate(today);
    const is_valid = await this.validate(_data)

    if (is_valid) {
      this.setState({ isLoaded: false })
      if (_data.skills) _data.skills = _data.skills.join(',')
      else _data.skills = ''
      if (_data.hides) _data.hides = _data.hides.join(',')
      var headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${this.props.token}`,
      }

      if (_data.id) {
        axios
          .put(`${AppConstants.API_BASE_URL}recruit/job/` + _data.id + `/`, _data, { headers: headers })
          .then(response => {
            console.log(response)
            this.handleSaveCandidateResponse(response, _data.is_new)
          })
          .catch(error => {
            console.log('errorrrrrrrrrr')
            this.setState({ isLoaded: true })
            let message = ''

            let eror = JSON.parse(error.response.request.response)
            for (var key in eror) {
              message = key + '-:' + eror[key]
              this.openSnackBar(message, 'error-bar')
            }
          })
      } else {
        axios
          .post(`${AppConstants.API_BASE_URL}recruit/job/`, _data, { headers: headers })
          .then(response => {
            console.log(response)
            this.handleSaveCandidateResponse(response, _data.is_new)
          })
          .catch(error => {
            this.setState({ isLoaded: true })
            let message = ''
            let eror = JSON.parse(error.response.request.response)
            for (var key in eror) {
              message = key + '-:' + eror[key]
              this.openSnackBar(message, 'error-bar')
            }
          })
      }
    }
  }

  openSnackBar = (message, bgcolor) => {
    const { snackBar } = this.state
    snackBar.open = true
    snackBar.message = message
    snackBar.backgroundColor = bgcolor
    this.setState({ snackBar: snackBar })
  }

  closeSnackBar = () => {
    const { snackBar } = this.state
    snackBar.open = false
    this.setState({ snackBar: snackBar })
  }

  getSnackBarContent = () => {
    const {
      closeSnackBar,
      state: { snackBar },
    } = this

    return (
      <Snackbar
        className={snackBar.backgroundColor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={closeSnackBar}
        message={<span>{snackBar.message}</span>}
      />
    )
  }
  getDate = () => {
    let tempDate = new Date()
    let date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate()
    const { data } = this.state
    const _data = { ...data }
    const prop = 'publish_at'
    _data[prop] = date

    this.setState({ data: _data })
    return date
  }
  getProgress = () =>
    this.state.isLoaded ? false : <CircularProgress size={24} className={this.props.classes.buttonProgress} />

  render() {
    const {
      state: { data, clients },
      props: { classes, contractPlacementFilter, placementFilter },
      handleChange,
    } = this
    console.log("Re_data", data)
    const { editorState } = this.state
    const { editorCandidateState } = this.state
    const { editorLongDescState } = this.state
    const { editorTemplateState } = this.state
    const { editorNoteState } = this.state

    let d = new ClientModel()
    return (
      <div>
        {this.getSnackBarContent()}

        <Dialog width="300" maxWidth="400" open={this.state.open} aria-labelledby="simple-dialog-title">
          <DialogTitle onClose={this.handleClose} id="simple-dialog-title">
            {' '}
            Add Client
          </DialogTitle>
          <DialogContent>
            <ClientForm
              data={d}
              token={this.state.auth_token}
              clientslist={this.state.clientslist}
              refreshdata={this.refreshClientsList}
              users={this.state.users}
              newclint={true}
            />
          </DialogContent>
        </Dialog>

        <Dialog width="300" maxWidth="400" open={this.state.openCategory} aria-labelledby="simple-dialog-title">
          <DialogTitle onClose={this.handleClose} id="simple-dialog-title">
            Add Category
          </DialogTitle>
          <DialogContent>
            <Table className={classes.root}>
              <TableBody>
                <TableRow className={classes.row}>
                  <TableCell className={classes.value}>
                    <AppTextField label="Category" fullWidth name="title" onChange={handleChange} />
                  </TableCell>
                </TableRow>
                <TableRow className="tr_last">
                  <TableCell className={classes.value} colSpan={2}>
                    <div className="action-buttons">
                      <div style={{ position: 'relative', display: 'inline-block', top: '0px' }}>
                        <AppButton
                          color="primary"
                          label="Save"
                          icon={{ component: SaveIcon, position: 'right' }}
                          onClick={this.saveCategory}
                          disabled={!this.state.isLoaded}
                        />
                        {this.getProgress()}

                        <AppButton
                          color="secondary"
                          label="Close"
                          onClick={this.props.close}
                          disabled={!this.state.isLoaded}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>

        <Dialog width="300" maxWidth="400" open={this.state.openSkill} aria-labelledby="simple-dialog-title">
          <DialogTitle onClose={this.handleClose} id="simple-dialog-title">
            Add Skill
          </DialogTitle>
          <DialogContent>
            <Table className={classes.root}>
              <TableBody>
                <TableRow className={classes.row}>
                  <TableCell className={classes.value}>
                    <AppTextField label="Skill" fullWidth name="title" onChange={handleChange} />
                  </TableCell>
                </TableRow>
                <TableRow className="tr_last">
                  <TableCell className={classes.value} colSpan={2}>
                    <div className="action-buttons">
                      <div style={{ position: 'relative', display: 'inline-block', top: '0px' }}>
                        <AppButton
                          color="primary"
                          label="Save"
                          icon={{ component: SaveIcon, position: 'right' }}
                          onClick={this.saveSkill}
                          disabled={!this.state.isLoaded}
                        />
                        {this.getProgress()}

                        <AppButton
                          color="secondary"
                          label="Close"
                          onClick={this.props.close}
                          disabled={!this.state.isLoaded}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>

        <Dialog width="300" maxWidth="400" open={this.state.openRecruiter} aria-labelledby="simple-dialog-title">
          <DialogTitle onClose={this.handleClose} id="simple-dialog-title">
            {' '}
            Add Recruiter
          </DialogTitle>
          <DialogContent>
            <Table className={classes.root}>
              <TableBody>
                <TableRow className={classes.row}>
                  <TableCell className={classes.value}>
                    <AppTextField fullWidth label="Email" name="email" onChange={handleChange} />
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.value}>
                    <AppTextField fullWidth label="Username" name="username" onChange={handleChange} />
                  </TableCell>
                </TableRow>

                <TableRow className={classes.row}>
                  <TableCell className={classes.value}>
                    <AppTextField fullWidth label="Password" name="password" onChange={handleChange} />
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.value}>
                    <AppTextField fullWidth label="Confirm Password" name="confirm_password" onChange={handleChange} />
                  </TableCell>
                </TableRow>

                <TableRow className="tr_last">
                  <TableCell className={classes.value} colSpan={2}>
                    <div className="action-buttons">
                      <div style={{ position: 'relative', display: 'inline-block', top: '0px' }}>
                        <AppButton
                          color="primary"
                          label="Save"
                          icon={{ component: SaveIcon, position: 'right' }}
                          onClick={this.saveRecruiter}
                          disabled={!this.state.isLoaded}
                        />
                        {this.getProgress()}

                        <AppButton
                          color="secondary"
                          label="Close"
                          onClick={this.props.close}
                          disabled={!this.state.isLoaded}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>
        <Table className={classes.root}>
          <TableBody>
            <TableRow className={classes.row}>
              <TableCell className={classes.value}>
                <AppTextField
                  label="Title"
                  value={data.title}
                  fullWidth
                  name="title"
                  onChange={handleChange}
                  helperText={this.state.job_title.length === 0 ? ' ' : 'This field is required'}
                  error={this.state.job_title.length === 0 ? false : true}
                />
              </TableCell>
              <TableCell className={classes.value} style={{ width: '20% !important' }}>
                {this.state.isClientLoaded === true ?
                  <Grid container spacing={1}>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                      <CircularProgress size={30} />
                    </Grid>
                  </Grid>
                  :

                  <Grid container spacing={1} >
                    <Grid item xs={11} >
                      <AppSelect
                        id="client"
                        label={'Client'}
                        value={parseInt(data.client)}
                        name="client"
                        onChange={handleChange}
                        items={this.state.clients_list}
                        helperText={this.state.job_client.length === 0 ? ' ' : 'This field is required'}
                        error={this.state.job_client.length === 0 ? false : true}
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <AppButton
                        style={{ marginTop: '2px' }}
                        className="addNew"
                        onClick={this.addNewClient}
                        icon={{ component: Add }}
                      />
                    </Grid>
                  </Grid>
                }
              </TableCell>

              <TableCell className={classes.value}>
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <AppSelect
                      fullWidth
                      id="category"
                      label="Category"
                      value={parseInt(data.category)}
                      name="category"
                      onChange={handleChange}
                      items={this.props.category}
                      helperText={this.state.job_category.length === 0 ? ' ' : 'This field is required'}
                      error={this.state.job_category.length === 0 ? false : true}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <AppButton
                      style={{ marginTop: '2px' }}
                      className="addNew"
                      onClick={this.addNewCategory}
                      icon={{ component: Add }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>

            <TableRow className={classes.row}>
              <TableCell className={classes.value}>
                <AppSelect
                  id="status"
                  label="Employment type"
                  value={data.id || data.employment_type ? data.employment_type : 3}
                  name="employment_type"
                  onChange={handleChange}
                  items={this.state.employment_type}
                />
              </TableCell>
              <TableCell className={classes.value}>
                <AppTextField
                  fullWidth
                  label="Publish date"
                  type="date"
                  value={data.id || data.publish_at ? data.publish_at : this.getDate()}
                  name="publish_at"
                  onChange={handleChange}
                  helperText={this.state.job_publish_at.length === 0 ? ' ' : 'This field is required'}
                  error={this.state.job_publish_at.length === 0 ? false : true}
                />
              </TableCell>

              <TableCell className={classes.value}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <AppSelect
                      fullWidth
                      id="frmRecruiter"
                      label="Recruiter"
                      // helperText={this.state.send_signature_html.length === 0 ? ' ' : 'This field is required'}
                      // error={this.state.send_signature_html.length === 0 ? false : true}
                      value={data && data.recruiter}
                      name="recruiter"
                      onChange={handleChange}
                      items={this.state.users}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppSelect
                      id="status"
                      label="Status"
                      value={data && data.status ? data.status : 2}
                      name="status"
                      onChange={handleChange}
                      items={this.state.status}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>

            <TableRow className={classes.row}>
              <TableCell className={classes.value}>
                <AppTextField
                  label="Short Description"
                  value={data.short_description}
                  fullWidth
                  name="short_description"
                  onChange={handleChange}
                />
              </TableCell>

              <TableCell className={classes.value}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <AppTextField
                      fullWidth
                      label={this.state.minpayrate}
                      value={data.min_salary}
                      name="min_salary"
                      onChange={handleChange}
                      helperText={this.state.job_min_salary.length === 0 ? ' ' : 'This field is required'}
                      error={this.state.job_min_salary.length === 0 ? false : true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppTextField
                      fullWidth
                      label={this.state.maxpayrate}
                      value={data.max_salary}
                      name="max_salary"
                      onChange={handleChange}
                      helperText={this.state.job_max_salary.length === 0 ? ' ' : 'This field is required'}
                      error={this.state.job_max_salary.length === 0 ? false : true}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={classes.value}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <AppSelect
                      id="priority"
                      label="Priority"
                      value={data.id || data.priority ? data.priority : 1}
                      name="priority"
                      onChange={handleChange}
                      items={this.state.prioritys}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppTextField
                      fullWidth
                      label="No. Of Jobs"
                      value={data.no_of_jobs}
                      type="number"
                      pattern="[0-9]"
                      min="0"
                      onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                      name="no_of_jobs"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow className={classes.row}>
              <TableCell className={classes.value}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <AppTextField
                      label="OTE"
                      value={data.job_ote}
                      fullWidth
                      name="job_ote"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppTextField
                      fullWidth
                      label="Fee"
                      value={data.job_fee}
                      type="number"
                      pattern="[0-9]"
                      min="0"
                      onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                      name="job_fee"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell className={classes.value}>
                <AppTextField
                  label="JD Link"
                  value={data.jd_link}
                  fullWidth
                  name="jd_link"
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell className={classes.value}>
                <AppTextField
                  label="Location"
                  value={data.city}
                  fullWidth
                  name="city"
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>

            <TableRow className={classes.row}>
              <TableCell className={classes.value}>
                <MultipleSelect
                  label={'Hide selected'}
                  name="hides"
                  values={data.hides}
                  options={['date', 'location', 'salary', 'client', 'category', 'short description']}
                  onChange={this.handleChangeHide}
                />
              </TableCell>

              <TableCell className={classes.value}>
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    {data.id ? (
                      <MultipleSelect
                        label={'Choose some skills'}
                        name="skills"
                        values={data.skills}
                        options={this.state.nskills}
                        onChange={this.handleChangeSkill}
                        SelectProps={{
                          isCreatable: true,
                          msgNoOptionsAvailable: 'Type and press ennter for new value',
                          msgNoOptionsMatchFilter: 'Type and press enter for new value',
                        }}
                      />
                    ) : (
                      <MultipleSelect
                        label={'Choose some skills'}
                        name="skills"
                        options={this.state.nskills}
                        onChange={this.handleChangeSkill}
                        SelectProps={{
                          isCreatable: true,
                          msgNoOptionsAvailable: 'Type and press ennter for new value',
                          msgNoOptionsMatchFilter: 'Type and press enter for new value',
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <AppButton
                      style={{ marginTop: '2px' }}
                      className="addNew"
                      onClick={this.addNewSkill}
                      icon={{ component: Add }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={classes.value}>
                <AppTextField
                  label="Intake call"
                  value={data.intake_call}
                  fullWidth
                  name="intake_call"
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>

            <TableRow className={classes.row}>
              <TableCell className={classes.value} colSpan={5}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <label>Candidate Feedback</label>
                    <Editor
                      editorState={editorCandidateState}
                      editorClassName="editorClassName"
                      toolbarClassName="toolbarClassName"
                      onEditorStateChange={this.onEditorCandidateStateChange}
                      onChange={() => {
                        this.handleWygChangeEditor(editorCandidateState, 'candidate_feedback')
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <label>Long Description</label>
                    <Editor
                      editorState={editorLongDescState}
                      editorClassName="editorClassName"
                      toolbarClassName="toolbarClassName"
                      onEditorStateChange={this.onEditorLongStateChange}
                      onChange={() => {
                        this.handleWygChangeEditor(editorLongDescState, 'long_description')
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <label>Template email</label>
                    <Editor
                      editorState={editorTemplateState}
                      editorClassName="editorClassName"
                      toolbarClassName="toolbarClassName"
                      onEditorStateChange={this.onEditorTemplateStateChange}
                      onChange={() => {
                        this.handleWygChangeEditor(editorTemplateState, 'template_email')
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <label>Notes</label>
                    <Editor
                      editorState={editorNoteState}
                      editorClassName="editorClassName"
                      toolbarClassName="toolbarClassName"
                      onEditorStateChange={this.onEditorNoteStateChange}
                      onChange={() => {
                        this.handleWygChangeEditor(editorNoteState, 'notes')
                      }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow className="tr_last">
              <TableCell className={classes.value} colSpan={2}>
                <div className="action-buttons">
                  <div style={{ position: 'relative', display: 'inline-block', top: '0px' }}>
                    <AppButton
                      color="primary"
                      label="Save"
                      icon={{ component: SaveIcon, position: 'right' }}
                      onClick={this.saveJob}
                      disabled={!this.state.isLoaded}
                    />
                    {this.getProgress()}

                    <AppButton
                      color="secondary"
                      label="Close"
                      onClick={this.props.close}
                      disabled={!this.state.isLoaded}
                    />
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div >
    )
  }
}

export default withStyles(styles)(JobForm)
