import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import AppTextField from '../common/AppTextField'
import _isEmpty from 'lodash/isEmpty'
import _isNumber from 'lodash/isNumber'
import Table from '@material-ui/core/Table'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Add from '@material-ui/icons/Add'
import { MultipleSelect } from 'react-select-material-ui'
import AppButton from '../common/AppButton'
import AppSelect from '../common/AppSelect'
import SaveIcon from '@material-ui/icons/Save'
import AddCircle from '@material-ui/icons/AddCircle'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import MoatsDialog from './Dialog/MoatsDialog'
import LinkedInInfoDialog from './Dialog/LinkedInInfo'
import Expedite from './Dialog/Expedite'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import ContactInfoDialog from './Dialog/ContactInfo'
import ClientContactInfoDialog from './Dialog/ClientContactInfo'
import AddHours from './Dialog/AddHours'
import AddHourSummary from './Dialog/AddHourSummary'
import AddHiringManager from './Dialog/AddHiringManager'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import * as AppConstants from '../../constants/app'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import AppExpansionPanel from '../common/AppExpansionPanel'
import './candidate.scss'
import CandidateModel from './CandidateModel'
import Checkbox from '@material-ui/core/Checkbox'
import { find } from 'lodash'
import Input from '@material-ui/core/Input'

import AppMoreDynamicTextField from '../common/AppMoreDynamicTextField'
import { TextField } from '@material-ui/core'
import SubmittalsMoats from './Submittals/SubmittalMoats'
import { sub_status, getStageByType, acc_stage_list } from '../../constants/status'
import Card from '@material-ui/core/Card'
import { Link, withRouter } from 'react-router'
import compose from 'recompose/compose'
import { connect } from "react-redux";
import config from '../../config/index';
import { receiveFetchData } from '../../actions/index';
import _isDate from 'lodash/isDate'
import CardMedia from '@material-ui/core/CardMedia';
import { DataUsageTwoTone } from '@material-ui/icons'
import AvatarIconPng from '../../assets/img/avatar.png';

const styles = (theme) => ({
  root: {
    marginLeft: '0px',
  },
  row: {
    backgroundColor: '#fff !important',
  },
  value: {
    borderBottom: 'none',
    verticalAlign: 'top',
    padding: '0 24px',
    width: '33.33%',
    backgroundColor: '#fff !important',
    color: 'black !important',
  },
  label: {
    fontWeight: 'bold',
    fontcolor: 'rgba(0, 0, 0, 0.54)',
  },
  relative: {
    position: 'relative',
  },
  actionButtons: {
    position: 'absolute',
    bottom: 0,
    right: '30px',
  },
  phone: {
    flex: 1,
    padding: '5px',
  },
  contactInfo: {
    display: 'flex',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  customTooltip: {
    color: 'red',
    fontSize: '16px',
    backgroundColor: 'transparent',
  },
  span: { marginBottom: '1rem' },
  parentcontanier: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  contanier: { display: 'flex', flexDirection: 'column' },
  Card: {
    border: '2px solid green',
    MozBorderRadius: '8px',
    WebkitBorderRadius: '8px',
    borderRadius: '8px',
    width: '300px',
    marginBottom: '1rem',
  },
  legend1: { marginBottom: '0px', marginLeft: '16px', fontSize: '20' },
})

const TERMS = [
  { value: '30', label: '30' },
  { value: '45', label: '45' },
  { value: '60', label: '60' },
  { value: '90', label: '90' },
]

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      <IconButton id="closeAddClient" aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})


const getVisaLabel = (id) => {
  try {
    switch (Number(id)) {
      case 1:
        return 'None'
      case 2:
        return 'Citizenship'
      case 3:
        return 'Green Card'
      case 5:
        return '1099'
      case 6:
        return 'Third party'
      case 7:
        return 'Other'
      default:
        return 'Super Admin'
    }
  } catch {
    return 'None'
  }
}

class CandidateTabForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: new CandidateModel(),
      editSubmittal: false,
      custom_term: false,
      submit_text: null,
      clients: [{ value: -1, label: 'None' }],
      jobs: [{ value: -1, label: 'None' }],
      users: [{ value: -1, label: 'None' }],
      oldjob: '',
      changes: [],
      status_visa: [
        { value: 1, label: 'None' },
        { value: 2, label: 'Citizenship' },
        { value: 3, label: 'Green Card' },
        { value: 5, label: '1099' },
        { value: 6, label: 'Third party' },
        { value: 7, label: 'Other' },
      ],
      employment_type: [
        { value: 1, label: 'Contract Job' },
        { value: 2, label: 'Contract To Hire' },
        { value: 3, label: 'Permanent Job' },
      ],
      status: acc_stage_list,
      // [
      //   { value: 1, label: 'Leads' },
      //   { value: 2, label: 'Submittals' },
      //   { value: 3, label: 'Sendout' },
      //   { value: 4, label: 'Interview' },
      //   { value: 5, label: 'Hired/Placements' },
      //   { value: 6, label: 'Rejected' },
      //   { value: 7, label: 'On Hold' },
      //   { value: 8, label: 'Closed' },
      // ],
      account_managers: [{ value: -1, label: 'None' }],
      third_parties: [{ value: -1, label: 'None' }],
      bill_rate: 0,
      from_date_month: '',
      expandDescription: {},
      client: {},
      dialogOpen: {
        moats: false,
        contact: false,
        linkedIn: false,
        client_contact: false,
        client_contact_hours: false,
        client_contact_hours_edit: false,
        client_contact_hours_sum: false,
        client_contact_hiring: false,
      },
      snackBar: {
        open: false,
        message: '',
        backgroundColor: '#1976d2',
      },
      isLoaded: true,
      isEdited: true,
      to_date: '',
      from_date: '',
      total_hours: 0,
      hoursedit: [],
      selectedhours: [],
      total_bill: 0,
      total_client_bill: 0,
      candidate_name: '',
      phone_error: '',
      email_error: '',
      send_signature_html: '',
      candidate_title: '',
      location: '',
      vacation: '',
      years: '',
      communication: '',
      availability: '',
      current_salary: '',
      pay_rate: '',
      start_data: false,
      interview_date: false,
      activity: '',
      notes: '',
      currentjob: '',
      hours: [],
      hiringmanager: [],
      contactsByClient: [],
      question: [],
      answers: [],
      nskills: [],
      openSkill: false,
      open: false,
      openCategory: false,
      openRecruiter: false,
    }
    this.saveCandidate = this.saveCandidate.bind(this);
    this.saveSkill = this.saveSkill.bind(this)

  }

  componentWillMount() {
    const {
      props: { data, clients, jobs, users, account_managers, third_parties, user_id, t_open },
    } = this;
    // const data = this.props.candidates.data;
    if (t_open) {
      this.handleOpenEdit()
    }
    this.getYearList()
    data.recruiter = data.recruiter || -1
    data.client = data.client || -1
    data.job = data.job || -1
    data.status_visa = data.status_visa || 2
    data.email = data.email || []
    data.phone = data.phone || []
    data.client_selected = !(data.client_selected === undefined || data.client_selected === null)
    let _clients = []
    if (!clients || clients.length === 0) {
      _clients = [{ value: -1, label: 'None' }]
    } else {
      _clients = [...clients]
    }
    let _jobs = [...this.state.jobs]
    if (data.client && data.client !== -1) {
      if (jobs || jobs.length > 0) {
        _jobs = this.filterJobsOnClient([...jobs], data.client)
      }
    }
    let _users = []
    if (!users || users.length === 0) {
      _users = [{ value: -1, label: 'None' }]
    } else {
      _users = [...users]
    }
    let _account_managers = []
    let _third_parties = []
    if (!account_managers || account_managers.length === 0) {
      _account_managers = [{ value: -1, label: 'None' }]
    } else {
      _account_managers = [...account_managers]
    }

    if (!_third_parties || third_parties.length === 0) {
      _third_parties = [{ value: -1, label: 'None' }]
    } else {
      _third_parties = [...third_parties]
    }

    this.setState({
      data: data,
      clients: _clients,
      jobs: _jobs,
      users: _users,
      account_managers: _account_managers,
      third_parties: _third_parties,
    })
  }

  componentDidMount() {
    this.loadHours();
    //this.loadManagers();
    this.loadDates();
    this.getAnswer();
    // this.fetchSkill();
    this.setState({ nskills: this.props.skills })

    //  this.calculateBillRate()
    this.fetchContactsByClientId();

    let data = new CandidateModel()
    if (this.props.data.id) {
      data = this.props.data
      //alert(data.publish_at)
      //alert(data.skills)
      let dskills = []
      let dskills_ids = []
      if (data.skill_name) {
        data.skill_name.forEach(function (s) {
          dskills.push(s.title);
          dskills_ids.push(s.id);

          //alert(s.id)
        })
        //   alert(dskills)
        data.skills = dskills_ids
        data.skillsJ = dskills.join(",");
        if (data.hides) {
          //  alert(data.hides);
          data.hides = data.hides.split(',')
        }
      }
    }
    // console.log("Sttttt",data);
    // if (this.props.data.id) {
    //   data = this.props.data
    //   //alert(data.publish_at)
    //   console.log('SKILLSSSS')
    //   //alert(data.skills)
    //   let dskills = []
    //   if (data.skill_name) {
    //     data.skill_name.forEach(function (s) {
    //       dskills.push(s.id)
    //       //alert(s.id)
    //     })
    //     //   alert(dskills)
    //     data.skills = dskills
    //     if (data.hides) {
    //       //  alert(data.hides);
    //       data.hides = data.hides.split(',')
    //     }
    //   }
    // }
  }
  handleChangeSkill = e => {
    //  alert(e);
    const { data } = this.state
    const _data = { ...data }

    _data['skills'] = e
    this.setState({ data: _data })
  }

  addNewSkill = () => {
    this.setState({ openSkill: true })
  }

  getValueByName = (obj, value) => {
    if (obj) {
      var searchField = 'value'
      var searchVal = value
      for (var i = 0; i < obj.length; i++) {
        if (obj[i].value == searchVal) {
          return obj[i].label
        }
      }
    }
  }

  updateClientInfo = async (id) => {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/clients/` + id + '/')
    this.setState({
      client: response.data,
      currentTerms: response.data.terms,
      currentAddress: response.data.address || '',
    })
  }

  handleChange = (e) => {
    let xdt = {}
    let index = ''
    if (e.nativeEvent) {
      index = e.nativeEvent.target.textContent
    }
    let nm = e.target.name
    if (index) {
      if (nm == 'send_signature_html') {
        let xx = this.getValueByName(this.props.users, e.target.value)
        let oxx = this.getValueByName(this.props.users, this.props.data[nm])
        let val = ' Recruiter Changed  from ' + oxx + ' to ' + xx + ', '
        xdt[e.target.name] = val
      } else if (nm == 'third_party_id') {
        let xx = this.getValueByName(this.props.third_parties, e.target.value)
        let oxx = this.getValueByName(this.props.third_parties, this.props.data[nm])
        let val = ' Third party Changed  from ' + oxx + ' to ' + xx + ', '
        xdt[e.target.name] = val
      } else {
        let xx = this.getValueByName(this.state[nm], e.target.value)
        let oxx = this.getValueByName(this.state[nm], this.props.data[nm])
        let val = nm + ' Changed  from ' + oxx + ' to ' + xx + ', '
        xdt[e.target.name] = val
      }
    } else {
      let val = nm + ' Changed  from ' + this.props.data[nm] + ' to ' + e.target.value + ', '
      xdt[e.target.name] = val
    }

    var joined = this.state.changes.concat(xdt)
    this.setState({ changes: joined })

    const { data } = this.state
    const _data = { ...data }

    if (e.target.name == 'resume_url_ajx') {
      const dataf = new FormData()
      let candidateID = this.state.data.id
      dataf.append('file', e.target.files[0])
      dataf.append('id', candidateID)

      var headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${this.props.token}`,
      }

      this.setState({ isLoaded: false })
      let th = this
      axios
        .post(`${AppConstants.API_BASE_URL}recruit/candidate/uploadingfile`, dataf, { headers: headers })
        .then((response) => {
          _data['resume_url'] = response.data.result
          th.setState({ data: _data })
          th.setState({ isLoaded: true })
          this.openSnackBar('File uploaded successfully', 'success-bar')
        })
        .catch((error) => {
          let message = 'Please check your inputs, and try again'
          this.openSnackBar(message, 'error-bar')
          this.resetState()
        })
    } else {
      const { data } = this.state
      const _data = { ...data }
      const prop = e.target.name

      if (e.target.name == 'withdraw' || e.target.name == 'confirmed') {
        if (_data[prop] == 1) {
          _data[prop] = 0
        } else {
          _data[prop] = 1
        }
      } else {
        _data[prop] = e.target.value
      }

      _data['bill_rate_manula'] = 0
      this.setState({ data: _data }, function () {
        if (prop == 'status_visa' || prop == 'pay_rate') this.calculateBillRate()
      })
    }
  }
  setBillRateManula = (e) => {
    const { data } = this.state
    const _data = { ...data }
    const prop = e.target.name
    _data['bill_rate_manula'] = 1
    this.setState({ data: _data })
  }

  editHoursData = (h) => {
    this.loadDates(h.id)
    this.setState({
      hoursedit: h,
    })
    this.openDialog('client_contact_hours_edit')
  }

  onselectcbd = (h) => {
    if (h.target.checked) {
      var joined = this.state.selectedhours.concat(h.target.value)
      this.setState({ selectedhours: joined })
    } else {
      var joined = this.state.selectedhours
      var filtered = joined.filter(function (value, index, arr) {
        return value != h.target.value
      })
      this.setState({ selectedhours: filtered })
    }
  }

  deleteHoursData = (h) => {
    if (window.confirm('Are you sure to delete')) {
      axios
        .delete(`${AppConstants.API_BASE_URL}recruit/candidate/weekhours/` + h + `/`)
        .then((res) => {
          this.loadHours()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  async saveSkill() {
    const _data = { ...this.state.data }
    this.setState({ isLoaded: false })
    var headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${this.props.token}`,
    }
    axios
      .post(`${AppConstants.API_BASE_URL}recruit/skill/`, _data, { headers: headers })
      .then(response => {
        console.log(response)
        // this.refreshCategory()
        // this.handleSaveCandidateResponse(response, _data.is_new);
        this.fetchSkill()
        this.setState({
          open: false,
          openCategory: false,
          openRecruiter: false,
          openSkill: false,
        })
        this.setState({ isLoaded: true })
      })
      .catch(error => {
        this.setState({ isLoaded: true })
        let message = ''
        let eror = JSON.parse(error.response.request.response)
        for (var key in eror) {
          message = key + '-:' + eror[key]
          this.openSnackBar(message, 'error-bar')
        }
      })
  }

  async fetchSkill() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client/?jobs=true`)
    if (response.data) {
      this.setState({ nskills: response.data.result.skills })
    }
    //alert('tatch')
  }

  async loadHours() {
    let candidateID = this.state.data.id
    let from = this.state.from_date_month
    let to = this.state.to_date
    if (candidateID) {
      const response = await axios.get(
        `${AppConstants.API_BASE_URL}recruit/candidate/weekhours/?candidate=` +
        candidateID +
        `&month=` +
        from +
        `&year=` +
        to
      )
      let resp = ''
      if (response.data.results) {
        resp = response.data.results
      } else {
        resp = response.data
      }
      this.setState({ hours: resp })

      let total_hours = 0
      let total_bill = 0
      let total_client_bill = 0
      let th = this
      if (resp) {
        resp.forEach(function iterator(item) {
          total_hours = parseFloat(parseFloat(total_hours) + parseFloat(item.hours))
          total_bill = total_bill + item.hours * th.state.data.pay_rate
          total_client_bill = parseFloat(total_client_bill) + parseFloat(th.calculateBill(item.hours))
        })
      }

      this.setState({
        total_hours: total_hours,
        total_bill: total_bill,
        total_client_bill: total_client_bill,
      })
    }
  }

  async loadManagers() {
    let candidateID = this.state.data.job
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/job/` + candidateID + `/`)
    if (response.data) {
      let data = response.data.hiringmanager[0]
      this.setState({ hiringmanager: data }, function () {
      })
    }
  }

  async fetchContactsByClientId() {
    let id = this.state.data.client
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/clients/` + id + '/')
    this.setState({
      contactsByClient: response.data.contact,
      client: response.data,
      currentAddress: response.data.address,
    })
  }

  getDates = (startDate, endDate) => {
    var dates = [],
      currentDate = startDate,
      addDays = function (days) {
        var date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
      }
    while (currentDate <= endDate) {
      dates.push(currentDate)
      currentDate = addDays.call(currentDate, 1)
    }
    return dates
  }

  handleClose = () => {
    this.setState({
      open: false,
      openCategory: false,
      openRecruiter: false,
    })
  }

  async getAnswer() {
    let candidateID = this.state.data.id
    let th = this
    if (candidateID) {
      const response = await axios.get(
        // `${AppConstants.API_BASE_URL}recruit/answer/`+ candidateID
        `${AppConstants.API_BASE_URL}recruit/job/${candidateID}/questions/answers`
      )
      let resp = ''
      if (response.data.results) {
        resp = response.data.results;
      } else {
        resp = response.data;
      }


      // // let alldates = []
      // if (resp) {
      //   resp.forEach(function iterator(item) {
      //     if (edit != item.id) {
      //       var mdates = th.getDates(new Date(item.start_date), new Date(item.end_date))
      //       mdates.forEach(function (date) {
      //         alldates.push(date)
      //       })
      //     }
      //   })
      // }

      this.setState({
        answers: resp
      })
    }
  }

  async loadDates(edit = 0) {
    let candidateID = this.state.data.id
    let th = this
    if (candidateID) {
      const response = await axios.get(
        `${AppConstants.API_BASE_URL}recruit/candidate/weekhours/?candidate=` + candidateID
      )
      let resp = ''
      if (response.data.results) {
        resp = response.data.results
      } else {
        resp = response.data
      }
      let alldates = []
      if (resp) {
        resp.forEach(function iterator(item) {
          if (edit != item.id) {
            var mdates = th.getDates(new Date(item.start_date), new Date(item.end_date))
            mdates.forEach(function (date) {
              alldates.push(date)
            })
          }
        })
      }

      this.setState({
        all_dates_dis: alldates,
      })
    }
  }

  filterJobsOnClient = (jobs, clientId) => jobs.filter((j) => j['detail'].client === clientId || j['value'] === -1)

  handleClientChange = (e) => {
    const { jobs } = this.props
    let oldjob = this.getValueByName(jobs, this.props.data.job)
    this.setState({
      oldjob: oldjob,
    })
    let xdt = {}
    let nm = e.target.name
    let xx = this.getValueByName(this.props.clients, e.target.value)
    let oxx = this.getValueByName(this.props.clients, this.props.data.client)
    let val = nm + ' Changed  from ' + oxx + ' to ' + xx + ', '
    xdt[e.target.name] = val
    var joined = this.state.changes.concat(xdt)
    this.setState({ changes: joined })
    this.updateClientInfo(e.target.value)
    const _jobs = this.filterJobsOnClient([...jobs], e.target.value)
    this.setState({ jobs: _jobs })
    const { data } = this.state
    const _data = { ...data }
    _data.client = e.target.value
    _data.job = -1
    //alert(e.target.value)
    this.setState({ data: _data })
  }

  handleClientCheckboxChange = (e) => {
    const { data } = this.state
    const _data = { ...data }
    _data.client_selected = e.target.checked
    this.setState({ data: _data })
  }

  handleJobChange = (e) => {
    const { data, jobs } = this.state

    const _data = { ...data }

    let crrunt_job = e.target.value
    //alert(crrunt_job)
    let Jobsfil = this.props.jobs.filter(function (user) {
      return user.value == crrunt_job
    })

    this.setState({ currentjob: Jobsfil[0].detail })

    _data.third_party_bill_rate = Jobsfil[0].detail.third_party_bill_rate
    _data.visa_1099_bill_rate = Jobsfil[0].detail.visa_1099_bill_rate
    this.setState({ data: _data })

    //this.loadManagers()
    let xdt = {}
    let nm = e.target.name
    let xx = this.getValueByName(jobs, e.target.value)
    let oxx = this.state.oldjob
    if (!oxx) {
      oxx = this.getValueByName(jobs, this.props.data.job)
    }
    let val = nm + ' Changed  from ' + oxx + ' to ' + xx + ', '
    xdt[e.target.name] = val
    let joined = this.state.changes.concat(xdt)
    this.setState({ changes: joined })

    const jobInfo = find(jobs, (o) => o.value === e.target.value)
    if (e.target.value === -1) {
      _data.job_title = null
    }
    _data.employment_type = jobInfo.detail.employment_type
    _data.job = e.target.value
    this.setState({ data: _data })
  }

  getDialog = () => {
    const { state, closeDialog, saveMoats } = this;
    if (state.dialogOpen.moats) {
      return (
        <MoatsDialog
          data={state.data}
          handleChange={this.handleChange}
          closeDialog={closeDialog}
          open={state.dialogOpen.moats}
          saveMoats={saveMoats}
          saveCandidate={this.saveCandidate}
        />
      )
    } else if (state.dialogOpen.linkedIn) {
      return <LinkedInInfoDialog data={state.data} closeDialog={closeDialog} open={state.dialogOpen.linkedIn} />
    } else if (state.dialogOpen.expedite) {
      return <Expedite data={state.data} answers={state.answers} closeDialog={closeDialog} open={state.dialogOpen.expedite} />
    } else if (state.dialogOpen.contact) {
      return (
        <ContactInfoDialog
          data={state.data}
          closeDialog={closeDialog}
          open={state.dialogOpen.contact}
          handleChange={this.handleChange}
        />
      )
    } else if (state.dialogOpen.client_contact) {
      return (
        <ClientContactInfoDialog
          data={state.data}
          closeDialog={closeDialog}
          open={state.dialogOpen.client_contact}
          handleChange={this.handleChange}
        />
        //   <ClientContactInfoDialog
        // data={state.data}
        // client={state.client}
        // closeDialog={closeDialog}
        // open={state.client}
        // handleChange={this.handleChange}
        // />
      )
    } else if (state.dialogOpen.client_contact_hours) {
      return (
        <AddHours
          data={state.data}
          dateDisable={state.all_dates_dis}
          closeDialog={closeDialog}
          token={this.props.token}
          open={state.dialogOpen.client_contact_hours}
          handleChange={this.handleChange}
        />
      )
    } else if (state.dialogOpen.client_contact_hours_edit) {
      return (
        <AddHours
          data={state.data}
          hours={state.hoursedit}
          token={this.props.token}
          dateDisable={state.all_dates_dis}
          closeDialog={closeDialog}
          open={state.dialogOpen.client_contact_hours_edit}
          handleChange={this.handleChange}
        />
      )
    } else if (state.dialogOpen.client_contact_hours_sum) {
      return (
        <AddHourSummary
          data={state.hours}
          hours={state.selectedhours}
          token={this.props.token}
          closeDialog={closeDialog}
          open={state.dialogOpen.client_contact_hours_sum}
          handleChange={this.handleChange}
        />
      )
    } else if (state.dialogOpen.client_contact_hiring) {
      return (
        <AddHiringManager
          data={state.data}
          token={this.props.token}
          closeDialog={closeDialog}
          open={state.dialogOpen.client_contact_hiring}
        />
      )
    }

    return null
  }

  openDialog = (key) => {
    const { dialogOpen } = this.state
    const _dialogOpen = { ...dialogOpen }
    Object.keys(_dialogOpen).forEach((k) => (_dialogOpen[k] = false))
    _dialogOpen[key] = true
    this.setState({ dialogOpen: _dialogOpen })
  }
  handleOpenEdit = () => {
    const { isEdited, editSubmittal } = this.state
    this.setState({ isEdited: !isEdited, editSubmittal: !editSubmittal })
  }
  closeDialog = (key, message) => {
    this.loadHours()
    // this.props.onCandidateUpdate()
    const { dialogOpen } = this.state
    const _dialogOpen = { ...dialogOpen }
    Object.keys(_dialogOpen).forEach((k) => (_dialogOpen[k] = false))
    _dialogOpen[key] = false
    this.setState({ dialogOpen: _dialogOpen })
    if (message) this.openSnackBar(message, 'primary-bar')
  }

  openSnackBar = (message, bgcolor) => {
    const { snackBar } = this.state
    snackBar.open = true
    snackBar.message = message
    snackBar.backgroundColor = bgcolor
    this.setState({ snackBar: snackBar })
  }

  closeSnackBar = () => {
    const { snackBar } = this.state
    snackBar.open = false
    this.setState({ snackBar: snackBar })
  }

  saveMoats = (moatsData) => {
    const { data } = this.state
    const _phones = [],
      _emails = []
    let count = new Date().getTime() + 1
    moatsData.phone.forEach((p) => {
      _phones.push({ id: count, label: 'Phone', value: p })
      count++
    })
    moatsData.phone = _phones
    moatsData.email.forEach((e) => {
      _emails.push({ id: count, label: 'Email', value: e })
      count++
    })
    moatsData.email = _emails
    const _data = { ...data, ...moatsData }
    this.setState({ data: _data })
    this.closeDialog()
  }

  handleSaveCandidateResponse = (response, is_new) => {
    this.setState({ isLoaded: true })
    // let message = 'Something went wrong, please contact admin';
    let message = 'There is some problem. Please contact your administrator'
    if (response.data && response.data['successful']) {
      message = response.data['message']
      const { onCandidateUpdate } = this.props
      if (onCandidateUpdate) setTimeout(() => onCandidateUpdate(response.data.result, is_new))
    }
    this.openSnackBar(message, 'success-bar')
  }

  async validate(data) {
    let errorFormat = "Invalid format"
    let errorText = 'This field is required'
    let candidate_name = ''
    let candidate_title = ''
    let location = ''
    let vacation = ''
    let communication = ''
    let availability = ''
    let current_salary = ''
    let paid_date = ''
    let pay_rate = ''
    let activity = ''
    let send_signature_html = ''
    let start_date = ''
    let isPhoneValid = false
    let isEmailValid = false


    if (data.email.length === 0) {
      data.email.forEach((item) => {
        item.errMsg = errorText
        item.err = true
        isEmailValid = false
      })
    }
    else {
      isEmailValid = true
      data.email.forEach((item) => {
        if (item.value === "") {
          item.errMsg = errorText
          item.err = true
          isEmailValid = isEmailValid && false
        } else if (!/[a-zA-Z0-9]+@\S+.com$/.test(item.value)) {
          item.errMsg = errorFormat
          item.err = true
          isEmailValid = isEmailValid && false
        } else {
          item.errMsg = ''
          item.err = false
          isEmailValid = isEmailValid && true
        }
      })
    }


    if (data.phone.length === 0) {
      data.phone.forEach((item) => {
        item.errMsg = errorText
        item.err = true
        isPhoneValid = true
      })
    }
    else {
      isPhoneValid = true
      data.phone.forEach((item) => {
        if (item.value === "") {
          item.errMsg = errorText
          item.err = true
          isPhoneValid = isPhoneValid && false
        } else if (!/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(item.value)) {
          item.errMsg = errorFormat
          item.err = true
          isPhoneValid = isPhoneValid && false
        } else {
          item.errMsg = ''
          item.err = false
          isPhoneValid = isPhoneValid && true
        }
      })
    }

    if (!data.name && data.status === 2) {
      candidate_name = errorText
    }

    if (data.paid && !data.paid_date) {
      paid_date = errorText
    }

    if (!data.name && data.status === 1) {
      candidate_name = errorText
    }

    if (!data.send_signature_html && data.status === 1) {
      send_signature_html = errorText
    }

    if (!data.job_title && data.status === 2) {
      candidate_title = errorText
    }

    if (!data.location && data.status === 2) {
      location = errorText
    }

    if (!data.vacation && data.status === 2) {
      vacation = errorText
    }

    if (!data.communication && data.status === 2) {
      communication = errorText
    }

    if (!data.availability && data.status === 2) {
      availability = errorText
    }

    // if (!data.salary && data.status===2) {
    //     current_salary = errorText;
    // }

    if (!data.activity && data.status === 2) {
      activity = errorText
    }

    // if (!data.pay_rate && data.status===2) {
    //     pay_rate = errorText;
    // }

    if (data.status === 5 && this.checkIsDate(data.start_date)) {
      start_date = errorText
      this.setState({
        submit_text: 'Fill Required value',
      })
      return false
    } else {
      this.setState({
        submit_text: '',
      })
    }

    if (data.status === 2) {
      if (
        !data.vacation ||
        !data.availability ||
        !data.communication ||
        !data.total_benefits ||
        !data.activity ||
        !data.salary ||
        !data.expected_salary ||
        !data.notes ||
        !data.phone ||
        !data.email
      ) {
        this.setState({
          submit_text: 'Fill Required value',
        })
        return false
      } else {
        this.setState({
          submit_text: '',
        })
      }
    }

    if (candidate_name ||
      location ||
      vacation ||
      communication ||
      availability ||
      activity ||
      send_signature_html ||
      paid_date
    ) {
      await this.setState({
        candidate_name,
        candidate_title,
        location,
        paid_date,
        vacation,
        communication,
        availability,
        activity,
        send_signature_html,
      })
      return false
    } else {
      if (!(isEmailValid && isPhoneValid)) {
        return false
      } else {
        return true
      }
    }
  }

  async resetState() {
    let candidate_name = ''
    let candidate_title = ''
    let location = ''
    let vacation = ''
    let communication = ''
    let availability = ''
    let current_salary = ''
    let activity = ''
    await this.setState({
      candidate_name,
      candidate_title,
      location,
      vacation,
      communication,
      availability,
      current_salary,
      activity,
    })
  }

  formatDate = (date) => {
    if (date) {
      var date = date.split('/')
      var f = date[2] + '-' + date[0] + '-' + date[1]
      return f
    }
  }

  formatToCalendarDate = (date) => {
    if (date) {
      if (typeof date === 'string') date = new Date(date)
      let month = date.getMonth() + 1
      if (month.toString().length === 1) month = `0${month}`

      let _date = date.getDate().toString()
      if (_date.length === 1) _date = `0${_date}`

      return `${month}/${_date}/${date.getFullYear()}`
    } else {
      return
    }
  }

  calculateBill = (hours) => {
    let billData = 0
    if (this.state.data.status_visa == 6) {
      let sum = this.state.data.pay_rate * parseFloat(this.state.data.job_third_party_bill_rate)
      if (sum > 0) billData = sum + 30
      else billData = 0.0
    } else if (this.state.data.status_visa == 5) {
      let sum = this.state.data.pay_rate * parseFloat(this.state.data.job_visa_1099_bill_rate)
      if (sum > 0) billData = sum + 30
      else billData = 0.0
    } else if (this.state.data.status_visa == 2) {
      let sum = this.state.data.pay_rate * parseFloat(this.state.data.job_citizen_bill_rate)
      if (sum > 0) billData = sum + 30
      else billData = 0.0
    } else if (this.state.data.status_visa == 3) {
      let sum = this.state.data.pay_rate * parseFloat(this.state.data.job_green_card_bill_rate)
      if (sum > 0) billData = sum + 30
      else billData = 0.0
    } else {
      billData = 0.0
    }
    return parseFloat(billData).toFixed(1)
  }

  calculateBillRate = () => {
    const { data } = this.state

    const _data = { ...data }

    if (this.state.currentjob) {
      let billData = 0
      let visa_rate = 0
      if (this.state.data.status_visa == 6) {
        let sum = this.state.data.pay_rate * parseFloat(this.state.currentjob.third_party_bill_rate)
        visa_rate = this.state.currentjob.third_party_bill_rate
        if (sum > 0) billData = sum + 30
        else billData = 0.0
      } else if (this.state.data.status_visa == 5) {
        let sum = this.state.data.pay_rate * parseFloat(this.state.currentjob.visa_1099_bill_rate)
        visa_rate = this.state.currentjob.visa_1099_bill_rate
        if (sum > 0) billData = sum + 30
        else billData = 0.0
      } else if (this.state.data.status_visa == 2) {
        let sum = this.state.data.pay_rate * parseFloat(this.state.currentjob.citizen_bill_rate)
        visa_rate = this.state.currentjob.citizen_bill_rate
        if (sum > 0) billData = sum + 30
        else billData = 0.0
      } else if (this.state.data.status_visa == 3) {
        let sum = this.state.data.pay_rate * parseFloat(this.state.currentjob.green_card_bill_rate)
        visa_rate = this.state.currentjob.green_card_bill_rate
        if (sum > 0) billData = sum + 30
        else billData = 0.0
      } else {
        billData = 0.0
      }
      _data['bill_rate'] = parseFloat(billData).toFixed(1)
      _data['visa_rate'] = visa_rate
      this.setState({ data: _data })
    } else {
      let billData = 0
      let visa_rate = 0
      if (this.state.data.status_visa == 6) {
        let sum = this.state.data.pay_rate * parseFloat(this.state.data.job_third_party_bill_rate)
        visa_rate = this.state.data.job_third_party_bill_rate
        if (sum > 0) billData = sum + 30
        else billData = 0.0
      } else if (this.state.data.status_visa == 5) {
        let sum = this.state.data.pay_rate * parseFloat(this.state.data.job_visa_1099_bill_rate)
        visa_rate = this.state.data.job_visa_1099_bill_rate
        if (sum > 0) billData = sum + 30
        else billData = 0.0
      } else if (this.state.data.status_visa == 2) {
        let sum = this.state.data.pay_rate * parseFloat(this.state.data.job_citizen_bill_rate)
        visa_rate = this.state.data.job_citizen_bill_rate
        if (sum > 0) billData = sum + 30
        else billData = 0.0
      } else if (this.state.data.status_visa == 3) {
        let sum = this.state.data.pay_rate * parseFloat(this.state.data.job_green_card_bill_rate)
        visa_rate = this.state.data.job_green_card_bill_rate
        if (sum > 0) billData = sum + 30
        else billData = 0.0
      } else {
        billData = 0.0
      }
      _data['bill_rate'] = parseFloat(billData).toFixed(1)
      _data['visa_rate'] = visa_rate
      this.setState({ data: _data })
    }
  }

  setFromDate = (e) => {
    if (e.target.value) {
      this.setState(
        {
          from_date_month: e.target.value,
        },
        function () {
          this.loadHours()
        }
      )
    }
  }

  setToDate = (e) => {
    if (e.target.value) {
      this.setState({
        to_date: e.target.value,
      })
    }
  }

  getYearList = () => {
    const year = new Date().getFullYear()
    let jsonArr = []
    for (let i = -5; i <= 10; i++) {
      jsonArr.push({
        value: year + i,
        label: year + i,
      })
      this.setState({
        years: jsonArr,
      })
    }
    return jsonArr
  }

  applyFilterbyTime = () => {
    this.loadHours()
  }

  async saveCandidate() {
    const _data = { ...this.state.data }
    console.log("awais", _data)
    const is_valid = await this.validate(_data)
    // return
    if (_data.skills) _data.skills = _data.skills.join(',')
    else _data.skills = ''
    const {
      currentAddress,
      currentTerm,
      client: { address, name, terms },
    } = this.state

    if (currentAddress !== address || currentTerm !== terms) {
      try {
        await axios.put(`${AppConstants.API_BASE_URL}recruit/clients/${_data.client}/`, {
          name,
          address,
          terms,
        })
      } catch (e) {
        this.openSnackBar('Address failed to update', 'error-bar')
      }
    }

    if (is_valid) {
      if (_data.recruiter === -1) _data.recruiter = ''
      if (_data.client === -1) _data.client = ''
      if (_data.job === -1) _data.job = ''
      _data.email = _data.email.filter((e) => e.value).map((e) => e.value)
      _data.phone = _data.phone.filter((p) => p.value).map((p) => p.value)
      if (_data.salary) {
        _data.salary = String(_data.salary).replace('$', '')
      }
      // if(!_data.job_id){
      //     _data.job_id = _data.client;
      // }
      _data.start_date = _data.start_date ? new Date(_data.start_date) : undefined
      _data.interview_date = _data.interview_date ? new Date(_data.interview_date) : undefined
      _data.placement_date = _data.placement_date ? new Date(_data.placement_date) : undefined
      _data.submitted_internally = _data.submitted_internally ? new Date(_data.submitted_internally) : undefined
      _data.submitted_to_client = _data.submitted_to_client ? new Date(_data.submitted_to_client) : undefined
      _data.paid_date = _data.paid_date ? new Date(_data.paid_date) : undefined
      _data.client_fee = _data.client_fee
      this.setState({ isLoaded: false })
      var headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${this.props.token}`,
      }

      var plainheaders = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Token ${this.props.token}`,
      }
      let datmsg = ''
      var uniq = []
      var nv = {}
      this.state.changes.forEach(function iterator(item) {
        for (var i in item) {
          if (uniq.indexOf() == -1) {
            uniq.push(i)
            nv[i] = item[i]
          } else {
            nv[i] = item[i]
          }
        }
      })
      let candidanm = this.state.data.name

      for (var i in nv) {
        datmsg = datmsg + nv[i]
      }

      let candidateID = this.state.data.id
      let recruiter = this.state.data.recruiter_name

      let rev = { candidate: datmsg, id: candidateID, rec: recruiter, name: candidanm }
      if (_data.status_visa == '') {
        _data.status_visa = 1
      }
      if (_data.job == '') {
        delete _data.job
      }
      if (_data.client == '') {
        delete _data.client
      }
      axios
        .post(`${AppConstants.API_BASE_URL}recruit/candidate/`, _data, { headers: headers })
        .then((response) => {
          if (candidateID) {
            axios
              .post(`${AppConstants.API_BASE_URL}recruit/candidate/logs/`, rev, { headers: headers })
              .then((response) => {
                this.handleSaveCandidateResponse(response, _data.is_new)
                this.resetState()
              })
              .catch((error) => {
                this.setState({ isLoaded: true })
                let message = 'Please check your inputs, and try again logs'
                this.openSnackBar(message, 'error-bar')
                this.resetState()
              })
          } else {
            this.handleSaveCandidateResponse(response, _data.is_new)
            this.resetState()
          }
        })
        .catch((error) => {
          this.setState({ isLoaded: true })
          let message = 'Please check your inputs, and try again'
          this.openSnackBar(message, 'error-bar')
          this.resetState()
        })
    } else {
      let message = 'Please check your inputs, and try again'
      this.openSnackBar(message, 'error-bar')
      this.resetState()
    }
  }

  getSnackBarContent = () => {
    const {
      closeSnackBar,
      state: { snackBar },
    } = this
    return (
      <Snackbar
        className={snackBar.backgroundColor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={closeSnackBar}
        message={<span>{snackBar.message}</span>}
      />
    )
  }
  getProgress = () =>
    this.state.isLoaded ? false : <CircularProgress size={24} className={this.props.classes.buttonProgress} />

  contactDetail = () => {
    this.state.contactsByClient.map(function (contact) {
      const { name, title, type } = contact
      if (type == 2) {
        alert(name)
        return (
          <TableRow>
            <TableCell>{name}</TableCell>
            <TableCell>{title}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        )
      }
    })
  }

  getLongDescription = (jobDetail) => {
    return this.state.expandDescription[jobDetail.id] ? <p>{jobDetail.long_description}</p> : null
  }

  getEploymentType = (etype) => {
    if (etype == 1) {
      return 'Contract Job'
    } else if (etype == 2) {
      return `Contract To Hire`
    } else if (etype == 3) {
      return ' Permanent Job'
    } else {
      return ''
    }
  }

  getEploymentTypeC = (etype) => {
    if (etype == 1) {
      return <span className="c_type"> </span>
    } else if (etype == 2) {
      return <span className="ch_type"> </span>
    } else if (etype == 3) {
      return <span className="p_type"> </span>
    } else {
      return ''
    }
  }

  onLongDescriptionClick = (detail) => {
    const _expandDescription = { ...this.state.expandDescription }
    _expandDescription[detail.id] = !this.state.expandDescription[detail.id]
    this.setState({ expandDescription: _expandDescription })
  }

  get expansionPanel() {
    function getEmails(cnt) {
      cnt.map(function (e) {

        return <p>Email:{e.email}</p>
      })
    }

    function getPhones(cnt) {
      cnt.map(function (e) {
        return <p>Phone:{e.email}</p>
      })
    }

    const { data } = this.state
    if (
      ((!data.job || data.job === -1) && data.client && data.client !== -1) ||
      (data.client_selected && data.client && data.client !== -1)
    ) {
      const { clients } = this.props
      const client = [...clients].find((j) => j['value'] === data.client)
      const detail = client ? client['detail'] : {}
      return [
        {
          expanded: 'companyInfo',
          header: 'Company Info',
          content: (
            <React.Fragment>
              <div>{detail.company_info}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'contact',
          header: 'Contact',
          content: (
            <React.Fragment>
              {
                detail.contact &&
                detail.contact.map((contact) => {
                  return (
                    <div>
                      <span>Name:{contact.name}</span>
                      <br />

                      {getEmails(contact.email)}
                      {getPhones(contact.phone)}
                    </div>
                  )
                })
                //detail.contact
              }
            </React.Fragment>
          ),
        },
        {
          expanded: 'competition',
          header: 'Competition',
          content: (
            <React.Fragment>
              <div>{detail.competitors}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'sellingPoint',
          header: 'Selling Point',
          content: (
            <React.Fragment>
              <div>{detail.selling_points}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'products',
          header: 'Products',
          content: (
            <React.Fragment>
              <div>{detail.products}</div>
            </React.Fragment>
          ),
        },
      ]
    } else if (data.client && data.client !== -1 && data.job && data.job !== -1) {
      const { jobs } = this.props
      const job = [...jobs].find((j) => j['value'] === data.job)
      const detail = job ? job['detail'] : {}
      return [
        {
          expanded: 'jobDetails',
          header: 'Job Details',
          content: (
            <React.Fragment>
              <div className="job-details">
                <h2 className="title">{detail.title}</h2>
                <div className="client-name">{detail.client_name}</div>
                <div className="location">{detail.location}</div>
                <div className="salary">
                  ${detail.min_salary}K - ${detail.max_salary}K
                </div>
                <p>{detail.short_description}</p>
                <div className="link" onClick={() => this.onLongDescriptionClick(detail)}>
                  Long Description
                </div>
                {this.getLongDescription(detail)}
              </div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'intakeCall',
          header: 'Intake Call & Interview',
          content: (
            <React.Fragment>
              <div>{detail.intake_call}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'candidateFeedBack',
          header: 'Candidate Feedback',
          content: (
            <React.Fragment>
              <div>{detail.candidate_feedback}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'companiesHiring',
          header: 'Companies Hiring',
          content: (
            <React.Fragment>
              <div>{detail.companies_hiring}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'templateEmail',
          header: 'Template Email',
          content: (
            <React.Fragment>
              <div>{detail.template_email}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'hiring Manager',
          header: 'Hiring Manager',
          content: (
            <React.Fragment>
              <div>
                <Table>
                  <TableHead>
                    <TableRow className="cotsumheader">
                      <TableCell> Name </TableCell>
                      <TableCell> Title </TableCell>
                      <TableCell> Email </TableCell>
                      <TableCell> Phone </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.contactsByClient.map((contact) => (
                      <TableRow>
                        <TableCell>{contact.name}</TableCell>
                        <TableCell>{contact.title}</TableCell>
                        <TableCell>
                          {contact.email.map((email) => (
                            <p>{email.email}</p>
                          ))}
                        </TableCell>
                        <TableCell>
                          {contact.phone.map((phone) => (
                            <p>{phone.phone}</p>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <AppButton color="warning" label="+" onClick={() => this.openDialog('client_contact_hiring')} />
            </React.Fragment>
          ),
        },
      ]
    }
    return []
  }

  get expansionPanel2() {
    function getEmails(cnt) {
      cnt.map(function (e) {

        return <p>Email:{e.email}</p>
      })
    }

    function getPhones(cnt) {
      cnt.map(function (e) {
        return <p>Phone:{e.email}</p>
      })
    }

    const { data } = this.state
    if (
      ((!data.job || data.job === -1) && data.client && data.client !== -1) ||
      (data.client_selected && data.client && data.client !== -1)
    ) {
      const { clients } = this.props
      const client = [...clients].find((j) => j['value'] === data.client)
      const detail = client ? client['detail'] : {}
      return [
        {
          expanded: 'jobDetails',
          header: 'Job Details',
          content: (
            <React.Fragment>
              <div className="job-details">
                <h2 className="title">{detail.title}</h2>
                <div className="client-name">{detail.client_name}</div>
                <div className="location">{detail.location}</div>
                <div className="salary">
                  ${detail.min_salary}K - ${detail.max_salary}K
                </div>
                <p>{detail.short_description}</p>
                <div className="link" onClick={() => this.onLongDescriptionClick(detail)}>
                  Long Description
                </div>
                {this.getLongDescription(detail)}
              </div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'intakeCall',
          header: 'Intake Call & Interview',
          content: (
            <React.Fragment>
              <div>{detail.intake_call}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'candidateFeedBack',
          header: 'Candidate Feedback',
          content: (
            <React.Fragment>
              <div>{detail.candidate_feedback}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'companiesHiring',
          header: 'Companies Hiring',
          content: (
            <React.Fragment>
              <div>{detail.companies_hiring}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'templateEmail',
          header: 'Template Email',
          content: (
            <React.Fragment>
              <div>{detail.template_email}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'hiring Manager',
          header: 'Hiring Manager',
          content: (
            <React.Fragment>
              <div>
                <Table>
                  <TableHead>
                    <TableRow className="cotsumheader">
                      <TableCell> Name </TableCell>
                      <TableCell> Title </TableCell>
                      <TableCell> Email </TableCell>
                      <TableCell> Phone </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.contactsByClient.map((contact) => (
                      <TableRow>
                        <TableCell>{contact.name}</TableCell>
                        <TableCell>{contact.title}</TableCell>
                        <TableCell>
                          {contact.email.map((email) => (
                            <p>{email.email}</p>
                          ))}
                        </TableCell>
                        <TableCell>
                          {contact.phone.map((phone) => (
                            <p>{phone.phone}</p>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <AppButton color="warning" label="+" onClick={() => this.openDialog('client_contact_hiring')} />
            </React.Fragment>
          ),
        },
      ]
    } else if (data.client && data.client !== -1 && data.job && data.job !== -1) {
      const { jobs } = this.props
      const job = [...jobs].find((j) => j['value'] === data.job)
      const detail = job ? job['detail'] : {}
      return [
        {
          expanded: 'jobDetails',
          header: 'Job Details',
          content: (
            <React.Fragment>
              <div className="job-details">
                <h2 className="title">{detail.title}</h2>
                <div className="client-name">{detail.client_name}</div>
                <div className="location">{detail.location}</div>
                <div className="salary">
                  ${detail.min_salary}K - ${detail.max_salary}K
                </div>
                <p>{detail.short_description}</p>
                <div className="link" onClick={() => this.onLongDescriptionClick(detail)}>
                  Long Description
                </div>
                {this.getLongDescription(detail)}
              </div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'intakeCall',
          header: 'Intake Call & Interview',
          content: (
            <React.Fragment>
              <div>{detail.intake_call}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'candidateFeedBack',
          header: 'Candidate Feedback',
          content: (
            <React.Fragment>
              <div>{detail.candidate_feedback}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'companiesHiring',
          header: 'Companies Hiring',
          content: (
            <React.Fragment>
              <div>{detail.companies_hiring}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'templateEmail',
          header: 'Template Email',
          content: (
            <React.Fragment>
              <div>{detail.template_email}</div>
            </React.Fragment>
          ),
        },
        {
          expanded: 'hiring Manager',
          header: 'Hiring Manager',
          content: (
            <React.Fragment>
              <div>
                <Table>
                  <TableHead>
                    <TableRow className="cotsumheader">
                      <TableCell> Name </TableCell>
                      <TableCell> Title </TableCell>
                      <TableCell> Email </TableCell>
                      <TableCell> Phone </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.contactsByClient.map((contact) => (
                      <TableRow>
                        <TableCell>{contact.name}</TableCell>
                        <TableCell>{contact.title}</TableCell>
                        <TableCell>
                          {contact.email.map((email) => (
                            <p>{email.email}</p>
                          ))}
                        </TableCell>
                        <TableCell>
                          {contact.phone.map((phone) => (
                            <p>{phone.phone}</p>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <AppButton color="warning" label="+" onClick={() => this.openDialog('client_contact_hiring')} />
            </React.Fragment>
          ),
        },
      ]
    }
    return []
  }

  checkIsValid = (check = false, value = '') => {
    if (check && _isNumber(value)) {
      return false
    } else if (check && _isEmpty(value)) {
      return true
    } else {
      return false
    }
  }

  checkIsDate = (value = '') => {
    if (_isEmpty(value)) {
      return true
    } else if (moment(value).isValid()) {
      return false
    } else {
      return true
    }
  }

  getSubstatus = (status) => {
    return [{ value: 1, label: 'None' }, ...sub_status.filter((o) => o.status === status)]
  }

  filterTableFields(
    classes,
    data,
    contractPlacementFilter,
    placementFilter,
    handleChange,
    users,
    openDialog,
    saveCandidate,
    getProgress,
    account_managers,
    third_parties,
    submit_text,
    client,
    isEdited, handleClientChange,
    handleJobChange,
    handleClientCheckboxChange,
    clients,
    jobs,
    user_id,
  ) {

    let validation = data.status == 2
    if (contractPlacementFilter) {
      return (
        <Table className={classes.root} style={{ marginLeft: '0px !important' }}>
          <TableBody>
            <TableRow className={classes.row}>
              <TableCell className={classes.value}>
                <div className="action-buttons">
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <AppButton color="warning" label="Add Hours" onClick={() => openDialog('client_contact_hours')} />
                  </div>

                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <AppButton
                      color="primary"
                      label="Save"
                      icon={{ component: SaveIcon, position: 'right' }}
                      onClick={saveCandidate}
                      disabled={!this.state.isLoaded}
                    />
                    {getProgress()}
                  </div>
                </div>
              </TableCell>
              <TableCell className={classes.value}>
                <AppTextField
                  label="Placement Date"
                  fullWidth
                  name="placement_date"
                  defaultValue={data.placement_date}
                  type="date"
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell className={classes.value}>
                <TextField
                  label="Start Date"
                  variant="outlined"
                  defaultValue={this.formatDate(data.start_date)}
                  fullWidth
                  name="start_date"
                  type="date"
                  // value={data.start_date}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    } else if (placementFilter) {
      return (
        <Table className={classes.root} style={{ marginLeft: '0px !important' }}>
          <TableBody>
            <TableRow className={classes.row}>
              <TableCell className={classes.value}>
                <AppSelect
                  fullWidth
                  id="frmRecruiter"
                  label="RC Name"
                  value={data.send_signature_html}
                  name="send_signature_html"
                  onChange={handleChange}
                  items={users}
                />
              </TableCell>
              <TableCell className={classes.value}>
                <AppTextField
                  label="RC Commission Percentage"
                  placeholder="10%"
                  value={data.rc_commission_percent}
                  fullWidth
                  name="rc_commission_percent"
                  onChange={handleChange}
                />
              </TableCell>

              <TableCell className={classes.value} style={{ width: '20%' }}>
                <AppTextField
                  label="Total RC Commission"
                  value={data.total_rc_commision_amount}
                  fullWidth
                  placeholder="$1100"
                  name="total_rc_commission"
                  disabled={true}
                />
              </TableCell>
              {data.paid === 1 && (
                <TableCell className={classes.value} style={{ width: '20%' }}>
                  <AppTextField
                    label="Paid Date"
                    defaultValue={moment(data.paid_date).format('YYYY-MM-DD')}
                    fullWidth
                    type="date"
                    onChange={handleChange}
                    placeholder="Paid Date"
                    name="paid_date"
                    error={this.state.paid_date}
                  />
                </TableCell>
              )}
            </TableRow>
            <TableRow className={classes.row}>
              <TableCell className={classes.value}>
                <AppSelect
                  fullWidth
                  id="frmRecruiter"
                  label="AM Name"
                  value={data.account_manager_id}
                  name="account_manager_id"
                  onChange={handleChange}
                  items={account_managers}
                />
              </TableCell>
              <TableCell className={classes.value} style={{ width: '20%' }}>
                <AppTextField
                  label="AM Commission Percentage"
                  value={data.am_commission_percent}
                  fullWidth
                  placeholder="5%"
                  name="am_commission_percent"
                  onChange={handleChange}
                />
              </TableCell>

              <TableCell className={classes.value} style={{ width: '20%' }}>
                <AppTextField
                  label="Total AM Commission"
                  value={data.total_am_commision_amount}
                  fullWidth
                  placeholder="$1300"
                  name="total_am_commission"
                  disabled={true}
                />
              </TableCell>

              <TableCell className={classes.value}>
                <AppSelect
                  fullWidth
                  name="paid"
                  value={data.paid}
                  onChange={handleChange}
                  id="paid"
                  label="Client Paid"
                  items={[
                    { value: 1, label: ' Paid' },
                    { value: 0, label: 'Not Paid' },
                  ]}
                />
              </TableCell>
            </TableRow>

            <TableRow className={classes.row}>
              <TableCell className={classes.value} style={{ width: '20%' }}>
                <AppTextField
                  label="Client Fee Percentage"
                  value={data.client_fee !== null ? data.client_fee : data.client_fee_percentage}
                  fullWidth
                  placeholder="20%"
                  name="client_fee"
                  onChange={handleChange}
                />
              </TableCell>

              <TableCell className={classes.value}>
                <AppTextField
                  label="Placement Date"
                  fullWidth
                  name="placement_date"
                  defaultValue={this.formatDate(data.placement_date)}
                  type="date"
                  onChange={handleChange}
                />
              </TableCell>
              {data.status === 1 ? (
                ''
              ) : (
                <TableCell className={classes.value}>
                  <AppTextField
                    label="Start Date"
                    defaultValue={this.formatDate(data.start_date)}
                    fullWidth
                    name="start_date"
                    type="date"
                    onChange={handleChange}
                  />
                </TableCell>
              )}

              <TableCell className={classes.value} style={{ width: '20%' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={0}
                      name="withdraw"
                      checked={data.withdraw == 0}
                      color="primary"
                      onChange={handleChange}
                    />
                  }
                  label="Confirmed"
                  labelPlacement="end"
                />
              </TableCell>

              <TableCell className={classes.value} style={{ width: '20%' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.withdraw == 1}
                      value={1}
                      name="withdraw"
                      color="primary"
                      onChange={handleChange}
                    />
                  }
                  label="Widthraw"
                  labelPlacement="end"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <AppTextField
                  onChange={handleChange}
                  label="Client Invoice #"
                  value={data.invoice_no}
                  fullWidth
                  name="invoice_no"
                />
              </TableCell>
              <TableCell>
                <AppTextField
                  onChange={handleChange}
                  label="Placement notes"
                  rows={5}
                  multiline={true}
                  value={data.placement_notes}
                  fullWidth
                  name="placement_notes"
                />
              </TableCell>
              <TableCell>
                <AppTextField
                  onChange={({ currentTarget }) => {
                    this.setState({
                      client: {
                        ...this.state.client,
                        address: currentTarget.value,
                      },
                    })
                  }}
                  label="Billing address"
                  rows={5}
                  multiline={true}
                  value={client.address || ''}
                  fullWidth
                  name="billing_address"
                />
              </TableCell>
              <TableCell>
                <AppSelect
                  fullWidth
                  id="terms"
                  label="Terms"
                  value={client.terms || ''}
                  name="terms"
                  onChange={this.handleChangeTerms}
                  items={[{ label: 'None', value: '' }, ...this.getTermsOptions()]}
                />
                <span onClick={this.handleCustomToggle} className="custom-term">
                  Custom{' '}
                  {this.state.custom_term ? (
                    <RemoveCircle className={classes.actionButton} color="primary" />
                  ) : (
                    <AddCircle className={classes.actionButton} color="primary" />
                  )}
                </span>
              </TableCell>
              {this.state.custom_term && (
                <TableCell>
                  <AppTextField
                    onChange={({ currentTarget }) => {
                      this.setState({
                        client: {
                          ...this.state.client,
                          terms: currentTarget.value,
                        },
                      })
                    }}
                    label="custom"
                    value={client.terms || ''}
                    fullWidth
                    name="terms"
                  />
                </TableCell>
              )}
            </TableRow>

            <TableRow className={classes.row}>
              <TableCell className={classes.value} colSpan={2}>
                <div className="action-buttons">
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <AppButton color="warning" label="Contact Info" onClick={() => openDialog('client_contact')} />
                  </div>

                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <AppButton
                      color="primary"
                      label="Save"
                      icon={{ component: SaveIcon, position: 'right' }}
                      onClick={saveCandidate}
                      disabled={!this.state.isLoaded}
                    />
                    {getProgress()}
                  </div>


                  {contractPlacementFilter !== true && (
                    <div style={{ display: 'inline-flex', width: '86%' }}>
                      <AppSelect
                        fullWidth
                        name="clients"
                        value={data.client}
                        onChange={handleClientChange}
                        label="Clients"
                        items={clients}
                      />
                    </div>
                  )}
                  {contractPlacementFilter !== true && (
                    <div style={{ display: 'inline-flex' }}>
                      <Checkbox
                        checked={data.client_selected}
                        value={data.client_selected}
                        color="primary"
                        onChange={handleClientCheckboxChange}
                      />
                    </div>
                  )}

                  {contractPlacementFilter !== true && (
                    <div style={{ display: 'inline-flex', width: '86%' }}>
                      <AppSelect
                        fullWidth
                        name="jobs"
                        value={data.job}
                        onChange={handleJobChange}
                        label="Jobs"
                        items={jobs}
                      />
                    </div>
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    } else {
      let lab = ''
      let labtext = ''
      let labClass = ''
      if (data.bill_rate_manula == 1) {
        lab = '*'
        labClass = 'error-custom'
        labtext = 'custom bill rate added'
      } else {
        lab = ''
        labtext = ''
        labClass = ''
      }
      return (
        <>
          {!isEdited ? (
            <>
              <Dialog width="300" maxWidth="400" open={this.state.openSkill} aria-labelledby="simple-dialog-title">
                <DialogTitle onClose={this.handleClose} id="simple-dialog-title">
                  {"Add Skill"}
                </DialogTitle>
                <DialogContent>
                  <Table className={classes.root} style={{ marginLeft: '0px !important' }}>
                    <TableBody>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.value}>
                          <AppTextField label="Skill" fullWidth name="title" onChange={handleChange} />
                        </TableCell>
                      </TableRow>
                      <TableRow className="tr_last">
                        <TableCell className={classes.value} colSpan={2}>
                          <div className="action-buttons">
                            <div style={{ position: 'relative', display: 'inline-block', top: '0px' }}>
                              <AppButton
                                color="primary"
                                label="Save"
                                icon={{ component: SaveIcon, position: 'right' }}
                                onClick={this.saveSkill}
                                disabled={!this.state.isLoaded}
                              />
                              {this.getProgress()}

                              <AppButton
                                color="secondary"
                                label="Close"
                                onClick={this.props.close}
                                disabled={!this.state.isLoaded}
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </DialogContent>
              </Dialog>

              <Table className={classes.root} style={{ marginLeft: '0px !important' }}>
                <TableBody>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Candidate Name"
                        value={data.name}
                        fullWidth
                        name="name"
                        onChange={handleChange}
                        helperText={this.state.candidate_name.length === 0 ? ' ' : 'This field is required'}
                        error={this.state.candidate_name.length === 0 ? false : true}
                      />
                    </TableCell>
                    <TableCell className={classes.value}>
                      <AppSelect
                        fullWidth
                        id="frmRecruiter"
                        label="Recruiter"
                        helperText={this.state.send_signature_html.length === 0 ? ' ' : 'This field is required'}
                        error={this.state.send_signature_html.length === 0 ? false : true}
                        value={data.send_signature_html}
                        name="send_signature_html"
                        onChange={handleChange}
                        items={users}
                      />
                    </TableCell>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Candidate Title"
                        value={data.linkedin_role}
                        fullWidth
                        name="role"
                        onChange={handleChange}
                      />
                    </TableCell>

                    {/*<TableCell className={classes.value} colSpan={2}>
                                      <AppTextField label="Company" value={data.company} fullWidth
                                                    name="company" onChange={handleChange}/>
                                  </TableCell>*/}
                  </TableRow>
                  <TableRow className={classes.row}>
                    {/*<TableCell className={classes.value} colSpan={2}>
                                      <AppTextField label="Industry" value={data.industry} fullWidth
                                                    name="industry" onChange={handleChange}/>
                                  </TableCell>*/}
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Location"
                        value={data.location}
                        fullWidth
                        name="location"
                        onChange={handleChange}
                        helperText={this.state.location.length === 0 ? ' ' : 'This field is required'}
                        error={this.state.location.length === 0 ? false : true}
                      />
                    </TableCell>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Vacation"
                        value={data.vacation}
                        fullWidth
                        name="vacation"
                        onChange={handleChange}
                        helperText={this.state.vacation.length === 0 ? ' ' : 'This field is required'}
                        error={this.checkIsValid(validation, data.vacation)}
                      />
                    </TableCell>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="LinkedIn Url"
                        value={data.linkedin_url}
                        fullWidth
                        name="linkedin_url"
                        onChange={handleChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.value}>
                      <AppSelect
                        fullWidth
                        name="status_visa"
                        value={data.status_visa}
                        onChange={handleChange}
                        id="status_visa"
                        label="Visa Status"
                        items={[
                          { value: 1, label: 'None' },
                          { value: 2, label: 'Citizenship' },
                          { value: 3, label: 'Green Card' },
                          { value: 5, label: '1099' },
                          { value: 6, label: 'Third party' },
                          { value: 7, label: 'Other' },
                        ]}
                      />
                    </TableCell>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Communication Skills"
                        value={data.communication}
                        fullWidth
                        name="communication"
                        onChange={handleChange}
                        helperText={this.state.communication.length === 0 ? ' ' : 'This field is required'}
                        error={this.checkIsValid(validation, data.communication)}
                      />
                    </TableCell>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Availability"
                        value={data.availability}
                        fullWidth
                        name="availability"
                        onChange={handleChange}
                        helperText={this.state.availability.length === 0 ? ' ' : 'This field is required'}
                        error={this.checkIsValid(validation, data.availability)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    {data.status_visa == 6 ? (
                      <TableCell className={classes.value}>
                        <AppSelect
                          fullWidth
                          id="frmRecruiter"
                          label="Third Party"
                          value={data.third_party_id}
                          name="third_party_id"
                          onChange={handleChange}
                          items={third_parties}
                        />
                      </TableCell>
                    ) : (
                      ''
                    )}

                    {/*<TableCell className={classes.value} colSpan={2}>
                                      <AppTextField label="Expected Salary" value={data.expected_salary} fullWidth
                                                    name="expected_salary" onChange={handleChange}/>
                                  </TableCell>*/}

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Source"
                        value={data.source}
                        fullWidth
                        name="source"
                        onChange={handleChange}
                        helperText={' '}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Candidate's Activity"
                        value={data.activity}
                        fullWidth
                        name="activity"
                        onChange={handleChange}
                        helperText={this.state.activity.length === 0 ? ' ' : 'This field is required'}
                        error={this.checkIsValid(validation, data.activity)}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Total benifits"
                        value={data.total_benefits}
                        fullWidth
                        name="total_benefits"
                        onChange={handleChange}
                        error={this.checkIsValid(validation, data.total_benefits)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    {data.job !== -1 && (
                      <>
                        {data.status !== 1 && (
                          <TableCell className={classes.value}>
                            <AppTextField
                              label="Follow up"
                              type="date"
                              defaultValue={this.formatDate(data.follow_up)}
                              fullWidth
                              name="follow_up"
                              onChange={handleChange}
                            />
                          </TableCell>
                        )}

                        <TableCell className={classes.value}>
                          {data.employment_type === 3 ? (
                            <AppTextField
                              label="Current Base Salary"
                              value={data.salary}
                              fullWidth
                              name="salary"
                              onChange={handleChange}
                              // error={this.checkIsValid(validation, data.salary)}
                              error={this.checkIsValid(validation, data.salary)}
                            />
                          ) : (
                            <AppTextField
                              label="Pay Rate"
                              value={data.pay_rate || ''}
                              fullWidth
                              name="pay_rate"
                              onChange={handleChange}
                              helperText={this.state.pay_rate.length === 0 ? ' ' : 'This field is required'}
                              error={this.checkIsValid(validation, data.pay_rate)}
                            />
                          )}
                        </TableCell>
                        {data.employment_type === 3 ? (
                          ''
                        ) : (
                          <TableCell className={labClass}>
                            <AppTextField
                              value={data.bill_rate}
                              label={lab + 'Bill Rate'}
                              defaultValue={data.bill_rate}
                              fullWidth
                              helperText={labtext}
                              name="bill_rate"
                              error={this.checkIsValid(validation, data.bill_rate)}
                              onChange={handleChange}
                              onKeyUp={this.setBillRateManula}
                            />
                          </TableCell>
                        )}
                      </>
                    )}

                    {data.employment_type === 3 ? (
                      <TableCell className={classes.value}>
                        <AppTextField
                          label="Salary Expectations "
                          value={data.expected_salary}
                          fullWidth
                          name="expected_salary"
                          onChange={handleChange}
                          error={this.checkIsValid(validation, data.expected_salary)}
                        />
                      </TableCell>
                    ) : (
                      ''
                    )}

                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="RC Score"
                        value={data.score}
                        fullWidth
                        helperText={' '}
                        name="score"
                        // error={this.checkIsValid(validation, data.interview_date)}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="AI Score"
                        value={data.score1}
                        fullWidth
                        helperText={' '}
                        disabled={true}
                        name="score1"
                        // error={this.checkIsValid(validation, data.interview_date)}
                        onChange={handleChange}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className={classes.row}>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="AA Score"
                        value={data.aa_score}
                        fullWidth
                        helperText={' '}
                        name="aa_score"
                        // error={this.checkIsValid(validation, data.interview_date)}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Ab Score"
                        value={data.ab_score}
                        fullWidth
                        helperText={' '}
                        name="ab_score"
                        // error={this.checkIsValid(validation, data.interview_date)}
                        onChange={handleChange}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className={classes.row}>
                    {data.status === 1 ? (
                      ''
                    ) : (
                      <TableCell className={classes.value}>
                        <AppTextField
                          label="Start Date"
                          defaultValue={this.formatDate(data.start_date)}
                          fullWidth
                          helperText={' '}
                          name="start_date"
                          type="date"
                          error={(data.status && data.status === 5) ? this.checkIsDate(data.start_date) : false}
                          onChange={handleChange}
                        />
                      </TableCell>
                    )}

                    {data.status !== 1 && (
                      <TableCell className={classes.value}>
                        <AppTextField
                          label="Interview Date"
                          defaultValue={this.formatDate(data.interview_date)}
                          fullWidth
                          helperText={' '}
                          name="interview_date"
                          type="date"
                          // error={this.checkIsValid(validation, data.interview_date)}
                          onChange={handleChange}
                        />
                      </TableCell>
                    )}

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Created"
                        value={data.created}
                        fullWidth
                        name="created"
                        onChange={handleChange}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className={classes.row}>
                    <TableCell className={classes.value}>
                      <AppTextField label="Updated" value={data.modified} fullWidth name="modified" disabled />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppSelect
                        fullWidth
                        name="status"
                        value={data.status}
                        onChange={handleChange}
                        id="Status"
                        label="Stage"
                        items={acc_stage_list
                          //   [
                          //   { value: 1, label: 'Leads' },
                          //   { value: 2, label: 'Submittals' },
                          //   { value: 3, label: 'Sendout' },
                          //   { value: 4, label: 'Interview' },
                          //   { value: 5, label: 'Hired/Placements' },
                          //   { value: 6, label: 'Rejected' },
                          //   // { value: 7, label: 'On Hold' },
                          //   // { value: 8, label: 'Closed' },
                          //   // { value: 8, label: 'Follow up' },
                          //   { value: 7, label: 'Internal' },
                          //   { value: 8, label: 'External' },
                          // ]
                        }
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppSelect
                        fullWidth
                        name="sub_status"
                        value={data.sub_status}
                        onChange={handleChange}
                        id="subSatus"
                        label="Status"
                        items={(data.status === 7) ? getStageByType("internal") : (data.status === 8) ? getStageByType("external") : (data.status === 9) ? getStageByType("pipeline") : sub_status}
                      // items={getStageByType}

                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      {data.employment_type != 3 ? (
                        <AppTextField label="Visa Rate" value={data.visa_rate} fullWidth name="visa_rate" disabled />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow className={classes.row}>

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Submitted Internally"
                        defaultValue={this.formatDate(data.submitted_internally)}
                        fullWidth
                        helperText={' '}
                        name="submitted_internally"
                        type="date"
                        // error={this.checkIsValid(validation, data.interview_date)}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Submitted To Client"
                        defaultValue={this.formatDate(data.submitted_to_client)}

                        value={data.submitted_to_client}
                        fullWidth
                        name="submitted_to_client"
                        type="date"
                        helperText={' '}
                        onChange={handleChange}
                      />
                    </TableCell>

                  </TableRow>

                  {(data.status === 5) && (
                    <TableRow className={classes.row}>
                      <TableCell className={classes.value} >
                        <AppTextField
                          label="Salary"
                          // value={data.client_fee !== null ? data.client_fee : data.current_salary}
                          value={data.salary}
                          fullWidth
                          // placeholder="20%"
                          disabled={user_id && (user_id === 10 || user_id === 2) ? false : true}
                          name="salary"
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell className={classes.value} >
                        <AppTextField
                          label="Client Fee Percentage"
                          value={data.client_fee !== null ? data.client_fee : data.client_fee_percentage}
                          fullWidth
                          disabled={user_id && (user_id === 10 || user_id === 2) ? false : true}
                          placeholder="20%"
                          name="client_fee"
                          onChange={handleChange}
                        />
                      </TableCell>

                      <TableCell className={classes.value}>
                        <AppTextField
                          label="Placement Date"
                          fullWidth
                          name="placement_date"
                          disabled={user_id && (user_id === 10 || user_id === 2) ? false : true}
                          defaultValue={this.formatDate(data.placement_date)}
                          type="date"
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow className={classes.row + ' candidate_browse_button'}>
                    <TableCell className={classes.value}>
                      <Input label="Resume Url" type="file" name="resume_url_ajx" onChange={handleChange} />

                      {data.resume_url ? (
                        <a download="true" target="_blank" href={`${AppConstants.SERVER_BASE_URL + data.resume_url}`}>
                          Download
                        </a>
                      ) : null}
                    </TableCell>
                  </TableRow>

                  <TableRow className={classes.row}>
                    {/*<TableCell className={classes.value} colSpan={2}>
                                      <AppTextField label="Message" value={data.message} fullWidth
                                                    rows={10} multiline={true} name="message"
                                                    onChange={handleChange}/>
                                  </TableCell>*/}
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Notes"
                        value={data.notes}
                        fullWidth
                        rows={10}
                        multiline={true}
                        name="notes"
                        error={this.checkIsValid(validation, data.notes)}
                        onChange={handleChange}
                      />
                    </TableCell>

                    {this.props.isSubmittal && (
                      <TableCell className={classes.value}>
                        <AppTextField
                          label="Submittal Notes"
                          value={data.submittal_notes}
                          fullWidth
                          rows={10}
                          multiline={true}
                          name="submittal_notes"
                          onChange={handleChange}
                        />
                      </TableCell>
                    )}

                    <TableCell className={classes.value}>
                      <div className={classes.phone}>
                        <AppMoreDynamicTextField
                          id="txtPhone"
                          label="Phone"
                          name="phone"
                          fullWidth
                          data={data.phone}
                          onChange={handleChange}
                          placeholder="000-000-0000"
                          leads={false}
                        />
                      </div>
                      <div className={classes.phone}>
                        <AppMoreDynamicTextField
                          id="txtEmail"
                          type="email"
                          label="Email"
                          name="email"
                          fullWidth
                          data={data.email}
                          onChange={handleChange}
                          placeholder="username@mail.com"
                          leads={false}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Bonus"
                        value={data.bonus}
                        fullWidth
                        name="bonus"
                        onChange={handleChange}
                        helperText={this.state.activity.length === 0 ? ' ' : 'This field is required'}
                        error={this.checkIsValid(validation, data.bonus)}
                      />
                    </TableCell>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="OTE Expectations"
                        value={data.current_ote}
                        fullWidth
                        name="current_ote"
                        onChange={handleChange}
                        helperText={this.state.activity.length === 0 ? ' ' : 'This field is required'}
                        error={this.checkIsValid(validation, data.current_ote)}
                      />
                    </TableCell>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Current OTE"
                        value={data.ote_expectation}
                        fullWidth
                        name="ote_expectation"
                        onChange={handleChange}
                        helperText={this.state.activity.length === 0 ? ' ' : 'This field is required'}
                        error={this.checkIsValid(validation, data.ote_expectation)}
                      />
                    </TableCell>

                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.value}>

                      <div style={{ position: 'relative', display: 'inline-block' }}>
                        {contractPlacementFilter !== true && (
                          <div style={{ width: '300px' }}>
                            <AppSelect
                              fullWidth
                              name="clients"
                              value={data.client}
                              onChange={handleClientChange}
                              label="Clients"
                              items={clients}
                              style={{ width: '100%' }}
                            />
                          </div>
                        )}
                      </div>

                    </TableCell>
                    <TableCell className={classes.value}>
                      <div class="d-flex justify-content-center align-items-center">
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          {contractPlacementFilter !== true && (
                            <div style={{ display: 'inline-flex' }}>
                              <Checkbox
                                checked={data.client_selected}
                                value={data.client_selected}
                                color="primary"
                                onChange={handleClientCheckboxChange}
                              />
                            </div>
                          )}
                        </div>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          {contractPlacementFilter !== true && (
                            <div style={{ width: '300px' }}>
                              <AppSelect
                                fullWidth
                                name="jobs"
                                value={data.job}
                                onChange={handleJobChange}
                                label="Jobs"
                                items={jobs}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className={classes.value}>
                      <Grid container spacing={1}>
                        <Grid item xs={11}>
                          {/* {data.id ? ( */}
                          <MultipleSelect
                            label={'Choose some skills'}
                            name="skills"
                            values={data.skills || "no skilss"}
                            options={this.state.nskills}
                            onChange={this.handleChangeSkill}
                            SelectProps={{
                              isCreatable: true,
                              msgNoOptionsAvailable: 'Type and press ennter for new value',
                              msgNoOptionsMatchFilter: 'Type and press enter for new value',
                            }}
                          />
                          {/* ) : ( */}
                          {/* <MultipleSelect
                        label={'Choose some skills'}
                        name="skills"
                        options={this.state.nskills}
                        onChange={this.handleChangeSkill}
                        SelectProps={{
                          isCreatable: true,
                          msgNoOptionsAvailable: 'Type and press ennter for new value',
                          msgNoOptionsMatchFilter: 'Type and press enter for new value',
                        }}
                      /> */}
                          {/* )} */}
                        </Grid>
                        <Grid item xs={1}>
                          <AppButton
                            style={{ marginTop: '2px' }}
                            className="addNew"
                            onClick={this.addNewSkill}
                            icon={{ component: Add }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell>
                      <TableCell style={{ width: '38.33%' }} className={classes.value} colSpan={2}>
                        <div className="action-buttons">
                          <div style={{ position: 'relative', display: 'inline-block' }}>
                            <AppButton color="warning" label="Cancel Edit" onClick={() => this.handleOpenEdit()} />
                          </div>
                          {/* <div style={{ position: 'relative', display: 'inline-block' }}>
                            <AppButton color="warning" label="Linkedin Info" onClick={() => openDialog('linkedIn')} />
                          </div> */}

                          <div style={{ position: 'relative', display: 'inline-block' }}>
                            <AppButton
                              color="primary"
                              label="Save"
                              icon={{ component: SaveIcon, position: 'right' }}
                              onClick={this.beforeSaveCandidate}
                              disabled={!this.state.isLoaded}
                            />

                            {getProgress()}

                          </div>


                          {submit_text && (
                            <Tooltip classes={{ tooltip: classes.customTooltip }} title={'Fill Required Info'}>
                              <InfoIcon color="secondary" fontSize="large" />
                            </Tooltip>
                          )}
                          {/*<AppButton*/}
                          {/*  color="secondary"*/}
                          {/*  label=""*/}
                          {/*  variant="round"*/}
                          {/*  icon={{component: Style, position: 'right'}}*/}
                          {/*  onClick={() => openDialog('moats')}*/}
                          {/*/>*/}
                        </div>
                      </TableCell>
                    </TableCell>
                    <TableCell colSpan={5} />
                  </TableRow>
                </TableBody>
              </Table>
            </>
          ) : (
            <div>
              {this.props.showInfo === true &&
                <div>
                  <section>
                    <Card class="cardStyle" style={{ padding: "50px", borderRadius: '10px', background: "#3f51b5" }}>
                      <div className="action-buttons">
                        {/* <div style={{ position: 'relative', display: 'inline-block' }}>
                    <AppButton color="warning" label="Linkedin Info" onClick={() => openDialog('linkedIn')} />
                  </div>
                  {this.state.answers.length > 0 && <div style={{ position: 'relative', display: 'inline-block' }}>
                    <AppButton color="warning" label="Expedite" onClick={() => { openDialog('expedite') }} />
                  </div>} */}
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <AppButton color="warning" label="Edit" onClick={() => this.handleOpenEdit()} />
                        </div>

                        {/* <div style={{ position: 'relative', display: 'inline-block' }}>
                        {contractPlacementFilter !== true && (
                          <div style={{ width: '300px' }}>
                            <AppSelect
                              fullWidth
                              name="clients"
                              value={data.client}
                              onChange={handleClientChange}
                              label="Clients"
                              items={clients}
                              style={{ width: '100%' }}
                            />
                          </div>
                        )}
                      </div> */}

                        {/* <div style={{ position: 'relative', display: 'inline-block' }}>
                          {contractPlacementFilter !== true && (
                            <div style={{ display: 'inline-flex' }}>
                              <Checkbox
                                checked={data.client_selected}
                                value={data.client_selected}
                                color="primary"
                                onChange={handleClientCheckboxChange}
                              />
                            </div>
                          )}
                        </div> */}
                        {/* <div style={{ position: 'relative', display: 'inline-block' }}>
                        {contractPlacementFilter !== true && (
                          <div style={{ width: '300px' }}>
                            <AppSelect
                              fullWidth
                              name="jobs"
                              value={data.job}
                              onChange={handleJobChange}
                              label="Jobs"
                              items={jobs}
                            />
                          </div>
                        )}
                      </div> */}

                      </div>
                      <div class="parentcontanier">
                        <section>
                          <Card class="cardStyle" style={{ margin: "20px 20px 50px 20px", borderRadius: "16px", background: "#3f51b5" }}>
                            <CardMedia
                              className={classes.cover}
                              image={data.image_url ? data.image_url : AvatarIconPng}
                              // image="/static/images/cards/live-from-space.jpg"
                              title="Live from space album cover"
                              style={{ borderRadius: '50%', height: "250px", width: "250px", maxHeight: "250px", maxWidth: "250px", margin: '25px' }}
                            />
                          </Card>
                          <Card class="cardStyle" style={{ margin: "20px 20px 50px 20px", borderRadius: "16px", padding: "10px", background: "White" }}>
                            {/* <legend class="legend1">Demographics:</legend> */}
                            <div class="contanier">
                              <span>
                                <label class="label1">Name:</label>
                                <label class="value">{data.name || '--'}</label>
                              </span>
                              <span>
                                <label class="label1">Title:</label>
                                <label class="value">{data.linkedin_role || '--'}</label>
                              </span>
                              <>
                                {data.phone.map((d, i) => {
                                  return (
                                    <>
                                      <span>
                                        <label class="label1">Phone:</label>
                                        <label class="value">{d.value || '--'}</label>
                                      </span>
                                    </>
                                  )
                                })}
                              </>
                              <>
                                {data.email.map((d, i) => {
                                  return (
                                    <>
                                      <span>
                                        <label class="label1">Email:</label>
                                        <label class="value">{d.value || '--'}</label>
                                      </span>
                                    </>
                                  )
                                })}
                              </>
                              <span>
                                <label class="label1">location:</label>
                                <label class="value">{data.location || '--'}</label>
                              </span>
                            </div>
                          </Card>

                        </section>
                        <section>
                          <Card class="cardStyle ml=2" style={{ margin: "5px 5px 5px 30px", borderRadius: "16px", padding: "10px" }}>
                            <legend class="legend1">Details:</legend>
                            <div class="inner">
                              <div class="contanierdetail">
                                <span>
                                  <label class="label1">Vacation:</label>
                                  <label class="value">{data.vacation}</label>
                                </span>
                                <span>
                                  <label class="label1">Visa Status:</label>
                                  <label class="value">{getVisaLabel(data.status_visa) || '--'}</label>
                                </span>
                                <span>
                                  <label class="label1">Communication Skills:</label>
                                  <label class="value">{data.communication || '--'}</label>
                                </span>
                                <span>
                                  <label class="label1">Availability:</label>
                                  <label class="value">{data.availability || '--'}</label>
                                </span>
                                <span>
                                  <label class="label1">Activity:</label>
                                  <label class="value">{data.activity || '--'}</label>
                                </span>
                                <span>
                                  <label class="label1">Total benefits:</label>
                                  <label class="value">{data.total_benefits || '--'}</label>
                                </span>
                                <span>
                                  <label class="label1">Follow Up:</label>
                                  <label class="value">{this.formatDate(data.follow_up)}</label>
                                </span>
                                <span>
                                  <label class="label1">RC Score:</label>
                                  <label class="value">{data.score || '--'}</label>
                                </span>
                              </div>
                              <div class="contanierdetail">
                                <span>
                                  <label class="label1">Current salary:</label>
                                  <label class="value">{data.salary || '--'}</label>
                                </span>
                                <span>
                                  <label class="label1">Desired salary:</label>
                                  <label class="value">{data.expected_salary || '--'}</label>
                                </span>
                                <span>
                                  <label class="label1">Start Date:</label>
                                  <label class="value">{this.formatDate(data.start_date) || '--'}</label>
                                </span>

                                <span>
                                  <label class="label1">Interview Date:</label>
                                  <label class="value">{this.formatDate(data.interview_date) || '--'}</label>
                                </span>
                                <span>
                                  <label class="label1">Created Date:</label>
                                  <label class="value">{data.created || '--'}</label>
                                </span>
                                <span>
                                  <label class="label1">Updated Date:</label>
                                  <label class="value">{data.modified || '--'}</label>
                                </span>
                                <span>
                                  <label class="label1">Skills:</label>
                                  <label class="value">{data.skillsJ || '--'}</label>
                                </span>
                                <span>
                                  <label class="label1">AI Score:</label>
                                  <label class="value">{data.score1 || '--'}</label>
                                </span>
                              </div>
                            </div>
                          </Card>
                          {/* <br></br> */}
                          <Card class="cardStyle" style={{ borderRadius: "16px", margin: "20px 5px 0px 30px", padding: "10px" }}>
                            <div class="contanier">
                              {(data.resume_url) &&
                                <span>
                                  <label class="label1">Resume:</label>
                                  <label class="value">
                                    {/* <AppButton color="info" label="Download" onClick={() => {
                            window.open(`${AppConstants.SERVER_BASE_URL + data.resume_url}`,'_blank','resizable=yes')
                          }} /> */}
                                    <a download="true" target="_blank" href={`${AppConstants.SERVER_BASE_URL + data.resume_url}`}>
                                      Download
                                    </a>
                                  </label>
                                </span>
                              }
                              <span>
                                <p class="value">
                                  <strong>Notes</strong> {data.notes}
                                </p>
                              </span>
                            </div>
                          </Card>

                        </section>
                      </div>
                    </Card>
                  </section>
                </div>
              }
            </div>
          )}
        </>
      )
    }
  }

  beforeSaveCandidate = async () => {
    const { data } = this.state;
    if ((await this.validate(data)) && (data.status === 7) && (data.sub_status === 2 || data.sub_status === 6)) {
      this.openDialog('moats')
    } else {
      this.saveCandidate()
    }
  }

  setEditSubmittal = () => {
    this.setState({
      editSubmittal: true,
    })
    const { isEdited } = this.state;
    this.setState({ isEdited: !isEdited })
  }

  handleChangeTerms = ({ target }) => {
    const { value } = target
    this.setState((state) => ({
      client: {
        ...state.client,
        terms: value,
      },
    }))
  }

  getTermsOptions = () => {
    const { terms } = this.state.client
    if (terms) {
      const index = TERMS.findIndex((t) => t.value === terms)
      if (index === -1) {
        return [...TERMS, { label: terms, value: terms }]
      } else {
        return TERMS
      }
    } else {
      return TERMS
    }
  }

  handleCustomToggle = () => {
    this.setState((state) => ({
      custom_term: !state.custom_term,
    }))
  }

  render() {
    const { isEdited } = this.state
    const {
      state: { data, clients, jobs, users, submit_text, editSubmittal, account_managers, third_parties },
      props: { classes, contractPlacementFilter, placementFilter, user_id },
      handleChange,
      openDialog,
      getDialog,
      handleOpenEdit,
      saveCandidate,
      getSnackBarContent,
      getProgress,
      expansionPanel,
      expansionPanel2,
      handleClientChange,
      handleJobChange,
      handleClientCheckboxChange,
    } = this

    return (
      <React.Fragment>
        {this.props.contact_filter ? (
          <div className="" >
            <div className="left">
              {getSnackBarContent()}
              {getDialog()}
              <div>
                {' '}
                <div>
                  <div className={this.props.classes.contactInfo}>
                    <div className={this.props.classes.phone}>
                      <AppSelect
                        name="filter_by_month"
                        onChange={this.setFromDate}
                        value={this.state.from_date_month}
                        label="Month"
                        items={[
                          { value: 1, label: 'Jan' },
                          { value: 2, label: 'Feb' },
                          { value: 3, label: 'March' },
                          { value: 4, label: 'April' },
                          { value: 5, label: 'May' },
                          { value: 6, label: 'June' },
                          { value: 7, label: 'July' },
                          { value: 8, label: 'August' },
                          { value: 9, label: 'Sept' },
                          { value: 10, label: 'Oct' },
                          { value: 11, label: 'Nov' },
                          { value: 12, label: 'Dec' },
                        ]}
                      />
                    </div>

                    <div className={this.props.classes.phone}></div>

                    <div className={this.props.classes.phone}>
                      <AppButton
                        color="primary"
                        label="Summary"
                        onClick={() => openDialog('client_contact_hours_sum')}
                      />
                    </div>
                  </div>
                </div>
                <Table>
                  <TableHead>
                    <TableRow className="cotsumheader">
                      <TableCell align="right"> &nbsp;</TableCell>
                      <TableCell align="right">Start Date</TableCell>
                      <TableCell align="right">End Date</TableCell>
                      <TableCell align="right">Hours</TableCell>
                      <TableCell align="right">Candidate Bill </TableCell>
                      <TableCell align="right"> Pay Date </TableCell>
                      <TableCell align="right"> Client Bill </TableCell>
                      <TableCell align="right"> Client Payed </TableCell>
                      <TableCell align="right"> Action </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.hours.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="right">
                          <Checkbox value={row.id} color="primary" onChange={this.onselectcbd} />
                        </TableCell>
                        <TableCell align="right">{this.formatToCalendarDate(row.start_date)}</TableCell>
                        <TableCell align="right">{this.formatToCalendarDate(row.end_date)}</TableCell>
                        <TableCell align="right">{row.hours} </TableCell>
                        <TableCell align="right">{row.hours * this.state.data.pay_rate}</TableCell>
                        <TableCell align="right">{this.formatToCalendarDate(row.paydate)} </TableCell>
                        <TableCell align="right">{this.calculateBill(row.hours)}</TableCell>
                        <TableCell align="right">{row.client_paid == 1 ? 'Yes' : 'No'} </TableCell>
                        <TableCell align="right">
                          <AppButton color="primary" onClick={() => this.editHoursData(row)} label="Edit" />

                          <AppButton color="secondary" onClick={() => this.deleteHoursData(row.id)} label="Delete" />
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow>
                      <TableCell align="right">
                        {' '}
                        <b>TOTAL </b>
                      </TableCell>
                      <TableCell align="right" colSpan="2">
                        -
                      </TableCell>
                      <TableCell align="right">{this.state.total_hours} </TableCell>
                      <TableCell align="right">{this.state.total_bill}</TableCell>
                      <TableCell align="right">- </TableCell>
                      <TableCell align="right">{this.state.total_client_bill}</TableCell>
                      <TableCell align="right">- </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>{' '}
              </div>{' '}
            </div>{' '}
          </div>
        ) : null}

        {/* <div className="container-form-row"> */}
        <div className="">
          {/* {!this.props.leads_filter && !placementFilter && editSubmittal ? (
            <SubmittalsMoats data={this.state.data} setEditSubmittal={this.setEditSubmittal} showHideApproval={this.props.showHideApproval} />

          ) : ( */}
          <>
            {this.props.showInfo === true &&

              <div className="left test" style={{ marginLeft: 'auto' }}>
                {getSnackBarContent()}
                {getDialog()}
                {this.filterTableFields(
                  classes,
                  data,
                  contractPlacementFilter,
                  placementFilter,
                  handleChange,
                  users,
                  openDialog,
                  saveCandidate,
                  getProgress,
                  account_managers,
                  third_parties,
                  submit_text,
                  this.state.client,
                  isEdited,
                  handleClientChange,
                  handleJobChange,
                  handleClientCheckboxChange,
                  clients,
                  jobs,
                  user_id,
                )}
              </div>
            }
            {this.props.showJobs === true &&
              <div class="">
                <div className={'left test'} style={{ marginLeft: 'auto' }}>
                  {placementFilter === true || contractPlacementFilter === true ? (
                    ''
                  ) : (
                    <>
                      {this.props.showJobs === true &&
                        <AppExpansionPanel data={expansionPanel} />
                      }
                    </>
                  )}

                </div>
                <div className={'right'} style={{ marginLeft: 'auto' }}>
                  {placementFilter === true || contractPlacementFilter === true ? (
                    ''
                  ) : (
                    <>
                      {this.props.showJobs === true &&
                        <AppExpansionPanel data={expansionPanel2} />
                      }
                    </>
                  )}

                </div>
              </div>
            }
          </>
          {/* )} */}
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(CandidateTabForm)

// const mapStateToProps = state => {
//   return {
//     ...state,
//     candidates: state.data.candidates,
//     jobs: state.data.jobs,
//     skills: state.data.skills,
//     auth: state.auth
//   };
// };
// const mapDispatchToProps = {
//   receiveFetchData: receiveFetchData,
// }

// export default compose(
//   withStyles(styles),
//   connect(mapStateToProps, mapDispatchToProps)
// )(withRouter(CandidateTabForm))

//
// <AppTextField label="Pay Rate" value={data.payRate || ''} fullWidth
// name="payRate" onChange={handleChange}
// helperText={data.payRate ? ' ' : 'This field is required'}
// error={!data.payRate}/>
