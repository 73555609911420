import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AppTextField from '../common/AppTextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import AppButton from '../common/AppButton'
import AppSelect from '../common/AppSelect'
import SaveIcon from '@material-ui/icons/Save'
import Snackbar from '@material-ui/core/Snackbar'
import * as AppConstants from '../../constants/app'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import ClientModel from './ClientModel'
import Grid from '@material-ui/core/Grid'
import './client.scss'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ContactForm from '../Contacts/Form'
import ContactModel from '../Contacts/ClientModel'

const styles = theme => ({
  root: { flexGrow: 1, backgroundColor: '#fff !important' },
  row: {
    backgroundColor: '#fff !important',
  },
  value: {
    borderBottom: 'none',
    verticalAlign: 'top',
    padding: '0 24px',
    width: '25%',
    backgroundColor: '#fff !important',
  },
  label: {
    fontWeight: 'bold',
    fontcolor: 'rgba(0, 0, 0, 0.54)',
    backgroundColor: '#fff !important',
  },
  relative: {
    position: 'relative',
    backgroundColor: '#fff !important',
  },
  actionButtons: {
    position: 'absolute',
    bottom: 0,
    right: '30px',
  },
  phone: {
    flex: 1,
    padding: '5px',
  },
  contactInfo: {
    display: 'flex',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      <IconButton id="closeAddClient" aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

class ClientForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: new ClientModel(),
      _data: '',
      contactdata: new ContactModel(),
      clients: [{ value: -1, label: 'None' }],
      jobs: [{ value: -1, label: 'None' }],
      users: [{ value: -1, label: 'None' }],
      oldjob: '',
      changes: [],
      status_visa: [
        { value: 1, label: 'None' },
        { value: 2, label: 'Citizenship' },
        { value: 3, label: 'Green Card' },
        { value: 5, label: '1099' },
        { value: 6, label: 'Third party' },
        { value: 7, label: 'Other' },
      ],
      status: [
        { value: '2', label: 'InActive' },
        { value: '1', label: 'Active' },
      ],
      employment_type: [
        { value: 1, label: 'Contract Job' },
        { value: 2, label: 'Contract To Hire' },
        { value: 3, label: 'Permanent Job' },
      ],
      employment_count: [
        { value: 1, label: 'From 1 to 10' },
        { value: 2, label: 'From 10 to 50' },
        { value: 3, label: 'From 50 to 100' },
        { value: 4, label: 'From 100 to 500' },
        { value: 5, label: 'From 500 to 1000' },
      ],
      account_managers: [{ value: -1, label: 'None' }],
      third_parties: [{ value: -1, label: 'None' }],
      bill_rate: 0,
      from_date_month: '',
      expandDescription: {},
      open: false,
      dialogOpen: {
        moats: false,
        contact: false,
        linkedIn: false,
        client_contact: false,
        client_contact_hours: false,
        client_contact_hours_edit: false,
      },
      snackBar: {
        open: false,
        message: '',
        backgroundColor: '#1976d2',
      },
      isLoaded: true,
      to_date: '',
      from_date: '',
      total_hours: 0,
      hoursedit: [],
      total_bill: 0,
      total_client_bill: 0,
      candidate_name: '',
      send_signature_html: '',
      candidate_title: '',
      location: '',
      vacation: '',
      years: '',
      users: [],
      communication: '',
      availability: '',
      current_salary: '',
      pay_rate: '',
      start_data: false,
      interview_date: false,
      activity: '',
      notes: '',
      currentjob: '',
      hours: [],
      job_title: '',
      client_name: '',
      job_location: '',
      job_max_salary: '',
      job_min_salary: '',
      job_short_description: '',
      short_description: '',
      job_description: '',
      job_category: '',
      job_client: '',
      job_status: '',
      maxpayrate: 'Max Salary',
      minpayrate: 'Min Salary',
    }

    this.saveJob = this.saveJob.bind(this)
    this.saveClient = this.saveClient.bind(this)
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  addContact = () => {
    this.setState({
      open: true,
    })
  }

  async fetchUsers() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/user/`)
    if (response.data && response.data['successful']) {
      this.setState({ users: response.data.result })
    }
  }

  handleChange = e => {
    // console.log();
    const { data } = this.state;
    const _data = { ...data };
    const prop = e.target.name;
    _data[prop] = e.target.value;
    console.log(prop, e.target.value);
    this.setState({ data: _data });
  }

  componentDidMount() {
    this.fetchUsers()
  }

  componentWillMount() {
    let _data = { ...this.state.data }
    _data.id = this.props.data.id
    _data.name = this.props.data.name
    _data.recruiter = this.props.data.recruiter
    _data.title = this.props.data.title
    _data.phone = this.props.data.phone
    _data.email = this.props.data.email
    _data.status = this.props.data.status

    _data.notes = this.props.data.notes
    _data.products = this.props.data.products
    _data.created = this.props.data.created
    _data.modified = this.props.data.modified
    _data.client_id = this.props.data.client_id
    this.setState({ data: this.props.data })
  }

  handleSaveCandidateResponse = (response, is_new) => {
    this.setState({ isLoaded: true })
    // let message = 'Something went wrong, please contact admin';
    let message = 'There is some problem. Please contact your administrator'
    if (response.data) {
      message = response.data['message']
      const { onCandidateUpdate } = this.props
      if (onCandidateUpdate) setTimeout(() => onCandidateUpdate(response.data.result, is_new))
    }
    this.openSnackBar('Job saved successfully', 'success-bar')
    this.props.close()
  }

  handleSaveClientResponse = (response, is_new) => {
    this.setState({ isLoaded: true })
    // let message = 'Something went wrong, please contact admin';
    let message = 'There is some problem. Please contact your administrator'

    if (response.data) {
      message = response.data['message']
      const { onCandidateUpdate } = this.props
      if (onCandidateUpdate) setTimeout(() => onCandidateUpdate(response.data.result, is_new))
    }
    this.openSnackBar('Client saved successfully', 'success-bar')
    this.props.refreshdata()
  }

  async saveJob() {
    let _data = { ...this.state.data }
    _data['id'] = this.props.data.id
    if (this.props.data.id) {
      //delete _data['client_id']
    }

    let temp = []
    _data['email'].forEach(function (x) {
      temp.push(x.value)
    })

    _data['email'] = temp

    temp = []
    _data['phone'].forEach(function (x) {
      temp.push(x.value)
    })

    _data['phone'] = temp

    this.setState({ isLoaded: false })
    var headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${this.props.token}`,
    }
    axios
      .post(`${AppConstants.API_BASE_URL}recruit/candidate/contacts/`, _data, { headers: headers })
      .then(response => {
        this.setState({ isLoaded: true })
        let message = 'Please check your inputs, and try again'
        this.openSnackBar(message, 'success-bar')
        this.props.refreshdata()
      })
      .catch(error => {
        this.setState({ isLoaded: true })
        let message = 'Please check your inputs, and try again'
        this.openSnackBar(message, 'success-bar')
        this.props.refreshdata()
      })
  }

  async validate(data) {
    let errorText = 'This field is required'
    let client_name = ''
    if (!data.name) {
      client_name = errorText
    }
    this.setState({ client_name })
    if (client_name) {
      await this.setState({ client_name })
      return false
    } else {
      return true
    }
  }

  async saveClient() {
    const _data = { ...this.state.data }
    const is_valid = await this.validate(_data)
    console.log(_data.id)
    if (is_valid) {
      this.setState({ isLoaded: false })
      var headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${this.props.token}`,
      }
      if (_data.id) {
        axios
          .put(`${AppConstants.API_BASE_URL}recruit/clients/` + _data.id + '/', _data, { headers: headers })
          .then(response => {
            this.handleSaveClientResponse(response, _data.is_new)
          })
          .catch(error => {
            this.setState({ isLoaded: true })
            let message = ''
            let eror = JSON.parse(error.response.request.response)
            for (var key in eror) {
              message = key + '-:' + eror[key]
              this.openSnackBar(message, 'error-bar')
            }
          })
      } else {
        axios
          .post(`${AppConstants.API_BASE_URL}recruit/clients/`, _data, { headers: headers })
          .then(response => {
            this.handleSaveClientResponse(response, _data.is_new)
          })
          .catch(error => {
            this.setState({ isLoaded: true })
            let message = ''
            let eror = JSON.parse(error.response.request.response)
            for (var key in eror) {
              message = key + '-:' + eror[key]
              this.openSnackBar(message, 'error-bar')
            }
          })
      }
    }
  }

  openSnackBar = (message, bgcolor) => {
    const { snackBar } = this.state
    snackBar.open = true
    snackBar.message = message
    snackBar.backgroundColor = bgcolor
    this.setState({ snackBar: snackBar })
  }

  closeSnackBar = () => {
    const { snackBar } = this.state
    snackBar.open = false
    this.setState({ snackBar: snackBar })
  }

  getSnackBarContent = () => {
    const {
      closeSnackBar,
      state: { snackBar },
    } = this
    return (
      <Snackbar
        className={snackBar.backgroundColor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={closeSnackBar}
        message={<span>{snackBar.message}</span>}
      />
    )
  }
  getProgress = () =>
    this.state.isLoaded ? false : <CircularProgress size={24} className={this.props.classes.buttonProgress} />

  render() {
    const {
      state: { data, clients },
      props: { classes, clientslist },
      handleChange,
    } = this
    console.log("clientslist", clientslist);
    console.log("status", data);
    return (
      <React.Fragment>
        <div className={classes.row}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              {this.getSnackBarContent()}
              <Table className={classes.root}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Name"
                        value={data.name}
                        fullWidth
                        name="name"
                        onChange={handleChange}
                        helperText={this.state.client_name.length === 0 ? ' ' : 'This field is required'}
                        error={this.state.client_name.length === 0 ? false : true}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Website"
                        value={data.website}
                        fullWidth
                        name="website"
                        onChange={handleChange}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Logo url"
                        value={data.logo_url}
                        fullWidth
                        name="logo_url"
                        onChange={handleChange}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Founded year"
                        fullWidth
                        value={data.founded_year}
                        type="number"
                        name="founded_year"
                        onChange={handleChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.value}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <AppTextField label="Fee" value={data.fee} type="number" name="fee" onChange={handleChange} />

                        </Grid>
                        <Grid item xs={6}>
                          {/* <AppTextField label="Fee" value={data.p_id} type="number" name="p_id" onChange={handleChange} /> */}
                          <AppSelect
                            id="p_id"
                            label={'Parent Client'}
                            value={parseInt(data.p_id)}
                            name="p_id"
                            onChange={handleChange}
                            items={clientslist}
                          // helperText={this.state.job_client.length === 0 ? ' ' : 'This field is required'}
                          // error={this.state.job_client.length === 0 ? false : true}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>


                    <TableCell className={classes.value}>
                      <AppSelect
                        fullWidth
                        name="employee_count"
                        label="Employee count"
                        value={parseInt(data.employee_count)}
                        onChange={handleChange}
                        items={this.state.employment_count}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Company info"
                        value={data.company_info}
                        fullWidth
                        name="company_info"
                        fullWidth
                        rows={4}
                        multiline={true}
                        onChange={handleChange}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Competitors"
                        value={data.competitors}
                        fullWidth
                        name="competitors"
                        fullWidth
                        rows={4}
                        multiline={true}
                        onChange={handleChange}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Selling points"
                        value={data.selling_points}
                        fullWidth
                        name="selling_points"
                        fullWidth
                        rows={4}
                        multiline={true}
                        onChange={handleChange}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        id="products"
                        label="Products"
                        name="products"
                        fullWidth
                        rows={4}
                        multiline={true}
                        value={data.products}
                        onChange={handleChange}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        id="description"
                        label="Description"
                        name="description"
                        fullWidth
                        rows={4}
                        multiline={true}
                        value={data.description}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Address"
                        value={data.location}
                        fullWidth
                        name="location"
                        onChange={handleChange}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.value}>
                      <AppTextField label="city" value={data.city} fullWidth name="city" onChange={handleChange} />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField label="state" value={data.state} fullWidth name="state" onChange={handleChange} />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField label="zip" value={data.zip} fullWidth name="zip" onChange={handleChange} />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Country"
                        value={data.country}
                        fullWidth
                        name="country"
                        onChange={handleChange}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.value}>
                      <AppSelect
                        fullWidth
                        name="status"
                        label="Status"
                        value={data === 1 || data.status === "1" ? data.status : '0'}
                        onChange={handleChange}
                        items={this.state.status}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="linkedin"
                        value={data.linkedin}
                        fullWidth
                        name="linkedin"
                        onChange={handleChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.value}>
                      <AppButton
                        color="primary"
                        label="Save"
                        icon={{ component: SaveIcon, position: 'right' }}
                        onClick={this.saveClient}
                        disabled={!this.state.isLoaded}
                      />
                      <AppButton
                        color="secondary"
                        label="Close"
                        onClick={this.props.close}
                        disabled={!this.state.isLoaded}
                      />
                      {this.getProgress()}
                    </TableCell>

                    <TableCell>
                      <AppButton color="primary" label="Add Contact" onClick={this.addContact} />

                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Dialog width="300" maxWidth="400" open={this.state.open} aria-labelledby="simple-dialog-title">
                <DialogTitle onClose={this.handleClose} id="simple-dialog-title">
                  {' '}
                  Add Contact
                </DialogTitle>
                <DialogContent>
                  <ContactForm
                    token={this.props.token}
                    hideclient={true}
                    newclint={false}
                    client={this.state.data.id}
                    users={this.state.users}
                    data={this.state.contactdata}
                    refreshdata={this.handleClose}
                  />
                </DialogContent>
              </Dialog>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ClientForm)
