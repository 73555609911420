import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link, withRouter } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AppButton from '../AppButton'
import AppTextField from '../AppTextField'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import axios from 'axios'
import * as AppConstants from '../../../constants/app'
import { ReactMUIDatatable } from 'react-material-ui-datatable'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import config from '../../../config/index'
import { receiveFetchData, login, logout } from '../../../actions/index'
// import { , setMessage } from "../actions/index";

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '64px',
  },
  grow: {
    flexGrow: 1,
  },
  menu: {
    display: 'flex',
  },
  logo: {
    height: '50px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

class ApplicationBar extends React.Component {
  constructor(props) {
    super(props)
    var myarr = window.location.pathname.split('/')
    var url = '/' + myarr[1]

    // transfers sessionStorage from one tab to another
    var sessionStorage_transfer = function (event) {
      if (!event) {
        event = window.event
      } // ie suq
      if (!event.newValue) return // do nothing if no value to work with

      if (event.key == 'getSessionStorage') {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem('checkLogin1', JSON.stringify(sessionStorage))
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem('checkLogin1') // <- could do short timeout as well.
      } else if (event.key == 'checkLogin1' && !sessionStorage.length) {
        // another tab sent data <- get it
        var data = JSON.parse(event.newValue)
        for (var key in data) {
          sessionStorage.setItem(key, data[key])
        }
      } else if (event.key == 'logout-event') {
        sessionStorage.removeItem('checkLogin')
      }
    }

    // listen for changes to localStorage
    if (window.addEventListener) {
      window.addEventListener('storage', sessionStorage_transfer, false)
    } else {
      window.attachEvent('onstorage', sessionStorage_transfer)
    }

    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', 'foobar')
      localStorage.removeItem('getSessionStorage', 'foobar')
    }

    if (
      (sessionStorage.getItem('checkLogin') == false || sessionStorage.getItem('checkLogin') == null) &&
      url != '/jobs-listing' &&
      url != '/jobs-details' &&
      url != '/jobs-filter' &&
      url !== '/client-jobs-view'
    ) {
      window.location.href = '/jobs-listing'
    }
  }

  state = {
    open: false,
    clocation: '',
    logs: [],
    openLogIn: false,
    loginInfo: [],
    auth_token_check: '',
    redirect: false,
    loadingButton: false,
    hasError: false,
    isUsernameEmpty: false,
    isPasswordEmpty: false,
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  handleLogout = () => {
    this.props.logout()
    sessionStorage.removeItem('checkLogin')
    localStorage.removeItem('getSessionStorage')
    localStorage.removeItem('checkLogin1')
    localStorage.setItem('logout-event', 'logout')
    localStorage.removeItem('logout-event', 'logout')
    window.location.href = '/jobs-listing'
  }

  showLogs = () => {
    this.getLogs()
    this.setState({
      open: true,
    })
  }

  formatDate = (date) => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`
    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`
    let hour = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    return `${month}/${_date}/${date.getFullYear()} (${hour}:${minutes}:${seconds})`
  }

  formatIT = (log) => {
    if (log.created) log.created = this.formatDate(log.created)
    return log
  }

  getLogs = () => {
    axios
      .get(`${AppConstants.API_BASE_URL}recruit/candidate/logs/`)
      .then((response) => {
        let _logs = []
        response.data.result.forEach((c) => _logs.push(this.formatIT(c)))
        this.setState({ logs: _logs })
      })
      .catch((error) => {})
  }

  handleCloseLogin = () => {
    this.setState({
      openLogIn: false,
      hasError: false,
      isUsernameEmpty: false,
      isPasswordEmpty: false,
      loginInfo: [],
    })
  }

  loginDialog = () => {
    this.setState({
      openLogIn: true,
    })
  }

  handleChangeLogin = (e) => {
    const { loginInfo } = this.state
    const _data = { ...loginInfo }
    const prop = e.target.name
    _data[prop] = e.target.value
    this.setState({ loginInfo: _data, hasError: false })
  }

  checkLoginCredentials = () => {
    this.getAuthToken()
  }

  async getUserData(email) {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/user/get_auth_user?email=${email}`)
    const result = response.data.result

    if (result) {
      this.props.login({
        ...this.props.auth,
        user_id: result.id,
        username: result.username,
        is_active: result.is_active,
        is_superuser: result.is_superuser,
      })
      window.location.href = '/candidates'
    }
  }

  async getAuthToken() {
    if (this.state.loginInfo.length === 0) {
      this.setState({
        isUsernameEmpty: true,
        isPasswordEmpty: true,
      })
    } else if (!this.state.loginInfo.hasOwnProperty('username') || this.state.loginInfo.username === '') {
      this.setState({
        isUsernameEmpty: true,
        isPasswordEmpty: false,
      })
    } else if (!this.state.loginInfo.hasOwnProperty('password') || this.state.loginInfo.password === '') {
      this.setState({
        isUsernameEmpty: false,
        isPasswordEmpty: true,
      })
    } else {
      this.setState({
        isUsernameEmpty: false,
        isPasswordEmpty: false,
        loadingButton: true,
      })
      const loginInfo = this.state.loginInfo
      let params = {
        username: loginInfo.username,
        password: loginInfo.password,
      }

      try {
        const response = await axios.post(`${AppConstants.API_BASE_URL}api-token-auth/`, params)
        if (response.data) {
          let token = response.data.token
          this.props.login({
            access_token: response.data.token,
            isLoggedIn: true,
            email: loginInfo.username,
          })
          this.getUserData(loginInfo.username)
          sessionStorage.setItem('checkLogin', true)
          sessionStorage.setItem('token', token)
          sessionStorage.setItem('email', loginInfo.username)
          this.setState({ checkLogin: true })
          this.setState({
            auth_token_check: token,
            openLogIn: false,
            redirect: true,
            loadingButton: false,
          })
        }
      } catch (error) {
        this.setState({
          hasError: true,
          loadingButton: false,
        })
      }
    }
  }

  formatToCalendarDate = (date) => {
    if (date) {
      if (typeof date === 'string') date = new Date(date)
      let month = date.getMonth() + 1
      if (month.toString().length === 1) month = `0${month}`

      let _date = date.getDate().toString()
      if (_date.length === 1) _date = `0${_date}`

      return `${month}/${_date}/${date.getFullYear()}`
    } else {
      return
    }
  }

  componentDidMount() {
    this.setState({
      clocation: window.location.pathname,
    })
    // this.getLogs()
  }
  showLogsbutton = () => {
    // console.log('URI')
    // console.log(this.state.clocation)
    let ur = this.state.clocation.split('/')[1]
    if (sessionStorage.getItem('checkLogin') == 'true') {
      return (
        <div>
          <AppButton
            color="primary"
            border="0px"
            label="Home"
            onClick={() => {
              window.location.href = '/candidates'
            }}
          />
          <AppButton color="primary" border="0px" label="Logs" onClick={this.showLogs} />{' '}
        </div>
      )
    }
  }

  showOtherbutton = () => {
    const { loadingButton } = this.state
    //let ur = this.state.clocation.split('/')[1]
    if (sessionStorage.getItem('checkLogin') === 'true') {
      return (
        <div>
          <AppButton
            color="primary"
            label="Questions"
            onClick={() => {
              window.location.href = '/question'
            }}
          />
          <AppButton
            color="primary"
            label="Add Third Party"
            onClick={() => {
              window.location.href = '/third-party'
            }}
          />
          <AppButton
            color="primary"
            label="Visa Status"
            onClick={() => {
              window.location.href = '/visa-status'
            }}
          />
          <AppButton
            color="primary"
            label="Archived"
            onClick={() => {
              window.location.href = '/archived'
            }}
          />
          <Link color="primary" label="Archived" to="/archived" />
          <AppButton
            color="primary"
            label="Candidates"
            onClick={() => {
              window.location.href = '/candidates'
            }}
          />

          <AppButton
            color="primary"
            label="Dashboard"
            onClick={() => {
              window.location.href = '/dashboard'
            }}
          />

          <AppButton
            color="primary"
            label="Jobs"
            onClick={() => {
              window.location.href = '/jobs'
            }}
          />

          <AppButton
            color="primary"
            label="Jobs Listing"
            onClick={() => {
              window.open(
                'https://jobs.anewrecruit.com/',
                '_blank' // <- This is what makes it open in a new window.
              )
            }}
          />

          <AppButton
            color="primary"
            label="Clients"
            onClick={() => {
              window.location.href = '/clients'
            }}
          />
          <AppButton
            color="primary"
            label="Activity Report"
            onClick={() => {
              window.location.href = '/activity-report'
            }}
          />
          <AppButton color="primary" label="Logout" onClick={this.handleLogout} />
        </div>
      )
    } else {
      return (
        <div>
          <AppButton
            color="primary"
            label="Jobs Listing"
            onClick={() => {
              window.location.href = '/jobs-listing'
            }}
          />

          <AppButton color="primary" onClick={this.loginDialog} label="Log In" />

          <Dialog fullWidth="true" maxWidth="sm" open={this.state.openLogIn} aria-labelledby="simple-dialog-title">
            <DialogTitle onClose={this.handleCloseLogin} id="simple-dialog-title">
              SIGN IN
            </DialogTitle>

            <DialogContent>
              <Table className="spplynow">
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    <AppTextField
                      fullWidth
                      name="username"
                      label="Username"
                      onChange={this.handleChangeLogin}
                      error={this.state.isUsernameEmpty}
                      helperText={this.state.isUsernameEmpty && 'Empty username'}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    <AppTextField
                      fullWidth
                      name="password"
                      type="password"
                      label="Password"
                      onChange={this.handleChangeLogin}
                      error={this.state.isPasswordEmpty}
                      helperText={this.state.isPasswordEmpty && 'Empty password'}
                    />
                  </TableCell>
                </TableRow>
              </Table>
              {this.state.hasError && (
                <p style={{ color: '#FF5733', textAlign: 'center', fontWeight: 700 }}>Invalid Sign In Credentails</p>
              )}
            </DialogContent>
            <DialogActions>
              <AppButton
                disabled={loadingButton}
                loading={loadingButton}
                onClick={this.checkLoginCredentials}
                color="primary"
                label="LOGIN"
              />
              <AppButton onClick={this.handleCloseLogin} label="Close" color="secondary" autoFocus></AppButton>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
  }

  render() {
    const { classes } = this.props
    const columns = [
      {
        name: 'name',
        label: 'Candidate',
      },
      {
        name: 'rec',
        label: 'Recruiter',
      },
      {
        name: 'log',
        label: 'Log',
      },
      {
        name: 'created',
        label: 'Created',
      },
    ]

    const { redirect } = this.state

    return (
      <div className={classes.root}>
        <Dialog fullWidth="true" maxWidth="500" open={this.state.open} aria-labelledby="simple-dialog-title">
          <DialogTitle onClose={this.handleClose} id="simple-dialog-title">
            {' '}
            LOGS
          </DialogTitle>

          <DialogContent>
            <ReactMUIDatatable perPage="15" title={'Logs list'} data={this.state.logs} columns={columns} />;
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <AppBar>
          <Toolbar>
            <a href="/">
              <img
                style={{ height: 75 }}
                className={classes.logo}
                src="https://static.wixstatic.com/media/9af6b7_89dc2a2d76264375bb5a698fb0e6a16e.png"
                alt="logo here"
              />
            </a>
            <div className={`${classes.menu} ${classes.grow}`}>{this.showLogsbutton()}</div>
            {this.showOtherbutton()}
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

ApplicationBar.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
  return {
    ...state,
    data: state.data.products,
    auth: state.auth,
  }
}
const mapDispatchToProps = {
  receiveFetchData: receiveFetchData,
  login: login,
  logout: logout,
}
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(withRouter(ApplicationBar))
// export default withStyles(styles)(ApplicationBar)
