// export const sub_status = [
//   { value: 1, label: 'None' },
//   { value: 2, label: 'Leads-Contacted', status: 1 },
//   { value: 3, label: 'Leads-Contacted by email/phone & date', status: 1 },
//   { value: 4, label: 'Submittals-On hold', status: 2 },
//   { value: 5, label: 'Submittals-Waiting for feebback', status: 2 },
//   { value: 6, label: 'Submittals-Rejected', status: 2 },
//   { value: 7, label: 'Submittals-Interview', status: 2 },
//   { value: 8, label: 'Sendout-On Hold', status: 3 },
//   { value: 9, label: 'Sendout-Rejected', status: 3 },
//   { value: 10, label: 'Sendout-Interviewed', status: 3 },
//   { value: 11, label: 'Rejected-Waiting for feedback', status: 6 },
//   { value: 12, label: 'Rejected-Interview', status: 6 }
// ]

export const sub_status = [
  { value: 1, label: 'None', color: "" },
  { value: 2, label: 'Internal - Pending for Review', status: 7, color: "peru", type: "internal" },
  { value: 3, label: 'Internal - RC Under Review', status: 7, color: "lightblue", type: "internal" },
  { value: 4, label: 'Internal - Approved', status: 7, color: "DarkSeaGreen", type: "internal" },
  { value: 5, label: 'Internal - On Hold', status: 7, color: "Yellow", type: "internal" },
  { value: 6, label: 'Internal - Submittal Pending for Review', status: 7, color: "grey", type: "internal" },
  { value: 7, label: 'Internal - Rejected', status: 7, color: "red", type: "internal" },
  { value: 8, label: 'External - On Hold', status: 8, color: "Yellow", type: "external" },
  { value: 9, label: 'External - Submitted - Pending Feedback', status: 8, color: "blue", type: "external" },
  { value: 10, label: 'External - Interview', status: 8, color: "DarkOliveGreen", type: "external" },
  { value: 11, label: 'External - Hired/Placement', status: 8, color: "RebeccaPurple", type: "external" },
  { value: 12, label: 'External - Rejected', status: 8, color: "red", type: "external" },
  { value: 13, label: 'Pipeline - Interviewing', status: 9, color: "RebeccaPurple", type: "pipeline" },
  { value: 14, label: 'Pipeline - Email Accepted', status: 9, color: "DarkOliveGreen", type: "pipeline" },
  { value: 15, label: 'Pipeline - On Hold', status: 9, color: "Yellow", type: "pipeline" },
  { value: 16, label: 'Pipeline - Replying', status: 9, color: "red", type: "pipeline" },
  { value: 17, label: 'Pipeline - No Response', status: 9, color: "black", type: "pipeline" },
  { value: 18, label: 'Pipeline - Rejected', status: 9, color: "red", type: "pipeline" },
  { value: 19, label: 'Pipeline - Not Interested', status: 9, color: "red", type: "pipeline" },

  { value: 20, label: 'Pipeline - Awaiting Feedback', status: 9, color: "pink", type: "pipeline" },
  { value: 21, label: 'Pipeline - Pending Submittal', status: 9, color: "orange", type: "pipeline" },
  { value: 22, label: 'Pipeline - Over the Range', status: 9, color: "purple", type: "pipeline" },
  { value: 23, label: 'External - Offer Extended', status: 8, color: "green", type: "external" },
  { value: 24, label: 'External - Offer Declined', status: 8, color: "red", type: "external" },






  (20, 'Pipeline - Awaiting Feedback'),
  (21, ''),
  (22, ''),
  (23, ''),
  (24, ''),

];

export const getStageByType = (str) => {
  let array = [];
  sub_status.map((o) => {

    if (o.type === str) {
      array.push(o);
    }
  });
  return array;
};


export const getSubStatusName = (id) => {
  // return sub_status.find(o => o.value === id).label.split('-')[1];
  const stage = sub_status.find((o) => o.value === id);
  return stage.label;
};

export const getSubStatusById = (id) => {
  // return sub_status.find(o => o.value === id).label.split('-')[1];
  const stage = sub_status.find((o) => o.value === id);
  return stage;
};

export const getSubStatusColor = (id) => {
  // return sub_status.find(o => o.value === id).label.split('-')[1];
  const stage = sub_status.find((o) => o.value === id);
  return stage.color;
};


export const stage_list =
  [
    { value: 1, label: 'Leads' },
    // { value: 2, label: 'Submittals' },
    // { value: 3, label: 'Sendout' },
    // { value: 4, label: 'Interview' },
    // { value: 5, label: 'Hired/Placements' },
    // { value: 6, label: 'Rejected' },
    // { value: 7, label: 'On Hold' },
    // { value: 8, label: 'Closed' },
    // { value: 8, label: 'Follow up' },
    { value: 7, label: 'Internal' },
    { value: 8, label: 'External' },
    { value: 9, label: 'Pipeline' },
  ];
export const acc_stage_list =
  [
    // { value: 1, label: 'Leads' },
    // { value: 2, label: 'Submittals' },
    // { value: 3, label: 'Sendout' },
    // { value: 4, label: 'Interview' },
    // { value: 5, label: 'Hired/Placements' },
    // { value: 6, label: 'Rejected' },
    // { value: 7, label: 'On Hold' },
    // { value: 8, label: 'Closed' },
    // { value: 8, label: 'Follow up' },
    { value: 7, label: 'Internal' },
    { value: 8, label: 'External' },
    { value: 9, label: 'Pipeline' },
  ];
export const getStageName = (id) => {
  const stage = stage_list.find((o) => o.value === id);
  if (stage) {
    return stage.label;
  } else {
    return "";
  }
};

export const getStageById = (id) => {
  // return sub_status.find(o => o.value === id).label.split('-')[1];
  const stage = stage_list.find((o) => o.value === id);
  return stage;
};