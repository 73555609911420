import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getStageName, getSubStatusName } from "../../constants/status.js"

const useStyles = makeStyles((theme) => ({
    tableCell: {
        color: '#5161bc',
        textAlign: 'center'

    },
    tableCellBlack: {
        color: 'black'
    },
    tableDataCell: {
        textAlign: 'center'
    }


}));

export default function HistoryTab({ data }) {
    // const data = props;
    const classes = useStyles();

    const formatDate = (date) => {
        if (typeof date === 'string') date = new Date(date)
        let month = date.getMonth() + 1
        if (month.toString().length === 1) month = `0${month}`
        let _date = date.getDate().toString()
        if (_date.length === 1) _date = `0${_date}`
        let hour = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        return `${month}/${_date}/${date.getFullYear()} (${hour}:${minutes}:${seconds})`
    }


    return (
        <>
            <Table style={{ width: "600px !important", maxWidth: "600px !important" }}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCell}>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {"Stage"}
                            </Box>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {"Status"}
                            </Box>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {"Created At"}
                            </Box>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {"Updated At"}
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.map((item, k) =>

                        <TableRow key={k}>
                            <TableCell className={classes.tableDataCell}>{getStageName(parseInt(item.status))}</TableCell>
                            <TableCell className={classes.tableDataCell}>{getSubStatusName(parseInt(item.sub_status))}</TableCell>
                            <TableCell className={classes.tableDataCell}>{formatDate(item.created_at)}</TableCell>
                            <TableCell className={classes.tableDataCell}>{formatDate(item.updated_at)}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );
}