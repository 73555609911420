import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  root: {
    padding: 0,
  },
  header: {
    textAlign: 'center',
    marginTop: 0,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  tableCell: {
    color: '#5161bc'
},
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

class Expedite extends React.Component {
  render() {
    const { props } = this
    const { answers } = props
    
    return (
      <React.Fragment>
        <Dialog
          open={props.open}
          maxWidth="md"
          fullWidth={true}
          onClose={() => props.closeDialog('linkedIn')}
          aria-labelledby="dialog-moats"
          className={props.classes.margin}
        >
          <DialogTitle onClose={() => props.closeDialog('linkedIn')} id="simple-dialog-title">
           Job Questions Answers
          </DialogTitle>

          <DialogContent className={props.classes.root}>
            <Table className={props.classes.root}>
              <TableHead>
                <TableRow>
                <TableCell className={props.classes.classes.tableCell}>Questions</TableCell>
                  <TableCell className={props.classes.classes.tableCell}>Answers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {answers.length==0 &&  <TableRow> <TableCell className={props.classes.classes.tableCell}> No Question Found</TableCell> <TableCell> </TableCell></TableRow>}
                {answers.length>0 && answers.map((key, value)=>{
                  
                  return(
                    <TableRow>
                  <TableCell className={props.classes.classes.tableCell}>{key.question.question}</TableCell>
                  <TableCell className={props.classes.classes.tableCell}>{key.answer}</TableCell>
                </TableRow>
                  )

                })}
               
               
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Expedite)
