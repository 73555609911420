import { SET_MESSAGE } from "../constants/base-constants";
import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    SET_AUTH
} from "../constants/auth-constants";
import {
    RECEIVE_FETCH_DATA
} from "../constants/fetch-constants";

const initialState = {
    auth: {
        isLoggedin: false,
        is_admin: 0,
        user_id: 0
    },
    data: {
    }
};

function rootReducer(state = initialState, action) {

    // Basic actions.
    switch (action.type) {
        case SET_MESSAGE:
            return {
                ...state,
                message: { ...state.message, ...action.message }
            };
    }

    // Auth actions.
    switch (action.type) {
        case AUTH_LOGIN:
            localStorage.setItem('recruiter-app-auth-user', JSON.stringify(action.user_data));
            state.auth = action.user_data;
            return { ...state };

        case AUTH_LOGOUT:
            localStorage.removeItem('recruiter-app-auth-user');
            state.auth = [];
            return { ...state };

        case SET_AUTH:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    ...action.user_data
                }
            };
    }

    // Fetch actions.
    switch (action.type) {
        case RECEIVE_FETCH_DATA:
            return {
                ...state,
                data: { ...state.data, ...action.fetch_data }
            };
    }

    return { ...state }
}

export default rootReducer;