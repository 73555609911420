
export const columns = [
  { id: 'plus', numeric: false, disablePadding: false, label: '' },
  { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
  { id: 'min_salary', numeric: false, disablePadding: false, label: 'Salary1' },
  { id: 'max_salary', numeric: false, disablePadding: false, label: 'Salary2' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'category_name', numeric: false, disablePadding: false, label: 'Category' },
  { id: 'publish_at_f', numeric: false, disablePadding: false, label: 'Publish Date' },
  { id: 'candidates', numeric: false, disablePadding: false, label: '#Candidates' },
  { id: 'created', numeric: false, disablePadding: false, label: 'Created' },
]

export const candidateColumns = [
  { id: 'plus', numeric: false, disablePadding: false, label: '' },
  {
    id: 'job_title',
    numeric: false,
    disablePadding: false,
    label: 'Job'
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Candidate Name'
  },
  {
    id: 'email_s',
    numeric: false,
    disablePadding: false,
    label: 'Candidate Email'
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'Location'
  },
  {
    id: 'salary',
    numeric: false,
    disablePadding: false,
    label: 'Current Salary'
  },
  {
    id: 'activity',
    numeric: false,
    disablePadding: false,
    label: 'Activity'
  },
  {
    id: 'status_display',
    numeric: false,
    disablePadding: false,
    label: 'Stage'
  },
  {
    id: 'sub_status',
    numeric: true,
    disablePadding: false,
    label: 'sub_status'
  },

  { id: 'notes', numeric: false, disablePadding: false, label: 'Notes' }

]