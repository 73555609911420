import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import AppCard from '../common/AppCard'
import AppButton from '../common/AppButton'
import AppDataTable from '../common/AppDataTable'
import AppTextField from '../common/AppTextField'
import Add from '@material-ui/icons/Add'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import CandidateForm from './Form'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as AppConstants from '../../constants/app'
import CandidateModel from './CandidateModel'
import axios from 'axios'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'
import { SingleSelect } from 'react-select-material-ui'
import { MultipleSelect } from 'react-select-material-ui'
import { CSVLink } from 'react-csv'
import '../../assets/css/custom.css'
import AppSelect from '../common/AppSelect'
import MenuItem from '@material-ui/core/MenuItem'

import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

import VerticalTabs from './VerticalTabs'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import config from '../../config/index'
import { receiveFetchData } from '../../actions/index'
import {
  sub_status,
  getStageByType,
  stage_list,
  getSubStatusName,
  getStageName,
  getSubStatusColor,
  getSubStatusById,
  getStageById,
} from '../../constants/status'
import { getUrlQueryParam } from '../../utility'
import { data } from 'jquery'

const styles = {
  root: {
    flexGrow: 1,
  },
  card: {
    padding: '1rem 0',
  },
  child: {
    backgroundColor: '#fff',
  },
}

let newData = []
class Candidates extends React.Component {
  constructor(props) {
    super(props)
    var handleRecruiterFilter = this.handleRecruiterFilter.bind(this)
  }

  state = {
    actual_data: [],
    selectedChild: [],
    pageHeading: 'Candidates',
    data: [],
    filterDatefrom: 0,
    filterDateto: 0,
    logopen: true,
    clients: [],
    jobs: [],
    jobsdrops: [],
    users: [],
    account_managers: [],
    opened: [],
    isLoaded: false,
    auth_token: '',
    placement_filter: false,
    leads_filter: true,
    total_leads_candidate_class: 1,
    total_lead_source_class: 0,
    submittals_filter: false,
    contract_placement_filter: false,
    page: 1,
    searchvale: 'AAAALL',
    total_record: 0,
    recruiter_filter: 0,
    client_filter: 0,
    job_filter: 0,
    stage_filter: 0,
    sub_status_filter: 0,
    total_sub: 0,
    total_inter: 0,
    total_send: 0,
    total_internals: 0,
    total_externals: 0,
    total_perma: 0,
    total_cont: 0,
    total_lead_source_count: 0,
    employe_filter: 0,
    total_sub_class: 0,
    total_inter_class: 0,
    total_send_class: 0,
    total_internal_class: 0,
    total_external_class: 0,
    total_perma_class: 0,
    total_cont_class: 0,
    third_party: [],
    columns: [],
    totalfee: 0,
    totalRcCommission: 0,
    label_text: 'Candidate List',
    total_txt: 'Total Candidates:',
    leads_with_source: false,
    source: '',
    skills_filter: 0,
    nskills: [],
    next_page: false,
    first: false,
    candidate_total: 0,
    state_filter_permanent_placement: 0,
    stageValue: 0,
    statusValue: 0,
    hasSearchContent: false,
    isMonthFilteredStarted: false,
  }

  componentWillMount() {
    // TODO: Make it one call, as I couldn't merge that to a single endpoint due to short time
    // this.getAuthToken();

    this.setFiltersOptionByUrl()
    if (this.props.auth && this.props.auth.access_token) {
      this.setState({
        auth_token: this.props.auth.access_token,
        // recruitersValue: 10,
      })
      // if (this.props.auth.user_id) {
      //   this.handleRecruiterFilter(this.props.auth.user_id);
      // } else {
      const sub_status_id = parseInt(getUrlQueryParam('substatus'))
      const status_id = parseInt(getUrlQueryParam('status'))
      if (sub_status_id != -1) {
        const sub_status_obj = getSubStatusById(sub_status_id)
        if (sub_status_obj) {
          this.handleStatusOnPageloadFilter(sub_status_obj)
        }
      } else if (status_id != -1) {
        const status_obj = getStageById(status_id)
        if (status_obj) {
          this.handleStatusOnPageloadFilter(status_obj)
        }
      } else {
        this.fetchCandidates(true)
      }
      // }
    }
    window.addEventListener('scroll', (e) => this.appendData(e))
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', (e) => this.appendData(e))
  }

  componentDidMount() {}

  appendData = (e) => {
    e.preventDefault()
    if (window.innerHeight + window.scrollY + 1 >= document.body.offsetHeight && !this.state.hasSearchContent) {
      console.log('Filter Clicked: ', this.state.page)
      console.log('Filter next_page: ', this.state.next_page)
      console.log('Filter total_record: ', this.state.total_record)

      if (!this.state.next_page && !this.state.isMonthFilteredStarted) {
        this.setState({ next_page: true })
        this.gotoNextPage()
      }

      // Show loading spinner and make fetch request to api
    }
  }

  otherApis() {
    this.fetchClientsSkillsJobs()
    this.fetchUsers()
    this.fetchAccountManagers()
    this.fetchThirdParty()
  }

  setFiltersOptionByUrl = () => {
    const param = this.useQuery()
    this.setState({
      recruiter_filter: param.get('client_id') || 0,
      stage_filter: param.get('stage_id') || 0,
      sub_stauts_filter: param.get('sub_stauts_id') || 0,
      from: param.get('from') || 0,
      to: param.get('to') || 0,
    })
  }

  useQuery = () => {
    return new URLSearchParams(this.props.location.search)
  }

  gotoNextPage = () => {
    console.log('gotoNextPage', this.state.data.length)
    console.log('total_record', this.state.total_record)
    if (this.state.total_record > this.state.data.length) {
      this.setState(
        {
          isLoaded: false,
          page: this.state.page + 1,
          next_page: true,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }
  }

  filterJobsOnClient = (jobs, clientId) => jobs.filter((j) => j['detail'].client === clientId || j['value'] === -1)

  updateSearchValue = (evt) => {
    if (evt.target.value != '') {
      this.setState({
        searchvale: evt.target.value,
        page: 1,
      })
    } else {
      this.setState(
        {
          searchvale: 'AAAALL',
          isLoaded: false,
          page: 1,
          hasSearchContent: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }

    if (evt.key == 'Enter') {
      this.setState(
        {
          isLoaded: false,
          page: 1,
          hasSearchContent: true,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }
  }

  gotoPrevPage = () => {
    if (this.state.page == 1) {
      return false
    }
    this.setState(
      {
        isLoaded: false,
        page: this.state.page - 1,
      },
      function () {
        this.fetchCandidates()
      }
    )
  }

  async getAuthToken() {
    let params = {
      username: 'admin@anewrecruit.com',
      password: 'Welcome123!!',
    }
    const response = await axios.post(`${AppConstants.API_BASE_URL}api-token-auth/`, params)
    if (response.data) {
      let token = response.data.token
      this.setState({
        auth_token: token,
      })
    }
  }

  async fetchSkill() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client/?jobs=true&is_active=true`)

    // const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client/?jobs=true`)
    if (response.data && response.data['successful']) {
      const { category, clients, jobs, skills } = response.data.result
      let arr = []
      let arr2 = [148, 43, 43, 112, 113, 24, 16, 147, 23, 18, 11, 6, 153]
      clients.map((key, value) => {
        if (arr2.includes(key.value)) {
          // console.log("Yesy---------")
        } else {
          // console.log("no---------")
          arr.push(key)
        }
      })
      this.setState({
        clients: arr,
        jobs: jobs,
        jobsdrops: jobs,
        nskills: skills,
      })
      this.props.receiveFetchData({
        skills: {
          ...this.props.skills,
          data: skills,
        },
      })
    }
  }

  async fetchCandidates(isInitial = false) {
    console.log('fetch candidates')
    let token = sessionStorage.getItem('token')
    var headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    }
    this.setState({ isLoaded: false })

    const param = this.useQuery()
    const location = param.get('location')
    if (location === 'dashboard') {
      const recruit_filter = param.get('client_id') || 0
      const stage_filter = param.get('stage_id') || 0
      const sub_status_filter = param.get('sub_status_id') || 0
      const from = param.get('from') || 0
      const to = param.get('to') || 0
      const response = await axios.get(
        `${AppConstants.API_BASE_URL}recruit/candidate/filter-candidates/` +
          this.state.page +
          '/' +
          this.state.searchvale,
        {
          headers: headers,
          params: {
            recruiter_filter: recruit_filter,
            stage_filter: stage_filter,
            sub_status_filter: stage_filter,
            from: from || 0,
            to: to || 0,
          },
        }
      )
      if (response.data && response.data['successful']) {
        let allfee = 0,
          totalRcCommissionAmount = 0
        const candidates = []
        response.data.result.forEach((c) => {
          c.paidtext = c.paid == 0 ? 'No' : 'Yes'
          let revenue = 0
          totalRcCommissionAmount += c.total_rc_commision_amount
          candidates.push(this.formatCandidate(c))
          newData.push(this.formatCandidate(c))
          if (c.client_fee != null) {
            if (!c.withdraw) {
              revenue = (c.salary * c.client_fee) / 100
            }
          } else {
            if (!c.withdraw) {
              revenue = (c.salary * c.client_fee_percentage) / 100
            }
          }
          allfee = parseInt(allfee) + parseFloat(revenue)
        })
        this.setState({
          isLoaded: true,
          data: candidates,
          actual_data: candidates,
          total_record: response.data.total,
          total_sub: 0,
          total_inter: 0,
          total_send: 0,
          total_internals: 0,
          total_externals: 0,
          total_perma: 0,
          total_cont: 0,
          totalfee: 0,
        })
      }
    } else {
      if (this.props.match.params.id) {
        console.log('First one')
        const response = await axios.get(
          `${AppConstants.API_BASE_URL}recruit/candidate/get-all-candidates/` +
            this.state.page +
            '/' +
            this.state.searchvale,
          {
            headers: headers,
            params: {
              recruiter_filter: this.state.recruiter_filter,
              client_filter: this.state.client_filter,
              job_filter: this.props.match.params.id,
              stage_filter: this.state.stage_filter,
              sub_status_filter: this.state.sub_status_filter || 0,
              employe_filter: this.state.employe_filter,
              from: this.state.from || this.state.filterDatefrom || 0,
              to: this.state.to || this.state.filterDateto || 0,
              leads: this.state.leads || false,
              leads_with_source: this.state.leads_with_source || false,
              source: this.state.source || '',
              skills_filter: this.state.skills_filter || 0,
            },
          }
        )

        if (response.data && response.data['successful']) {
          console.log('second one')
          const candidates = []
          let allfee = 0
          response.data.result.forEach((c) => {
            let eml = sessionStorage.getItem('email')

            if (this.state.employe_filter == 3 && this.state.stage_filter == 5) {
              if (eml == 'admin@anewrecruit.com' || eml == 'alfredo@anewrecruit.com') {
                candidates.push(this.formatCandidate(c))
                allfee = allfee + c.client_fee_amount
              } else {
                if (eml == c.recruiter_email) {
                  candidates.push(this.formatCandidate(c))
                  allfee = allfee + c.client_fee_amount
                }
              }
            } else {
              candidates.push(this.formatCandidate(c))
              newData.push(this.formatCandidate(c))
              allfee = allfee + c.client_fee_amount
            }
          })
          this.setState({
            isLoaded: true,
            data: newData,
            actual_data: candidates,
            total_record: response.data.total,
            total_sub: response.data.total_sub,
            total_inter: response.data.total_inter,
            total_send: response.data.total_send,
            total_internals: response.data.total_internals,
            total_externals: response.data.total_externals,
            total_perma: response.data.total_perma,
            total_cont: response.data.total_cont,
            totalfee: allfee,
            total_applicant: response.data.total_applicant,
            candidate_total: response.data.total_lead_candidate,
            total_lead_source_count: response.data.total_lead_source_count,
            total_source_job_listing_count: response.data.total_source_job_listing_count,
          })
          // console.log('candidate data');
          // console.log(JSON.stringify(candidates));
        }
      } else {
        if (!this.state.next_page) {
          console.log('not next page', this.state.state_filter_permanent_placement)
          const response = await axios.get(
            `${AppConstants.API_BASE_URL}recruit/candidate/get-all-candidates/` +
              this.state.page +
              '/' +
              this.state.searchvale,
            {
              headers: headers,
              params: {
                recruiter_filter: this.state.recruiter_filter,
                client_filter: this.state.client_filter,
                job_filter: this.state.job_filter,
                stage_filter: this.state.stage_filter,
                sub_status_filter: this.state.sub_status_filter || 0,
                employe_filter: this.state.employe_filter,
                from: this.state.filterDatefrom || this.state.from || 0,
                to: this.state.filterDateto || this.state.to || 0,
                applicant: this.state.applicant_filter || false,
                leads: this.state.leads_filter || false,
                leads_with_source: this.state.leads_with_source || false,
                source: this.state.source || '',
                skills_filter: this.state.skills_filter || 0,
                filter_permanent_placement: this.state.state_filter_permanent_placement || 0,
              },
            }
          )

          if (response.data && response.data['successful']) {
            console.log('Third one workded')
            this.otherApis()
            let allfee = 0,
              totalRcCommissionAmount = 0
            const candidates = []
            response.data.result.forEach((c) => {
              c.paidtext = c.paid == 0 ? 'No' : 'Yes'
              let revenue = 0
              totalRcCommissionAmount += c.total_rc_commision_amount
              candidates.push(this.formatCandidate(c))
              if (c.client_fee != null) {
                if (!c.withdraw) {
                  revenue = (c.salary * c.client_fee) / 100
                }
              } else {
                if (!c.withdraw) {
                  revenue = (c.salary * c.client_fee_percentage) / 100
                }
              }

              allfee = parseInt(allfee) + parseFloat(revenue)
              //console.log(revenue, allfee);
              //console.log('Total RC Commission', totalRcCommissionAmount);
            })
            let total = 0
            if (this.state.applicant_filter === true) {
              total = response.data.total_applicant
              console.log('1', response.data)
            } else if (this.state.leads_with_source === true) {
              console.log('2', response.data)

              if (this.state.total_source_job_listing_class === 1) {
                console.log('_2', response.data)
                total = response.data.total_source_job_listing_count
              } else {
                console.log('_1', response.data)
                total = response.data.total_lead_source_count
              }
            } else if (this.state.leads_filter === true) {
              console.log('3', response.data)

              total = response.data.total_lead_candidate
            } else if (this.state.stage_filter === 8 && this.state.sub_status_filter === 11) {
              total = response.data.total_perma
              console.log('4', response.data)
            } else if (this.state.stage_filter === 7) {
              total = response.data.total_internals
              console.log('5', response.data)
            } else if (this.state.stage_filter === 8) {
              total = response.data.total_externals
              console.log('6', response.data)
            } else if (this.state.stage_filter === 5 && this.state.employe_filter === 1) {
              total = response.data.total_cont
              console.log('7', response.data)
            } else if (this.state.recruiter_filter > 0) {
              total = response.data.total
              console.log('8', response.data)
            } else if (this.state.client_filter > 0) {
              total = response.data.total
              console.log('9', response.data)
            } else if (this.state.job_filter > 0) {
              total = response.data.total_sub
              console.log('10', response.data)
            } else {
              total = response.data.total
              console.log('11', response.data)
            }

            let _data = this.state.data

            if (this.state.next_page) {
              _data.push(...candidates)
              this.setState({
                data: _data,
                next_page: false,
              })
              this.props.receiveFetchData({
                candidates: {
                  ...this.props.candidates,
                  data: _data,
                },
              })
              console.log('test1')
            } else {
              console.log('test2')
              this.setState({
                data: candidates,
                next_page: false,
              })
              this.props.receiveFetchData({
                candidates: {
                  ...this.props.candidates,
                  data: { candidates },
                },
              })
            }
            console.log('total a', total)

            this.setState({
              isLoaded: true,
              // data: candidates,
              actual_data: candidates,
              total_record: total,
              total_sub: response.data.total_sub,
              total_inter: response.data.total_inter,
              total_send: response.data.total_send,
              total_internals: response.data.total_internals,
              total_externals: response.data.total_externals,
              total_perma: response.data.total_perma,
              total_cont: response.data.total_cont,
              totalfee: allfee,
              totalRcCommission: totalRcCommissionAmount,
              total_applicant: response.data.total_applicant,
              candidate_total: response.data.total_lead_candidate,
              total_lead_source_count: response.data.total_lead_source_count,
              total_source_job_listing_count: response.data.total_source_job_listing_count,
            })
          }
        } else if (this.state.total_record > this.state.data.length + 1) {
          console.log('anext_page')
          const response = await axios.get(
            `${AppConstants.API_BASE_URL}recruit/candidate/get-all-candidates/` +
              this.state.page +
              '/' +
              this.state.searchvale,
            {
              headers: headers,
              params: {
                recruiter_filter: this.state.recruiter_filter,
                client_filter: this.state.client_filter,
                job_filter: this.state.job_filter,
                stage_filter: this.state.stage_filter,
                sub_status_filter: this.state.sub_status_filter || 0,
                employe_filter: this.state.employe_filter,
                from: this.state.filterDatefrom || this.state.from || 0,
                to: this.state.filterDateto || this.state.to || 0,
                applicant: this.state.applicant_filter || false,
                leads: this.state.leads_filter || false,
                leads_with_source: this.state.leads_with_source || false,
                source: this.state.source || '',
                skills_filter: this.state.skills_filter || 0,
              },
            }
          )

          if (response.data && response.data['successful']) {
            // console.log("Third one workded");
            // this.otherApis();
            let allfee = 0,
              totalRcCommissionAmount = 0
            const candidates = []
            response.data.result.forEach((c) => {
              c.paidtext = c.paid == 0 ? 'No' : 'Yes'
              let revenue = 0
              totalRcCommissionAmount += c.total_rc_commision_amount
              candidates.push(this.formatCandidate(c))
              if (c.client_fee != null) {
                if (!c.withdraw) {
                  revenue = (c.salary * c.client_fee) / 100
                }
              } else {
                if (!c.withdraw) {
                  revenue = (c.salary * c.client_fee_percentage) / 100
                }
              }

              allfee = parseInt(allfee) + parseFloat(revenue)
              //console.log(revenue, allfee);
              //console.log('Total RC Commission', totalRcCommissionAmount);
            })
            let total = 0
            if (this.state.applicant_filter === true) {
              total = response.data.total_applicant
              console.log('1', response.data)
            } else if (this.state.leads_with_source === true) {
              console.log('2', response.data)

              if (this.state.total_source_job_listing_class === 1) {
                console.log('_2', response.data)
                total = response.data.total_source_job_listing_count
              } else {
                console.log('_1', response.data)
                total = response.data.total_lead_source_count
              }
            } else if (this.state.leads_filter === true) {
              console.log('3', response.data)

              total = response.data.total_lead_candidate
            } else if (this.state.stage_filter === 8 && this.state.sub_status_filter === 11) {
              total = response.data.total_perma
              console.log('4', response.data)
            } else if (this.state.stage_filter === 7) {
              total = response.data.total_internals
              console.log('5', response.data)
            } else if (this.state.stage_filter === 8) {
              total = response.data.total_externals
              console.log('6', response.data)
            } else if (this.state.stage_filter === 5 && this.state.employe_filter === 1) {
              total = response.data.total_cont
              console.log('7', response.data)
            } else if (this.state.recruiter_filter > 0) {
              total = response.data.total
              console.log('8', response.data)
            } else if (this.state.client_filter > 0) {
              total = response.data.total
              console.log('9', response.data)
            } else if (this.state.job_filter > 0) {
              total = response.data.total_sub
              console.log('10', response.data)
            } else {
              total = response.data.total
              console.log('11', response.data)
            }
            let _data = this.state.data

            if (this.state.next_page) {
              _data.push(...candidates)
              this.setState({
                data: _data,
                next_page: false,
              })
              this.props.receiveFetchData({
                candidates: {
                  ...this.props.candidates,
                  data: _data,
                },
              })
            } else {
              this.setState({
                data: candidates,
                next_page: false,
              })
              this.props.receiveFetchData({
                candidates: {
                  ...this.props.candidates,
                  data: { candidates },
                },
              })
            }

            this.setState({
              isLoaded: true,
              // data: candidates,
              actual_data: candidates,
              total_record: total,
              total_sub: response.data.total_sub,
              total_inter: response.data.total_inter,
              total_send: response.data.total_send,
              total_externals: response.data.total_internals,
              total_internals: response.data.total_externals,
              total_perma: response.data.total_perma,
              total_cont: response.data.total_cont,
              totalfee: allfee,
              totalRcCommission: totalRcCommissionAmount,
              total_applicant: response.data.total_applicant,
              candidate_total: response.data.total_lead_candidate,
              total_lead_source_count: response.data.total_lead_source_count,
              total_source_job_listing_count: response.data.total_source_job_listing_count,
            })
          }
        } else {
          this.setState({
            isLoaded: true,
            next_page: false,
          })
        }
      }
    }
  }

  async fetchCandidatesCutsom(type, page, from, to) {
    let token = sessionStorage.getItem('token')
    var headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    }
    this.setState({ isLoaded: false })

    const response = await axios.get(
      `${AppConstants.API_BASE_URL}recruit/candidate/get-all-candidates/` + page + '/' + this.state.searchvale,
      {
        headers: headers,
        params: {
          recruiter_filter: this.state.recruiter_filter,
          client_filter: this.state.client_filter,
          job_filter: this.state.job_filter,
          stage_filter: this.state.stage_filter,
          sub_status_filter: this.state.sub_status_filter || 0,
          employe_filter: this.state.employe_filter,
          from: from,
          to: to,
          applicant: this.state.applicant_filter || false,
          leads: this.state.leads_filter || false,
          leads_with_source: this.state.leads_with_source || false,
          source: this.state.source || '',
          skills_filter: this.state.skills_filter || 0,
          filter_permanent_placement: type,
        },
      }
    )

    if (response.data && response.data['successful']) {
      this.otherApis()
      let allfee = 0,
        totalRcCommissionAmount = 0
      const candidates = []

      response.data.result.forEach((c) => {
        c.paidtext = c.paid == 0 ? 'No' : 'Yes'
        let revenue = 0
        totalRcCommissionAmount += c.total_rc_commision_amount
        candidates.push(this.formatCandidate(c))
        if (c.client_fee != null) {
          if (!c.withdraw) {
            revenue = (c.salary * c.client_fee) / 100
          }
        } else {
          if (!c.withdraw) {
            revenue = (c.salary * c.client_fee_percentage) / 100
          }
        }

        allfee = parseInt(allfee) + parseFloat(revenue)
      })

      let total = 0
      if (this.state.applicant_filter === true) {
        total = response.data.total_applicant
        console.log('1', response.data)
      } else if (this.state.leads_with_source === true) {
        console.log('2', response.data)

        if (this.state.total_source_job_listing_class === 1) {
          console.log('_2', response.data)
          total = response.data.total_source_job_listing_count
        } else {
          console.log('_1', response.data)
          total = response.data.total_lead_source_count
        }
      } else if (this.state.leads_filter === true) {
        console.log('3', response.data)

        total = response.data.total_lead_candidate
      } else if (this.state.stage_filter === 8 && this.state.sub_status_filter === 11) {
        total = response.data.total_perma
        console.log('4', response.data)
      } else if (this.state.stage_filter === 7) {
        total = response.data.total_internals
        console.log('5', response.data)
      } else if (this.state.stage_filter === 8) {
        total = response.data.total_externals
        console.log('6', response.data)
      } else if (this.state.stage_filter === 5 && this.state.employe_filter === 1) {
        total = response.data.total_cont
        console.log('7', response.data)
      } else if (this.state.recruiter_filter > 0) {
        total = response.data.total
        console.log('8', response.data)
      } else if (this.state.client_filter > 0) {
        total = response.data.total
        console.log('9', response.data)
      } else if (this.state.job_filter > 0) {
        total = response.data.total_sub
        console.log('10', response.data)
      } else {
        total = response.data.total
        console.log('11', response.data)
      }

      this.setState({
        isLoaded: true,
        total_record: total,
        total_sub: response.data.total_sub,
        total_inter: response.data.total_inter,
        total_send: response.data.total_send,
        total_internals: response.data.total_internals,
        total_externals: response.data.total_externals,
        total_perma: response.data.total_perma,
        total_cont: response.data.total_cont,
        totalfee: allfee,
        totalRcCommission: totalRcCommissionAmount,
        total_applicant: response.data.total_applicant,
        candidate_total: response.data.total_lead_candidate,
        total_lead_source_count: response.data.total_lead_source_count,
        total_source_job_listing_count: response.data.total_source_job_listing_count,
        next_page: false,
      })

      return candidates
    }
  }

  fetchClientsSkillsJobs = async () => {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client/?jobs=true&is_active=true`)
    if (response.data && response.data['successful']) {
      const { clients, jobs, skills } = response.data.result
      this.setState({ clients: clients })
      this.setState({
        clients: clients,
        jobsdrops:
          this.state.client_filter === 0
            ? jobs
            : jobs.filter((job) => job['detail'].client === this.state.client_filter),
        nskills:
          this.state.client_filter === 0
            ? skills.filter((skill) => skill.label !== '')
            : skills.filter((skill) => skill.value === this.state.job_filter && skill.label !== ''),
      })
    }
  }

  async fetchUsers() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/user/`)
    if (response.data && response.data['successful']) {
      this.setState({ users: response.data.result })
    }
  }

  async fetchAccountManagers() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/account_manager/`)
    if (response.data && response.data['successful']) {
      this.setState({ account_managers: response.data.result })
    }
  }

  async fetchThirdParty() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/third-party/`)
    if (response.data && response.data['successful']) {
      this.setState({ third_party: response.data.result })
    }
  }

  formatToCalendarDate = (date) => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`

    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`

    return `${month}/${_date}/${date.getFullYear()}`
  }

  applyFiltesDate = (e) => {
    let key = e.target.name
    let val = e.target.value
    this.setState({ [key]: val }, function () {
      if (this.state.filterDatefrom != 0 && this.state.filterDateto != 0) {
        this.fetchCandidates()
      }
    })
  }

  clearapplyFiltesDate = () => {
    console.log('clearapplyFiltesDate')
    this.setState({ filterDatefrom: 0, filterDateto: 0, isMonthFilteredStarted: false }, function () {
      this.fetchCandidates()
    })
  }

  formatDate = (date) => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`
    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`
    let hour = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    return `${month}/${_date}/${date.getFullYear()} (${hour}:${minutes}:${seconds})`
  }

  formatCandidate = (candidate) => {
    const _candidate = { ...candidate }
    if (_candidate.email[0]) {
      _candidate['email_s'] = _candidate.email[0].email
    } else {
      _candidate['email_s'] = ''
    }
    _candidate.phone = _candidate.phone.map((p) => {
      return {
        id: p.id,
        value: p.phone,
        label: 'Phone',
      }
    })
    _candidate.email = _candidate.email.map((e) => {
      return {
        id: e.id,
        value: e.email,
        label: 'Email',
      }
    })

    if (_candidate.weekly_total_hours) {
      _candidate.weekly_total_hours = _candidate.weekly_total_hours.as_float__sum
    }
    if (_candidate.bill_rate) {
      _candidate.bill_rate = parseFloat(_candidate.bill_rate).toFixed(1)
    } else {
      let bill = parseFloat(_candidate.bill_rate_cal) * _candidate.pay_rate
      if (bill > 0) bill = bill + 30
      else bill = 0.0
      _candidate.bill_rate = parseFloat(bill).toFixed(1)
    }

    if (_candidate.bill_rate) {
      _candidate.bill_rate = parseFloat(_candidate.bill_rate).toFixed(1)
    }

    let bill = parseFloat(_candidate.bill_rate_cal) * _candidate.pay_rate
    if (bill > 0) bill = bill + 30
    else bill = 0.0
    _candidate.bill_rate_cals = bill.toFixed(1)

    // if(_candidate.employment_type==1){
    //
    //  _candidate.net_revenue= (_candidate.bill_rate*_candidate.weekly_total_hours)-(_candidate.pay_rate*_candidate.weekly_total_hours);
    // }else{
    //
    //  _candidate.net_revenue=parseFloat( _candidate.salary)-(_candidate.client_fee_amount+parseFloat(_candidate.total_rc_commision_amount)+parseFloat(_candidate.total_am_commision_amount));
    // }
    if (_candidate.client_fee != null) {
      _candidate.net_revenue =
        (_candidate.salary * _candidate.client_fee) / 100 -
        parseFloat(_candidate.total_rc_commision_amount) -
        parseFloat(_candidate.total_am_commision_amount)
    } else {
      _candidate.net_revenue =
        (_candidate.salary * _candidate.client_fee_percentage) / 100 -
        parseFloat(_candidate.total_rc_commision_amount) -
        parseFloat(_candidate.total_am_commision_amount)
    }

    _candidate.net_revenue = parseFloat(_candidate.net_revenue).toFixed(1)
    const status_name = getSubStatusName(parseInt(_candidate.sub_status))
    const stage_name = getStageName(parseInt(_candidate.status))
    const status_color = getSubStatusColor(parseInt(_candidate.sub_status))
    _candidate.sub_status_name = status_name
    _candidate.stage_name = stage_name
    _candidate.status_color = status_color

    if (_candidate.start_date) _candidate.start_date = this.formatToCalendarDate(_candidate.start_date)
    if (_candidate.interview_date) _candidate.interview_date = this.formatToCalendarDate(_candidate.interview_date)
    if (_candidate.placement_date) _candidate.placement_date = this.formatToCalendarDate(_candidate.placement_date)
    _candidate.created = this.formatDate(_candidate.created)
    _candidate.modified = this.formatDate(_candidate.modified)
    if (_candidate.submitted_to_client)
      _candidate.submitted_to_client = this.formatToCalendarDate(_candidate.submitted_to_client)
    if (_candidate.submitted_internally)
      _candidate.submitted_internally = this.formatToCalendarDate(_candidate.submitted_internally)

    //client_fee_amount=client_fee_amount_candidate.modified
    return _candidate
  }

  addNewCandidate = () => {
    this.setState({
      placement_filter: false,
      submittals_filter: false,
      contract_placement_filter: false,
    })
    const { data } = this.state
    const _data = [...data]
    if (!_data.find((c) => c.is_new === true)) {
      _data.splice(0, 0, new CandidateModel(true))
      this.setState({ data: _data })
    }
  }

  get columns() {
    if (this.state.placement_filter) {
      return [
        // {id: 'recruiter_name', numeric: false, disablePadding: false, label: 'Recruiter'},

        // {id: 'client_name', numeric: false, disablePadding: false, label: 'Client'},
        // {id: 'job_title', numeric: false, disablePadding: false, label: 'Client Job Title'},
        // {id: 'name', numeric: false, disablePadding: false, label: 'Candidate Name'},
        // {id: 'location', numeric: false, disablePadding: false, label: 'Location'},
        // {id: 'salary, numeric: false, disablePadding: false, label: 'Salary'},
        // {id: 'rc', numeric: false, disablePadding: false, label: 'RC'},
        // {id: 'am', numeric: false, disablePadding: false, label: 'AM'},
        // {id: 'start_date', numeric: false, disablePadding: false, label: 'Start Date'},
        // {id: 'placement_date', numeric: false, disablePadding: false, label: 'Placement Date'},
        //

        // {id: 'source', numeric: false, disablePadding: false, label: 'Source'},
        // {id: 'activity', numeric: false, disablePadding: false, label: 'Activity'},
        // {id: 'created', numeric: false, disablePadding: false, label: 'Created At'},
        // {id: 'status_display', numeric: false, disablePadding: false, label: 'Stage'}
        {
          id: 'client_name',
          numeric: false,
          disablePadding: false,
          label: 'Client Name',
        },
        {
          id: 'name',
          numeric: false,
          disablePadding: false,
          label: 'Candidate Name',
        },
        {
          id: 'email_s',
          numeric: false,
          disablePadding: false,
          label: 'Candidate Email',
        },
        {
          id: 'job_title',
          numeric: false,
          disablePadding: false,
          label: 'Candidate Title',
        },
        // {
        //   id: 'score',
        //   numeric: false,
        //   disablePadding: false,
        //   label: 'RC Score',
        // },
        {
          id: 'score1',
          numeric: false,
          disablePadding: false,
          label: 'AI Score',
        },
        {
          id: 'location',
          numeric: false,
          disablePadding: false,
          label: 'Location',
        },
        {
          id: 'salary',
          numeric: false,
          disablePadding: false,
          label: 'Current Salary',
        },
        {
          id: 'client_fee_percentage',
          numeric: false,
          disablePadding: false,
          label: 'Client Fee Percentage',
        },
        {
          id: 'recruiter_name',
          numeric: false,
          disablePadding: false,
          label: 'RC Name',
        },
        {
          id: 'am_name',
          numeric: false,
          disablePadding: false,
          label: 'AM Name',
        },
        {
          id: 'client_fee_amount',
          numeric: false,
          disablePadding: false,
          label: 'Total Revenue',
        },
        {
          id: 'total_rc_commision_amount',
          numeric: false,
          disablePadding: false,
          label: 'Total RC Commission',
        },
        //{id: 'total_am_commision_amount', numeric: false, disablePadding: false, label: 'Total AM Commission'},
        {
          id: 'paidtext',
          numeric: false,
          disablePadding: false,
          label: 'Client Paid',
        },
        {
          id: 'invoice_no',
          numeric: false,
          disablePadding: false,
          label: 'Client Invoice #',
        },
        //{id: 'net_revenue', numeric: false, disablePadding: false, label: 'Net Revenue'},
        {
          id: 'start_date',
          numeric: false,
          disablePadding: false,
          label: 'Start Date',
        },
        {
          id: 'placement_date',
          numeric: false,
          disablePadding: false,
          label: 'Placement Date',
        },
      ]
    } else if (this.state.contract_placement_filter) {
      return [
        {
          id: 'client_name',
          numeric: false,
          disablePadding: false,
          label: 'Client Name',
        },
        {
          id: 'name',
          numeric: false,
          disablePadding: false,
          label: 'Candidate Name',
        },
        {
          id: 'email_s',
          numeric: false,
          disablePadding: false,
          label: 'Candidate Email',
        },
        // {
        //   id: 'job_title',
        //   numeric: false,
        //   disablePadding: false,
        //   label: 'Client Job Title',
        // },
        {
          id: 'location',
          numeric: false,
          disablePadding: false,
          label: 'Location',
        },
        {
          id: 'pay_rate',
          numeric: false,
          disablePadding: false,
          label: 'Pay Rate',
        },
        {
          id: 'bill_rate',
          numeric: false,
          disablePadding: false,
          label: 'Bill Ratee',
        },
        {
          id: 'weekly_total_hours',
          numeric: false,
          disablePadding: false,
          label: 'Total Hours',
        },
        {
          id: 'net_revenue',
          numeric: false,
          disablePadding: false,
          label: 'Net Revenue',
        },
        {
          id: 'start_date',
          numeric: false,
          disablePadding: false,
          label: 'Start Date',
        },
        {
          id: 'placement_date',
          numeric: false,
          disablePadding: false,
          label: 'Placement Date',
        },
        {
          id: 'stage_name',
          numeric: false,
          disablePadding: false,
          label: 'Stage',
        },
        {
          id: 'sub_status_name',
          numeric: false,
          disablePadding: false,
          label: 'Status',
        },
      ]
    } else if (this.state.submittals_filter) {
      return [
        {
          id: 'job_title',
          numeric: false,
          disablePadding: false,
          label: 'Job',
        },
        {
          id: 'name',
          numeric: false,
          disablePadding: false,
          label: 'Candidate Name',
        },
        {
          id: 'email_s',
          numeric: false,
          disablePadding: false,
          label: 'Candidate Email',
        },
        {
          id: 'location',
          numeric: false,
          disablePadding: false,
          label: 'Location',
        },
        {
          id: 'salary',
          numeric: false,
          disablePadding: false,
          label: 'Current Salary',
        },
        {
          id: 'status_display',
          numeric: false,
          disablePadding: false,
          label: 'Stage',
        },
        {
          id: 'recruiter_name',
          numeric: false,
          disablePadding: false,
          label: 'Recruiter',
        },
      ]
    } else {
      return [
        {
          id: 'name',
          numeric: false,
          disablePadding: false,
          label: 'Candidate Name',
        },
        {
          id: 'client_name',
          numeric: false,
          disablePadding: false,
          label: 'Client Name',
        },
        {
          id: 'job_title',
          numeric: false,
          disablePadding: false,
          label: 'Job',
        },
        // {
        //   id: 'score',
        //   numeric: false,
        //   disablePadding: false,
        //   label: 'RC Score',
        // },
        {
          id: 'score1',
          numeric: false,
          disablePadding: false,
          label: 'AI Score',
        },
        {
          id: 'recruiter_name',
          numeric: false,
          disablePadding: false,
          label: 'Recruiter',
        },
        {
          id: 'email_s',
          numeric: false,
          disablePadding: false,
          label: 'Candidate Email',
        },
        {
          id: 'location',
          numeric: false,
          disablePadding: false,
          label: 'Location',
        },
        {
          id: 'salary',
          numeric: false,
          disablePadding: false,
          label: 'Current Salary',
        },
        {
          id: 'source',
          numeric: false,
          disablePadding: false,
          label: 'Source',
        },
        // {
        //   id: 'activity',
        //   numeric: false,
        //   disablePadding: false,
        //   label: 'Activity',
        // },
        {
          id: 'created',
          numeric: false,
          disablePadding: false,
          label: 'Created At',
        },
        // {
        //   id: 'job_type',
        //   numeric: false,
        //   disablePadding: false,
        //   label: 'Job Type',
        // },
        {
          id: 'stage_name',
          numeric: false,
          disablePadding: false,
          label: 'Stage',
        },
        {
          id: 'sub_status_name',
          numeric: false,
          disablePadding: false,
          label: 'Status',
        },
        {
          id: 'status_color',
          numeric: false,
          disablePadding: false,
          label: '',
        },
      ]
    }
  }

  csvHeaders = () => {
    if (this.state.placement_filter) {
      return [
        { key: 'client_name', label: 'Client Name' },
        { key: 'name', label: 'Candidate Name' },
        { key: 'email_s', label: 'Candidate Email' },
        { key: 'job_title', label: 'Candidate Title' },
        { key: 'location', label: 'Location' },
        { key: 'salary', label: 'Current Salary' },
        { key: 'client_fee_percentage', label: 'Client Fee Percentage' },
        { key: 'recruiter_name', label: 'RC Name' },
        { key: 'am_name', label: 'AM Name' },
        { key: 'client_fee_amount', label: 'Total Client Fee' },
        { key: 'total_rc_commision_amount', label: 'Total RC Commission' },
        //{key: 'total_am_commision_amount',label: 'Total AM Commission'},
        //{key: 'net_revenue',label: 'Net Revenue'},
        { key: 'paid', label: 'Client Paid' },
        { key: 'invoice_no', label: 'Client Invoice #' },
        { key: 'start_date', label: 'Start Date' },
        { key: 'placement_date', label: 'Placement Date' },
      ]
    } else if (this.state.contract_placement_filter) {
      return [
        { key: 'client_name', label: 'Client Name' },
        { key: 'name', label: 'Candidate Name' },
        { key: 'email_s', label: 'Candidate Email' },
        { key: 'job_title', label: 'Client Job Title' },
        { key: 'location', label: 'Location' },
        { key: 'pay_rate', label: 'Pay Rate' },
        { key: 'bill_rate', label: 'Bill Ratee' },
        { key: 'weekly_total_hours', label: 'Total Hours' },
        { key: 'net_revenue', label: 'Net Revenue' },
        { key: 'start_date', label: 'Start Date' },
        { key: 'placement_date', label: 'Placement Date' },
      ]
    } else if (this.state.submittals_filter) {
      return [
        { key: 'client_name', label: 'Client' },
        { key: 'job_title', label: 'Client Job Title' },
        { key: 'name', label: 'Candidate Name' },
        { key: 'email_s', label: 'Candidate Email' },
        { key: 'location', label: 'Location' },
        { key: 'salary', label: 'Current Salary' },
        { key: 'status_display', label: 'Stage' },
        { key: 'recruiter_name', label: 'Recruiter' },
      ]
    } else {
      return [
        { key: 'recruiter_name', label: 'Recruiter' },
        { key: 'client_name', label: 'Client Name' },
        { key: 'job_title', label: 'Client Job Title' },
        { key: 'name', label: 'Candidate Name' },
        { key: 'email_s', label: 'Candidate Email' },
        { key: 'location', label: 'Location' },
        { key: 'salary', label: 'Current Salary' },
        { key: 'source', label: 'Source' },
        { key: 'activity', label: 'Activity' },
        { key: 'created', label: 'Created At' },
        // { key: 'job_type', label: 'Job Type' },
        { key: 'status_display', label: 'Stage' },
        { key: 'stage_name', label: 'Stage' },
        { key: 'sub_status_name', label: 'Status' },
      ]
    }
  }

  clearFilters = () => {
    this.setState({
      isLoaded: false,
      selectedChild: [],
      page: 1,
      pageHeading: 'All Candidates',
      searchvale: 'AAAALL',
      recruiter_filter: 0,
      client_filter: 0,
      job_filter: 0,
      stage_filter: 0,
      sub_status_filter: 0,
      total_sub_class: 0,
      total_inter_class: 0,
      total_send_class: 0,
      total_internal_class: 0,
      total_external_class: 0,
      total_perma_class: 0,
      total_cont_class: 0,
      employe_filter: 0,
      total_applicant_class: 0,
      total_leads_candidate_class: 0,
      total_lead_source_class: 0,
      total_source_job_listing_class: 0,
      applicant_filter: false,
      leads_filter: false,
    })

    this.setState({
      placement_filter: false,
      submittals_filter: false,
      contract_placement_filter: false,
    })

    this.setState(
      {
        stage_filter: 0,
        sub_status_filter: 0,
      },
      function () {
        this.fetchCandidates()
      }
    )
  }

  handleRecruiterFilter = (value) => {
    this.setState(
      {
        isLoaded: false,
        recruitersValue: value !== -1 ? value : 0,
        recruiter_filter: value !== -1 ? value : 0,
        page: 1,
        leads_filter: false,
        submittals_filter: false,
        contract_placement_filter: false,
      },
      () => this.fetchCandidates()
    )
  }

  handleClientFilter = (value) => {
    this.setState(
      {
        isLoaded: false,
        page: 1,
        jobsdrops: '',
        nskills: '',
        client_filter: value !== -1 ? value : 0,
        leads_filter: false,
        submittals_filter: false,
        contract_placement_filter: false,
      },
      () => this.fetchCandidates()
    )
  }

  handleJobFilter = (value) => {
    this.setState(
      {
        isLoaded: false,
        page: 1,
        leads_filter: false,
        job_filter: value !== -1 ? value : 0,
        submittals_filter: false,
        contract_placement_filter: false,
      },
      () => this.fetchCandidates()
    )
  }

  totalCandidates = () => {
    return this.state.total_record
  }

  handleStageFilter = (e) => {
    // alert(e)

    const _data = []
    const { actual_data } = this.state
    // const status_id = e.join(',');
    const status_id = e
    this.setState({
      stageValue: status_id,
      isLoaded: false,
      page: 1,
      employe_filter: 0,
      leads_filter: false,
      skills_filter: 0,
    })
    if (status_id !== -1) {
      this.setState(
        {
          stage_filter: status_id || 0,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    } else {
      this.setState(
        {
          stage_filter: 0,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }
    this.setState({
      placement_filter: false,
      submittals_filter: false,
      contract_placement_filter: false,
      leads_filter: false,
    })
  }

  handleSubStatusFilter = (e) => {
    //alert(e)

    const _data = []
    const { actual_data } = this.state
    const status_id = e.join(',')
    this.setState({
      isLoaded: false,
      page: 1,
      employe_filter: 0,
      leads_filter: false,
      skills_filter: 0,
    })
    if (status_id !== -1) {
      this.setState(
        {
          sub_status_filter: status_id || 0,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    } else {
      this.setState(
        {
          sub_status_filter: 0,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }
    this.setState({
      placement_filter: false,
      submittals_filter: false,
      contract_placement_filter: false,
      leads_filter: false,
    })
  }

  handleStatusOnPageloadFilter = (subStatusObj = {}) => {
    const _data = []
    const { actual_data } = this.state
    const { value, status } = subStatusObj
    this.setState({
      isLoaded: false,
      page: 1,
      employe_filter: 0,
      leads_filter: false,
      skills_filter: 0,
    })
    if (status && value) {
      this.setState(
        {
          sub_status_filter: value || 0,
          stage_filter: status || 0,

          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    } else if (value && !status) {
      this.setState(
        {
          stage_filter: value || 0,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    } else {
      this.setState(
        {
          sub_status_filter: 0,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }
    this.setState({
      placement_filter: false,
      submittals_filter: false,
      contract_placement_filter: false,
      leads_filter: false,
    })
  }

  handleSkillsFilter = (e) => {
    //alert(e)
    const _data = []
    const { actual_data } = this.state
    const status_id = e.join(',')
    this.setState({
      isLoaded: false,
      page: 1,
      employe_filter: 0,
      leads_filter: false,
    })
    if (status_id !== -1) {
      this.setState(
        {
          skills_filter: status_id || 0,
          stage_filter: 0,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    } else {
      this.setState(
        {
          skills_filter: 0,
          stage_filter: 0,
          leads_filter: false,
        },
        function () {
          this.fetchCandidates()
        }
      )
    }
    this.setState({
      placement_filter: false,
      submittals_filter: false,
      contract_placement_filter: false,
      leads_filter: false,
    })
  }

  filterLeads = () => {
    this.setState(
      {
        isLoaded: false,
        page: 1,
        pageHeading: 'Candidates',
        total_sub_class: 0,
        total_inter_class: 0,
        total_send_class: 0,
        total_internal_class: 0,
        total_external_class: 0,
        total_perma_class: 0,
        total_cont_class: 0,
        stage_filter: 0,
        sub_status_filter: 0,
        employe_filter: 0,
        total_applicant_class: 0,
        total_leads_candidate_class: 1,
        total_lead_source_class: 0,
        total_source_job_listing_class: 0,
        applicant_filter: 'false',
        leads_filter: true,
        placement_filter: false,
        leads_with_source: false,
        source: '',
        total_txt: 'Total Candidates',
        label_text: 'Candidates Lists',
      },
      () => {
        this.fetchCandidates()
      }
    )
  }

  filterLeadsOne = (source) => {
    if (source === 'linkedin') {
      this.setState(
        {
          isLoaded: false,
          page: 1,
          pageHeading: 'Leads',
          total_sub_class: 0,
          total_inter_class: 0,
          total_send_class: 0,
          total_internal_class: 0,
          total_external_class: 0,
          total_perma_class: 0,
          total_cont_class: 0,
          total_applicant_class: 0,
          stage_filter: 0,
          sub_status_filter: 0,
          total_leads_candidate_class: 0,
          total_lead_source_class: 1,
          total_source_job_listing_class: 0,
          employe_filter: 0,
          applicant_filter: false,
          leads_filter: false,
          placement_filter: false,
          leads_with_source: true,
          source: source,
          skills_filter: 0,
          total_txt: 'Total Leads',
          label_text: 'Leads Lists',
        },
        () => {
          this.fetchCandidates()
        }
      )
    } else {
      this.setState(
        {
          isLoaded: false,
          page: 1,
          pageHeading: 'Job listing Applicants',
          total_sub_class: 0,
          total_inter_class: 0,
          total_send_class: 0,
          total_internal_class: 0,
          total_external_class: 0,
          total_perma_class: 0,
          total_cont_class: 0,
          total_applicant_class: 0,
          stage_filter: 0,
          sub_status_filter: 0,
          total_leads_candidate_class: 0,
          total_lead_source_class: 0,
          total_source_job_listing_class: 1,
          employe_filter: 0,
          applicant_filter: false,
          leads_filter: false,
          placement_filter: false,
          leads_with_source: true,
          source: source,
          skills_filter: 0,
          total_txt: 'Total Job listing Applicants',
          label_text: 'Job listing Applicants Lists',
        },
        () => {
          this.fetchCandidates()
        }
      )
    }
  }

  filterApplicant = () => {
    this.setState(
      {
        isLoaded: false,
        page: 1,
        pageHeading: 'Stages',
        total_sub_class: 0,
        total_inter_class: 0,
        total_send_class: 0,
        total_internal_class: 0,
        total_external_class: 0,
        total_perma_class: 0,
        total_cont_class: 0,
        total_applicant_class: 1,
        total_leads_candidate_class: 0,
        total_lead_source_class: 0,
        total_source_job_listing_class: 0,
        employe_filter: 0,
        applicant_filter: true,
        leads_filter: false,
        placement_filter: false,
        leads_with_source: false,
        skills_filter: 0,
        source: '',
        total_txt: 'Total Stages',
        label_text: 'Stages Lists',
      },
      () => {
        this.fetchCandidates()
      }
    )
  }

  filterSubmittals = () => {
    this.setState({
      isLoaded: false,
      page: 1,
      pageHeading: 'Submittals',
      total_sub_class: 1,
      total_txt: 'Total Submittals',
      label_text: 'Candidate Lists',
      total_inter_class: 0,
      total_send_class: 0,
      total_internal_class: 0,
      total_external_class: 0,
      total_perma_class: 0,
      total_cont_class: 0,
      employe_filter: 0,
      stage_filter: 7,
      sub_status_filter: 6,
      total_applicant_class: 1,
      total_leads_candidate_class: 0,
      total_lead_source_class: 0,
      total_source_job_listing_class: 0,
      applicant_filter: false,
      leads_filter: false,
    })

    this.setState({
      placement_filter: false,
      submittals_filter: false,
      contract_placement_filter: false,
    })

    this.setState(
      {
        stage_filter: 7,
        sub_status_filter: 6,
      },
      function () {
        this.fetchCandidates()
      }
    )
  }

  filterInterviews = () => {
    this.setState({
      isLoaded: false,
      pageHeading: 'Interviews',
      page: 1,
      total_sub_class: 0,
      total_inter_class: 1,
      total_send_class: 0,
      total_internal_class: 0,
      total_external_class: 0,
      total_perma_class: 0,
      total_cont_class: 0,
      employe_filter: 0,
      total_applicant_class: 1,
      total_leads_candidate_class: 0,
      stage_filter: 8,
      sub_status_filter: 10,
      total_lead_source_class: 0,
      total_source_job_listing_class: 0,
      total_txt: 'Total Candidates',
      label_text: 'Candidate lists',
      applicant_filter: false,
      leads_filter: false,
    })

    this.setState({
      placement_filter: false,
      submittals_filter: false,
      contract_placement_filter: false,
    })

    this.setState(
      {
        stage_filter: 8,
        sub_status_filter: 10,
      },
      function () {
        this.fetchCandidates()
      }
    )
  }

  filterSendouts = () => {
    this.setState({
      isLoaded: false,
      page: 1,
      pageHeading: 'Sendouts',
      total_sub_class: 0,
      total_inter_class: 0,
      total_send_class: 1,
      total_internal_class: 0,
      total_external_class: 0,
      total_perma_class: 0,
      total_cont_class: 0,
      employe_filter: 0,
      total_applicant_class: 1,
      stage_filter: 8,
      sub_status_filter: 9,
      total_leads_candidate_class: 0,
      total_lead_source_class: 0,
      total_source_job_listing_class: 0,
      applicant_filter: false,
      total_txt: 'Total Sendouts',
      label_text: 'Candidate lists',
      leads_filter: false,
    })

    this.setState({
      placement_filter: false,
      submittals_filter: false,
      contract_placement_filter: false,
    })

    this.setState(
      {
        stage_filter: 8,
        sub_status_filter: 9,
      },
      function () {
        this.fetchCandidates()
      }
    )
  }

  countSubmittals = () => {
    return this.state.total_sub
  }

  countInterviews = () => {
    return this.state.total_inter
  }

  countSendouts = () => {
    return this.state.total_send
  }

  countInternals = () => {
    return this.state.total_internals
  }

  countExternals = () => {
    return this.state.total_externals
  }

  countPlacements = () => {
    return this.state.total_perma
  }

  countContracts = () => {
    return this.state.total_cont
  }

  renderStageFilter = () => {
    const { placement_filter } = this.state
    if (!placement_filter)
      return (
        <div id="clients" style={{ width: '100%', position: 'relative', left: '-8%' }}>
          <SingleSelect
            name="status"
            id="Status"
            label="Stage"
            options={stage_list}
            onChange={this.handleStageFilter}
            style={{ width: '75%' }}
            value={this.state.stagesValue}
            // inputProps={{
            //   name: 'stagesValue',
            //   id: 'frmClients',
            // }}
          />
        </div>
      )

    return null
  }

  renderSubStatusFilter = () => {
    const { placement_filter } = this.state
    if (!placement_filter)
      return (
        <div id="clients" style={{ width: '100%', position: 'relative', left: '-10%' }}>
          <MultipleSelect
            name="sub_status"
            id="id_sub_status"
            label="Status"
            // options={
            //   this.state.stageValue === 7
            //     ? getStageByType("internal")
            //     : this.state.stageValue === 8
            //       ? getStageByType("external")
            //       : sub_status
            // }
            options={
              this.state.stageValue === 7
                ? getStageByType('internal')
                : this.state.stageValue === 8
                ? getStageByType('external')
                : this.state.stageValue === 9
                ? getStageByType('pipeline')
                : sub_status
            }
            onChange={this.handleSubStatusFilter}
            style={{ width: '75%' }}
            value={this.state.statusValue}
            inputProps={{
              name: 'statusValue',
              id: 'frmClients',
            }}
          />
        </div>
      )

    return null
  }

  renderSkillsFilter = () => {
    const { placement_filter } = this.state
    if (!placement_filter)
      return (
        <div id="clients" style={{ width: '100%', position: 'relative', left: '-6%' }}>
          <MultipleSelect
            name="search_skills"
            id="search_skills"
            label="Skills"
            options={this.state.nskills}
            onChange={this.handleSkillsFilter}
            style={{ width: '75%' }}
            value={this.state.skillsValue}
            inputProps={{
              name: 'skillsValue',
              id: 'frmClients',
            }}
          />
        </div>
      )

    return null
  }

  getFilterCardContents = () => {
    const { placement_filter } = this.state
    const user_id = this.props.auth.user_id
    return (
      <div className="filter-section" style={{ paddingBottom: '10px' }}>
        <div className="search-section custom-section" style={{ display: 'inline-flex' }}>
          <div id="recruiter" style={{ width: '100%' }}>
            {placement_filter ? (
              (user_id === 2 || user_id === 10) && (
                <SingleSelect
                  placeholder="Recruiter"
                  id="frmRecruiter"
                  label="Recruiter"
                  style={{ width: '75%' }}
                  name="recruitersValue"
                  value={this.state.recruitersValue}
                  options={this.state.users}
                  onChange={this.handleRecruiterFilter}
                />
              )
            ) : (
              <SingleSelect
                placeholder="Recruiter"
                id="frmRecruiter"
                label="Recruiter"
                style={{ width: '70%' }}
                name="recruitersValue"
                value={this.state.recruitersValue}
                options={this.state.users}
                onChange={this.handleRecruiterFilter}
              />
            )}
            {/* <InputLabel htmlFor="demo-simple-select-label">Recruiter</InputLabel> */}
            {/* <Select
              labelId="Recruiter"
              id="frmRecruiter"
              placeholder="Recruiter"

              style={{ width: '68%',marginTop:'30px' }}

              name="recruitersValue"
              value={this.state.recruitersValue}
              options={this.state.users}
              onChange={this.handleRecruiterFilter}
            >
              {(this.state.users || []).map((user) => {
                return <MenuItem key={user.value} value={user.value}>{user.label}</MenuItem>
              })}
            </Select> */}
          </div>

          <div id="clients" style={{ width: '100%', position: 'relative', left: '-4%' }}>
            <SingleSelect
              name="clients"
              label="Clients"
              options={this.state.clients}
              onChange={this.handleClientFilter}
              style={{ width: '70%' }}
            />
          </div>
          <div id="clients" style={{ width: '100%', position: 'relative', left: '-6%' }}>
            <SingleSelect
              name="jobs"
              label="Jobs"
              options={this.state.jobsdrops}
              onChange={this.handleJobFilter}
              style={{ width: '70%' }}
            />
          </div>
          {this.renderSkillsFilter()}
          {this.renderStageFilter()}
          {this.renderSubStatusFilter()}
          {/*<AppTextField label="Search" size="lg"/>*/}
          {/*<AppButton*/}
          {/*    color="primary"*/}
          {/*    label="Search"*/}
          {/*    icon={{component: SearchIcon, position: 'right'}}*/}
          {/*/>*/}
          <div
            style={{ position: 'relative', left: '-13%' }}
            className={'color-cls-' + this.state.total_leads_candidate_class}
          >
            <Fab
              className="changeonhover custom-fab-button"
              color="primary"
              aria-label="Edit"
              onClick={this.filterLeads}
            >
              <Icon className="custom-fab-button-icon">{this.state.candidate_total || 0}</Icon>
            </Fab>
            <span>
              <strong>
                <p class="tooltip">
                  {'All Candidates'}
                  <span class="tooltiptext">{'All Candidates of Linkedin Sourcing , Job Listing , Inmails.'}</span>
                </p>
                {/* <small style={{ marginLeft: '19px' }}></small> */}
              </strong>
            </span>
          </div>
          <div
            style={{ position: 'relative', left: '-11%' }}
            className={'color-cls-' + this.state.total_lead_source_class}
          >
            <Fab
              className="changeonhover custom-fab-button"
              color="primary"
              aria-label="Edit"
              onClick={() => this.filterLeadsOne('linkedin')}
            >
              <Icon className="custom-fab-button-icon">{this.state.total_lead_source_count || 0}</Icon>
            </Fab>
            <span>
              <strong>
                <p class="tooltip" style={{ marginLeft: '11px' }}>
                  {'Leads'}
                  <span class="tooltiptext">{'Candidates with status Leads only.'}</span>
                </p>
                {/* <small style={{ marginLeft: '12px' }}>Leads</small> */}
              </strong>
            </span>
          </div>

          <div
            style={{ position: 'relative', left: '-9%' }}
            className={'color-cls-' + this.state.total_source_job_listing_class}
          >
            <Fab
              className="changeonhover custom-fab-button"
              color="primary"
              aria-label="Edit"
              onClick={() => this.filterLeadsOne('Job Listing')}
            >
              <Icon className="custom-fab-button-icon">{this.state.total_source_job_listing_count || 0}</Icon>
            </Fab>
            <span>
              <strong>
                <p class="tooltip">
                  {'Job listing Applicants'}
                  <span class="tooltiptext">{'Candidates coming from Job listing page only.'}</span>
                </p>
                {/* <small >Job listing Applicants</small> */}
              </strong>
            </span>
          </div>

          <div
            style={{ position: 'relative', left: '-7%' }}
            className={'color-cls-' + this.state.total_applicant_class}
          >
            <Fab
              className="changeonhover custom-fab-button"
              color="primary"
              aria-label="Edit"
              onClick={this.filterApplicant}
            >
              <Icon className="custom-fab-button-icon">{this.state.total_applicant || 0}</Icon>
            </Fab>
            <span>
              <strong>
                <p class="tooltip" style={{ marginLeft: '11px' }}>
                  {'Stages'}
                  <span class="tooltiptext">{'All candidates but not LEADS & Permanent Placements.'}</span>
                </p>
                {/* <small>Applicant</small> */}
              </strong>
            </span>
          </div>

          <div style={{ position: 'relative', left: '-5%' }} className={'color-cls-' + this.state.total_internal_class}>
            <Fab
              className="changeonhover custom-fab-button"
              color="primary"
              aria-label="Edit"
              onClick={this.filterInternals}
            >
              <Icon className="custom-fab-button-icon">{this.countInternals()}</Icon>
            </Fab>
            <strong>
              <p class="tooltip" style={{ marginLeft: '11px' }}>
                {'Internal'}
                <span class="tooltiptext">{'Candidate with status Internals only.'}</span>
              </p>
              {/* <small>Permanent Placements</small> */}
            </strong>
          </div>

          <div style={{ position: 'relative', left: '-3%' }} className={'color-cls-' + this.state.total_external_class}>
            <Fab
              className="changeonhover custom-fab-button"
              color="primary"
              aria-label="Edit"
              onClick={this.filterExternals}
            >
              <Icon className="custom-fab-button-icon">{this.countExternals()}</Icon>
            </Fab>
            <strong>
              <p class="tooltip" style={{ marginLeft: '11px' }}>
                {'External'}
                <span class="tooltiptext">{'Candidate with status External - Hired/Placement only.'}</span>
              </p>
              {/* <small>Permanent Placements</small> */}
            </strong>
          </div>

          <div style={{ position: 'relative', left: '-1%' }} className={'color-cls-' + this.state.total_perma_class}>
            <Fab
              className="changeonhover custom-fab-button"
              color="primary"
              aria-label="Edit"
              onClick={this.filterPlacements}
            >
              <Icon className="custom-fab-button-icon">{this.countPlacements()}</Icon>
            </Fab>
            <strong>
              <p class="tooltip" style={{ marginLeft: '-13px' }}>
                {'External - Hired/Placement'}
                <span class="tooltiptext">{'Candidate with status External - Hired/Placement only.'}</span>
              </p>
              {/* <small>Permanent Placements</small> */}
            </strong>
          </div>

          {/* <div style={{ position: 'relative', left: '-1%' }} className={'color-cls-' + this.state.total_cont_class}>
            <Fab className="changeonhover custom-fab-button" color="primary" aria-label="Edit" onClick={this.filterContractPlacements}>
              <Icon className="custom-fab-button-icon">{this.countContracts()}</Icon>
            </Fab>
            <strong>
              <p class="tooltip">{"Contract Placements"}
                <span class="tooltiptext">Tooltip text</span>
              </p>
              <small>Contract Placements</small>
            </strong>
          </div> */}
        </div>
        <div>
          <div>
            <AppButton
              label="A d d"
              color="secondary"
              onClick={this.addNewCandidate}
              // icon={{ component: Add, position: "right" }}
            />
          </div>
          <div>
            <AppButton label="Clear" color="primary" onClick={this.clearFilters} />
          </div>
        </div>
      </div>
    )
  }

  filterPlacements = () => {
    this.setState(
      {
        isLoaded: false,
        page: 1,
        placement_filter: true,
        contract_placement_filter: false,
        pageHeading: 'Permanent Placement',
        total_sub_class: 0,
        total_inter_class: 0,
        total_send_class: 0,
        total_perma_class: 1,
        total_cont_class: 0,
        total_applicant_class: 0,
        stage_filter: 8,
        sub_status_filter: 11,
        employe_filter: 0,
        recruiter_filter: 0,
        total_txt: 'Total Placements:',
        label_text: 'Placement List',
        total_leads_candidate_class: 0,
        total_lead_source_class: 0,
        leads_filter: false,
        applicant_filter: false,
      },
      () => this.fetchCandidates()
    )
  }

  filterInternals = () => {
    this.setState(
      {
        isLoaded: false,
        page: 1,
        placement_filter: false,
        contract_placement_filter: false,
        pageHeading: 'Internals',
        total_sub_class: 0,
        total_inter_class: 0,
        total_send_class: 0,
        total_internal_class: 1,
        total_external_class: 0,
        total_perma_class: 0,
        total_cont_class: 0,
        total_applicant_class: 0,
        stage_filter: 7,
        sub_status_filter: 0,
        employe_filter: 0,
        total_txt: 'Total Internals:',
        label_text: 'Internal List',
        total_leads_candidate_class: 0,
        total_lead_source_class: 0,
        leads_filter: false,
        applicant_filter: false,
      },
      function () {
        this.fetchCandidates()
      }
    )
  }

  filterExternals = () => {
    this.setState(
      {
        isLoaded: false,
        page: 1,
        placement_filter: false,
        contract_placement_filter: false,
        pageHeading: 'Externals',
        total_sub_class: 0,
        total_inter_class: 0,
        total_send_class: 0,
        total_internal_class: 0,
        total_external_class: 1,
        total_perma_class: 0,
        total_cont_class: 0,
        total_applicant_class: 0,
        stage_filter: 8,
        sub_status_filter: 0,
        employe_filter: 0,
        total_txt: 'Total Placements:',
        label_text: 'Placement List',
        total_leads_candidate_class: 0,
        total_lead_source_class: 0,
        leads_filter: false,
        applicant_filter: false,
      },
      function () {
        this.fetchCandidates()
      }
    )
  }

  filterContractPlacements = () => {
    this.setState(
      {
        isLoaded: false,
        page: 1,
        stage_filter: 5,
        contract_placement_filter: true,
        placement_filter: false,
        pageHeading: 'Contract Placement',
        total_sub_class: 0,
        total_inter_class: 0,
        total_send_class: 0,
        total_internal_class: 0,
        total_external_class: 0,
        total_perma_class: 0,
        total_cont_class: 1,
        employe_filter: 1,
        leads_filter: false,
        total_leads_candidate_class: 0,
        total_lead_source_class: 0,
      },
      function () {
        this.fetchCandidates()
      }
    )
  }

  onCandidateUpdate = (candidate, is_new) => {
    this.fetchCandidates()
  }

  checkDeimalValue = (value) => {
    let returnValue = 0
    if (value && value != '0.0000') {
      returnValue = value
      var decimalLength = 0
      var sp = (value + '').split('.')
      if (sp[1] !== undefined) {
        decimalLength = sp[1].length
        if (decimalLength > 2) {
          returnValue = parseFloat(value).toFixed(2)
        }
      } else {
        //decimalLength =  0;
      }
    }

    return returnValue
  }

  getChildRow = (d, colSpan, test) => {
    const { classes } = this.props
    //d.bonus = (d.bonus && d.bonus != '0.0000') ? d.bonus : 0;
    //d.current_ote = (d.current_ote && d.current_ote != '0.0000') ? d.current_ote : 0;
    //d.ote_expectation = (d.ote_expectation && d.ote_expectation != '0.0000') ? d.ote_expectation : 0;
    d.bonus = this.checkDeimalValue(d.bonus)
    d.current_ote = this.checkDeimalValue(d.current_ote)
    d.ote_expectation = this.checkDeimalValue(d.ote_expectation)
    return (
      <TableRow key={`child-${d.id}`}>
        <TableCell colSpan={colSpan} className={classes.child}>
          <VerticalTabs
            data={d}
            clients={this.state.clients}
            jobs={this.state.jobsdrops}
            users={this.state.users}
            leads_filter={this.state.leads_filter}
            contact_filter={this.state.contract_placement_filter}
            account_managers={this.state.account_managers}
            third_parties={this.state.third_party}
            isSubmittal={this.state.stage_filter === 2}
            token={this.state.auth_token}
            onCandidateUpdate={this.onCandidateUpdate}
            placementFilter={this.state.placement_filter}
            contractPlacementFilter={this.state.contract_placement_filter}
            showHideApproval={true}
            skills={this.props.skills.data}
            user_id={this.props.auth.user_id}
            t_open={test}
          />
        </TableCell>
      </TableRow>
    )
  }

  getChildRows = () => {
    const {
      state: { data },
      getChildRow,
    } = this
    const colSpan = data && data.length > 0 ? Object.keys(data[0]).length + 1 : 1
    return data.map((d) => {
      return {
        id: d.id,
        element: getChildRow(d, colSpan, false),
      }
    })
  }

  getChildRows1 = () => {
    const {
      state: { data },
      getChildRow,
    } = this
    const colSpan = data && data.length > 0 ? Object.keys(data[0]).length + 1 : 1
    return data.map((d) => {
      return {
        id: d.id,
        element: getChildRow(d, colSpan, true),
      }
    })
  }

  getProgress = () => (this.state.isLoaded ? false : <LinearProgress />)

  setDates = ({ startDate, endDate }) => {
    this.setState(
      {
        filterDatefrom: moment(startDate).format('YYYY-MM-DD'),
        filterDateto: moment(endDate).format('YYYY-MM-DD'),
        // state_filter_permanent_placement: 1,
      },
      () => {
        this.fetchCandidates()
      }
    )
  }

  customSetMultipleDate = (tempMonths, dateType) => {
    console.log('tempMonths', tempMonths)
    console.log('date type', dateType)
    if (dateType !== 0) {
      this.setState({ isMonthFilteredStarted: true })
      let filtereData = []
      tempMonths.forEach(async (month) => {
        const response = await this.fetchCandidatesCutsom(dateType, 1, month.first, month.last)
        filtereData = [...filtereData, ...response]
        this.setState({ data: [...filtereData] })
      })
    } else {
      this.setState(
        {
          state_filter_permanent_placement: dateType,
          page: 1,
          filterDatefrom: moment(tempMonths[0].first).format('YYYY-MM-DD'),
          filterDateto: moment(tempMonths[0].last).format('YYYY-MM-DD'),
        },
        () => this.fetchCandidates()
      )
    }
  }

  customSetDates = (startDate, endDate) => {
    this.setState(
      {
        filterDatefrom: moment(startDate).format('YYYY-MM-DD'),
        filterDateto: moment(endDate).format('YYYY-MM-DD'),
      },
      () => this.fetchCandidates()
    )
  }

  updateCandidateData = (record, id) => {
    const data = this.state.data.slice()
    const index = data.findIndex((o) => o.id === id)
    data.splice(data, 1, record)
    this.setState({
      data,
    })
  }

  render() {
    const {
      getFilterCardContents,
      columns,
      state: { data },
      props: { classes },
      getChildRows,
      getProgress,
    } = this
    const filterCard = {
      content: getFilterCardContents(),
    }
    const param = this.useQuery()
    const location = param.get('location') || ''

    return (
      <div className={classes.root}>
        <div className={classes.card + ' cardsn'}>{location !== 'dashboard' && <AppCard {...filterCard} />}</div>
        <div className={classes.card}>
          {getProgress()}
          {this.state.total_applicant_class !== 1 && <h1 className="page-heading">{this.state.pageHeading} </h1>}
          {this.state.total_applicant_class === 1 && (
            <div className="icon-group">
              <h1 style={{ left: '0px', position: 'absolute' }} className="page-heading">
                {this.state.pageHeading}
              </h1>
              <div style={{ paddingRight: '70px' }} className={'color-cls-' + this.state.total_sub_class}>
                <Fab className="changeonhover" color="primary" aria-label="Edit" onClick={this.filterSubmittals}>
                  <Icon>{this.countSubmittals()}</Icon>
                </Fab>
                <strong>
                  <p class="tooltip cust_tool" style={{ marginLeft: '-30px' }}>
                    {'Internal - Submittal Pending for Review'}
                    {/* <span class="tooltiptext">{"Candidate with status External - Hired/Placement only."}</span> */}
                  </p>
                  {/* <small>Permanent Placements</small> */}
                </strong>
              </div>
              <div style={{ paddingRight: '70px' }} className={'color-cls-' + this.state.total_inter_class}>
                <Fab className="changeonhover" color="primary" aria-label="Edit" onClick={this.filterInterviews}>
                  <Icon>{this.countInterviews()}</Icon>
                </Fab>
                <strong>
                  <p class="tooltip cust_tool" style={{ marginLeft: '-30px' }}>
                    {'External - Interview'}
                    {/* <span class="tooltiptext">{"Candidate with status External - Hired/Placement only."}</span> */}
                  </p>
                  {/* <small>Permanent Placements</small> */}
                </strong>
              </div>
              <div style={{ paddingRight: '70px' }} className={'color-cls-' + this.state.total_send_class}>
                <Fab className="changeonhover" color="primary" aria-label="Edit" onClick={this.filterSendouts}>
                  <Icon>{this.countSendouts()}</Icon>
                </Fab>
                <strong>
                  <p class="tooltip cust_tool" style={{ marginLeft: '-30px' }}>
                    {'External - Submitted Pending Feedback'}
                    {/* <span class="tooltiptext">{"Candidate with status External - Hired/Placement only."}</span> */}
                  </p>
                  {/* <small>Permanent Placements</small> */}
                </strong>
              </div>
            </div>
          )}
          <AppDataTable
            selected={this.state.selectedChild}
            columns={columns}
            data={data}
            opened=""
            type={this.state.pageHeading}
            label={this.state.label_text}
            search="true"
            updateCandidateData={this.updateCandidateData}
            setDates={this.setDates}
            filterDatefrom={this.state.filterDatefrom || moment().format('YYYY-MM-DD')}
            filterDateto={this.state.filterDateto || moment().format('YYYY-MM-DD')}
            pagging="true"
            token={this.state.auth_token}
            totalText={this.state.total_txt}
            totalCandidates={this.totalCandidates()}
            totalfee={this.state.totalfee}
            showtotalfee={this.state.placement_filter}
            totalRcCommission={this.state.totalRcCommission}
            showTotalRcCommission={this.state.placement_filter}
            applicantFilter={this.state.applicant_filter}
            applyFiltesDate={this.applyFiltesDate}
            clearapplyFiltesDate={this.clearapplyFiltesDate}
            childRows={getChildRows()}
            childRowsEdit={this.getChildRows1()}
            customSetDates={this.customSetDates}
            reloadCandidate={this.filterLeads}
            isArchived={0}
            user_id={this.props.auth.user_id}
            show_edit_button={true}
            customSetMultipleDate={this.customSetMultipleDate}
            rightAction={
              <>
                <AppTextField
                  size="lg"
                  onKeyUp={this.updateSearchValue}
                  onChange={this.updateSearchValue}
                  label="Search"
                  type="search"
                  margin="normal"
                />
                <CSVLink data={data} className="hidden" headers={this.csvHeaders()} filename={'candidate.csv'}>
                  Export CSV
                </CSVLink>
              </>
            }
          />
          {getProgress()}
          <div className="pager" style={{ height: '10px !important' }}>
            {/* <div className="pager-inner">
              <span className="page-link" onClick={this.gotoPrevPage}>
                Prev
              </span>
              &nbsp; &nbsp;{this.state.page}&nbsp;of&nbsp;{Math.ceil(this.state.total_record / 25)}&nbsp; &nbsp;
              <span className="page-link" onClick={this.gotoNextPage}>
                Next
              </span>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}

Candidates.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    ...state,
    candidates: state.data.candidates,
    jobs: state.data.jobs,
    skills: state.data.skills,
    auth: state.auth,
  }
}
const mapDispatchToProps = {
  receiveFetchData: receiveFetchData,
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(withRouter(Candidates))

// export default withRouter(withStyles(styles)(Candidates))
