import axios from "axios"
import * as AppConstants from "../../constants/app"

export const getAuthToken = async () => {
  let params = {
    username: 'admin@anewrecruit.com',
    password: 'Welcome123!!'
  }

  const response = await axios.post(`${AppConstants.API_BASE_URL}api-token-auth/`, params)
  if (response.data) {
    let token = response.data.token
    return token
  }
  return null
}

export const fetchUsers = async () => {
  const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/user/`)
  if (response.data && response.data['successful']) {
    return response.data.result
  }
  return []
}


export const fetchAccountManagers = async () => {
  const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/account_manager/`)
  if (response.data && response.data['successful']) {
    return response.data.result
  }
  return []
}

export const fetchThirdParty = async () => {
  const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/third-party/`)
  if (response.data && response.data['successful']) {
    return response.data.result
  }
  return []
}
