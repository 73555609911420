class ClientModel {
  is_new = false
  id = ''
  name = ''
  recruiter = ''
  city = ''
  state = ''
  zip = ''
  country = ''
  title = ''
  phone = []
  email = []
  status = -1

  constructor(is_new) {
    this.is_new = is_new
  }
}

export default ClientModel
