import React from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import './index.scss'

const styles = (theme) => ({
  root: {
    margin: theme.spacing.unit,
  },
})

class AppTextField extends React.Component {
  state = {
    visibility: false,
  }

  onVisibilityUpdate = (ref, visibility) => {
    this.setState({ visibility: visibility })
  }

  render() {
    const { props, onVisibilityUpdate } = this
    const { classes } = props
    let size = props.size || 'md'
    const fullWidth = props.fullWidth ? 'full-width' : ''
    size = fullWidth ? '' : size
    let _props = {
      ...props,
      variant: 'outlined',
      InputLabelProps: {
        shrink: true,
      },
      className: `${classes.root} input ${size} ${props.className}`,
    }
    if (props.labelInput) {
      if (!this.state.visibility) {
        _props = { ..._props, style: { visibility: 'hidden' } }
      }
      return (
        <div className={`app-text-field ${fullWidth}`}>
          <InputLabel className="label" onClick={() => onVisibilityUpdate(this.InputRef, true)}>
            {props.value}
          </InputLabel>
          <TextField
            ref={(ref) => {
              this.InputRef = ref
            }}
            {..._props}
            onBlur={() => onVisibilityUpdate(this.InputRef, false)}
          />
        </div>
      )
    } else {
      return (
        <div className={`app-text-field app-text-field-filter ${fullWidth}`}>
          <TextField {..._props} />
        </div>
      )
    }
  }
}

AppTextField.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppTextField)
