/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
import React, {useState} from 'react'
import TableRow from "@material-ui/core/TableRow"
import {candidateColumns} from "./columns"
import TableCell from "@material-ui/core/TableCell"
import {getEploymentType, getEploymentTypeC} from "./util"
import Button from "@material-ui/core/Button"
import AppSelect from "../common/AppSelect"
import AppTextField from "../common/AppTextField"
import {CircularProgress} from "@material-ui/core"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import CandidateForm from "../Candidates/Form"
import {getSubStatusName} from "../../constants/status"

const CandidatesDataRow = ({d, token, page_data, reloadCandidates, job_id, stage_id}) => {
  const [expand, setExpand] = useState(false)

  const getActionIcon = () => {
    if (expand)
      return (
        <RemoveCircleOutlineIcon onClick={toggleExpandCollapse}/>
      )

    return <AddCircleOutlineIcon onClick={toggleExpandCollapse}/>
  }

  const rightAlign = column => (column.numeric ? 'right' : 'left')

  const toggleExpandCollapse = () => setExpand(!expand)

  return (
    <>
      <TableRow>
        {
          candidateColumns.map((column) => {
            {
              if (column.id === 'plus') {
                return (
                  <TableCell key={column.id}>
                    {getActionIcon()}
                  </TableCell>
                )
              } else if (d[column.id] === 'Leads') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    style={{color: 'chocolate', fontWeight: 'bold'}}
                    align={rightAlign(column)}
                  >
                    {d[column.id]}
                  </TableCell>
                )
              } else if (column.id === 'name') {
                if (d.withdraw == 1) {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      style={{color: 'red', fontWeight: 'bold'}}
                      align={rightAlign(column)}
                    >
                      {d[column.id]}{' '}
                    </TableCell>
                  )
                } else {
                  return (
                    <TableCell key={`${column.id}${d.id}`} align={rightAlign(column)}>
                      {d[column.id]}{' '}
                    </TableCell>
                  )
                }
              } else if (d[column.id] === 'Submittals') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    style={{color: 'orange', fontWeight: 'bold'}}
                    align={rightAlign(column)}
                  >
                    {d[column.id]}
                  </TableCell>
                )
              } else if (d[column.id] === 'Sendout') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    style={{color: 'green', fontWeight: 'bold'}}
                    align={rightAlign(column)}
                  >
                    {d[column.id]}
                  </TableCell>
                )
              } else if (d[column.id] === 'Interview') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    style={{color: 'Tomato', fontWeight: 'bold'}}
                    align={rightAlign(column)}
                  >
                    {d[column.id]}
                  </TableCell>
                )
              } else if (d[column.id] === 'Hired/Placement') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    style={{color: '#90EE90', fontWeight: 'bold'}}
                    align={rightAlign(column)}
                  >
                    {d[column.id]}
                  </TableCell>
                )
              } else if (d[column.id] === 'Rejected') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    style={{color: 'violet', fontWeight: 'bold'}}
                    align={rightAlign(column)}
                  >
                    {d[column.id]}
                  </TableCell>
                )
              } else if (d[column.id] === 'On Hold') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    style={{
                      color: 'SlateBlue',
                      fontWeight: 'bold'
                    }}
                    align={rightAlign(column)}
                  >
                    {d[column.id]}
                  </TableCell>
                )
                if (d[column.id] === 'Leads') {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      style={{
                        color: 'chocolate',
                        fontWeight: 'bold'
                      }}
                      align={rightAlign(column)}
                    >
                      {d[column.id]}
                    </TableCell>
                  )
                }
              } else if (d[column.id] === 'Closed') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    style={{
                      color: 'MediumSeaGree',
                      fontWeight: 'bold'
                    }}
                    align={rightAlign(column)}
                  >
                    {d[column.id]}
                  </TableCell>
                )
              } else if (column.id == 'pay_rate') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    className={`app-table-cell-${d[column.id]}`}
                    align={rightAlign(column)}
                  >
                    ${d['pay_rate']}/hour
                  </TableCell>
                )
              } else if (column.id == 'net_revenue') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    className={`app-table-cell-${d[column.id]}`}
                    align={rightAlign(column)}
                  >
                    ${d['net_revenue']}
                  </TableCell>
                )
              } else if (column.id == 'job_title') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    style={{}}
                    className={`app-table-cell-${d[column.id]}`}
                    align={rightAlign(column)}
                  >
                    {d['job_title']}
                  </TableCell>
                )
              } else if (column.id == 'products') {
                let prod = d['products']
                let res = ''
                if (prod) res = prod.substring(0, 50) + '...'
                return (
                  <TableCell
                    title={prod}
                    key={`${column.id}${d.id}`}
                    className={`app-table-cell-${d[column.id]}`}
                    align={rightAlign(column)}
                  >
                    {' '}
                    {res}
                  </TableCell>
                )
              } else if (column.id == 'contact') {
                let res = 0
                if (d['contact']) res = d['contact'].length
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    className={`app-table-cell-${d[column.id]}`}
                    align={rightAlign(column)}
                  >
                    {' '}
                    {res}
                  </TableCell>
                )
              } else if (column.id == 'no_of_jobs') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    className={`app-table-cell-${d[column.id]}`}
                    align={rightAlign(column)}
                  >
                    <a href={'/jobs/' + d.id}>{d.no_of_jobs}</a>
                  </TableCell>
                )
              } else if (column.id == 'contact_obj') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    className={`app-table-cell-${d[column.id]}`}
                    align={rightAlign(column)}
                  >
                    <a href={'/contacts/' + d.id}>{d.contact_obj}</a>
                  </TableCell>
                )
              } else if (column.id == 'candidates') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    className={`app-table-cell-${d[column.id]}`}
                    align={rightAlign(column)}
                  >
                    <a href={'/candidates/' + d.id}>{d.candidates}</a>
                  </TableCell>
                )
              } else if (column.id == 'company_infos') {
                if (d.company_infos) {
                  var y = d.company_infos
                    .split(' ')
                    .slice(0, 3)
                    .join('+')
                } else {
                  // eslint-disable-next-line no-redeclare
                  var y = ''
                }

                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    className={`app-table-cell-${d[column.id]}`}
                    align={rightAlign(column)}
                    title="{d.company_infos}"
                  >
                    {y}
                  </TableCell>
                )
              } else if (column.id == 'job_type') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    className={`app-table-cell-${d[column.id]} ` + getEploymentTypeC(d.job_type)}
                    align={rightAlign(column)}
                  >
                    {getEploymentType(d.job_type)}
                  </TableCell>
                )
              } else if (column.id == 'action') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    className={`app-table-cell-${d[column.id]}`}
                    align={rightAlign(column)}
                  >
                    <Button onClick={() => this.props.editRow(d)} size="sm" label="Edit" color="primary">
                      Edit
                    </Button>
                  </TableCell>
                )
              } else if (column.id == 'source') {
                if (d['source'] == '') {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      className={`app-table-cell-${d[column.id]}`}
                      align={rightAlign(column)}
                    >
                      Via Online Application
                    </TableCell>
                  )
                } else {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      className={`app-table-cell-${d[column.id]}`}
                      align={rightAlign(column)}
                    >
                      {d['source']}
                    </TableCell>
                  )
                }
              } else if (column.id == 'salary') {
                let pay_rate
                let salary
                if (d['pay_rate'] === null) {
                  pay_rate = ''
                } else {
                  pay_rate = '$' + d['pay_rate'] + '/hour'
                }

                if (d['salary'] === null) {
                  salary = ''
                } else {
                  salary = '$' + d['salary']
                }

                if (d['employment_type'] == 1) {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      className={`app-table-cell-${d[column.id]}`}
                      align={rightAlign(column)}
                    >
                      {pay_rate}
                    </TableCell>
                  )
                } else {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      className={`app-table-cell-${d[column.id]}`}
                      align={rightAlign(column)}
                    >
                      {salary}
                    </TableCell>
                  )
                }
              } else if (column.id == 'client_fee_percentage') {
                if (!d.client_fee) {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      className={`app-table-cell-${d[column.id]}`}
                      align={rightAlign(column)}
                    >
                      {d['client_fee_percentage']}
                    </TableCell>
                  )
                } else {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      className={`app-table-cell-${d[column.id]}`}
                      align={rightAlign(column)}
                    >
                      {d['client_fee']}
                    </TableCell>
                  )
                }
              } else if (column.id == 'sub_status') {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    className={`app-table-cell-${d[column.id]}`}
                    align={rightAlign(column)}
                  >
                    {getSubStatusName(d['sub_status']) || 'None'}
                  </TableCell>
                )

              } else if (column.id == 'client_fee_amount') {
                let fee

                if (d.withdraw == 1) {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      className={`app-table-cell-${d[column.id]}`}
                      align={rightAlign(column)}
                    >
                      0
                    </TableCell>
                  )
                } else {
                  if (!d.client_fee) {
                    fee = (d['salary'] * d['client_fee_percentage']) / 100
                    return (
                      <TableCell
                        key={`${column.id}${d.id}`}
                        className={`app-table-cell-${d[column.id]}`}
                        align={rightAlign(column)}
                      >
                        {fee}
                      </TableCell>
                    )
                  } else {
                    fee = (d['salary'] * d['client_fee']) / 100
                    return (
                      <TableCell
                        key={`${column.id}${d.id}`}
                        className={`app-table-cell-${d[column.id]}`}
                        align={rightAlign(column)}
                      >
                        {fee}
                      </TableCell>
                    )
                  }
                }
              } else if (column.id === 'paidtext') {
                if (this.state.editRow && this.state.editRow === d.id) {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      className={`app-table-cell-${d[column.id]}`}
                      align={rightAlign(column)}
                      onClick={() => this.setState({editRow: d.id})}
                    >
                      <AppSelect
                        style={{minWidth: '100px', marginTop: '5px'}}
                        id="paidtext"
                        label="paid"
                        value={this.state.paidtext}
                        name="paidtext"
                        onChange={(e) => this.handleChangeInline({paidtext: e.target.value})}
                        items={[
                          {value: 1, label: ' Yes'},
                          {value: 0, label: 'No'}
                        ]}
                      />
                    </TableCell>
                  )
                } else {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      className={`app-table-cell-${d[column.id]}`}
                      align={rightAlign(column)}
                      onClick={() => this.setState({
                        editRow: d.id,
                        invoice_no: d.invoice_no,
                        paidtext: d.paid
                      })}

                    >
                      {d[column.id] || '--'}
                    </TableCell>
                  )
                }

              } else if (column.id === 'invoice_no') {
                if (this.state.editRow && this.state.editRow === d.id) {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      className={`app-table-cell-${d[column.id]}`}
                      align={rightAlign(column)}
                    >
                      <AppTextField
                        size="sm"
                        style={{minWidth: '125px'}}
                        label="Invoice No"
                        type="text"
                        name="invoice_no"
                        value={this.state.invoice_no}
                        onChange={(e) => this.handleChangeInline({invoice_no: e.target.value})}
                        margin="normal"
                      />
                      <div className='action-button-inline'>
                        <Button
                          onClick={() => this.saveCandidate(d)}
                          variant="contained"
                          color="primary"
                          disabled={this.state.loading}
                        >
                          Save
                          {this.state.loading &&
                          <CircularProgress className='loading-btn' color='#ff' size={22}/>}
                        </Button>
                        <Button
                          variant="contained"
                          style={{marginLeft: '10px'}}
                          onClick={() => this.setState({editRow: null, invoice_no: '', paidtext: 0})}
                          color="secondary"
                        >
                          Cancel
                        </Button>
                      </div>
                    </TableCell>
                  )

                } else {
                  return (
                    <TableCell
                      key={`${column.id}${d.id}`}
                      className={`app-table-cell-${d[column.id]}`}
                      align={rightAlign(column)}
                      onClick={() => this.setState({
                        editRow: d.id,
                        invoice_no: d.invoice_no,
                        paidtext: d.paid
                      })}
                    >
                      {d[column.id] || '--'}
                    </TableCell>
                  )
                }

              } else {
                return (
                  <TableCell
                    key={`${column.id}${d.id}`}
                    className={`app-table-cell-${d[column.id]}`}
                    align={rightAlign(column)}
                  >
                    {d[column.id]}
                  </TableCell>
                )
              }
            }
          })
        }
      </TableRow>
      {
        expand &&
        <TableRow>
          <TableCell colSpan={10}>
            <CandidateForm
              data={d}
              clients={page_data.clients || []}
              jobs={[]}
              users={page_data.users}
              leads_filter={false}
              contact_filter={0}
              account_managers={page_data.account_managers}
              third_parties={page_data.third_parties}
              isSubmittal={true}
              token={token}
              onCandidateUpdate={() => reloadCandidates(job_id, stage_id)}
              placementFilter={false}
              contractPlacementFilter={false}
              showHideApproval={false}
            />
          </TableCell>
        </TableRow>
      }
    </>
  )
}

export default CandidatesDataRow