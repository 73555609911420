import React, { useState } from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import AppTextField from '../common/AppTextField'
import Input from '@material-ui/core/Input'
import * as AppConstants from '../../constants/app'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { DialogTitle } from '@material-ui/core'
import CandidateModel from '../Candidates/CandidateModel'
import axios from 'axios'

const ApplyNowForm = ({ open, handleClose, saveCandidate, data, loading, authToken, openSnackBar }) => {
  const [state, setState] = useState({
    ...new CandidateModel(),
    name: '',
    resume_url_ajx: '',
    phone: '',
    email: '',
  })
  const [error, setError] = useState({})

  const validate = () => {
    let err = {}
    const { email, phone, resume_url_ajx, name } = state
    err = test('name', name, err)
    err = test('email', email, err)
    err = test('phone', phone, err)
    err = test('resume_url_ajx', resume_url_ajx, err)
    console.log('error', err)
    setError(err)
    return err
  }

  const test = (name, value, error) => {
    if (value === '') {
      return { ...error, [name]: 'This field is required' }
    }
    return error
  }

  const handleChangeCandidate = ({ target }) => {
    const { name, value } = target

    const _data = state

    if (name === 'resume_url_ajx') {
      openSnackBar('Please wait while uploading', 'success-bar')
      const dataf = new FormData()
      dataf.append('file', target.files[0])
      dataf.append('id', 0)

      var headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      }

      // this.setState({ isLoaded: false })
      // let th = this
      axios
        .post(`${AppConstants.API_BASE_URL}recruit/candidate/uploadingfile`, dataf, { headers: headers })
        .then((response) => {
          _data['resume_url'] = response.data.result
          _data['resume_url_ajx'] = response.data.result
          // th.setState({ candidate: _data })
          setState(_data)
          // th.setState({ isLoaded: true })
          openSnackBar('File uploaded successfully', 'success-bar')
        })
        .catch((error) => {
          console.log('errrr')

          let message = 'Please check your inputs, and try again'
          openSnackBar(message, 'error-bar')
          //this.resetState();
        })
    }

    setState({
      ...state,
      [name]: value,
    })
    if (value !== '') {
      delete error[name]
      setError({
        ...error,
      })
    }
  }

  const submitForm = () => {
    console.log('state', state)
    let err = validate()
    if (Object.values(err).length > 0) {
      console.log('hasError')
    } else {
      saveCandidate(state)
    }
  }

  return (
    <Dialog fullWidth="true" maxWidth="sm" open={open} aria-labelledby="simple-dialog-title">
      <DialogTitle onClose={handleClose} id="simple-dialog-title">
        Apply Now
      </DialogTitle>

      <DialogContent>
        <Table className="spplynow">
          <TableRow>
            <TableCell style={{ border: 'none' }}>
              <AppTextField
                style={{ margin: '0' }}
                helperText={error.name}
                error={!!error.name}
                fullWidth
                name="name"
                label="Name"
                onChange={handleChangeCandidate}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell style={{ border: 'none' }}>
              <AppTextField
                style={{ margin: '0' }}
                fullWidth
                name="title"
                label="Title"
                onChange={handleChangeCandidate}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell style={{ border: 'none' }}>
              <AppTextField
                style={{ margin: '0' }}
                helperText={error.email}
                error={!!error.email}
                fullWidth
                name="email"
                label="Email"
                onChange={handleChangeCandidate}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ border: 'none' }}>
              <AppTextField
                style={{ margin: '0' }}
                helperText={error.phone}
                error={!!error.phone}
                fullWidth
                name="phone"
                label="Phone"
                onChange={handleChangeCandidate}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={{}}>
              <Input
                helperText={error.resume_url_ajx}
                error={!!error.resume_url_ajx}
                label="Resume Url"
                type="file"
                name="resume_url_ajx"
                onChange={handleChangeCandidate}
              />

              {data.resume_url ? (
                <a download="true" target="_blank" href={`${AppConstants.SERVER_BASE_URL + data.resume_url}`}>
                  Download
                </a>
              ) : null}

              <TableRow>
                <TableCell style={{ border: 'none' }}>&nbsp;</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ display: 'none' }}>
              <AppTextField
                multiline={true}
                rows={5}
                fullWidth
                name="title"
                label="Cover latter"
                onChange={handleChangeCandidate}
              />
            </TableCell>
          </TableRow>
        </Table>
      </DialogContent>
      <div className="dialog-action-button">
        <Button onClick={submitForm} variant="contained" color="primary" label="SAVE" disabled={loading}>
          {loading ? 'Saving...' : 'Save'}
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary" autoFocus>
          Close
        </Button>
      </div>
    </Dialog>
  )
}

export default ApplyNowForm
