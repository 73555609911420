import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import AddCircle from '@material-ui/icons/AddCircle'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import Grid from '@material-ui/core/Grid'
import './index.scss'
import './candidate.scss'
import AppTextField from '../AppTextField'
import Button from '@material-ui/core/Button'
import AppButton from '../AppButton'
import moment from 'moment'
import AppSelect from '../AppSelect'
import axios from 'axios'
import * as AppConstants from '../../../constants/app'
import Snackbar from '@material-ui/core/Snackbar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinkedinImg from '../../../assets/img/linkedin.png'
import BlackLinkedinImg from '../../../assets/img/blacklinkedin.png'
import Alfredo from '../../../assets/img/alfredo.jpeg'
import Aleska from '../../../assets/img/aleska.jpeg'
import Martinique from '../../../assets/img/martinique.jpeg'
import Elizabeth from '../../../assets/img/Elizabeth.jpeg'
import Joumana from '../../../assets/img/joumana.jpeg'
import Jennifer from '../../../assets/img/jennifer.jpeg'
import Ana from '../../../assets/img/ana.jpeg'
import { Link } from 'react-router-dom'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'
import _ from 'lodash'

const headStyles = (theme) => ({
  expandCollapse: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  checkbox: {
    width: '20px',
    paddingRight: 0,
    paddingLeft: 0,
  },
})

class AppDataTableHead extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property)
  }

  //alert(this.props.search)

  expandCollapse = () => (this.props.hasChildRows ? <TableCell className={this.props.classes.expandCollapse} /> : null)

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, columns, search, classes, isActivityReport } =
      this.props

    return (
      <TableHead>
        <TableRow key="tableHead">
          <TableCell className={classes.checkbox}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount && rowCount > 0}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {!this.props.isActivityReport && <TableCell className={classes.checkbox}></TableCell>}
          {this.expandCollapse()}
          {columns.map(
            (column) => (
              <TableCell
                className="app-table-cell"
                key={`tableHead${column.id}`}
                align={column.numeric ? 'right' : 'left'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
                style={column.id == 'job_title' ? {} : {}}
              >
                <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    )
  }
}

AppDataTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}
AppDataTableHead = withStyles(headStyles)(AppDataTableHead)

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    minHeight: '64px',
    maxHeight: '64px',
    position: 'absolute',
    right: '30px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  title: {
    flex: '0 0 auto',
  },
})

let AppDataTableToolbar = (props) => {
  const {
    numSelected,
    classes,
    handleCandidateDeletion,
    handleDeleteConfirm,
    handleRestoreConfirm,
    handleArchiveConfirm,
    deleteConfirm,
    deleteCancel,
    isArchived,
    user_id,
  } = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {props.label}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <div>
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" onClick={handleCandidateDeletion}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Dialog
              open={deleteConfirm}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {isArchived ? 'Delete/Restore Candidate(s)' : 'Archive Candidate(s)'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {isArchived
                    ? 'Are you sure you want to delete/restore selected candidate(s)?'
                    : 'Are you sure you want to archive selected candidate(s)?'}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {isArchived ? (
                  <>
                    <Button onClick={handleRestoreConfirm} color="primary" autoFocus>
                      {'Restore'}
                    </Button>
                    {((user_id && user_id === 2) || user_id === 10) && (
                      <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                        {'Delete'}
                      </Button>
                    )}
                  </>
                ) : (
                  <Button onClick={handleArchiveConfirm} color="primary" autoFocus>
                    {'Archive'}
                  </Button>
                )}
                <Button onClick={deleteCancel} color="primary">
                  {'Cancel'}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div>
            <Grid container spacing={0} alignItems="flex-end">
              <Grid item>
                {props.search ? null : (
                  <AppTextField
                    size="lg"
                    style={{
                      marginBottom: '5px',
                      position: 'relative',
                      right: '100px',
                    }}
                    onChange={(e) => props.handleTableSearch(e.target.value)}
                    value={props.searchText}
                    label={'Search'}
                    type="search"
                    margin="normal"
                  />
                )}
              </Grid>

              {/*<Grid item>*/}
              {/*    <SearchIcon style={{marginBottom: '7px'}}/>*/}
              {/*</Grid>*/}
              {/*<Grid item>*/}
              {/*    <TextField*/}
              {/*        onChange={(e) => props.handleTableSearch(e.target.value)} value={props.searchText}*/}
              {/*        label={`Search ${props.label}`}*/}
              {/*        type="search"*/}
              {/*        margin="normal"*/}
              {/*    />*/}
              {/*</Grid>*/}
            </Grid>
          </div>
        )}
      </div>
    </Toolbar>
  )
}

AppDataTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
}

AppDataTableToolbar = withStyles(toolbarStyles)(AppDataTableToolbar)

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  actionButton: {
    cursor: 'pointer',
  },
  expandCollapse: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  checkbox: {
    width: '20px',
    paddingRight: 0,
    paddingLeft: 0,
  },
})

const minOffset = 0
const maxOffset = 10

class AppDataTable extends React.Component {
  constructor(props) {
    super(props)
    const thisYear = new Date().getFullYear()

    this.state = {
      buttonActive: 'buton-inactive-blue',
      selectValid: '',
      selectValidRange: '',
      selectValidLebel: '',
      showHideButtons: 'seletct-Type-range',
      showButtons: 'seletct-Type-range',
      selectValidRangeLebel: '',
      janClass: 'buton-inactive-blue',
      febClass: 'buton-inactive-blue',
      marchClass: 'buton-inactive-blue',
      aprilClass: 'buton-inactive-blue',
      mayClass: 'buton-inactive-blue',
      juneClass: 'buton-inactive-blue',
      julyClass: 'buton-inactive-blue',
      augustClass: 'buton-inactive-blue',
      septClass: 'buton-inactive-blue',
      octClass: 'buton-inactive-blue',
      novClass: 'buton-inactive-blue',
      decClass: 'buton-inactive-blue',
      order: 'asc',
      error: null,
      orderBy: 'calories',
      selected: [],
      opened: [],
      data: [],
      page: 0,
      rowsPerPage: 25,
      searchText: '',
      search: true,
      pagging: false,
      loading: false,
      editRow: '',
      invoice_no: '',
      paidtext: 0,
      delete_confirm: 0,
      snackBar: {
        open: false,
        message: '',
        backgroundColor: '#1976d2',
      },

      thisYear: thisYear,
      selectedYear: 0,
      date_type_filter: 0,
      focusedInput: null,
      report_month: '',
      selectedMonths: [],
      selectedMonthsRange: [],
      open_edit: false,
    }
  }

  componentWillMount() {}

  componentWillReceiveProps(nextProps, nextContext) {
    let { data, opened } = nextProps
    const _opened = [...opened, ...this.state.opened]
    this.setState({ data: data, rowsPerPage: data.length, opened: [] }, () =>
      this.handleTableSearch(this.state.searchText)
    )
  }
  setJan = (month = 'Jan', year = 2021, dateType = 0) => {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })
      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.janClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )

          this.setState({
            janClass: 'buton-active-pink',
          })
          console.log(this.state.selectedMonthsRange, '....!!!!!!!')
        } else if (this.state.janClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')

          var newRange = this.state.selectedMonthsRange.filter(function (el) {
            return el.first !== first
          })

          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            async () =>
              await this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          console.log(this.state.selectedMonthsRange, '111111111', first)
          this.setState({
            janClass: 'buton-inactive-blue',
          })
        }
      }
    }
  }
  setFeb = (month = 'Jan', year = 2021, dateType = 0) => {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })
      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.febClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )

          this.setState({
            febClass: 'buton-active-pink',
          })
        } else if (this.state.febClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          var newRange = this.state.selectedMonthsRange.filter(function (el) {
            return el.first !== first
          })

          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            febClass: 'buton-inactive-blue',
          })
        }
      }
    }
  }
  setMarch = (month = 'Jan', year = 2021, dateType = 0) => {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })
      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.marchClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )

          this.setState({
            marchClass: 'buton-active-pink',
          })
        } else if (this.state.marchClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          var newRange = this.state.selectedMonthsRange.filter(function (el) {
            return el.first !== first
          })

          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            marchClass: 'buton-inactive-blue',
          })
          // this.setMonths('', this.state.selectedYear)
        }
      }
    }
  }
  setApril = (month = 'Jan', year = 2021, dateType = 0) => {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })
      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.aprilClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )

          this.setState({
            aprilClass: 'buton-active-pink',
          })
        } else if (this.state.febClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          const newRange = this.state.selectedMonthsRange.filter(function (el) {
            return el.first !== first
          })

          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            aprilClass: 'buton-inactive-blue',
          })
        }
      }
    }
  }
  setMay = (month = 'Jan', year = 2021, dateType = 0) => {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })
      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.mayClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            mayClass: 'buton-active-pink',
          })
        } else if (this.state.mayClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          const newRange = this.state.selectedMonthsRange.filter(function (el) {
            return el.first !== first
          })

          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            mayClass: 'buton-inactive-blue',
          })
        }
      }
    }
  }
  setJune = (month = 'Jan', year = 2021, dateType = 0) => {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })
      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.juneClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            juneClass: 'buton-active-pink',
          })
        } else if (this.state.juneClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          const newRange = this.state.selectedMonthsRange.filter(function (el) {
            return el.first !== first
          })

          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            juneClass: 'buton-inactive-blue',
          })
        }
      }
    }
  }
  setJuly = (month = 'Jan', year = 2021, dateType = 0) => {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })
      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.julyClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            julyClass: 'buton-active-pink',
          })
        } else if (this.state.julyClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          const newRange = this.state.selectedMonthsRange.filter(function (el) {
            return el.first !== first
          })

          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            julyClass: 'buton-inactive-blue',
          })
        }
      }
    }
  }
  setAugust = (month = 'Jan', year = 2021, dateType = 0) => {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })
      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.augustClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            augustClass: 'buton-active-pink',
          })
        } else if (this.state.augustClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          const newRange = this.state.selectedMonthsRange.filter(function (el) {
            return el.first !== first
          })

          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            augustClass: 'buton-inactive-blue',
          })
        }
      }
    }
  }
  setSept = (month = 'Jan', year = 2021, dateType = 0) => {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })
      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.septClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            septClass: 'buton-active-pink',
          })
        } else if (this.state.septClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          const newRange = this.state.selectedMonthsRange.filter(function (el) {
            return el.first !== first
          })

          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            septClass: 'buton-inactive-blue',
          })
        }
      }
    }
  }
  setOct = (month = 'Jan', year = 2021, dateType = 0) => {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })
      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.octClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            octClass: 'buton-active-pink',
          })
        } else if (this.state.octClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          const newRange = this.state.selectedMonthsRange.filter(function (el) {
            return el.first !== first
          })

          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            octClass: 'buton-inactive-blue',
          })
        }
      }
    }
  }
  setNov = (month = 'Jan', year = 2021, dateType = 0) => {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })
      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.novClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            novClass: 'buton-active-pink',
          })
        } else if (this.state.novClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          const newRange = this.state.selectedMonthsRange.filter(function (el) {
            return el.first !== first
          })

          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            novClass: 'buton-inactive-blue',
          })
        }
      }
    }
  }
  setDec = (month = 'Jan', year = 2021, dateType = 0) => {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })
      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.decClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            decClass: 'buton-active-pink',
          })
        } else if (this.decClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          const newRange = this.state.selectedMonthsRange.filter(function (el) {
            return el.first !== first
          })

          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          this.setState({
            decClass: 'buton-inactive-blue',
          })
        }
      }
    }
  }
  getCurrentPageData = () => {
    const { data } = this.state
    if (data && data.length > 0) {
      const { rowsPerPage, page } = this.state
      return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }
    return []
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    const order = this.state.orderBy === property && this.state.order === 'desc' ? 'asc' : 'desc'

    this.setState({ order, orderBy }, () => {
      orderBy === 'start_date'
        ? this.setState({
            data: _.orderBy(this.state.data, (obj) => moment(obj.start_date), order),
          })
        : orderBy === 'placement_date'
        ? this.setState({
            data: _.orderBy(this.state.data, (obj) => moment(obj.placement_date), order),
          })
        : this.setState({
            data: _.sortBy(this.state.data, orderBy, order),
          })
    })
  }

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const _data = this.getCurrentPageData()
      this.setState({ selected: _data.map((n) => n.id) })
      return
    }
    this.setState({ selected: [] })
  }

  handleClick = (event, id, prop = 'selected', is_edit = false) => {
    const propState = this.state[prop]
    const selectedIndex = propState.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      console.log('taimoor1')
      if (is_edit) {
        this.setState({ open_edit: true })
      } else {
        this.setState({ open_edit: false })
      }
      newSelected = newSelected.concat(propState, id)
    } else if (selectedIndex === 0) {
      this.setState({ open_edit: false })

      newSelected = newSelected.concat(propState.slice(1))
      console.log('taimoor2')
    } else if (selectedIndex === propState.length - 1) {
      newSelected = newSelected.concat(propState.slice(0, -1))
      console.log('taimoor3')
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(propState.slice(0, selectedIndex), propState.slice(selectedIndex + 1))
      console.log('taimoor4')
    }
    this.setState({ [prop]: newSelected })
    console.log('taimoor: ' + prop, newSelected)
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1

  isOpened = (id) => this.state.opened.indexOf(id) !== -1

  rightAlign = (column) => (column.numeric ? 'right' : 'left')

  getChildRow = (isOpened, id) => {
    if (isOpened) {
      if (!this.state.open_edit) {
        const { childRows } = this.props
        const childRow = childRows.find((r) => r.id === id)
        console.log('childRow', childRow.element)
        return childRow ? childRow.element : null
      } else {
        const { childRowsEdit } = this.props
        const childRow = childRowsEdit.find((r) => r.id === id)
        console.log('childRow', childRow.element)
        return childRow ? childRow.element : null
      }
    }
    return null
  }

  handleChangeInline = (data) => {
    this.setState({
      ...data,
    })
  }

  get hasChildRows() {
    return !!this.props.childRows && this.props.childRows.length > 0
  }

  getCandidateName = (words) => {
    return words.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
  }

  handleTableSearch = (searchText) => {
    const { searchableColumns, data } = this.props
    this.setState({ searchText: searchText })
    if (searchText && data && data.length > 0) {
      const _data = []
      const searchField = searchableColumns || Object.keys(data[0])
      data.forEach((d) => {
        for (let k in d) {
          if (
            d.hasOwnProperty(k) &&
            searchField.indexOf(k) !== -1 &&
            d[k] &&
            d[k].toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          ) {
            _data.push(d)
            break
          }
        }
      })
      this.setState({ data: _data })
    } else {
      let _data = this.props.data.slice(0)
      this.setState({ data: _data })
    }
  }

  getExpandCollapse = (d) => {
    const {
      hasChildRows,
      props: { classes },
    } = this
    if (hasChildRows) {
      const { opened } = this.state
      // if (opened.indexOf((this.props.isActivityReport ? d.ac_log_id : d.id)) === -1 && !d.is_new) {
      if (opened.indexOf(d.id) === -1 && !d.is_new) {
        return (
          <TableCell className={classes.expandCollapse}>
            {/* {console.log("isActivityReport",this.props.isActivityReport)}
            {console.log("isActivityReport",d.id)} */}
            <AddCircle
              className={classes.actionButton}
              color="primary"
              // onClick={event => this.handleClick(event, (this.props.isActivityReport ? d.ac_log_id : d.id), 'opened')}
              onClick={(event) => this.handleClick(event, d.id, 'opened')}
            />
            {this.props.show_edit_button && (
              // <AppButton
              // label="Add"
              // color="secondary"
              // onClick={this.addNewCandidate}
              // icon={{ component: Add, position: 'right' }}/>
              <EditRoundedIcon
                className={classes.actionButton}
                color="primary"
                // onClick={event => this.handleClick(event, (this.props.isActivityReport ? d.ac_log_id : d.id), 'opened', true)}
                onClick={(event) => this.handleClick(event, d.id, 'opened', true)}
              />
            )}
          </TableCell>
        )
      } else {
        return (
          <TableCell className={classes.expandCollapse}>
            <RemoveCircle
              className={classes.actionButton}
              color="secondary"
              // onClick={event => this.handleClick(event, (this.props.isActivityReport ? d.ac_log_id : d.id), 'opened')}
              onClick={(event) => this.handleClick(event, d.id, 'opened')}
            />
          </TableCell>
        )
      }
    }
    return null
  }

  getEploymentType = (etype) => {
    if (etype == 1) {
      return 'Contract Job'
    } else if (etype == 2) {
      return `Contract To Hire`
    } else if (etype == 3) {
      return ' Permanent Job'
    } else {
      return ''
    }
  }

  getEploymentTypeC = (etype) => {
    if (etype == 1) {
      return 'c_type'
    } else if (etype == 2) {
      return 'ch_type'
    } else if (etype == 3) {
      return 'p_type'
    } else {
      return ''
    }
  }

  formatMoney = (amount, decimalCount = 0, decimal = '.', thousands = ',') => {
    try {
      decimalCount = Math.abs(decimalCount)
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount

      const negativeSign = amount < 0 ? '-' : ''

      let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
      let j = i.length > 3 ? i.length % 3 : 0

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : '')
      )
    } catch (e) {
      // console.log(e)
      return null
    }
  }

  async validate(data) {
    let errorText = 'This field is required'
    let candidate_name = ''
    let candidate_title = ''
    let location = ''
    let vacation = ''
    let communication = ''
    let availability = ''
    let current_salary = ''
    let pay_rate = ''
    let activity = ''
    let send_signature_html = ''

    if (!data.name && data.status === 2) {
      candidate_name = errorText
    }

    if (!data.name && data.status === 1) {
      candidate_name = errorText
    }

    if (!data.send_signature_html && data.status === 1) {
      send_signature_html = errorText
    }

    if (!data.job_title && data.status === 2) {
      candidate_title = errorText
    }

    if (!data.location && data.status === 2) {
      location = errorText
    }

    if (!data.vacation && data.status === 2) {
      vacation = errorText
    }

    if (!data.communication && data.status === 2) {
      communication = errorText
    }

    if (!data.availability && data.status === 2) {
      availability = errorText
    }

    // if (!data.salary && data.status===2) {
    //     current_salary = errorText;
    // }

    if (!data.activity && data.status === 2) {
      activity = errorText
    }

    // if (!data.pay_rate && data.status===2) {
    //     pay_rate = errorText;
    // }

    if (candidate_name || location || vacation || communication || availability || activity || send_signature_html) {
      await this.setState({
        candidate_name,
        candidate_title,
        location,
        vacation,
        communication,
        availability,
        activity,
        send_signature_html,
      })
      return false
    } else {
      return true
    }

    // if (!data.vacation) {
    //     console.log('vacation');
    //     this.setState({errors: {vacation: true}}, () => {
    //         this.setState({errors: {vacation: true}});
    //         console.log('vacation', this.state.errors.vacation);
    //     });
    // } else {
    //     this.setState({errors: {vacation: false}}, () => {
    //         this.setState({errors: {vacation: false}});
    //     });
    // }
    //
    //
    // if (!data.name) {
    //     this.setState({errors: {candidate_name: true}}, () => {
    //         this.setState({errors: {candidate_name: true}});
    //         console.log('candidate_name', this.state.errors.candidate_name);
    //     });
    // } else {
    //     this.setState({errors: {candidate_name: false}}, () => {
    //         this.setState({errors: {candidate_name: true}});
    //     });
    // }
    //
    // console.log('name', this.state.errors.candidate_name);
    // console.log('vacation', this.state.errors.vacation);

    // if (!data.job_title) {
    //     this.setState({errors: {candidate_title: true}});
    // } else {
    //     this.setState({errors: {candidate_title: false}});
    // }
  }

  closeSnackBar = () => {
    const { snackBar } = this.state
    snackBar.open = false
    this.setState({ snackBar: snackBar })
  }

  getSnackBarContent = () => {
    const {
      closeSnackBar,
      state: { snackBar },
    } = this
    return (
      <Snackbar
        className={snackBar.backgroundColor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={closeSnackBar}
        message={<span>{snackBar.message}</span>}
      />
    )
  }

  openSnackBar = (message, bgcolor) => {
    const { snackBar } = this.state
    snackBar.open = true
    snackBar.message = message
    snackBar.backgroundColor = bgcolor
    this.setState({ snackBar: snackBar })
  }

  handleSaveCandidateResponse = (response, is_new) => {
    this.setState({ isLoaded: true })
    // let message = 'Something went wrong, please contact admin';
    let message = 'There is some problem. Please contact your administrator'
    if (response.data && response.data['successful']) {
      message = response.data['message']
      const { onCandidateUpdate } = this.props
      if (onCandidateUpdate) setTimeout(() => onCandidateUpdate(response.data.result, is_new))
    }
    this.openSnackBar(message, 'success-bar')
  }

  async saveCandidate(data) {
    // this is for update paid and invoice_no inline
    const { paidtext, invoice_no } = this.state
    const _data = { ...data, paid: paidtext, paidtext: paidtext === 1 ? 'Yes' : 'No', invoice_no }
    const is_valid = await this.validate(_data)

    if (is_valid) {
      if (_data.recruiter === -1) _data.recruiter = ''
      if (_data.client === -1) _data.client = ''
      if (_data.job === -1) _data.job = ''
      _data.email = _data.email.filter((e) => e.value).map((e) => e.value)
      _data.phone = _data.phone.filter((p) => p.value).map((p) => p.value)
      if (_data.salary) {
        _data.salary = String(_data.salary).replace('$', '')
      }
      // if(!_data.job_id){
      //     _data.job_id = _data.client;
      // }
      _data.start_date = _data.start_date ? new Date(_data.start_date) : undefined
      _data.interview_date = _data.interview_date ? new Date(_data.interview_date) : undefined
      _data.placement_date = _data.placement_date ? new Date(_data.placement_date) : undefined
      _data.client_fee = _data.client_fee
      var headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${this.props.token}`,
      }

      var plainheaders = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Token ${this.props.token}`,
      }
      let datmsg = `paid updated from  ${data.paid} to ${_data.paid} and invoice number updated
        from ${data.invoice_no} to ${_data.invoice_no}
      .`
      let candidanm = data.name

      let candidateID = data.id
      let recruiter = data.recruiter_name

      let rev = { candidate: datmsg, id: candidateID, rec: recruiter, name: candidanm }
      if (_data.status_visa == '') {
        _data.status_visa = 1
      }
      if (_data.job == '') {
        delete _data.job
      }
      if (_data.client == '') {
        delete _data.client
      }
      this.setState({ loading: true })
      axios
        .post(`${AppConstants.API_BASE_URL}recruit/candidate/`, _data, { headers: headers })
        .then((response) => {
          if (candidateID) {
            axios
              .post(`${AppConstants.API_BASE_URL}recruit/candidate/logs/`, rev, { headers: headers })
              .then((response) => {
                this.handleSaveCandidateResponse(response, _data.is_new)
                this.props.updateCandidateData(_data, _data.id)
                this.setState({
                  editRow: null,
                  loading: false,
                })
              })
              .catch((error) => {
                this.setState({ isLoaded: true })
                let message = 'Please check your inputs, and try again logs'
                this.openSnackBar(message, 'error-bar')
                this.setState({ loading: false })
              })
          } else {
            this.handleSaveCandidateResponse(response, _data.is_new)
            this.resetState()
          }
        })
        .catch((error) => {
          this.setState({ isLoaded: true })
          let message = 'Please check your inputs, and try again'
          this.openSnackBar(message, 'error-bar')
          // this.resetState()
        })

      /*  const response = await axios.post(, ,
              );
            console.log('submitted data', );
            this.handleSaveCandidateResponse(response, _data.is_new);
            this.resetState();
            */
    }
  }

  //handle candidate deletion
  handleDeleteConfirm = async () => {
    const { reloadCandidate } = this.props

    //deleting the candidate
    let token = sessionStorage.getItem('token')
    var headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    }
    const id = this.state.selected
    const response = await axios.post(`${AppConstants.API_BASE_URL}recruit/candidate/archive-status/`, {
      headers: headers,
      params: {
        id: id,
        type: 'Delete',
      },
    })
    if (response.data && response.data['successful']) {
      this.setState({ delete_confirm: 0 })
      this.setState({ selected: [] })
      reloadCandidate()
    } else {
      this.setState({ error: 'Error while archiving candidate' })
    }
  }

  handleRestoreConfirm = async () => {
    const { reloadCandidate } = this.props

    //deleting the candidate
    let token = sessionStorage.getItem('token')
    var headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    }
    const id = this.state.selected
    const response = await axios.post(`${AppConstants.API_BASE_URL}recruit/candidate/archive-status/`, {
      headers: headers,
      params: {
        id: id,
        type: 'Restore',
      },
    })
    if (response.data && response.data['successful']) {
      this.setState({ delete_confirm: 0 })
      this.setState({ selected: [] })
      reloadCandidate()
    } else {
      this.setState({ error: 'Error while archiving candidate' })
    }
  }

  //handle candidate deletion
  handleArchiveConfirm = async () => {
    const { reloadCandidate } = this.props

    //deleting the candidate
    let token = sessionStorage.getItem('token')
    var headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    }
    const id = this.state.selected
    const response = await axios.post(`${AppConstants.API_BASE_URL}recruit/candidate/archive-status/`, {
      headers: headers,
      params: {
        id: id,
        type: 'Archive',
      },
    })
    if (response.data && response.data['successful']) {
      this.setState({ delete_confirm: 0 })
      this.setState({ selected: [] })
      reloadCandidate()
    } else {
      this.setState({ error: 'Error while archiving candidate' })
    }
  }

  handleClearDatesFilter = async () => {
    this.props.clearapplyFiltesDate()
    this.setState({
      selectedYear: 0,
      report_month: '',
      selectedMonths: [],
      date_type_filter: 0,
      janClass: 'buton-inactive-blue',
      febClass: 'buton-inactive-blue',
      marchClass: 'buton-inactive-blue',
      aprilClass: 'buton-inactive-blue',
      mayClass: 'buton-inactive-blue',
      juneClass: 'buton-inactive-blue',
      julyClass: 'buton-inactive-blue',
      augustClass: 'buton-inactive-blue',
      septClass: 'buton-inactive-blue',
      octClass: 'buton-inactive-blue',
      novClass: 'buton-inactive-blue',
      decClass: 'buton-inactive-blue',
    })
  }
  handleDeleteCancel = async () => {
    this.setState({ delete_confirm: 0 })
  }

  getPotentialRevValue = (d) => {
    const value = ((parseInt(d.min_salary) + parseInt(d.max_salary)) / parseInt(d.job_no_of_jobs)) * parseInt(d.job_fee)
    if (isNaN(value)) {
      return 0
    }
    return parseFloat(value).toFixed(2)
  }

  handleCandidateDeletion = async () => {
    this.setState({ delete_confirm: 1 })

    //  const {reloadCandidate} = this.props;
    //
    //  //deleting the candidate
    //  let token = sessionStorage.getItem('token');
    //  var headers = {
    //    'Content-Type': 'application/json',
    //    Authorization: token,
    //  }
    // const id = this.state.selected;
    //  const response = await axios.post(
    //    `${AppConstants.API_BASE_URL}recruit/candidate/archive-status/`,
    //    {
    //      headers: headers,
    //      params: {
    //        "id":id,
    //        "type":"Archive"
    //      },
    //    }
    //  )
    //  if (response.data && response.data['successful']) {
    //    this.setState({selected:[]});
    //    reloadCandidate();
    //  }else{
    //    this.setState({error:"Error while archiving candidate"});
    //  }
  }

  onHandleChange = (evt) => {
    // Handle Change Here
    if (evt.target.value === 0 || evt.target.value === '0') {
      // this.handleClearDatesFilter()
    } else {
      console.log(evt.target.value, ',,,,,,,,,,,,,,')
      this.setState({ selectedYear: evt.target.value })
      this.props.clearapplyFiltesDate()
      this.setState({
        showHideButtons: '',
        janClass: 'buton-inactive-blue',
        febClass: 'buton-inactive-blue',
        marchClass: 'buton-inactive-blue',
        aprilClass: 'buton-inactive-blue',
        mayClass: 'buton-inactive-blue',
        juneClass: 'buton-inactive-blue',
        julyClass: 'buton-inactive-blue',
        augustClass: 'buton-inactive-blue',
        septClass: 'buton-inactive-blue',
        octClass: 'buton-inactive-blue',
        novClass: 'buton-inactive-blue',
        decClass: 'buton-inactive-blue',
      })
      this.setState({
        report_month: 'Jan',
        selectedMonths: ['Jan'],
        selectedMonthsRange: [],
      })
    }
  }

  onMonthChange = (evt) => {
    // Handle Change Here
    const val = evt.target.value
    this.setState({
      report_month: val,
      selectedMonths: [...this.state.selectedMonths, val],
    })

    // if (this.state.selectedYear === 0 || this.state.selectedYear === 0) {
    //   this.setMonths('', this.state.selectedYear)
    // } else {
    //   this.setMonths(val, this.state.selectedYear)
    // }
  }

  onDateTypeFilterChange = (evt) => {
    // Handle Change Here
    this.setState({ date_type_filter: evt.target.value })
    this.setState({ showButtons: 'right-action-bottom' })
    this.props.clearapplyFiltesDate()
    this.setState({
      janClass: 'buton-inactive-blue',
      febClass: 'buton-inactive-blue',
      marchClass: 'buton-inactive-blue',
      aprilClass: 'buton-inactive-blue',
      mayClass: 'buton-inactive-blue',
      juneClass: 'buton-inactive-blue',
      julyClass: 'buton-inactive-blue',
      augustClass: 'buton-inactive-blue',
      septClass: 'buton-inactive-blue',
      octClass: 'buton-inactive-blue',
      novClass: 'buton-inactive-blue',
      decClass: 'buton-inactive-blue',
    })
  }

  splitSkills(skils, index) {
    if (skils) {
      const array = skils.split(',')
      const resultArray = array.slice(0, index)
      // const resultString = resultArray.join(",");
      return resultArray.join(',')
    } else {
      return ''
    }
  }

  splitNotes(maxLength = 25, str) {
    let result = ''
    if (str) {
      result = str.substring(0, maxLength) + '...'
    }
    return result
  }
  removeFromArray = (arr, first) => {
    const objWithIdIndex = arr.findIndex((obj) => obj.first === first)
    arr.splice(objWithIdIndex, 1)

    return arr
  }
  setMonths(month = 'Jan', year = 2021, dateType = 0) {
    if (this.state.selectedYear && this.state.date_type_filter > 0) {
      this.setState({ selectValidRange: '' })
      this.setState({ selectValid: '' })
      this.setState({ selectValidLebel: '' })
      this.setState({ selectValidLebel: '' })

      if (month === '' && year === '') {
        this.props.customSetMultipleDate(this.state.selectedMonthsRange, dateType)
      } else {
        if (this.state.febClass === 'buton-inactive-blue') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          this.setState(
            {
              selectedMonthsRange: [...this.state.selectedMonthsRange, { first: first, last: last }],
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          console.log(first, '3333333333333333', last)
        }
        if (this.state.febClass === 'buton-active-pink') {
          this.setState({
            selectedYear: year,
            report_month: month,
            selectedMonths: [...this.state.selectedMonths, month],
          })
          const my_date = new Date(year + ', ' + month)
          const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
          const first = moment(first_date).format('YYYY-MM-DD h:mm:ss')
          const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
          const last = moment(last_date).format('YYYY-MM-DD h:mm:ss')
          const indexOfObject = this.state.selectedMonthsRange.findIndex((object) => {
            return object.first === first
          })
          const newRange = this.state.selectedMonthsRange.splice(indexOfObject, 1)
          this.setState(
            {
              selectedMonthsRange: newRange,
            },
            () => this.props.customSetMultipleDate(this.state.selectedMonthsRange, this.state.date_type_filter)
          )
          console.log(this.state.selectedMonthsRange, '3333333333333333')
          // this.props.customSetMultipleDate(
          //   this.removeFromArray(this.state.selectedMonthsRange, { first: first, last: last }),
          //   this.state.date_type_filter
          // )
        }
      }
    }

    if (!this.state.selectedYear) {
      this.setState({ selectValid: 'emptySelect' })
      this.setState({ selectValidLebel: 'errorLebeling' })
    }
    if (this.state.date_type_filter < 1) {
      this.setState({ selectValidRange: 'emptySelect' })
      this.setState({ selectValidRangeLebel: 'errorLebeling' })
    } else {
      this.setState((state) => ({ error: !state.selectedYear || state.selectedYear === '' }))
      this.setState((state) => ({ error: state.date_type_filter < 1 }))
    }
  }

  render() {
    const {
      rightAlign,
      getCandidateName,
      props,
      getChildRow,
      handleTableSearch,
      hasChildRows,
      getExpandCollapse,
      getCurrentPageData,
    } = this
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      searchText,
      data,
      thisYear,
      selectedYear,
      report_month,
      date_type_filter,
      selectedMonths,
    } = this.state

    const options = []
    options.push({ value: 0, label: '' })
    for (let i = minOffset; i <= maxOffset; i++) {
      const year = thisYear - i
      options.push({ value: year, label: year })
    }
    const date_type_filter_list = [
      { value: 0, label: '' },
      { value: 1, label: 'Start Date' },
      { value: 2, label: 'Placement Date' },
    ]
    const { classes, columns, search, pagging } = props
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)
    const data_length = data.length
    // console.log("taimoor: data", data);
    return (
      <Paper className={classes.root}>
        {this.getSnackBarContent()}
        <div className="head-action">
          <div className="left-action">
            <div className="left-action-left">
              <AppDataTableToolbar
                searchText={searchText}
                search={search}
                numSelected={selected.length}
                label={props.label}
                handleTableSearch={handleTableSearch}
                handleCandidateDeletion={this.handleCandidateDeletion}
                handleDeleteConfirm={this.handleDeleteConfirm}
                handleRestoreConfirm={this.handleRestoreConfirm}
                handleArchiveConfirm={this.handleArchiveConfirm}
                deleteConfirm={this.state.delete_confirm}
                deleteCancel={this.handleDeleteCancel}
                isArchived={props.isArchived}
                user_id={props.user_id}
              />
              <h3 style={{ marginLeft: 25, marginTop: -17 }}>
                {' '}
                {props.totalText}{' '}
                <small>
                  <strong>{props.totalCandidates}</strong>
                </small>
              </h3>

              {props.showtotalfee && (
                <h3 style={{ marginLeft: 25, color: 'green' }}>
                  Total Revenue
                  <strong> ${this.formatMoney(props.totalfee) || props.totalfee}</strong>
                </h3>
              )}
              {props.showTotalRcCommission && (
                <h3 style={{ marginLeft: 25, color: 'green' }}>
                  Total RC Commission
                  <strong> ${this.formatMoney(props.totalRcCommission) || props.totalRcCommission}</strong>
                </h3>
              )}
            </div>
            {props.showtotalfee && (
              <div class="left-action-right">
                {/* <DateRangePicker
                isRTL={false}
                startDate={moment(this.props.filterDatefrom) || null} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={moment(this.props.filterDateto) || null} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={props.setDates} // PropTypes.func.isRequired,
                isOutsideRange={function noRefCheck() { }}
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              /> */}
                <AppButton label="Clear" color="primary" onClick={this.handleClearDatesFilter} />
              </div>
            )}
          </div>

          <div className="right-action">
            {!props.showtotalfee && !this.props.showDateFilter && (
              <div className="right-action-search">{this.props.rightAction || null}</div>
            )}
            {props.showtotalfee && (
              <>
                <div class="right-action-top">
                  <AppSelect
                    size="sm"
                    id="years"
                    label="Selected Year"
                    value={selectedYear}
                    name="years-selected"
                    onChange={(e) => this.onHandleChange(e)}
                    items={options}
                    selectClasses={this.state.selectValid}
                    lebelClass={this.state.selectValidLebel}
                  />
                  <AppSelect
                    className="seletct-Type-range"
                    size="sm"
                    id="date_type_filter_id"
                    label="Select Date Type Filter"
                    value={date_type_filter}
                    name="date_type_filter_name"
                    onChange={(e) => this.onDateTypeFilterChange(e)}
                    items={date_type_filter_list}
                    selectClasses={this.state.selectValidRange}
                    lebelClass={this.state.selectValidRangeLebel}
                    hideShowButtons={this.state.showHideButtons}
                  />
                  <div className="right-action-search">{this.props.rightAction || null}</div>
                </div>
                <div class={this.state.showButtons}>
                  <AppButton
                    label="JAN"
                    color={selectedMonths.includes('Jan') ? 'secondary' : 'primary'}
                    onClick={() => {
                      // this.setMonths()
                      this.setJan('Jan', this.state.selectedYear)
                    }}
                    className={this.state.janClass}
                  />
                  <AppButton
                    label="FEB"
                    color={selectedMonths.includes('Feb') ? 'secondary' : 'primary'}
                    onClick={() => {
                      // this.setMonths()
                      this.setFeb('Feb', this.state.selectedYear)
                    }}
                    className={this.state.febClass}
                  />
                  <AppButton
                    label="MAR"
                    color={selectedMonths.includes('Mar') ? 'secondary' : 'primary'}
                    onClick={() => {
                      this.setMarch('Mar', this.state.selectedYear)
                    }}
                    className={this.state.marchClass}
                  />
                  <AppButton
                    label="APR"
                    color={selectedMonths.includes('Apr') ? 'secondary' : 'primary'}
                    onClick={() => {
                      this.setApril('Apr', this.state.selectedYear)
                    }}
                    className={this.state.aprilClass}
                  />
                  <AppButton
                    label="MAY"
                    color={selectedMonths.includes('May') ? 'secondary' : 'primary'}
                    onClick={() => {
                      this.setMay('May', this.state.selectedYear)
                    }}
                    className={this.state.mayClass}
                  />
                  <AppButton
                    label="JUN"
                    color={selectedMonths.includes('Jun') ? 'secondary' : 'primary'}
                    onClick={() => {
                      this.setJune('Jun', this.state.selectedYear)
                    }}
                    className={this.state.juneClass}
                  />
                  <AppButton
                    label="JUL"
                    color={selectedMonths.includes('Jul') ? 'secondary' : 'primary'}
                    onClick={() => {
                      this.setJuly('Jul', this.state.selectedYear)
                    }}
                    className={this.state.julyClass}
                  />
                  <AppButton
                    label="AUG"
                    color={selectedMonths.includes('Aug') ? 'secondary' : 'primary'}
                    onClick={() => {
                      this.setAugust('Aug', this.state.selectedYear)
                    }}
                    className={this.state.augustClass}
                  />
                  <AppButton
                    label="SEP"
                    color={selectedMonths.includes('Sep') ? 'secondary' : 'primary'}
                    onClick={() => {
                      this.setSept('Sep', this.state.selectedYear)
                    }}
                    className={this.state.septClass}
                  />
                  <AppButton
                    label="OCT"
                    color={selectedMonths.includes('Oct') ? 'secondary' : 'primary'}
                    onClick={() => {
                      this.setOct('Oct', this.state.selectedYear)
                    }}
                    className={this.state.octClass}
                  />
                  <AppButton
                    label="NOV"
                    color={selectedMonths.includes('Nov') ? 'secondary' : 'primary'}
                    onClick={() => {
                      this.setNov('Nov', this.state.selectedYear)
                    }}
                    className={this.state.novClass}
                  />
                  <AppButton
                    label="DEC"
                    color={selectedMonths.includes('Dec') ? 'secondary' : 'primary'}
                    onClick={() => {
                      this.setDec('Dec', this.state.selectedYear)
                    }}
                    className={this.state.decClass}
                  />
                </div>
              </>
            )}

            {this.props.showDateFilter && (
              <div class="activity-action">
                <AppSelect
                  size="sm"
                  id="years"
                  label="Selected Year"
                  value={selectedYear}
                  name="years-selected"
                  onChange={(e) => this.onHandleChange(e)}
                  items={options}
                />
                <AppSelect
                  size="sm"
                  id="months"
                  label="Selected Month"
                  value={report_month}
                  name="months-selected"
                  disabled={parseInt(selectedYear) > 0 ? false : true}
                  onChange={(e) => this.onMonthChange(e)}
                  items={[
                    // { value: "", label: "None" },
                    { value: 'Jan', label: 'January' },
                    { value: 'Feb', label: 'February' },
                    { value: 'Mar', label: 'March' },
                    { value: 'Apr', label: 'April' },
                    { value: 'May', label: 'May' },
                    { value: 'Jun', label: 'June' },
                    { value: 'Jul', label: 'July' },
                    { value: 'Aug', label: 'August' },
                    { value: 'Sep', label: 'september' },
                    { value: 'Oct', label: 'October' },
                    { value: 'Nov', label: 'November' },
                    { value: 'Dec', label: 'December' },
                  ]}
                />
                <div className="right-action-search">{this.props.rightAction || null}</div>
              </div>
            )}
          </div>
        </div>

        <div className={classes.tableWrapper} style={{ overflowX: 'visible' }}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <AppDataTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={getCurrentPageData().length}
              columns={columns}
              hasChildRows={hasChildRows}
              isActivityReport={props.isActivityReport}
            />
            <TableBody>
              {data.length > 0 ? (
                <>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((d, i) => {
                    // const isSelected = this.isSelected(this.props.isActivityReport ? d.ac_log_id : d.id);
                    // const isOpened = !d.is_new ? this.isOpened(this.props.isActivityReport ? d.ac_log_id : d.id) : true;
                    const isSelected = this.isSelected(d.id)
                    const isOpened = !d.is_new ? this.isOpened(d.id) : true
                    return (
                      <React.Fragment>
                        <TableRow
                          hover
                          role="checkbox"
                          className={`table-row-custom`}
                          aria-checked={isSelected}
                          tabIndex={-1}
                          // key={this.props.isActivityReport ? d.ac_log_id : d.id}
                          key={d.id}
                          selected={isSelected}
                        >
                          <TableCell className={classes.checkbox}>
                            <Checkbox checked={isSelected} onClick={(event) => this.handleClick(event, d.id)} />
                          </TableCell>

                          {getExpandCollapse(d)}
                          {!this.props.isActivityReport && (
                            <TableCell>
                              <Avatar alt={d.name} style={{ height: '60px', width: '60px' }} src={d.image_url} />
                            </TableCell>
                          )}
                          {columns.map((column) => {
                            if (d[column.id] === 'Leads') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  style={{ color: 'chocolate', fontWeight: 'bold' }}
                                  align={rightAlign(column)}
                                >
                                  {d[column.id]}
                                </TableCell>
                              )
                            } else if (column.id === 'name') {
                              if (d.withdraw == 1) {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    style={{ color: 'red', fontWeight: 'bold' }}
                                    align={rightAlign(column)}
                                  >
                                    {d[column.id]}{' '}
                                  </TableCell>
                                )
                              } else if (d.paid === 1 && this.props.showtotalfee) {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    style={{ color: 'green', fontWeight: 'bold' }}
                                    align={rightAlign(column)}
                                  >
                                    {d[column.id]}
                                  </TableCell>
                                )
                              } else {
                                return (
                                  <TableCell key={`${column.id}${d.id}`} align={rightAlign(column)}>
                                    {getCandidateName(d[column.id])}{' '}
                                  </TableCell>
                                )
                              }
                            } else if (d[column.id] === 'Submittals') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  style={{ color: 'orange', fontWeight: 'bold' }}
                                  align={rightAlign(column)}
                                >
                                  {d[column.id]}
                                </TableCell>
                              )
                            } else if (d[column.id] === 'Sendout') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  style={{ color: 'green', fontWeight: 'bold' }}
                                  align={rightAlign(column)}
                                >
                                  {d[column.id]}
                                </TableCell>
                              )
                            } else if (d[column.id] === 'Interview') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  style={{ color: 'Tomato', fontWeight: 'bold' }}
                                  align={rightAlign(column)}
                                >
                                  {d[column.id]}
                                </TableCell>
                              )
                            } else if (d[column.id] === 'Hired/Placement') {
                              if (props.applicantFilter === true) {
                                return <TableCell></TableCell>
                              } else {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    style={{ color: '#90EE90', fontWeight: 'bold' }}
                                    align={rightAlign(column)}
                                  >
                                    {d[column.id]}
                                  </TableCell>
                                )
                              }
                            } else if (d[column.id] === 'Rejected') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  style={{ color: 'violet', fontWeight: 'bold' }}
                                  align={rightAlign(column)}
                                >
                                  {d[column.id]}
                                </TableCell>
                              )
                            } else if (d[column.id] === 'On Hold') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  style={{
                                    color: 'SlateBlue',
                                    fontWeight: 'bold',
                                  }}
                                  align={rightAlign(column)}
                                >
                                  {d[column.id]}
                                </TableCell>
                              )
                              if (d[column.id] === 'Leads') {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    style={{
                                      color: 'chocolate',
                                      fontWeight: 'bold',
                                    }}
                                    align={rightAlign(column)}
                                  >
                                    {d[column.id]}
                                  </TableCell>
                                )
                              }
                            } else if (d[column.id] === 'Closed') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  style={{
                                    color: 'MediumSeaGree',
                                    fontWeight: 'bold',
                                  }}
                                  align={rightAlign(column)}
                                >
                                  {d[column.id]}
                                </TableCell>
                              )
                            } else if (column.id === 'pay_rate') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  ${d['pay_rate']}/hour
                                </TableCell>
                              )
                            } else if (column.id === 'net_revenue') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  ${d['net_revenue']}
                                </TableCell>
                              )
                            } else if (column.id === 'job_title') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  style={{}}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  {d['job_title']}
                                </TableCell>
                              )
                            } else if (column.id === 'bill_rate') {
                              let lab = ''
                              if (data.bill_rate_manula === 1) {
                                lab = '*'
                              } else {
                                lab = ''
                              }
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  <span color="secondary">{lab}</span> ${d['bill_rate']}
                                </TableCell>
                              )
                            } else if (column.id === 'products') {
                              let prod = d['products']
                              let res = ''
                              if (prod) res = prod.substring(0, 50) + '...'
                              return (
                                <TableCell
                                  title={prod}
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  {' '}
                                  {res}
                                </TableCell>
                              )
                            } else if (column.id === 'contact') {
                              let res = 0
                              if (d['contact']) res = d['contact'].length
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  {' '}
                                  {res}
                                </TableCell>
                              )
                            } else if (column.id === 'no_of_jobs') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  <a href={'/jobs/' + d.id}>{d.no_of_jobs}</a>
                                </TableCell>
                              )
                            } else if (column.id === 'contact_obj') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  <a href={'/contacts/' + d.id}>{d.contact_obj}</a>
                                </TableCell>
                              )
                            } else if (column.id === 'potential_rev') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  {this.getPotentialRevValue(d)}
                                </TableCell>
                              )
                            } else if (column.id === 'candidates') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  <a href={'/candidates/' + d.id}>{d.candidates}</a>
                                </TableCell>
                              )
                            } else if (column.id === 'total_pipelines') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  <a href={'/candidates/' + d.id + '?status=9'}>{d.total_pipelines}</a>
                                </TableCell>
                              )
                            } else if (column.id === 'total_internal_submittals') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  <a href={'/candidates/' + d.id + '?substatus=6'}>{d.total_internal_submittals}</a>
                                </TableCell>
                              )
                            } else if (column.id === 'total_client_submittals') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  <a href={'/candidates/' + d.id + '?substatus=9'}>{d.total_client_submittals}</a>
                                </TableCell>
                              )
                            } else if (column.id === 'total_interviews') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  <a href={'/candidates/' + d.id + '?substatus=10'}>{d.total_interviews}</a>
                                </TableCell>
                              )
                            } else if (column.id === 'company_infos') {
                              if (d.company_infos) {
                                var y = d.company_infos.split(' ').slice(0, 3).join('+')
                              } else {
                                var y = ''
                              }
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                  title="{d.company_infos}"
                                >
                                  {y}
                                </TableCell>
                              )
                            } else if (column.id === 'job_type') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]} ` + this.getEploymentTypeC(d.job_type)}
                                  align={rightAlign(column)}
                                >
                                  {this.getEploymentType(d.job_type)}
                                </TableCell>
                              )
                            } else if (column.id === 'action') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  <Button onClick={() => this.props.editRow(d)} size="sm" label="Edit" color="primary">
                                    Edit
                                  </Button>
                                </TableCell>
                              )
                            } else if (column.id === 'source') {
                              if (d['source'] === '') {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    className={`app-table-cell-${d[column.id]}`}
                                    align={rightAlign(column)}
                                  >
                                    Via Online Application
                                  </TableCell>
                                )
                              } else if (d['source'] == 'Linkedin') {
                                return (
                                  <TableCell>
                                    <a href={d.linkedin_url} target={'_blank'}>
                                      <Avatar
                                        alt={d.name}
                                        style={{ height: '60px', width: '60px' }}
                                        src={LinkedinImg}
                                      />
                                    </a>
                                  </TableCell>
                                )
                              } else if (
                                d['source'] == 'Job Portal' &&
                                d.linkedin_url != '' &&
                                d.linkedin_url != null
                              ) {
                                return (
                                  <TableCell>
                                    <a href={d.linkedin_url} target={'_blank'}>
                                      <Avatar
                                        alt={d.name}
                                        style={{ height: '60px', width: '60px' }}
                                        src={BlackLinkedinImg}
                                      />
                                    </a>
                                  </TableCell>
                                )
                              } else {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    className={`app-table-cell-${d[column.id]}`}
                                    align={rightAlign(column)}
                                  >
                                    {d['source']}
                                  </TableCell>
                                )
                              }
                            } else if (column.id === 'salary') {
                              let pay_rate
                              let salary
                              if (d['pay_rate'] === null) {
                                pay_rate = ''
                              } else {
                                pay_rate = '$' + d['pay_rate'] + '/hour'
                              }

                              if (d['salary'] === null) {
                                salary = ''
                              } else {
                                salary = '$' + d['salary']
                              }

                              if (d['employment_type'] == 1) {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    className={`app-table-cell-${d[column.id]}`}
                                    align={rightAlign(column)}
                                  >
                                    {pay_rate}
                                  </TableCell>
                                )
                              } else {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    className={`app-table-cell-${d[column.id]}`}
                                    align={rightAlign(column)}
                                  >
                                    {salary}
                                  </TableCell>
                                )
                              }
                            } else if (column.id === 'client_fee_percentage') {
                              if (!d.client_fee) {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    className={`app-table-cell-${d[column.id]}`}
                                    align={rightAlign(column)}
                                  >
                                    {d['client_fee_percentage']}
                                  </TableCell>
                                )
                              } else {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    className={`app-table-cell-${d[column.id]}`}
                                    align={rightAlign(column)}
                                  >
                                    {d['client_fee']}
                                  </TableCell>
                                )
                              }
                            } else if (column.id === 'client_fee_amount') {
                              let fee

                              if (d.withdraw == 1) {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    className={`app-table-cell-${d[column.id]}`}
                                    align={rightAlign(column)}
                                  >
                                    0
                                  </TableCell>
                                )
                              } else {
                                if (!d.client_fee) {
                                  fee = (d['salary'] * d['client_fee_percentage']) / 100
                                  return (
                                    <TableCell
                                      key={`${column.id}${d.id}`}
                                      className={`app-table-cell-${d[column.id]}`}
                                      align={rightAlign(column)}
                                    >
                                      {fee}
                                    </TableCell>
                                  )
                                } else {
                                  fee = (d['salary'] * d['client_fee']) / 100
                                  return (
                                    <TableCell
                                      key={`${column.id}${d.id}`}
                                      className={`app-table-cell-${d[column.id]}`}
                                      align={rightAlign(column)}
                                    >
                                      {fee}
                                    </TableCell>
                                  )
                                }
                              }
                            } else if (column.id === 'paidtext') {
                              if (this.state.editRow && this.state.editRow === d.id) {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    className={`app-table-cell-${d[column.id]}`}
                                    align={rightAlign(column)}
                                    onClick={() => this.setState({ editRow: d.id })}
                                  >
                                    <AppSelect
                                      style={{ minWidth: '100px', marginTop: '5px' }}
                                      id="paidtext"
                                      label="paid"
                                      value={this.state.paidtext}
                                      name="paidtext"
                                      onChange={(e) => this.handleChangeInline({ paidtext: e.target.value })}
                                      items={[
                                        { value: 1, label: ' Yes' },
                                        { value: 0, label: 'No' },
                                      ]}
                                    />
                                  </TableCell>
                                )
                              } else {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    className={`app-table-cell-${d[column.id]}`}
                                    align={rightAlign(column)}
                                    onClick={() =>
                                      this.setState({
                                        editRow: d.id,
                                        invoice_no: d.invoice_no,
                                        paidtext: d.paid,
                                      })
                                    }
                                  >
                                    {d[column.id] || '--'}
                                  </TableCell>
                                )
                              }
                            } else if (column.id === 'invoice_no') {
                              if (this.state.editRow && this.state.editRow === d.id) {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    className={`app-table-cell-${d[column.id]}`}
                                    align={rightAlign(column)}
                                  >
                                    <AppTextField
                                      size="sm"
                                      style={{ minWidth: '125px' }}
                                      label="Invoice No"
                                      type="text"
                                      name="invoice_no"
                                      value={this.state.invoice_no}
                                      onChange={(e) => this.handleChangeInline({ invoice_no: e.target.value })}
                                      margin="normal"
                                    />
                                    <div className="action-button-inline">
                                      <Button
                                        onClick={() => this.saveCandidate(d)}
                                        variant="contained"
                                        color="primary"
                                        disabled={this.state.loading}
                                      >
                                        Save
                                        {this.state.loading && (
                                          <CircularProgress className="loading-btn" color="#ff" size={22} />
                                        )}
                                      </Button>
                                      <Button
                                        variant="contained"
                                        style={{ marginLeft: '10px' }}
                                        onClick={() => this.setState({ editRow: null, invoice_no: '', paidtext: 0 })}
                                        color="secondary"
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </TableCell>
                                )
                              } else {
                                return (
                                  <TableCell
                                    key={`${column.id}${d.id}`}
                                    className={`app-table-cell-${d[column.id]}`}
                                    align={rightAlign(column)}
                                    onClick={() =>
                                      this.setState({
                                        editRow: d.id,
                                        invoice_no: d.invoice_no,
                                        paidtext: d.paid,
                                      })
                                    }
                                  >
                                    {d[column.id] || '--'}
                                  </TableCell>
                                )
                              }
                            } else if (column.id === 'recruiter_name') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  {d['recruiter_name'] === 'Jennifer' ? (
                                    <Avatar
                                      alt={d.recruiter_name}
                                      src={Jennifer}
                                      style={{ height: '60px', width: '60px' }}
                                    />
                                  ) : d['recruiter_name'] === 'Aleska' ? (
                                    <Avatar
                                      alt={d.recruiter_name}
                                      src={Aleska}
                                      style={{ height: '60px', width: '60px' }}
                                    />
                                  ) : d['recruiter_name'] === 'Alfredo' ? (
                                    <Avatar
                                      alt={d.recruiter_name}
                                      src={Alfredo}
                                      style={{ height: '60px', width: '60px' }}
                                    />
                                  ) : d['recruiter_name'] === 'Elizabeth' ? (
                                    <Avatar
                                      alt={d.recruiter_name}
                                      src={Elizabeth}
                                      style={{ height: '60px', width: '60px' }}
                                    />
                                  ) : d['recruiter_name'] === 'Martinique' ? (
                                    <Avatar
                                      alt={d.recruiter_name}
                                      src={Martinique}
                                      style={{ height: '60px', width: '60px' }}
                                    />
                                  ) : d['recruiter_name'] === 'Vanshika' ? (
                                    <Avatar
                                      alt={d.recruiter_name}
                                      src={Ana}
                                      style={{ height: '60px', width: '60px' }}
                                    />
                                  ) : d['recruiter_name'] === 'Joumana' ? (
                                    <Avatar
                                      alt={d.recruiter_name}
                                      src={Joumana}
                                      style={{ height: '60px', width: '60px' }}
                                    />
                                  ) : (
                                    <Avatar
                                      alt={d.recruiter_name}
                                      src={
                                        'https://media-exp1.licdn.com/dms/image/C4D0BAQHgOORR5g-Ahw/company-logo_200_200/0/1519862906937?e=1619049600&v=beta&t=3FY10TedKl3xt_vaY-BA9XQIBWV-xU2RBkcMUl4ILPc'
                                      }
                                      style={{ height: '60px', width: '60px' }}
                                    />
                                  )}
                                </TableCell>
                              )
                            } else if (column.id === 'skill_value') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  <Tooltip
                                    title={
                                      <span
                                        class="tooltiptext"
                                        style={{
                                          color: 'white',
                                          size: '15px !important',
                                          fontSize: '15px !important',
                                        }}
                                      >
                                        {d[column.id]}
                                      </span>
                                    }
                                    style={{ size: '15px' }}
                                  >
                                    <div>{this.splitSkills(d[column.id], 2)}</div>
                                  </Tooltip>
                                </TableCell>
                              )
                            } else if (column.id === 'notes') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  <Tooltip
                                    title={
                                      <h5 class="tooltiptext" style={{ color: 'white' }}>
                                        {d[column.id]}
                                      </h5>
                                    }
                                    style={{ size: '15px' }}
                                  >
                                    <div>{this.splitNotes(25, d[column.id])}</div>
                                  </Tooltip>
                                </TableCell>
                              )
                            } else if (column.id === 'job_notes') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  <Tooltip
                                    title={
                                      <h5 class="tooltiptext" style={{ color: 'white' }}>
                                        {d[column.id]}
                                      </h5>
                                    }
                                    style={{ size: '15px' }}
                                  >
                                    <div>{this.splitNotes(25, d[column.id])}</div>
                                  </Tooltip>
                                </TableCell>
                              )
                            } else if (column.id === 'status_color') {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  style={{ backgroundColor: d[column.id] }}
                                >
                                  {'               '}
                                </TableCell>
                              )
                            } else {
                              return (
                                <TableCell
                                  key={`${column.id}${d.id}`}
                                  className={`app-table-cell-${d[column.id]}`}
                                  align={rightAlign(column)}
                                >
                                  {d[column.id]}
                                </TableCell>
                              )
                            }
                          })}
                        </TableRow>
                        {/* {() => getChildRow(isOpened, this.props.isActivityReport ? d.ac_log_id : d.id)} */}
                        {getChildRow(isOpened, d.id)}
                      </React.Fragment>
                    )
                  })}

                  {emptyRows > 0 && (
                    <TableRow key="emptyRow" style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={columns.length + 2} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow key="emptyRow">
                  <TableCell colSpan={columns.length + 2}>No Data Available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {props.pagging ? null : (
          <TablePagination
            rowsPerPageOptions={[25, 50, 75, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
      </Paper>
    )
  }
}

AppDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppDataTable)
