import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppButton from '../common/AppButton';
import AppDataTable from '../common/AppDataTable';
import Add from '@material-ui/icons/Add';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as AppConstants from '../../constants/app';
import axios from 'axios';
import ClientForm from './Form';
import ClientModel from './ClientModel';
import './client.scss';
import { CSVLink } from 'react-csv';

const styles = {
  root: {
    flexGrow: 1,
  },
  card: {
    marginBottom: '50px',
  },
  child: {
    backgroundColor: '#fff',
  },
}

class Clients extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    actual_data: [],
    selectedChild: [],
    pageHeading: 'All Clients',
    data: [],
    open: false,
    logopen: true,
    clients: [],
    empcount: {
      1: 'From 1 to 10',
      2: 'From 10 to 50',
      3: 'From 50 to 100',
      4: 'From 100 to 500',
      5: 'From 500 to 1000',
    },
    clientslist: [],
    users: [],
    account_managers: [],
    opened: [],
    isLoaded: false,
    auth_token: '',
    placement_filter: false,
    submittals_filter: false,
    contract_placement_filter: false,
    page: 1,
    searchvale: 'AAAALL',
    total_record: 0,
    recruiter_filter: 0,
    client_filter: 0,
    job_filter: 0,
    stage_filter: 0,
    total_sub: 0,
    total_inter: 0,
    total_send: 0,
    total_perma: 0,
    total_cont: 0,
    employe_filter: 0,
    total_sub_class: 0,
    total_inter_class: 0,
    total_send_class: 0,
    total_perma_class: 0,
    total_cont_class: 0,
    third_party: [],
    columns: [],
  }

  componentDidMount() {
    // TODO: Make it one call, as I couldn't merge that to a single endpoint due to short time
    this.getAuthToken()
    this.fetchClients()
    this.fetchUsers()
  }

  async getAuthToken() {
    let params = {
      username: 'admin@anewrecruit.com',
      password: 'Welcome123!!',
    }
    const response = await axios.post(`${AppConstants.API_BASE_URL}api-token-auth/`, params)
    if (response.data) {
      let token = response.data.token
      this.setState({
        auth_token: token,
      })
    }
  }

  async fetchUsers() {
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/user/`)
    if (response.data && response.data['successful']) {
      this.setState({ users: response.data.result })
    }
  }

  async fetchClients() {
    let th = this
    const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/client/`)
    if (response.data) {
      let clients = []
      let clientslist = []
      response.data.result.forEach(function (c) {
        clientslist.push(c)
        //if(c.detail.contact){
        if (c.detail.id) {
          //c.detail.contact.forEach(function(cnt){
          clients.push(th.formatIT(c.detail))
          //})
          //}
        }
      })

      //console.log('AAAAAA')
      //  console.log(JSON.stringify(clients));

      this.setState({ clients: clients, clientslist: clientslist, isLoaded: true })
    }
  }

  formatIT = clients => {
    console.log(clients)
    let _clients = { ...clients }
    _clients['name'] = clients.name
    _clients['address'] = ''
    _clients['client_short_name'] = clients.shortname
    //_clients['id']=contact.id
    _clients['id'] = clients.id
    _clients['main_phone_number'] = ''
    /*if(_clients.email[0]){
            _clients['phone_nm']=_clients.phone[0].phone
        }
        if(_clients.phone[0]){
          _clients['email_nm']=_clients.email[0].email
        }

        _clients.phone = _clients.phone.map(p => {
            return {
                id: p.id,
                value: p.phone,
                label: 'Phone'
            }
        });
        _clients.email = _clients.email.map(e => {
            return {
                id: e.id,
                value: e.email,
                label: 'Email'
            }
        });



        _clients['cmp_name']=clients.name*/
    //_clients['location']=clients.location
    _clients['city'] = clients.city
    _clients['state'] = clients.state
    _clients['zip_code'] = clients.zip
    _clients['country'] = clients.country
    _clients['status'] = clients.status
    _clients['company_infos'] = clients.company_info
    //_clients['products']=clients.products
    //_clients['status_display']=contact.status_display
    //_clients['notes']=contact.notes
    /*if(contact.created)
        _clients['created_']=this.formatToCalendarDate(contact.created)
        if(contact.created)
        _clients['created']=this.formatDate(contact.created)
        if(contact.modified)
        _clients['modified']=this.formatDate(contact.modified)*/
    _clients['employee_count_'] = this.state.empcount[clients.employee_count]
    _clients['website'] = clients.website
    _clients['linked_website'] = clients.linkedin
    _clients['contact_obj'] = 'Contact'
    _clients['no_of_jobs'] = ''
    let _jobsArr = []
    if (clients.job && clients.job.length) {
      _clients['no_of_jobs'] = clients.job.length
      clients.job.forEach(function (jb) {
        _jobsArr.push(jb.title)
      })
    }
    _clients['job_title'] = _jobsArr.toString()

    return _clients
  }

  formatToCalendarDate = date => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`

    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`

    return `${month}/${_date}/${date.getFullYear()}`
  }

  addJobsPopup = () => {
    this.setState({
      open: true,
    })
  }

  refreshClients = () => {
    this.fetchClients()
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
    this.fetchClients()
  }

  addNewCandidate = () => {
    const { clients } = this.state
    const _data = [...clients]
    if (!_data.find(c => c.is_new === true)) {
      _data.splice(0, 0, new ClientModel(true))
      this.setState({ clients: _data })
    }
  }

  formatDate = date => {
    if (typeof date === 'string') date = new Date(date)
    let month = date.getMonth() + 1
    if (month.toString().length === 1) month = `0${month}`
    let _date = date.getDate().toString()
    if (_date.length === 1) _date = `0${_date}`
    let hour = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    return `${date.getFullYear()}-${month}-${_date}`
  }

  get columns() {
    return [
      { id: 'id', numeric: false, disablePadding: false, label: 'Unique #' },
      { id: 'name', numeric: false, disablePadding: false, label: 'Client Name' },
      { id: 'address', numeric: false, disablePadding: false, label: 'Street Address' },
      { id: 'city', numeric: false, disablePadding: false, label: 'City' },
      { id: 'state', numeric: false, disablePadding: false, label: 'State' },
      { id: 'zip_code', numeric: false, disablePadding: false, label: 'Zip Code' },
      { id: 'country', numeric: false, disablePadding: false, label: 'Country' },
      { id: 'employee_count_', numeric: false, disablePadding: false, label: '# Employees' },
      { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
      { id: 'contact_obj', numeric: false, disablePadding: false, label: 'Contact Obj Ref#' },
      { id: 'website', numeric: false, disablePadding: false, label: 'Website' },
      { id: 'linked_website', numeric: false, disablePadding: false, label: 'LinkedIn' },
      { id: 'no_of_jobs', numeric: false, disablePadding: false, label: '# of Jobs' },
      { id: 'company_infos', numeric: false, disablePadding: false, label: 'Industry' },
      { id: 'job_title', numeric: false, disablePadding: false, label: 'job Title' },
    ]
  }

  csvHeaders = () => {
    return [
      { key: 'id', label: 'Unique #' },
      { key: 'name', label: 'Client Name' },
      { key: 'client_short_name', label: 'Client Short Name' },
      { key: 'address', label: 'Street Address' },
      { key: 'city', label: 'City' },
      { key: 'state', label: 'State' },
      { key: 'zip_code', label: 'Zip Code' },
      { key: 'country', label: 'Country' },
      { key: 'employee_count', label: '# Employees' },
      { key: 'status', label: 'Status' },
      { key: 'website', label: 'Website' },
      { key: 'linked_website', label: 'LinkedIn' },
      { key: 'no_of_jobs', label: '# of Jobs' },
      { key: 'company_infos', label: 'Industry' },
    ]
  }

  getChildRow = d => {
    let pad = { padding: '15px' }
    return (
      <TableRow key={`child-${d.id}`}>
        <TableCell colSpan="18" style={pad}>
          <ClientForm
            data={d}
            token={this.state.auth_token}
            clientslist={this.state.clientslist}
            refreshdata={this.refreshClients}
            users={this.state.users}
            newclint={false}
            close={this.handleClose}
          />
        </TableCell>
      </TableRow>
    )
  }

  getChildRows = () => {
    const {
      state: { clients },
      getChildRow,
    } = this
    return clients.map(d => {
      return {
        id: d.id,
        element: getChildRow(d),
      }
    })
  }

  getProgress = () => (this.state.isLoaded ? false : <LinearProgress />)

  render() {
    const {
      columns,
      state: { data, opened },
      props: { classes },
      getChildRows,
      getProgress,
    } = this
    return (
      <div className={classes.root}>
        <div className="filter-section">
          <div className="search-section" style={{ display: 'inline-flex' }}></div>

          <div style={{ marginTop: '20px' }}>
            <AppButton
              label="Add New Client"
              color="secondary"
              onClick={this.addNewCandidate}
              icon={{ component: Add, position: 'right' }}
            />
          </div>
        </div>

        <div className={classes.card}>
          {getProgress()}
          <h1>{this.state.pageHeading} </h1>
          <div style={{ position: 'absolute', top: '207px', left: '7%', zIndex: 9 }}>
            <AppButton
              label="Contacts"
              color="secondary"
              onClick={() => {
                window.location.href = '/contacts'
              }}
            />
          </div>

          <div style={{ position: 'absolute', top: '240px', right: '2%', zIndex: 9 }}>
            <CSVLink data={this.state.clients} headers={this.csvHeaders()} filename={'Clients.csv'}>
              Export Csv
            </CSVLink>
          </div>
          <AppDataTable
            selected={this.state.selectedChild}
            columns={columns}
            data={this.state.clients}
            opened=""
            label="Client List"
            childRows={this.getChildRows()}
          />
        </div>
      </div>
    )
  }
}

Clients.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Clients)
