import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import AppMoreDynamicTextField from '../../common/AppMoreDynamicTextField'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import axios from 'axios'
import * as AppConstants from '../../../constants/app'
import AppButton from '../../common/AppButton'
import AppTextField from '../../common/AppTextField'
import ClientModel from '../../Contacts/ClientModel'

const styles = theme => ({
  root: {
    padding: 0,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  header: {
    textAlign: 'center',
    marginTop: 0,
  },
  contactInfo: {
    display: 'flex',
    padding: '0 16px',
  },
  boxSpacing: {
    marginRight: '15px',
  },
  phone: {
    flex: 1,
  },
  email: {
    flex: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

class AddHiringManager extends React.Component {
  state = {
    data: new ClientModel(),
    _data: '',
  }

  componentWillMount() {
    //  this.setState({data: {...}})
    //this.fetchJobs(this.props.data);
  }

  // async fetchJobs(id) {
  //       const response = await axios.get(`${AppConstants.API_BASE_URL}recruit/job/`+id+`/`);
  //       if (response.data) {
  //         let data=this.formatIT(response.data.hiringmanager[0])
  //           this.setState({data: data},function(){
  //             //console.log(response.data.hiringmanager)
  //             //console.log('HIRING MANAGER')
  //               //console.log(this.state.data)
  //           })
  //       }
  //   };

  formatIT = clients => {
    //   let _clients = {...clients};
    // _clients.phone = _clients.phone.map(p => {
    //     return {
    //         id: p.id,
    //         value: p.phone,
    //         label: 'Phone'
    //     }
    // });
    // _clients.email = _clients.email.map(p => {
    //     return {
    //         id: p.id,
    //         value: p.email,
    //         label: 'Email'
    //     }
    // });
    // _clients.title = _clients.title.map(p => {
    //     return {
    //         id: p.id,
    //         value: p.title,
    //         label: 'Title'
    //     }
    // });
    //  return _clients
  }

  handleChange = e => {
    const { data } = this.state
    const _data = { ...data }
    const prop = e.target.name
    _data[prop] = e.target.value
    this.setState({ data: _data }, function() {
      //alert()
    })

    //  alert(this.state.data.id)
  }

  saveJobHiring = () => {
    let _data = { ...this.state.data }
    _data.email = _data.email.filter(e => e.value).map(e => e.value)
    _data.phone = _data.phone.filter(p => p.value).map(p => p.value)
    _data.title = _data.title
    _data.name = _data.name
    _data.client_id = this.props.data.client
    // _data.recruiter = 9;
    _data.type = 2
    _data.status = 3

    var headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${this.props.token}`,
    }

    axios
      .post(`${AppConstants.API_BASE_URL}recruit/candidate/contacts/`, _data, {
        headers: headers,
      })
      .then(response => {
        this.props.closeDialog()
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    const { props, handleChange } = this
    const { data } = this.state
    return (
      <React.Fragment>
        <Dialog
          open={props.open}
          maxWidth="lg"
          fullWidth={true}
          onClose={() => props.closeDialog('linkedIn')}
          aria-labelledby="dialog-moats"
          className={props.classes.margin}
        >
          <DialogTitle onClose={() => props.closeDialog('linkedIn')} id="simple-dialog-title">
            Contact
          </DialogTitle>

          <DialogContent className={props.classes.root}>
            <div className={props.classes.contactInfo + ' hiring_add_contact_pop'}>
              <div>
                <AppTextField
                  id="txtcontact"
                  label="Contact Person"
                  name="name"
                  fullWidth
                  data={data.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <AppTextField
                  id="txttitle"
                  label="Title"
                  name="title"
                  fullWidth
                  data={data.title}
                  onChange={handleChange}
                />
              </div>
              <div className={props.classes.phone}>
                <AppMoreDynamicTextField
                  id="txtPhone"
                  label="Phone"
                  name="phone"
                  fullWidth
                  data={data.phone}
                  onChange={handleChange}
                />
              </div>
              <div className={props.classes.email}>
                <AppMoreDynamicTextField
                  id="txtEmail"
                  label="Email"
                  name="email"
                  fullWidth
                  data={data.email}
                  onChange={handleChange}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <AppButton color="primary" label="SAVE" onClick={this.saveJobHiring} />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(AddHiringManager)
