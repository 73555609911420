import React from 'react'
import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import moment from 'moment'
import withStyles from '@material-ui/core/styles/withStyles'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import CategoryIcon from '@material-ui/icons/Category'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import Grid from '@material-ui/core/Grid'
import { formatMoney } from '../../utility'

const styles = {
  card: {
    borderRadius: '20px',
    margin: ' 20px 80px 20px 80px',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },
  cardcontent: {
    paddingBottom: 0,
  },
  title: {
    fontSize: 24,
    color: '#2aadde',
  },
  toolbarButtons: {
    marginRight: 12,
    float: 'right',
  },
  divider: {
    margin: `5px 0`,
  },
  bodyname: {
    fontSize: 16,
    margin: '15px 0',
    maxHeight: 110,
    overflow: 'hidden',
  },
  pos: {
    marginTop: 10,
    marginBottom: 0,
  },
  topbar: {
    padding: 10,
    display: 'inline-block',
  },
  typeText: {
    color: 'red',
  },
  dflex: {
    display: 'flex',
    alignItems: 'center',
  },
  dateText: {
    marginLeft: '10px',
    color: '#6f6a6a',
    fontSize: '17px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
}

const JobCard = (props) => {
  const { classes, job, goToJobDetails, selected, onApplyNow, viewJobDetails } = props

  return (
    <Grid item xs="12" sm="12" md="12">
      <Card className={clsx(classes.card, 'job-card', selected ? 'job-card-selected' : '')}>
        <CardContent className={classes.cardcontent}>
          <div>
            <div className={classes.dflex}>
              <Typography variant="h5" component="h2" className={classes.title}>
                <a className='job-link-title' onClick={() => goToJobDetails(job.id)}>
                  <span> {job.title} (ANEW{job.id}) </span> </a>
              </Typography>
              <span className={classes.dateText}>{moment(job.publish_at).fromNow()}</span>
            </div>
          </div>

          <div className='job-card-body'>
            <Typography variant="caption" component="p" className={classes.bodyname}>
              <div
                dangerouslySetInnerHTML={{ __html: `${job.long_description.replace(/(<([^>]+)>)/gi, '').substring(0, 300)}...` }}/>
            </Typography>
            <div className='job-card-bottom'>
              <div className={classes.flexCenter}>
                <div className={clsx(classes.flexCenter, 'job-type')}>
                  <Chip color='primary' label={job.employment_type}/>
                </div>

                <div className={clsx(classes.flexCenter, 'job-salary')}>
                  <LocalAtmIcon/>&nbsp;{formatMoney(job.min_salary)} - ${formatMoney(job.max_salary)}
                </div>
                {
                  job.hides.indexOf('category') == -1 &&
                  <div className={clsx(classes.flexCenter, 'job-category')}>
                    <CategoryIcon color="secondary"/>&nbsp;{job.category_name}
                  </div>
                }
              </div>
              {
                !viewJobDetails &&
                <div className='job-right-action'>
                  <div>
                    <Chip
                      color='secondary'
                      label='Apply now'
                      onClick={() => onApplyNow(job)}
                    />
                  </div>
                  <Chip
                    style={{ backgroundColor: '#0b9cf0' }}
                    onClick={() => goToJobDetails(job.id)}
                    label="View Details"
                    color="primary"
                  />
                </div>
              }

            </div>


          </div>
        </CardContent>
      </Card>
    </Grid>
  )

}

export default withStyles(styles)(JobCard)