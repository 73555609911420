import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AppTextField from '../common/AppTextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import AppButton from '../common/AppButton'
import AppSelect from '../common/AppSelect'
import SaveIcon from '@material-ui/icons/Save'
import Snackbar from '@material-ui/core/Snackbar'
import * as AppConstants from '../../constants/app'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import ClientModel from './ClientModel'
import Grid from '@material-ui/core/Grid'
import AppMoreDynamicTextField from '../common/AppMoreDynamicTextField'
import './client.scss'
import { sub_status ,stage_list} from '../../constants/status'


const styles = theme => ({
  root: { flexGrow: 1, backgroundColor: '#fff !important' },
  row: {
    backgroundColor: '#fff !important',
  },
  value: {
    borderBottom: 'none',
    verticalAlign: 'top',
    padding: '0 24px',
    width: '25%',
    backgroundColor: '#fff !important',
  },
  label: {
    fontWeight: 'bold',
    fontcolor: 'rgba(0, 0, 0, 0.54)',
    backgroundColor: '#fff !important',
  },
  relative: {
    position: 'relative',
    backgroundColor: '#fff !important',
  },
  actionButtons: {
    position: 'absolute',
    bottom: 0,
    right: '30px',
  },
  phone: {
    flex: 1,
    padding: '5px',
  },
  contactInfo: {
    display: 'flex',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: new ClientModel(),
      _data: '',
      clients: [{ value: -1, label: 'None' }],
      jobs: [{ value: -1, label: 'None' }],
      users: [{ value: -1, label: 'None' }],
      oldjob: '',
      changes: [],
      status_visa: [
        { value: 1, label: 'None' },
        { value: 2, label: 'Citizenship' },
        { value: 3, label: 'Green Card' },
        { value: 5, label: '1099' },
        { value: 6, label: 'Third party' },
        { value: 7, label: 'Other' },
      ],
      status: stage_list,

      // [
      //   { value: 1, label: 'Leads' },
      //   { value: 2, label: 'Submittals' },
      //   { value: 3, label: 'Sendout' },
      //   { value: 4, label: 'Interview' },
      //   { value: 5, label: 'Hired/Placements' },
      //   { value: 6, label: 'Rejected' },
      //   { value: 7, label: 'On Hold' },
      //   { value: 8, label: 'Closed' },
      //   { value: 8, label: 'Follow up' },
      // ],
      employment_type: [
        { value: 1, label: 'Contract Job' },
        { value: 2, label: 'Contract To Hire' },
        { value: 3, label: 'Permanent Job' },
      ],
      account_managers: [{ value: -1, label: 'None' }],
      third_parties: [{ value: -1, label: 'None' }],
      bill_rate: 0,
      from_date_month: '',
      expandDescription: {},
      dialogOpen: {
        moats: false,
        contact: false,
        linkedIn: false,
        client_contact: false,
        client_contact_hours: false,
        client_contact_hours_edit: false,
      },
      snackBar: {
        open: false,
        message: '',
        backgroundColor: '#1976d2',
      },
      isLoaded: true,
      to_date: '',
      from_date: '',
      total_hours: 0,
      hoursedit: [],
      total_bill: 0,
      total_client_bill: 0,
      candidate_name: '',
      send_signature_html: '',
      candidate_title: '',
      location: '',
      vacation: '',
      years: '',
      communication: '',
      availability: '',
      current_salary: '',
      pay_rate: '',
      start_data: false,
      interview_date: false,
      activity: '',
      notes: '',
      currentjob: '',
      hours: [],
      job_title: '',
      job_location: '',
      job_max_salary: '',
      job_min_salary: '',
      job_short_description: '',
      short_description: '',
      job_description: '',
      job_category: '',
      job_client: '',
      job_status: '',
      maxpayrate: 'Max Salary',
      minpayrate: 'Min Salary',
    }

    this.saveJob = this.saveJob.bind(this)
  }

  handleChange = e => {
    const { data } = this.state
    const _data = { ...data }
    const prop = e.target.name
    _data[prop] = e.target.value
    this.setState({ data: _data })
  }

  componentDidMount() {
    //alert(this.props.client)
  }

  componentWillMount() {
    let _data = { ...this.state.data }
    _data.id = this.props.data.id
    _data.name = this.props.data.name
    _data.recruiter = this.props.data.recruiter
    _data.title = this.props.data.title
    _data.phone = this.props.data.phone
    _data.email = this.props.data.email
    _data.status = this.props.data.status
    _data.notes = this.props.data.notes
    _data.products = this.props.data.products
    _data.created = this.props.data.created
    _data.modified = this.props.data.modified
    if (this.props.client) {
      _data.client_id = this.props.client
      // alert('ddd'+this.props.client)
    } else {
      _data.client_id = this.props.data.client_id
    }

    this.setState({ data: _data })
  }

  handleSaveCandidateResponse = (response, is_new) => {
    this.setState({ isLoaded: true })
    // let message = 'Something went wrong, please contact admin';
    let message = 'There is some problem. Please contact your administrator'
    if (response.data) {
      message = response.data['message']
      const { onCandidateUpdate } = this.props
      if (onCandidateUpdate) setTimeout(() => onCandidateUpdate(response.data.result, is_new))
    }
    this.openSnackBar('Job saved successfully', 'success-bar')
    this.props.close()
  }

  async saveJob() {
    let _data = { ...this.state.data }
    _data['id'] = this.props.data.id
    if (this.props.data.id) {
      //delete _data['client_id']
    }

    let temp = []
    _data['email'].forEach(function(x) {
      temp.push(x.value)
    })

    _data['email'] = temp

    temp = []
    _data['phone'].forEach(function(x) {
      temp.push(x.value)
    })

    _data['phone'] = temp

    this.setState({ isLoaded: false })
    var headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${this.props.token}`,
    }
    axios
      .post(`${AppConstants.API_BASE_URL}recruit/candidate/contacts/`, _data, { headers: headers })
      .then(response => {
        this.setState({ isLoaded: true })
        let message = 'Please check your inputs, and try again'
        this.openSnackBar(message, 'success-bar')
        this.props.refreshdata()
      })
      .catch(error => {
        this.setState({ isLoaded: true })
        let message = 'Please check your inputs, and try again'
        this.openSnackBar(message, 'success-bar')
        this.props.refreshdata()
      })
  }

  openSnackBar = (message, bgcolor) => {
    const { snackBar } = this.state
    snackBar.open = true
    snackBar.message = message
    snackBar.backgroundColor = bgcolor
    this.setState({ snackBar: snackBar })
  }

  closeSnackBar = () => {
    const { snackBar } = this.state
    snackBar.open = false
    this.setState({ snackBar: snackBar })
  }

  getSnackBarContent = () => {
    const {
      closeSnackBar,
      state: { snackBar },
    } = this
    return (
      <Snackbar
        className={snackBar.backgroundColor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={closeSnackBar}
        message={<span>{snackBar.message}</span>}
      />
    )
  }
  getProgress = () =>
    this.state.isLoaded ? false : <CircularProgress size={24} className={this.props.classes.buttonProgress} />

  render() {
    const {
      state: { data, clients },
      props: { classes },
      handleChange,
    } = this

    return (
      <React.Fragment>
        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item xs={8} sm={10}>
              {this.getSnackBarContent()}
              <Table className={classes.root}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Contact person"
                        value={data.name}
                        fullWidth
                        name="name"
                        onChange={handleChange}
                        helperText={this.state.job_title.length === 0 ? ' ' : 'This field is required'}
                        error={this.state.job_title.length === 0 ? false : true}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Title"
                        value={data.title}
                        fullWidth
                        name="title"
                        onChange={handleChange}
                        helperText={this.state.job_title.length === 0 ? ' ' : 'This field is required'}
                        error={this.state.job_title.length === 0 ? false : true}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppSelect
                        fullWidth
                        name="recruiter"
                        label="Recruiter"
                        value={data.recruiter}
                        onChange={handleChange}
                        items={this.props.users}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppSelect
                        fullWidth
                        name="status"
                        label="Stages"
                        value={data.status}
                        onChange={handleChange}
                        items={this.state.status}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.value}>
                      <AppTextField label="city" value={data.city} fullWidth name="city" onChange={handleChange} />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField label="state" value={data.state} fullWidth name="state" onChange={handleChange} />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField label="zip" value={data.zip} fullWidth name="zip" onChange={handleChange} />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        label="Country"
                        value={data.country}
                        fullWidth
                        name="country"
                        onChange={handleChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.value}>
                      <AppTextField
                        id="products"
                        label="Products"
                        name="products"
                        fullWidth
                        rows={4}
                        multiline={true}
                        value={data.products}
                        onChange={handleChange}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        id="notes"
                        label="Notes"
                        name="notes"
                        fullWidth
                        rows={4}
                        multiline={true}
                        value={data.notes}
                        onChange={handleChange}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppMoreDynamicTextField
                        id="txtEmail"
                        label="Email"
                        name="email"
                        fullWidth
                        data={data.email}
                        onChange={handleChange}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppMoreDynamicTextField
                        id="txtPhone"
                        label="Phone"
                        name="phone"
                        fullWidth
                        data={data.phone}
                        onChange={handleChange}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.value}>
                      <AppTextField
                        id="created"
                        label="Created"
                        name="created"
                        type="date"
                        fullWidth
                        value={data.created}
                        onChange={handleChange}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppTextField
                        id="modified"
                        label="Follow Up Date"
                        type="date"
                        name="modified"
                        fullWidth
                        value={data.modified}
                        onChange={handleChange}
                      />
                    </TableCell>

                    <TableCell className={classes.value}>
                      <AppButton
                        color="primary"
                        label="Save"
                        icon={{ component: SaveIcon, position: 'right' }}
                        onClick={this.saveJob}
                        disabled={!this.state.isLoaded}
                      />
                      {this.getProgress()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.value}>
                      {this.props.newclint ? (
                        <AppTextField label="Client Name" name="client_name" fullWidth onChange={handleChange} />
                      ) : this.props.hideclient ? (
                        ''
                      ) : (
                        <AppSelect
                          fullWidth
                          name="client_id"
                          value={data.client_id}
                          label="Clients"
                          onChange={handleChange}
                          items={this.props.clientslist}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ContactForm)
