import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import AppTextField from '../../common/AppTextField'
import AppButton from '../../common/AppButton'
import AppSelect from '../../common/AppSelect'
import Snackbar from '@material-ui/core/Snackbar'
import axios from 'axios'
import * as AppConstants from '../../../constants/app'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  root: {
    padding: 0,
    height: '320px',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  header: {
    textAlign: 'center',
    marginTop: 0,
  },
  contactInfo: {
    display: 'flex',
    padding: '0 16px',
    height: '300px',
  },
  phone: {
    flex: 1,
  },
  email: {
    flex: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

var divStyle = {
  'border-radius': '8px',
  'margin-right': '15px',
  border: '1px solid #dcdcdc',
  padding: 0,
  'padding-left': '7px',
}

var spanstyle = {
  color: 'red',
}

class AddHours extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      start_date: new Date(),
      end_date: new Date(),
    }
  }

  state = {
    start_date: '',
    end_date: '',
    hours: '',
    message: '',
    open: false,
    className: '',
    paydate: '',
    client_paid: '',
    candidate_paid: '',
    dateDisable: '',
    shoerrr: false,
  }

  closeSnackBar = () => {
    this.setState({ open: false })
  }
  /*
    componentDidMount() {
        // TODO: Make it one call, as I couldn't merge that to a single endpoint due to short time
        alert(this.props.dateDisable)
        this.setState({
          dateDisable:this.props.dateDisable
        })
    }*/

  handleChange = e => {
    if (this.state.hours == '') {
      this.setState({
        open: true,
        message: 'Please enter all required field(Start Date, End Date, Hours)',
        className: 'error-bar',
      })
    } else if (this.state.start_date == '') {
      this.setState({
        open: true,
        message: 'Please enter all required field(Start Date, End Date, Hours)',
        className: 'error-bar',
      })
    } else {
      const _hdata = {
        start_date: this.formatDate(this.state.start_date),
        end_date: this.formatDate(this.state.end_date),
        hours: this.state.hours,
        candidate: this.props.data.id,
        paydate: this.state.paydate,
        client_paid: this.state.client_paid,
        candidate_paid: this.state.candidate_paid,
      }
      let headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${this.props.token}`,
      }
      let candidateID = this.props.data.id
      let recruiter = this.props.data.recruiter_name
      let candidanm = this.props.data.name
      let hrs = this.state.hours
      let rev = ''
      if (this.props.hours) {
        let hid = this.props.hours.id
        rev = {
          candidate:
            hrs +
            ' Hours updated for ' +
            this.formatDate(this.state.start_date) +
            ' to ' +
            this.formatDate(this.state.end_date),
          id: candidateID,
          rec: recruiter,
          name: candidanm,
        }
        axios
          .put(`${AppConstants.API_BASE_URL}recruit/candidate/weekhours/` + hid + `/`, _hdata)
          .then(response => {
            this.setState({
              open: true,
              message: 'Hours updated Succesfully',
              className: 'success-bar',
            })
            let th = this
            setTimeout(function() {
              th.props.closeDialog('client_contact_hours_edit: false')
            }, 700)
          })
          .catch(error => {
            let message = 'Please check your inputs, and try again'
            this.setState({
              open: true,
              message: message,
              className: 'error-bar',
            })
          })
      } else {
        rev = {
          candidate:
            hrs +
            ' Hours Added for ' +
            this.formatDate(this.state.start_date) +
            ' to ' +
            this.formatDate(this.state.end_date),
          id: candidateID,
          rec: recruiter,
          name: candidanm,
        }
        axios
          .post(`${AppConstants.API_BASE_URL}recruit/candidate/weekhours/`, _hdata)
          .then(response => {
            this.setState({
              open: true,
              message: 'Hours Added Succesfully',
              className: 'success-bar',
            })
            let th = this
            setTimeout(function() {
              th.props.closeDialog('client_contact_hours: false')
            }, 700)
          })
          .catch(error => {
            let message = 'Please check your inputs, and try again'
            this.setState({
              open: true,
              message: message,
              className: 'error-bar',
            })
          })
      }

      axios
        .post(`${AppConstants.API_BASE_URL}recruit/candidate/logs/`, rev, {
          headers: headers,
        })
        .then(response => {})
        .catch(error => {})
    }
  }

  setHours = e => {
    if (e.target.value) {
      this.setState({
        hours: e.target.value,
      })
    }
  }

  setClientPay = e => {
    if (e.target.value) {
      this.setState({
        client_paid: e.target.value,
      })
    }
  }

  setCanPay = e => {
    if (e.target.value) {
      this.setState({
        candidate_paid: e.target.value,
      })
    }
  }

  setPayDate = e => {
    if (e.target.value) {
      this.setState({
        paydate: e.target.value,
      })
    }
  }

  handleChangedate = ({ startDate, endDate }) => {
    startDate = startDate || this.state.start_date
    endDate = endDate || this.state.end_date
    if (this.getDatesvalid(startDate, endDate)) {
      this.setState({ start_date: startDate, end_date: endDate })
      this.setState({ shoerrr: false })
    } else {
      this.setState({ shoerrr: true })
    }
  }

  handleChangeStart = startDate => this.handleChangedate({ startDate })

  handleChangeEnd = endDate => this.handleChangedate({ endDate })

  getDatesvalid = (startDate, endDate) => {
    let dates = true
    let fromd = startDate
    let to = endDate
    let check = this.props.dateDisable[0]
    let check2 = this.props.dateDisable[this.props.dateDisable.length - 1]

    if (check.getTime() <= to.getTime() && check.getTime() >= fromd.getTime()) {
      dates = false
    }

    if (check2.getTime() <= to.getTime() && check2.getTime() >= fromd.getTime()) {
      dates = false
    }
    return dates
  }
  formatDate = date => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  componentDidMount() {
    const { props } = this
    if (props.hours) {
      this.setState({
        start_date: new Date(props.hours.start_date),
        end_date: new Date(props.hours.end_date),
        hours: props.hours.hours,
        paydate: props.hours.paydate,
        client_paid: props.hours.client_paid,
      })
    }
  }

  render() {
    const { props, handleChange } = this
    const { data } = this.state
    return (
      <React.Fragment>
        <Dialog
          open={props.open}
          maxWidth="600"
          fullWidth={true}
          onClose={() => props.closeDialog('client_contact_hours: false')}
          aria-labelledby="dialog-moats"
          className={props.classes.margin}
        >
          <DialogTitle onClose={() => props.closeDialog('client_contact_hours: false')} id="simple-dialog-title">
            {' '}
            Add Hours
          </DialogTitle>

          <DialogContent className={props.classes.root}>
            <Snackbar
              className={this.state.className}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.open}
              autoHideDuration={700}
              message={<span>{this.state.message}</span>}
            />

            <h2 className={props.classes.header}>Add Hours</h2>
            <p className="date_aleadryp">
              {' '}
              <span className="date_aleadry"> </span> : Date Already Taken{' '}
            </p>

            <div className={props.classes.contactInfo}>
              <div className={props.classes.phone}>
                <fieldset style={divStyle}>
                  <legend>
                    <span>​ Start Date</span>
                  </legend>
                  <DatePicker
                    className="datepicker-ranage"
                    selected={this.state.start_date}
                    selectsStart
                    startDate={this.state.start_date}
                    excludeDates={this.props.dateDisable}
                    highlightDates={this.props.dateDisable}
                    dateFormat="MM/dd/yyyy"
                    endDate={this.state.end_date}
                    onChange={this.handleChangeStart}
                  />
                </fieldset>
              </div>
              <div className={props.classes.phone}>
                <fieldset style={divStyle}>
                  <legend>
                    <span>​ End Date</span>
                  </legend>
                  <DatePicker
                    className="datepicker-ranage"
                    selected={this.state.end_date}
                    selectsEnd
                    excludeDates={this.props.dateDisable}
                    highlightDates={this.props.dateDisable}
                    startDate={this.state.start_date}
                    dateFormat="MM/dd/yyyy"
                    endDate={this.state.end_date}
                    onChange={this.handleChangeEnd}
                    minDate={this.state.start_date}
                  />
                </fieldset>

                {this.state.shoerrr ? (
                  <p className="date_aleadryp" style={spanstyle}>
                    {' '}
                    Invalid Date range{' '}
                  </p>
                ) : null}
              </div>

              <div className={props.classes.email}>
                <AppTextField
                  label="Hours"
                  fullWidth
                  name="hours"
                  value={this.state.hours}
                  type="number"
                  onChange={this.setHours}
                />
              </div>
              <div className={props.classes.phone}>
                <AppTextField
                  label="Pay Date"
                  fullWidth
                  name="pay_date"
                  value={this.state.paydate}
                  type="date"
                  onChange={this.setPayDate}
                />
              </div>

              <div className={props.classes.phone}>
                <AppSelect
                  fullWidth
                  name="client_paid"
                  value={this.state.client_paid}
                  onChange={this.setClientPay}
                  label="Client Paid"
                  items={[
                    { value: 1, label: 'Yes' },
                    { value: 0, label: 'No' },
                  ]}
                />
              </div>

              <div className={props.classes.phone}>
                <AppSelect
                  fullWidth
                  name="client_paid"
                  value={this.state.candidate_paid}
                  onChange={this.setCanPay}
                  label="Candidate Paid"
                  items={[
                    { value: 1, label: 'Yes' },
                    { value: 0, label: 'No' },
                  ]}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <AppButton label="ADD HOURS" color="primary" onClick={this.handleChange} />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(AddHours)
