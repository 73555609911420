import React from 'react'
import moment from 'moment'
import './statsticsTable.scss'
import ContentLoader from 'react-content-loader'

const StatsticsTable = ({data = [], loading, gotoCandidate, payload, chartColor}) => {

  const handleRedirect = (client_id, stage_id) => {
    let {startDate, endDate} = payload.date
    if (startDate !== endDate) {
      startDate = moment(startDate).format('YYYY-MM-DD')
      endDate = moment(endDate).format('YYYY-MM-DD')
      gotoCandidate(`?client_id=${client_id}&stage_id=${stage_id}&from=${startDate}&to=${endDate}&location=dashboard`)
    } else {
      gotoCandidate(`?client_id=${client_id}&stage_id=${stage_id}&&location=dashboard`)
    }

  }

  if (loading)
    return (
      <ContentLoader style={{width: '100%'}}/>
    )

  return (
    <div className='s-table-container'>

      {
        <table className='s-table'>
          <thead>
          <tr>
            <th>Recruiters</th>
            <th>Leads</th>
            <th>Submitals</th>
            <th>Sendout</th>
            <th>Interview</th>
            <th>Closed</th>
            <th>Placements</th>
          </tr>
          </thead>
          <tbody>
          {
            data.map((d) => (
              <tr>
                <td>
                  <div className=''>{d.name}</div>
                </td>
                <td>
                  <div
                    onClick={() => handleRedirect(d.client_id, 1)}
                    className='round-div'
                    style={{ background: chartColor.leads }}
                  >
                    {d.leads.total}
                  </div>
                </td>
                <td>
                  <div
                    onClick={() => handleRedirect(d.client_id, 2)}
                    className='round-div'
                    style={{ background: chartColor.submittlas }}
                  >
                    {d.submitals.total}
                  </div>
                </td>
                <td>
                  <div
                    onClick={() => handleRedirect(d.client_id, 3)}
                    className='round-div'
                    style={{ background: chartColor.sendout }}
                  >
                    {d.sendout.total}
                  </div>
                </td>
                <td>
                  <div
                    onClick={() => handleRedirect(d.client_id, 4)}
                    className='round-div'
                    style={{ background: chartColor.interview }}
                  >
                    {d.interview.total}
                  </div>
                </td>
                <td>
                  <div
                    onClick={() => handleRedirect(d.client_id, 14)}
                    className='round-div'
                    style={{ background: chartColor.closed }}
                  >
                    {d.closed.total}
                  </div>
                </td>
                <td>
                  <div
                    onClick={() => handleRedirect(d.client_id, 5)}
                    className='round-div'
                    style={{ background: chartColor.permanantPlacement }}
                  >
                    {d.hired.total}
                  </div>
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>

      }
    </div>
  )
}

export default StatsticsTable